import { Component, OnInit } from '@angular/core';
import { TdLoadingService } from '@covalent/core/loading';
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { CustomService } from '@app/shared/service/custom/custom.service';
import { AuthService } from '@app/core/auth.service';
import { CommonConfig } from '@shared/common.config';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent implements OnInit {
  public dialogRef;
  public isSoftDelete = true;
  public isHardDelete = false;
  public commonConfig = CommonConfig;
  public parentObject;
  constructor(private readonly firestoreService: FirestoreService, private readonly customService: CustomService, public auth: AuthService,
    private readonly _loadingService: TdLoadingService) { }

  ngOnInit() {
  }

  /**
   * @description function used for submit delete request
   */
  async submitDelete() {
    this._loadingService.register();
    const authSubscribe = this.auth.user.subscribe(async (res) => {
      if (res) {
        if (this.auth.checkIsUserAdmin(res)) {
          const dataObject = { isSoftDelete: this.isSoftDelete, isHardDelete: this.isHardDelete };
          this.submitDeleteRequest(dataObject);
          this.customService.showSnackBarSuccess(this.commonConfig.successMessage.deleteRecord);
          this.dialogRef.close();
        } else {
          this.customService.showSnackBarError(this.commonConfig.validationMessage.deleteAction);
        }
      } else {
        this.customService.showSnackBarError(this.commonConfig.validationMessage.deleteAction);
      }
      this._loadingService.resolve();
      authSubscribe.unsubscribe();
    });
  }

  /**
   * @description Function used for submit delete action
   */
  async submitDeleteRequest(dataObject) {
    for (const objectId of this.parentObject.selectedIds) {
      try {
        await this.firestoreService.update(`${this.parentObject.collectionName}/${objectId}`, dataObject);
        this.parentObject.updateRecord();
        this.parentObject.selectedIds = [];
      } catch (err) {
        this.customService.showSnackBarError(this.commonConfig.validationMessage.accountDeleted);
      }
    }
  }

}
