import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TdDialogService } from '@covalent/core/dialogs';

import { Subscription } from 'rxjs';

import { AuthService } from '@app/core/auth.service';
import { FirestoreService } from '@app/shared/service/firestore/firestore.service';
import { DataStorageService } from '@app/shared/service/storage/data-storage.service';
import { CustomService } from '@app/shared/service/custom/custom.service';
import { VendorsService } from '@app/shared/service/vendors/vendors.service';
import { Vendor } from '@app/core/model/vendor';

@Component({
  selector: 'app-vendorsnav',
  templateUrl: './vendorsnav.component.html'
})
export class VendorsnavComponent implements OnInit, OnDestroy {
  @Input() commonConfig;
  uid: string;
  @Input() currentPage: string;
  userInfo;
  loading = true;
  isVendor: boolean;
  vendorId = '';
  myVendorId: string;
  isFrom: string;
  isAdmin: boolean;
  adminSubscription: Subscription;
  authSubscription: Subscription;
  customId: string;
  vendorStatus: string;
  isVendorOwner: boolean;
  iAmMemberOf: Partial<Vendor>;

  constructor(private router: Router,
    private route: ActivatedRoute, public auth: AuthService, public fs: FirestoreService, public storage: DataStorageService,
    private customService: CustomService, public vendorsService: VendorsService, private _dialogService: TdDialogService) {
    this.isVendorOwner = true;
  }

  ngOnInit() {
    this.customId = this.customService.guid();
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.vendorId = params['id'];
      }
    });
    this.authSubscription = this.auth.user.subscribe(res => {
      this.isFrom = this.route.snapshot.queryParams['from'] || '';
      this.userInfo = res;
      this.loading = false;
      if (this.userInfo && this.userInfo.uid) {
        this.uid = this.userInfo.uid;
        this.isVendor = this.userInfo.isVendor;
      }
      this.vendorSubscribe();
    });
    this.adminSubscription = this.storage.adminPanelStatus.subscribe(status => {
      if (status) {
        this.isAdmin = status.isAdmin;
      }
    });
  }
  async vendorSubscribe() {
    this.iAmMemberOf = await this.vendorsService.getVendorByUid(this.uid, true);
    if (this.iAmMemberOf['id'] && this.iAmMemberOf['isActive']) {
      if (this.uid !== this.iAmMemberOf['uid']) {
        this.isVendorOwner = false;
      }
      this.myVendorId = this.iAmMemberOf['id'];
      this.vendorStatus = this.iAmMemberOf['status'];
      if (!this.vendorId) {
        this.vendorId = this.myVendorId;
      }
    } else {
      const iAmOwnerOf = await this.vendorsService.getVendorByUid(this.uid);
      if (iAmOwnerOf['id']) {
        this.myVendorId = iAmOwnerOf['id'];
        this.vendorStatus = iAmOwnerOf['status'];
        if (!this.vendorId) {
          this.vendorId = this.myVendorId;
        }
      } else {
        this.isVendorOwner = false;
      }
    }
  }
  ngOnDestroy() {
    if (this.adminSubscription) {
      this.adminSubscription.unsubscribe();
    }
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  createVendor() {
    if (this.isVendor) {
      this._dialogService.openConfirm({
        message: this.commonConfig.popupAlertMessage.memberAlert,
        acceptButton: this.commonConfig.dialogService.accept,
        cancelButton: this.commonConfig.dialogService.cancel
      })
        .afterClosed().subscribe((accept: boolean) => {
          if (accept) {
            if (this.iAmMemberOf['id']) {
              this.vendorsService.removeDisabledVendorMember(this.uid, this.iAmMemberOf['id']);
              this.router.navigate([`${this.commonConfig.routerName.vendors}/${this.commonConfig.routerName.createvendor}`, this.customId, this.uid]);
            } else {
              this.router.navigate([`${this.commonConfig.routerName.vendors}/${this.commonConfig.routerName.createvendor}`, this.customId, this.uid]);
            }
          }
        });
    } else {
      this.router.navigate([`${this.commonConfig.routerName.vendors}/${this.commonConfig.routerName.createvendor}`, this.customId, this.uid]);
    }
  }

}
