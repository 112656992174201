import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unclaimed-vendors',
  templateUrl: './unclaimed-vendors.component.html'
})
export class UnclaimedVendorsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
