import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialogflow',
  templateUrl: './dialogflow.component.html'
})
export class DialogflowComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
