import { Component, OnInit } from '@angular/core';
import { CommonConfig } from '@shared/common.config';

@Component({
  selector: 'app-preview-publication',
  templateUrl: './preview-publication.component.html',
  styleUrls: ['./preview-publication.component.scss']
})
export class PublicationPreviewComponent implements OnInit {
  public dialogRef;
  public commonConfig = CommonConfig;
  public previewObj;
  constructor() { }

  ngOnInit() { }

}
