import { Injectable } from '@angular/core';
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { CommonConfig } from '@shared/common.config';
import { OrderByDirection, orderByDesc } from '@app/core/model/strings-type';
import { Category } from '@app/core/model/category';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private readonly commonConfig = CommonConfig;
  private readonly orderType: OrderByDirection = orderByDesc;
  private readonly arrayContains = 'array-contains';

  constructor(private readonly firestoreService: FirestoreService,
    private readonly http: HttpClient) { }

  /**
   * @description functions used fetch Categories.
   */
  fetchCategories(lastKey: string, pageSize = this.commonConfig.pageSize, data = { fieldName: this.commonConfig.collectionFieldName.createdAt, orderType: this.orderType }) {
    let categoryQueryRef = this.firestoreService.fetchCollection(CommonConfig.collectionName.categories).ref
      .orderBy(data.fieldName, data.orderType).limit(pageSize);
    if (lastKey) {
      categoryQueryRef = categoryQueryRef.startAfter(lastKey);
    }
    return categoryQueryRef.get();
  }

  /**
   * @description functions used get Category data based on category id.
   * @param catId category id
   */
  getCategoryData(catId: string) {
    return new Promise<Category>((resolve) => {
      const ref = this.firestoreService.doc$(`${CommonConfig.collectionName.categories}/${catId}`).subscribe((result: Category) => {
        ref.unsubscribe();
        resolve(result);
      });
    });
  }

  /**
   * @description functions used get Category data based on category name.
   * @param catName category name
   */
  getCategoryByName(catName: string) {
    return this.firestoreService.colWithIds$(this.commonConfig.collectionName.categories,
      (ref) => ref.orderBy('createdAt', 'desc')
        .where('name', '==', catName));
  }

  /**
   * @description functions to call suggestNewCategory cloud function.
   */
  suggestNewCategory(data) {
    const unsubscriber = this.http.post(`${environment.cloudEndPoint}suggestNewCategory`, data).subscribe(res => { unsubscriber.unsubscribe(); });
  }

  async fetchProjectCategoryCount(categoryKey: string, categoryName: string) {
    const projectCategorySubscriber = await this.firestoreService.fetchCollection(CommonConfig.collectionName.project).ref
      .where('category', this.arrayContains, { 'key': categoryKey, 'name': categoryName })
      .where('isActive', '==', true)
      .where('isArchive', '==', false)
      .where('isHardDelete', '==', false)
      .where('isSoftDelete', '==', false)
      .where('status', '==', CommonConfig.projectStatus.published)
      .get();
    let projectCategoryCount = CommonConfig.zero;
    projectCategorySubscriber.forEach(element => {
      projectCategoryCount++;
    });
    return Promise.resolve(projectCategoryCount);
  }

  async fetchUserCategoryCount(categoryKey: string, categoryName: string) {
    const userCategorySubscriber = await this.firestoreService.fetchCollection(CommonConfig.collectionName.users).ref
      .where('category', this.arrayContains, { 'key': categoryKey, 'name': categoryName })
      .where('isActive', '==', true)
      .where('isHardDelete', '==', false)
      .where('isSoftDelete', '==', false)
      .get();
    let userCategoryCount = CommonConfig.zero;
    userCategorySubscriber.forEach(element => {
      userCategoryCount++;
    });
    return Promise.resolve(userCategoryCount);
  }

  async vendorCategoryCount(categoryKey: string, categoryName: string, claimType: boolean) {
    const vendorCategorySubscriber = await this.firestoreService.fetchCollection(CommonConfig.collectionName.vendors).ref
      .where('category', this.arrayContains, { 'key': categoryKey, 'name': categoryName })
      .where('isArchive', '==', false)
      .where('isActive', '==', true)
      .where('isDeleted', '==', false)
      .where('isSoftDelete', '==', false)
      .where('isHardDelete', '==', false)
      .where('isRevised', '==', false)
      .where('isRejected', '==', false)
      .where('status', '==', CommonConfig.vendorStatus.published)
      .where('isClaim', '==', claimType)
      .get();
    let vendorCategoryCount = CommonConfig.zero;
    vendorCategorySubscriber.forEach(element => {
      vendorCategoryCount++;
    });
    return Promise.resolve(vendorCategoryCount);
  }

}
