import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { trackMessages } from '@app/shared/common.constants';
import { User } from '@app/core/model/user';

declare let window;
declare const analytics;
@Injectable({
  providedIn: 'root'
})
export class GanalyticsService {

  constructor() { }

  /**
   * @description Function to identify user for analytics event
   * @param {object} userInfo contain a values in object
   */
  public gaIdentifyUser(userInfo: User) {
    analytics.identify(userInfo['uid'], {
      name: userInfo['displayName'],
      email: userInfo['email'],
      isProjectSegment: userInfo['isProjectSegment'],
      isVendorSegment: userInfo['isVendorSegment']
    });
    window.intercomSettings = {
      app_id: environment.intercomAppId,
      custom_launcher_selector: '.intercom-launcher',
      name: userInfo['displayName'],
      email: userInfo['email'],
      user_id: userInfo['uid']
    };
  }

  public gaIdentifyRegisterUser(userInfo) {
    analytics.identify(userInfo['uid'], {
      name: userInfo['displayName'],
      email: userInfo['email'],
      user_hash: userInfo['userHashKey']
    });
  }

  /**
   * @description Function to track analytics event on site
   * @param {string} eventName contain a track messaage
   */
  public analyticsTrackEvent(eventName: string, projectId?: string, action?: string) {
    if (environment.isGAnalaytics) {
      if (action) {
        const publicProjectClickedLabel = {
          label: `Public project ${projectId} has been clicked ${action}`
        };
        Object.assign(trackMessages.publicProjectClicked, publicProjectClickedLabel);
      }
      if (projectId) {
        const customTrackMessageLabel = Object.assign(trackMessages[eventName], {label: trackMessages[eventName].label.replace('{projectId}', projectId)});
        analytics.track(trackMessages[eventName]['eventName'], customTrackMessageLabel);
      } else {
        analytics.track(trackMessages[eventName]['eventName'], trackMessages[eventName]);
      }
    }
  }

  /**
   * @description Function to track page for analytics on site
   * @param {string} title contain a current page title
   * @param {string} path contain a current page path
   */
  public analyticsPageTrack(title: string, path: string) {
    if (environment.isGAnalaytics) {
      analytics.page(title, {
        title,
        path
      });
    }
  }
}
