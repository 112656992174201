import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material';
// Config
import { CommonConfig } from '@shared/common.config';
// Service
import { ProjectListService } from '@shared/service/projects/project-list.service';

@Component({
  selector: 'app-toolbar-action-buttons',
  templateUrl: './toolbar-action-buttons.component.html'
})
export class ToolbarActionButtonsComponent implements OnInit {
  commonConfig = CommonConfig;
  viewType: string;
  @Input() currentPage;
  @Input() currentProjectObj;
  @Input() queryEncodeData;
  @ViewChild(MatMenuTrigger, { static: false }) menuTrigger: MatMenuTrigger;

  constructor(
    private readonly router: Router,
    public projectListService: ProjectListService,
  ) { }

  ngOnInit() {
    this.projectListService.projectFilterMenuTriggerStatus.subscribe(projectFilterMenuTriggerStatus => {
      if (projectFilterMenuTriggerStatus) {
        this.menuTrigger.closeMenu();
      }
    });
  }

  // Project navigation
  routerOnLink(type: string) {
    let routerText;
    if (this.currentPage === this.commonConfig.routerName.projects) {
      routerText = this.commonConfig.routerName.browse_project;
    } else {
      routerText = this.commonConfig.routerName.myprojects;
    }
    if (this.queryEncodeData) {
      this.router.navigate([`${this.commonConfig.routerName.projects}/${routerText}`, type], { queryParams: { data: this.queryEncodeData } });
    } else {
      this.router.navigate([`${this.commonConfig.routerName.projects}/${routerText}`, type]);
    }
  }

}
