import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatChipInputEvent, MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { map, startWith, debounceTime, delay } from 'rxjs/operators';
// Config
import { CommonConfig } from '@app/shared/common.config';
// Component
import { SuggestNewCategoryComponent } from '@shared/component/suggest-new-category/suggest-new-category.component';
// Service
import { ProjectsService } from '@shared/service/projects/projects.service';
import { CreateProjectService } from '@shared/service/projects/create-project.service';

@Component({
  selector: 'app-select-category',
  templateUrl: './select-category.component.html',
  styleUrls: ['./select-category.component.scss']
})
export class SelectCategoryComponent implements OnInit {
  @Input() categories = [];
  @Input() form;
  @Input() placeholder: string;
  @Input() parentObject;
  @Input() isSuggestCategory;
  @Input() isAllCategory;
  commonConfig = CommonConfig;
  isSuggestNewCat;
  // start material chip
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  categoryCtrl = new FormControl();
  filteredCategories: Observable<string[]>;
  allCategories = [];
  categoriesData = [];
  suggestedCatName;
  public categoryError = false;
  @ViewChild('selectCategoryInput', { static: false }) categoryInput: ElementRef;
  // end material chip
  constructor(
    private readonly router: Router,
    public dialog: MatDialog,
    private readonly projectsService: ProjectsService,
    public createProjectService: CreateProjectService,
  ) {
    if (!this.placeholder) {
      this.placeholder = this.commonConfig.placeholder.category;
    }
  }

  ngOnInit() {
    this.segmentCategorySubscriber();
    this.checkAddedRelatedCategory();
    const nullValue = null;
    this.filteredCategories = this.categoryCtrl.valueChanges.pipe(
      startWith(nullValue),
      map((category: string | null) => {
        if (category) {
          this.suggestedCatName = category;
          const filteredCat = this._filter(category);
          if (filteredCat.length) {
            return filteredCat;
          } else {
            return this.suggestNewCategory();
          }
        } else {
          return this.allCategories.slice();
        }
      })
    );
  }

  // Change category subscriber
  segmentCategorySubscriber() {
    this.projectsService.changeSegmentCategoryValue.subscribe(segmentValue => {
      if (segmentValue['segment']) {
        if (segmentValue['segment'] === this.commonConfig.segment.other) {
          const segmentOtherSubscribe = this.projectsService.findAllCategories().subscribe(categoryResult => {
            this.categorySegmentChangeData(categoryResult);
            segmentOtherSubscribe.unsubscribe();
          });
        } else {
          const segmentSubscribe = this.projectsService.findAllCategoriesBySegment(segmentValue['segment']).subscribe(categoryResult => {
            this.categorySegmentChangeData(categoryResult);
            segmentSubscribe.unsubscribe();
          });
        }
      } else {
        const segmentOtherSubscribe = this.projectsService.findAllCategories().subscribe(categoryResult => {
          this.categorySegmentChangeData(categoryResult);
          segmentOtherSubscribe.unsubscribe();
        });
      }

    });
    if (this.isAllCategory) {
      const categorySubscribe = this.projectsService.findAllCategories().subscribe(categoryResult => {
        this.categorySegmentChangeData(categoryResult);
        categorySubscribe.unsubscribe();
      });
    }
  }

  categorySegmentChangeData(categoryResult) {
    this.categoriesData = categoryResult;
    this.allCategories = this.categoriesData;
    this.categorySegmentChange();
  }

  suggestNewCategory() {
    if (this.isSuggestCategory) {
      return [{ name: this.commonConfig.validationMessage.suggestCategory }];
    } else {
      return [{ name: this.commonConfig.validationMessage.noCategoryFound }];
    }
  }

  /**
   * @description function used for filter category data using segment
   */
  categorySegmentChange() {
    if (this.form.get('segment')) {
      this.filterCategoryData(this.form.get('segment').value);
      this.form.get('segment').valueChanges.pipe(debounceTime(this.commonConfig.delay300)).subscribe((value) => {
        this.filterCategoryData(value);
        this.categories = [];
      });
    }
    this.form.get('category').setValue(this.categories);
  }

  /**
   * @description function used for filter category data using segment
   */
  filterCategoryData(segmentValue: string) {
    if (segmentValue && this.commonConfig.segment.all.key !== segmentValue) {
      this.allCategories = this.categoriesData.filter((catData) => {
        return catData.segment === segmentValue;
      });
    } else {
      this.allCategories = this.categoriesData;
    }
  }

  // start material chip
  add(event: MatChipInputEvent): void {
    const input = event.input;
    if (input) {
      input.value = '';
    }
    this.categoryCtrl.setValue(null);
  }

  remove(categoryKey: string, categoryName: string, isRelatedCategory: boolean): void {
    const index = this.categories.findIndex((x) => x.key === categoryKey);
    if (index >= 0) {
      this.categories.splice(index, this.commonConfig.one);
      this.form.get('category').setValue(this.categories);
      if (isRelatedCategory) {
        this.createProjectService.removeRelatedCategory.next({ 'key': categoryKey, 'name': categoryName });
      }
      this.setIsAddendums();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value) {
      const keyValue = event.option.value;
      if (!this.categoryExists(keyValue)) {
        this.categories.push({ key: keyValue, name: event.option.viewValue });
        this.form.get('category').setValue(this.categories);
        this.setIsAddendums();
      }
    } else {
      if (this.isSuggestCategory) {
        const dialogRef = this.dialog.open(SuggestNewCategoryComponent, { panelClass: 'publish-addendums' });
        const instance = dialogRef.componentInstance;
        instance.dialogRef = dialogRef;
        instance.name = this.suggestedCatName;
        instance.parentObject = this.parentObject;
      }
    }
    this.categoryInput.nativeElement.value = '';
    this.categoryCtrl.setValue(null);
  }

  categoryExists(key: string) {
    return this.categories.some(function (el) {
      return el.key === key;
    });
  }

  checkAddedRelatedCategory() {
    this.createProjectService.addRelatedCategoryStatus.subscribe(addRelatedCategoryStatus => {
      if (addRelatedCategoryStatus) {
        this.form.get('category').setValue(this.categories);
      }
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allCategories.filter((category) => category.name.toLowerCase().indexOf(filterValue) === 0);
  }

  // end material chip

  get getCategory() {
    return this.form.get('category');
  }

  clearCategoryText($event) {
    $event.target.value = '';
  }

  trackByFn(index: number) {
    return index;
  }

  /**
   * @description function used for set addendums change in update project
   */
  setIsAddendums() {
    if (this.parentObject && this.parentObject.setIsAddendums) {
      this.parentObject.setIsAddendums();
    }
  }
}
