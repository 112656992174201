import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
// Config
import { CommonConfig } from '@shared/common.config';
import { CommonUtils } from '@shared/common.util';
// Model
import { Project } from '@app/core/model/project';
// Service
import { AuthService } from '@app/core/auth.service';
import { CustomService } from '@shared/service/custom/custom.service';
import { ProjectListService } from '@shared/service/projects/project-list.service';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: [
    './list-view.component.scss',
    '../../../+projects/projects.component.scss',
    '../../../../assets/scss/grid-view.scss'
  ]
})
export class ListViewComponent implements OnInit {
  @Input() projects;
  @Input() loadingFinished = true;
  @Output() projectEmitter = new EventEmitter<Project>();
  @Input() currentPage: string;
  commonConfig = CommonConfig;
  customId: string;
  uid: string;
  private readonly authSubscription: Subscription;
  starClass = 'list-star';
  public currentURL: string;

  constructor(
    private readonly router: Router,
    private readonly auth: AuthService,
    private readonly customService: CustomService,
    public projectListService: ProjectListService
    ) {
    this.authSubscription = auth.user.subscribe(res => {
      this.uid = res['uid'];
      this.authSubscription.unsubscribe();
    });
    this.currentURL = new CommonUtils().makePrefixURL(this.router.url, 'list');
  }

  ngOnInit() {
    this.customId = this.customService.guid();
  }

  trackByFn(index: number) {
    return index;
  }
}
