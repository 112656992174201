import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
// Config
import { CommonConfig } from '@shared/common.config';

@Component({
  selector: 'app-member-participant-list',
  templateUrl: './member-participant-list.component.html'
})
export class MemberParticipantListComponent implements OnInit, OnDestroy {
  @Input() participant;
  @Input() navigateFrom;
  commonConfig = CommonConfig;
  constructor(
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

}
