import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/core/auth.guard';

import { RedirectInstagramComponent } from './+vendors/create-vendor/redirect-instagram/redirect-instagram.component';
import { RedirectURLComponent } from './+projects/redirect-ads/redirect.ads.components';
import { DialogflowComponent } from '@app/dialogflow/dialogflow.component';
import { TermsAndConditionsComponent } from '@app/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from '@app/privacy-policy/privacy-policy.component';
import { UnsubscribeReminderComponent } from '@app/shared/component/projects/unsubscribe-reminder/unsubscribe-reminder.component';


const CreateProjectModule = '@app/+projects/create-project/create-project.module#CreateProjectModule';
const ProjectDetailsModule = '@app/+projects/project-details/project-details.module#ProjectDetailsModule';
const VendorprofileModule = '@app/+vendors/vendor-profile/vendor-profile.module#VendorprofileModule';
const CreateVendorModule = '@app/+vendors/create-vendor/create-vendor.module#CreateVendorModule';
const ProfileModule = '@app/+profile/profile.module#ProfileModule';

const routes: Routes = [
  { path: 'dashboard', loadChildren: '@app/+dashboard/dashboard.module#DashboardModule', canActivate: [AuthGuard] },
  { path: 'deals', loadChildren: '@app/+deals/deals.module#DealsModule', canActivate: [AuthGuard] },
  { path: 'settings', loadChildren: '@app/+settings/settings.module#SettingsModule', canActivate: [AuthGuard] },
  { path: 'settings/billing', loadChildren: '@app/+settings/settings.module#SettingsModule', canActivate: [AuthGuard] },
  { path: 'projects', loadChildren: '@app/+projects/projects.module#ProjectsModule' },
  { path: 'projects/browse-project/:type', loadChildren: '@app/+projects/projects.module#ProjectsModule' },
  {
    path: 'projects/create-project/:customId', loadChildren: CreateProjectModule,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard/create-project/:customId', loadChildren: CreateProjectModule,
    canActivate: [AuthGuard]
  },
  {
    path: 'projects/edit-project/:customId', loadChildren: CreateProjectModule,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/project-detail/edit-project/:customId/:uid', loadChildren: CreateProjectModule,
    canActivate: [AuthGuard]
  },
  {
    path: 'projects/browse-project/project-detail/:id', loadChildren: ProjectDetailsModule
  },
  {
    path: 'dashboard/project-detail/:id', loadChildren: ProjectDetailsModule
  },
  {
    path: 'projects/my-project/project-detail/:id', loadChildren: ProjectDetailsModule
  },
  {
    path: 'projects/project-favorite/project-detail/:id', loadChildren: ProjectDetailsModule
  },
  {
    path: 'projects/archive-project/project-detail/:id', loadChildren: ProjectDetailsModule
  },
  {
    path: 'projects/my-project/:type', loadChildren: '@app/+projects/my-projects/my-projects.module#MyProjectsModule'
  },
  {
    path: 'projects/archive-project', loadChildren: '@app/+projects/archived-project/archived-project.module#ArchivedProjectModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'projects/project-favorite', loadChildren: '@app/+projects/favorite-project/favorite-project.module#FavoriteProjectModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'terms-service', loadChildren: '@app/+projects/bid-terms-service/terms-service.module#BidTermsServiceModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'vendors/browse-vendor/:type', loadChildren: '@app/+vendors/vendors.module#VendorsModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'vendors/vendor-profile/:id', loadChildren: VendorprofileModule,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/manage-bids/vendor-profile/:id', loadChildren: VendorprofileModule,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/manage-bids/project-detail/vendor-profile/:projectid/:uid/:id', loadChildren: VendorprofileModule,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/vendor-profile/:id', loadChildren: VendorprofileModule,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard/vendor-profile/:id', loadChildren: VendorprofileModule,
    canActivate: [AuthGuard]
  },
  {
    path: 'vendors/browse-vendor/vendor-profile/:id', loadChildren: VendorprofileModule,
    canActivate: [AuthGuard]
  },
  {
    path: 'vendors/my-vendor/vendor-profile/:id', loadChildren: VendorprofileModule,
    canActivate: [AuthGuard]
  },
  { path: 'vendors/my-vendor', loadChildren: '@app/+vendors/my-vendor/my-vendor.module#MyvendorModule', canActivate: [AuthGuard] },
  { path: 'redirect-instagram', component: RedirectInstagramComponent, canActivate: [AuthGuard] },
  {
    path: 'vendors/create-vendor/:customId/:id', loadChildren: CreateVendorModule,
    canActivate: [AuthGuard]
  },
  {
    path: 'vendors/vendor-profile/edit-vendor/:customId/:id', loadChildren: CreateVendorModule,
    canActivate: [AuthGuard]
  },
  {
    path: 'vendors/unclaimed/:customId', loadChildren: CreateVendorModule
  },
  /* Comment for future use
  {
    path: 'vendors/vendor-feature/:type/:id/:view', loadChildren: '@app/+vendors/products-services/products-services.module#ProductsServicesModule',
    canActivate: [AuthGuard]
  },*/
  {
    path: 'vendors/vendor-profile/:type/:id/:view', loadChildren: '@app/+vendors/products-services/products-services.module#ProductsServicesModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'vendors/vendor-profile/:type/:id/:view', loadChildren: '@app/+vendors/products-services/products-services.module#ProductsServicesModule',
    canActivate: [AuthGuard]
  },
  { path: 'search/:type', loadChildren: '@app/+search/search.module#SearchModule', canActivate: [AuthGuard] },
  { path: 'signup', loadChildren: '@app/+signup/signup.module#SignupModule' },
  { path: 'login', loadChildren: '@app/+login/login.module#LoginModule' },
  { path: 'forgot', loadChildren: '@app/+forgotpassword/forgot.module#ForgotModule', canActivate: [AuthGuard] },
  { path: 'verify-reset', loadChildren: '@app/+forgotpassword/forgot.module#ResetPasswordModule'},
  { path: 'profile', loadChildren: ProfileModule, canActivate: [AuthGuard] },
  { path: 'admin', loadChildren: '@app/+admin/admin.module#AdminModule', canActivate: [AuthGuard] },
  {
    path: 'admin/project-detail/:id/:uid', loadChildren: ProjectDetailsModule,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/manage-bids/project-detail/:id/:uid', loadChildren: ProjectDetailsModule,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/create-project/:customId/:uid', loadChildren: CreateProjectModule,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/vendors/vendor-profile/:id/:uid', loadChildren: VendorprofileModule,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/vendors/vendor-profile/:id', loadChildren: VendorprofileModule,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/create-vendor/:customId/:id', loadChildren: CreateVendorModule,
    canActivate: [AuthGuard]
  },
  // for create un-claim vendor profile
  {
    path: 'admin/create-vendor/:customId', loadChildren: CreateVendorModule,
    canActivate: [AuthGuard]
  },
  { path: 'admin/profile', loadChildren: ProfileModule, canActivate: [AuthGuard] },
  { path: 'admin/manage-vendors/profile', loadChildren: ProfileModule, canActivate: [AuthGuard] },
  { path: '', loadChildren: '@app/+landing/landing.module#LandingModule', canActivate: [AuthGuard] },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'dev', loadChildren: '@app/+dev/dev.module#DevModule' },
  { path: 'ad/:adsId', component: RedirectURLComponent, canActivate: [AuthGuard] },
  { path: 'dialogflow', component: DialogflowComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'project-unsubscribe/:oid', component: UnsubscribeReminderComponent },
  { path: 'kiwidev', loadChildren: '@app/+kiwidev/kiwidev.module#KiwidevModule' },
  { path: '**', redirectTo: '/dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
