import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomValidator } from '@app/shared/validator/custom.validator';
import { CommonConfig } from '@app/shared/common.config';
import { OrganizationConfig } from '@app/shared/organization.config';
import { FirestoreService } from '@app/shared/service/firestore/firestore.service';
import { UserActivityService } from '@shared/service/user-activity/user-activity.service';
import { CustomService } from '@app/shared/service/custom/custom.service';
import { OrganizationService } from '@shared/service/organization/organization.service';
import { User } from '@app/core/model/user';
import { UserInformation } from '@app/core/model/user-information';
import { OrganizationMembers } from '@app/core/model/organization-members';

@Component({
  selector: 'app-create-organization',
  templateUrl: './create-organization.component.html'
})
export class CreateOrganizationComponent implements OnInit {
  public commonConfig = CommonConfig;
  public organizationConfig = OrganizationConfig;
  public createOrgForm: FormGroup;
  public isSubmit = false;
  public dialogRef;
  public userInfo: User;
  private userBriefDetails: UserInformation;
  public organizationMember: Partial<OrganizationMembers>;

  constructor(private readonly formBuilder: FormBuilder, private readonly customService: CustomService,
    public fs: FirestoreService, public readonly userActivityService: UserActivityService,
    public readonly organizationService: OrganizationService) { }

  ngOnInit() {
    this.setUserBriefDetails();
    this.buildFrom();
  }

  /**
   * @description Function used for create reactive form for create organization
   */
  buildFrom() {
    this.createOrgForm = this.formBuilder.group({
      name: ['', CustomValidator.required],
      domainEmail: [this.getEmailDomain(), CustomValidator.required],
      uid: [this.userInfo.uid, CustomValidator.required],
      actionBy: [''],
      userInformation: [null],
      status: [this.organizationConfig.orgStatus.pending],
      isRejected: [false],
      isActive: [true],
      isSoftDelete: [false],
      isHardDelete: [false]
    });
    this.createOrgForm.get('userInformation').setValue(this.userBriefDetails);
  }

  /**
   * @description Function used for get email domain
   */
  getEmailDomain = () => this.userInfo.email.replace(/.*@/, '');

  /**
   * @description Function used for set brief user details
   */
  setUserBriefDetails() {
    const userBriefDetails = {
      uid: this.userInfo.uid,
      displayName: this.userInfo.displayName || '',
      email: this.userInfo.email || '',
      photoUrl: (this.userInfo.profilePhoto && this.userInfo.profilePhoto.url) ? this.userInfo.profilePhoto.url : ''
    };
    this.userBriefDetails = userBriefDetails;
  }

  /**
   * @description Function used for submit the create organization form data
   */
  submit() {
    this.isSubmit = true;
    this.fs.add(`${this.commonConfig.collectionName.organizations}`, this.createOrgForm.value).then(async (res) => {
        this.saveUserOrganizationActivity(res['id']);
        const orgDetail = {id: res['id'], name: this.createOrgForm.value.name};
        const orgData = {
          orgId: res['id'],
          organization: orgDetail,
          uid: this.userInfo.uid,
          userInformation: this.userBriefDetails,
          role: this.organizationConfig.roles.superAdmin.key,
          status: this.organizationConfig.orgMemberStatus.approved,
          request: this.organizationConfig.orgMemberRequest.joined,
          invitedBy: '',
          isOwner: true,
        };
        this.organizationService.createOrganizationMember(orgData).then(data => {
          this.updateOrgInUser(orgData);
          this.isSubmit = false;
          this.customService.showSnackBarSuccess(this.commonConfig.successMessage.createOrganization);
          this.dialogRef.close();
        }).catch(err => {
          this.customService.showSnackBarError(this.commonConfig.validationMessage.someError);
        });
      }).catch(err => {
        this.customService.showSnackBarError(this.commonConfig.validationMessage.someError);
      });
  }

  /**
   * @description Function used for save the activity for the organization
   * @param {string} organizationId contain the uniqe id of the organization
   */
  saveUserOrganizationActivity(organizationId: string) {
    const dataObject = { uid: this.userInfo.uid, activityType: this.commonConfig.systemActivityType.createOrganization,
      ownerId: this.userInfo.uid, activityBelongsTo: this.commonConfig.belongTo.organization, organizationId};
    this.userActivityService.saveUserAccountActivity(dataObject);
  }

  /**
   * @description Function used to update organization details associated with users in user collection
   * @param data contains data of organization member.
   */
  updateOrgInUser(data: Partial<OrganizationMembers>) {
    this.organizationService.updateOrgInUser(data);
  }

}
