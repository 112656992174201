import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
// Config
import { CommonConfig } from '@shared/common.config';
// Component
import { WalkthroughmodalComponent } from '@shared/component/projects/walkthroughmodal/walkthroughmodal.component';
import { WalkthroughrequestmodalComponent } from '@shared/component/projects/walkthroughrequestmodal/walkthroughrequestmodal.component';
// Model
import { ProjectWalkthrough } from '@app/core/model/project-walkthrough';
// Service
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { ProjectsService } from '@shared/service/projects/projects.service';
import { ProjectActivityService } from '@shared/service/project-activity/project-activity.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectWtService {
  private readonly commonConfig = CommonConfig;
  constructor(
    public dialog: MatDialog,
    public fs: FirestoreService,
    public projectsService: ProjectsService,
    private readonly projectActivityService: ProjectActivityService,
  ) { }

  getWalkthroughScheduler(projectId: string) {
    return this.fs.colWithIds$(CommonConfig.collectionName.projectwalkthrough, (ref) => ref.orderBy('createdAt', 'desc')
      .where('projectId', '==', projectId).where('isDeleted', '==', false).where('isActive', '==', true));
  }

  updateProjectVendorWtRequest(projectId: string, isVendorRequestTimeslot: boolean) {
    const vendorRequestTimeObj = {
      isVendorRequestTimeslot
    };
    this.fs.update(`${this.commonConfig.collectionName.project}/${projectId}`, vendorRequestTimeObj);
  }

  manageVendorRequestTimeslot(wtSchedulerResults) {
    let isVendorRequestedTimeslot = false;
    if (wtSchedulerResults.length) {
      wtSchedulerResults.forEach((wtSchedulerElement) => {
        if (wtSchedulerElement.requestVendorId && wtSchedulerElement.requestVendorId.length > CommonConfig.zero && wtSchedulerElement.from.toDate() > new Date().getTime()) {
          isVendorRequestedTimeslot = true;
        }
      });
    } else {
      isVendorRequestedTimeslot = false;
    }
    return isVendorRequestedTimeslot;
  }

  manageVendorRequestExpireTimeslot(wtSchedulerResults) {
    let isVendorRequestedExpireTimeslot = false;
    if (wtSchedulerResults.length) {
      wtSchedulerResults.forEach((wtSchedulerElement) => {
        if (wtSchedulerElement.from) {
          if (wtSchedulerElement.from.toDate() > new Date().getTime()) {
            isVendorRequestedExpireTimeslot = true;
          }
          if (wtSchedulerElement.from.toDate() < new Date().getTime()) {
            isVendorRequestedExpireTimeslot = true;
          }
        }
      });
    } else {
      isVendorRequestedExpireTimeslot = false;
    }
    return isVendorRequestedExpireTimeslot;
  }

  openDialog($events, walkthrough, type: string, thisObj) {
    $events.stopPropagation();
    let dialogRef;
    if (type === 'claim') {
      dialogRef = this.dialog.open(WalkthroughmodalComponent);
    } else {
      dialogRef = this.dialog.open(WalkthroughrequestmodalComponent);
    }
    const instance = dialogRef.componentInstance;
    instance.dialogRef = dialogRef;
    instance.data = walkthrough;
    instance.vendorId = thisObj.bidderVendorId;
    instance.projects = thisObj.projects;
    instance.authUid = thisObj.authUid;
    dialogRef.afterClosed().subscribe(result => { });
  }

  cancelWalkthroughScheduler($events, projectWalkthrough: Partial<ProjectWalkthrough>, thisObj) {
    $events.stopPropagation();
    const requireData = {
      authUid: thisObj.authUid,
      action: CommonConfig.walkthroughStatus.canceled,
      vendorId: thisObj.bidderVendorId,
      projects: thisObj.projects
    };
    this.projectActivityService.cancelWalkthroughScheduler(projectWalkthrough, requireData);
    const allMentors = [thisObj.authUid];
    const activityDataObject = {
      uid: thisObj.authUid, projectId: projectWalkthrough.projectId,
      walkthroughId: projectWalkthrough.id, walkthroughTime: projectWalkthrough.from, activityType: CommonConfig.projectActivityType.walkthroughCancelled, allMentors
    };
    this.projectsService.saveProjectActivity(activityDataObject);
  }
}
