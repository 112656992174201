import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { FirestoreService } from '@app/shared/service/firestore/firestore.service';
import * as _moment from 'moment';
import { CommonConfig } from '@shared/common.config';
const moment = (_moment)['default'] ? (_moment)['default'] : _moment;
@Injectable({
  providedIn: 'root'
})
export class ChargebeeService {
  constructor(public firestoreService: FirestoreService, private http: HttpClient) {
  }

  /**
   * @description function to hit get chargebee api for subscriptions plans list
   */
  getSubscriptionPlanList() {
    return this.http.get(`${environment.cloudEndPoint}getChargebeePlans`);
  }

  /**
   * @description function to create new checkout subscription for user
   * @param {object} detail contain a user information for create subscriptions
   */
  checkoutNewSubscription(detail: object) {
    return this.http.post(`${environment.cloudEndPoint}checkoutNewSubscription`, detail);
  }

  /**
   * @description function to update existing subscription for user
   * @param {object} detail contain a user information for create subscriptions
   */
  checkoutExistingSubscription(detail: object) {
    return this.http.post(`${environment.cloudEndPoint}checkoutExistingSubscription`, detail);
  }

  /**
   * @description function to get particular hosted page data
   * @param {string} hostId contain a hostid for create subscriptions
   */
  retriveHostPageData(hostId: string) {
    return this.http.post(`${environment.cloudEndPoint}retriveHostPageData`, hostId);
  }

  /**
   * @description function to open chargeebee checkout self portal for payment
   * @param {object} hostedPageDetail contain a unique hostid for selected plan
   * @param {any} that contain a reference on component function
   */
  openCheckoutPopup(hostedPageDetail: object, that) {
    that.chargebeeIns.openCheckout({
      hostedPage: () => {
        // required
        // This function should return a promise, that will resolve a hosted page object
        // If the library that you use for making ajax calls, can return a promise,
        // you can directly return that.
        return new Promise(function (resolve, reject) {
          // This sampe response should be fetched as a result of an ajax call
          const response = {
            'id': hostedPageDetail['id'],
            'type': hostedPageDetail['type'],
            'url': hostedPageDetail['url'],
            'state': hostedPageDetail['state'],
            'embed': true,
            'created_at': hostedPageDetail['created_at'],
            'expires_at': hostedPageDetail['expires_at']
          };
          resolve(response);
        });
      },
      loaded: () => {
        // Optional
        // will be called once checkout page is loaded
      },
      error: () => {
        // Optional
        // will be called if the promise passed causes an error
      },
      step: () => {
        // Optional
        // will be called for each step involved in the checkout process
      },
      success: (hostedPageId) => {
        // Optional
        // will be called when a successful checkout happens.
        const responseData = this.retriveHostPageData(hostedPageId);
        const checkoutData = responseData.subscribe(res => {
          const hostedPageData = res['data'];
          const subscriptionDetail = hostedPageData['hosted_page']['content']['subscription'];
          const customerDetail = hostedPageData['hosted_page']['content']['customer'];
          const subscriptionPaylod = {
            subscription: {
              subscription_id: subscriptionDetail['id'],
              customer_id: subscriptionDetail['customer_id'],
              plan_id: subscriptionDetail['plan_id'],
              // Note:- we are considring in_trail status as a active status of subscription
              status: subscriptionDetail['status'] === CommonConfig.planStatus.inTrial ? CommonConfig.planStatus.active : subscriptionDetail['status'],
              hostedPageId: hostedPageData['hosted_page']['id']
            },
            companyName: customerDetail['company'],
            phone: customerDetail['phone']
          };
          that.submit(true, subscriptionPaylod);
          checkoutData.unsubscribe();
        });
      },
      close: () => {
        // Optional
        // will be called when the user closes the checkout modal box
      }
    });
  }

  /**
   * @description function to get particular plan detail
   * @param {string} planId contain a unique plan id
   */
  retrivePlanDetail(planId: string) {
    return this.http.post(`${environment.cloudEndPoint}retrivePlanDetail`, planId);
  }

  /**
   * @description function get invoice history of customer
   * @param {object} detail contain a customer detail
   */
  getInvoiceHistory(detail: object) {
    return new Promise((resolve, reject) => {
      const invoiceHistoryData = this.http.post(`${environment.cloudEndPoint}getInvoiceHistory`, detail).subscribe(res => {
        invoiceHistoryData.unsubscribe();
        if (res) {
          const invoiceData = res['data']['list'];
          const response = invoiceData.map(ele => {
            ele.invoice.date = moment(ele.invoice.date * CommonConfig.thousand).format(CommonConfig.dateFormat[0]);
            ele.invoice.due_date = moment(ele.invoice.due_date * CommonConfig.thousand).format(CommonConfig.dateFormat[0]);
            ele.invoice.status = ele.invoice.status === CommonConfig.paymentStatus.payment_due
              ? CommonConfig.paymentStatus.paymentDue
              : ele.invoice.status === CommonConfig.paymentStatus.not_paid ? CommonConfig.paymentStatus.notPaid : ele.invoice.status;
            ele.invoice.amount_paid = ele.invoice.amount_paid / CommonConfig.hundred;
            return ele;
          });
          resolve(response);
        } else {
          resolve([]);
        }
      }, err => {
        resolve([]);
      });
    });
  }

  /**
   * @description function to hit get chargebee api for subscriptions plans list
   * @param {object} detail contain a user information for create subscriptions
   */
  createSubscription(detail: object) {
    return this.http.post(`${environment.cloudEndPoint}createSubscriptions`, detail);
  }

  /**
   * @description function to hit get chargebee api for customer detail
   * @param {object} detail contain a user information for update customer detail
   */
  updateCustomerDetail(detail: object) {
    return this.http.post(`${environment.cloudEndPoint}updateCustomerDetail`, detail);
  }

  /**
   * @description function to get particular addon detail
   * @param {string} addonId contain a unique addon id
   */
  retriveAddonDetail(addonId: string) {
    return new Promise((resolve, reject) => {
      const addonDetail = this.http.post(`${environment.cloudEndPoint}retriveAddonDetail`, addonId).subscribe(res => {
        addonDetail.unsubscribe();
        if (res) {
          const addonData = res['data']['addon'];
          resolve(addonData);
        } else {
          resolve([]);
        }
      }, err => {
        resolve([]);
      });
    });
  }

  /**
   * @description function to update subscriptions with addon
   * @param {object} detail contain a user information for update subscriptions
   */
  addAddonInProject(detail: object) {
    return this.http.post(`${environment.cloudEndPoint}addAddonInProject`, detail);
  }
}
