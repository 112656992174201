import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TdDialogService } from '@covalent/core/dialogs';
import { MatDialog } from '@angular/material';
// Config
import { CommonConfig } from '@shared/common.config';
import { OrganizationConfig } from '@shared/organization.config';

@Injectable({
  providedIn: 'root'
})
export class CheckProjectService {
  private readonly ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  private readonly commonConfig = CommonConfig;
  public organizationConfig = OrganizationConfig;

  constructor(
    private readonly router: Router,
    private readonly _location: Location,
    public dialog: MatDialog,
    private readonly _dialogService: TdDialogService,
  ) { }

  // Function used for check is project type is express
  isExpressTypeProject(projectType) {
    return projectType === this.commonConfig.projectType.express;
  }

  addDayInDate(date: Date, day: number) {
    date = new Date(date);
    date.setDate(date.getDate() + day);
    return date;
  }

  openConfirm(createProjectObj, message: string): void {
    this._dialogService.openConfirm({
      message,
      acceptButton: this.commonConfig.dialogService.accept,
      cancelButton: this.commonConfig.dialogService.cancel
    })
      .afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((accept: boolean) => {
        if (accept) {
          if (createProjectObj.isAdmin) {
            if (createProjectObj.projectId) {
              this.router.navigate([`/${this.commonConfig.routerName.admin}/${this.commonConfig.routerName.projectdetail}`,
              createProjectObj.projectId, createProjectObj.uid], { queryParams: { from: this.commonConfig.routerName.manageprojects, orgId: createProjectObj.orgId } });
            } else {
              this.router.navigate([`/${this.commonConfig.routerName.admin}/${this.commonConfig.routerName.manageprojects}`],
                { queryParams: { orgId: createProjectObj.orgId } });
            }
          } else {
            this.backClicked();
          }
        }
      });
  }

  backClicked() {
    this._location.back();
  }

}
