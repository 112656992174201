import { Component, OnInit, OnChanges, OnDestroy, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TdMediaService } from '@covalent/core/media';
import { Router, ActivatedRoute } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { switchMap, map, takeUntil } from 'rxjs/operators';
// Config
import { CommonConfig } from '@shared/common.config';
// Component
import { NeedProjectRevisionComponent } from '@shared/component/projects/need-project-revision/need-project-revision.component';
// Model
import { User } from '@app/core/model/user';
import { Project } from '@app/core/model/project';
import { ProjectBid } from '@app/core/model/project-bid';
// Service
import { AuthService } from '@app/core/auth.service';
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { AlgoliaService } from '@shared/service/algolia/algolia.service';
import { ProjectListService } from '@shared/service/projects/project-list.service';
import { OrganizationService } from '@shared/service/organization/organization.service';
import { ProjectsService } from '@shared/service/projects/projects.service';
import { TdLoadingService } from '@covalent/core/loading';

@Component({
  selector: 'app-admin-project-listing',
  templateUrl: './admin-project-listing.component.html',
  styleUrls: ['./admin-project-listing.component.scss', '../../../../assets/scss/admin-portal.scss']
})
export class AdminProjectListingComponent implements OnInit, OnChanges, OnDestroy {
  @Input() currentPage: string;
  @Output() orgEvent = new EventEmitter<string>();
  @Output() multipleCheckedEvent = new EventEmitter<boolean>();
  @Output() selectedIdEvent = new EventEmitter();
  @Output() deleteProjectEvent = new EventEmitter<boolean>();
  @Output() duplicateProjectEvent = new EventEmitter<string>();
  @Output() scrapeVendorEvent = new EventEmitter();
  @Output() scrapeVendorDownloadEvent = new EventEmitter();
  public queryEncodeData;
  public querydncodeData = {};
  commonConfig = CommonConfig;
  loadingFinished = false;
  public page: number;
  public nbPages: number;
  public nbHits: number;
  pageSize = this.commonConfig.bidPageSigeForAdmin;
  public algoliaIndices = this.commonConfig.algoliaIndices.projectSearch;
  private readonly ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  projectFilterData;
  projects = [];
  public isMultipleChecked = false;
  public selectedIds = [];
  public projectColumnSelectedData = [];
  hideme = {};
  lowestBid = [];
  public orgId = '';
  public orgName = '';
  public isAdmin = true;
  bidStatus: string;
  bidList: Partial<ProjectBid[]> = [];
  public authUser: Partial<User>;
  projectColumnData: Array<object> = this.commonConfig.projectColumn;

  constructor(
    public media: TdMediaService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public fs: FirestoreService,
    private readonly as: AlgoliaService,
    public auth: AuthService,
    private readonly projectListService: ProjectListService,
    public readonly organizationService: OrganizationService,
    public projectsService: ProjectsService,
    public dialog: MatDialog,
    private readonly _loadingService: TdLoadingService
  ) { }

  ngOnInit() {
    this.getQueryParams();
    this.projectListService.deleteProjectRecordValue.subscribe(isUpdateRecord => {
      if (isUpdateRecord['isUpdateRecord']) {
        this.updateRecord();
      }
    });

    /**
     * if project imgae updated
     */
    this.projectsService.projectsUpdated.subscribe((data) => {
      if (data) {
        this._loadingService.register();
        setTimeout(() => {
          this.isMultipleChecked = false;
          this.getQueryParams();
          this.resetProjectData();
          this.getProjectList();
          this._loadingService.resolve();
        }, this.commonConfig.delay3000);
      }
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  // used for get filter data from child to parent
  getProjectFilterData(filterFormData) {
    this.projectFilterData = filterFormData;
  }

  /**
   * @description get url query params
   */
  getQueryParams() {
    this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
      this.orgId = params['orgId'] || '';
      this.orgEvent.emit(this.orgId);
      if (params['projectdata']) {
        this.queryEncodeData = this.route.snapshot.queryParams['projectdata'];
        this.querydncodeData = JSON.parse(decodeURI(this.route.snapshot.queryParams['projectdata']));
        if (this.querydncodeData['algoliaIndices']) {
          this.algoliaIndices = this.querydncodeData['algoliaIndices'];
        }
        this.checkUserPermission();
        this.resetProjectData();
        this.getProjectList();
      }
    });
  }

  /**
   * @description Function used for set organization name for global admin
   */
  async getSetOrgName() {
    const orgDetail = await this.organizationService.getOrgDetailByOrgId(this.orgId);
    this.orgName = (orgDetail && orgDetail['name']) ? orgDetail['name'] : '';
  }

  /**
   * @description function for check loged in user permission for admin access
   */
  async checkUserPermission() {
    const user = await this.auth.currentUser();
    this.authUser = user;
    let orgMember = {};
    if (user && this.orgId) {
      if (this.auth.checkIsUserAdmin(this.authUser)) {
        this.getSetOrgName();
      } else {
        orgMember = await this.organizationService.getOrgMemberDetailByUidAndOrgid(this.orgId, this.authUser.uid);
        this.orgName = (orgMember && orgMember['organization']) ? orgMember['organization']['name'] : '';
      }
    }
    if (!this.auth.checkIsUserAdmin(this.authUser) && !this.organizationService.isProjectPermissions(orgMember)) {
      this.router.navigate([`/${this.commonConfig.routerName.dashboard}`]);
    }
  }

  resetProjectData() {
    this.projects = [];
    this.page = this.nbHits = this.nbPages = 0;
    this.loadingFinished = true;
  }

  getProjectList() {
    this.loadingFinished = true;
    this.querydncodeData['orgId'] = this.orgId;
    this.querydncodeData['currentPage'] = this.currentPage;
    this.as.searchProjects(this, this.querydncodeData, (err, result) => {
      if (result.hits && result.hits.length) {
        if (this.currentPage === this.commonConfig.routerName.managebids) {
          const promises = result.hits.map(async (project: Project) => {
            return this.getLowestBid(project.id, project.bidStatus).then(res => {
              project.lowbid = res && { bidAmount: res['bidAmount'], createdAt: res['createdAt'], status: res['status'] } || null;
              return project;
            });
          });
          Promise.all(promises)
            .then(results => {
              this.projects = [...this.projects, ...results];
              this.page = result.page;
              this.nbHits = result.nbHits;
              this.nbPages = result.nbPages;
              this.loadingFinished = false;
            }).catch();
        } else {
          result.hits.forEach(async (data: Project) => {
            data['is_checked'] = false;
            const category = data['category'] || [];
            const radius = data['radious'] || this.commonConfig.defaultRadius;
            const filterDataObject = { type: this.commonConfig.belongTo.vendor, category, location: data['location'], radious: radius };
            data['unCliamVendor'] = await this.as.getUnclaimedVendorCount(filterDataObject);
            data['cliamVendor'] = await this.as.getClaimedVendorCount(filterDataObject);
          });
          this.projects = [...this.projects, ...result.hits];
          this.loadingFinished = false;
        }
      } else {
        this.loadingFinished = false;
      }
      this.page = result.page;
      this.nbHits = result.nbHits;
      this.nbPages = result.nbPages;
    });
  }

  onScroll() {
    if (!this.loadingFinished && this.page < this.nbPages) {
      this.page = this.page + 1;
      this.getProjectList();
    }
  }

  sortData(algoliaIndices?: string) {
    if (algoliaIndices) {
      this.algoliaIndices = algoliaIndices;
    }
    let routerSubString = this.commonConfig.routerName.manageprojects;
    this.querydncodeData['algoliaIndices'] = this.algoliaIndices;
    this.queryEncodeData = encodeURI(JSON.stringify(this.querydncodeData));
    if (this.currentPage === this.commonConfig.routerName.scrapeVendors) {
      routerSubString = this.commonConfig.routerName.scrapeVendors;
    } else if (this.currentPage === this.commonConfig.routerName.managebids) {
      routerSubString = this.commonConfig.routerName.managebids;
    }
    this.router.navigate([`/${this.commonConfig.routerName.admin}/${routerSubString}`],
      { queryParams: { projectdata: this.queryEncodeData, orgId: this.orgId } });
  }

  public getLowestBid(projectid: string, status: string) {
    if (status && status === 'accepted') {
      return new Promise((resolve) => {
        this.fs.colWithIds$(this.commonConfig.collectionName.projectbid,
          (ref) => ref.where('projectId', '==', projectid)
            .where('status', '==', 'accepted').where('isSoftDelete', '==', false).limit(1))
          .subscribe(response => {
            resolve(response[0]);
          });
      });
    } else {
      return new Promise((resolve) => {
        this.fs.colWithIds$(this.commonConfig.collectionName.projectbid,
          (ref) => ref.where('projectId', '==', projectid)
            .where('isRejected', '==', false).where('isSoftDelete', '==', false).orderBy('bidAmount', 'asc').limit(1))
          .subscribe(response => {
            resolve(response[0]);
          });
      });
    }
  }

  trackByFn(index: number, project: Project) {
    return project.id;
  }

  projectColumnVisibilityData(data) {
    this.projectColumnData = data;
  }

  /**
   * @description check/uncheck manage of project list
   * @param {number} projectIndex containt the project index
   * @param {boolean} isChecked contain the boolean flag
   */
  onProjectChecked(isChecked: boolean, projectIndex?: number) {
    if (typeof projectIndex === 'number') {
      this.projects[projectIndex]['is_checked'] = isChecked;
    } else {
      this.projects = this.projects.map(e => {
        e['is_checked'] = isChecked;
        return e;
      });
    }
    this.selectedIds = this.projects.filter(x => x['is_checked']).map(e => e['id']);
    this.isMultipleChecked = (this.selectedIds.length > 0);
    this.selectedIdEvent.emit(this.selectedIds);
    this.multipleCheckedEvent.emit(this.isMultipleChecked);
  }

  /**
   * @description function used for delete projects
   * @param {boolean} isMultipleDelete contain true or false value
   */
  deleteProjects(projectIndex?: number) {
    if (typeof projectIndex === 'number') {
      this.selectedIds.push(this.projects[projectIndex]['id']);
      this.selectedIdEvent.emit(this.selectedIds);
    }
    this.deleteProjectEvent.emit(true);
  }

  /**
   * @description update the current array list
   */
  updateRecord() {
    this.isMultipleChecked = false;
    if (this.projects.length === this.selectedIds.length) {
      this.resetProjectData();
      this.getProjectList();
    } else {
      this.projects = this.projects.filter(x => !this.selectedIds.includes(x['id']));
    }
  }

  updateProjectStatus(type: string, project: Project) {
    this.projectListService.updateProjectStatus(type, project, this.authUser);
  }

  needRevision(project: Project) {
    const dialogRef = this.dialog.open(NeedProjectRevisionComponent, {
      width: '360px'
    });
    const instance = dialogRef.componentInstance;
    instance.dialogRef = dialogRef;
    instance.project = project;
    instance.authUser = this.authUser;
  }

  /**
   * @description function used for duplicate project
   * @param {boolean} projectId contain project id
   */
  duplicateProject(projectId: string) {
    this.duplicateProjectEvent.emit(projectId);
  }

  /**
   * @description function used for scrape google places, for the category of that project and a radius set by the user
   */
  onScrapeVendors(project: Project) {
    this.scrapeVendorEvent.emit(project);
  }

  onScrapeVendorsDownload(project: Project) {
    this.scrapeVendorDownloadEvent.emit(project);
  }

  // Function used for show less bid information
  isShowLessBidInfo = (project: Project) => {
    return project.projectType === this.commonConfig.projectType.sealedbid && (new Date().getTime() < Number(project.bidEndAt));
  }

  // Show/Hide bid data
  showHide(project: Project, id: string) {
    this.bidStatus = project['bidStatus'];
    const bidsSubscribe = this.fs.colWithIds$(this.commonConfig.collectionName.projectbid,
      (ref) => ref.limit(this.pageSize)
        .orderBy('createdAt', 'desc')
        .where('isSoftDelete', '==', false)
        .where('projectId', '==', id)).pipe(switchMap(bids => {
          if (!bids.length) {
            return [{}];
          } else {
            const res = bids.map(vendor => {
              return this.fs.doc$(`${this.commonConfig.collectionName.vendors}/${vendor['vendorId']}`).pipe(map(vendorInfo =>
                Object.assign(vendor, { 'vendorInfo': vendorInfo })));
            });
            return combineLatest(res);
          }
        })).subscribe((response: Partial<ProjectBid[]>) => {
          this.bidList = response;
          bidsSubscribe.unsubscribe();
          this.hideMeTrueFalse(id);
        });
  }

  // Function used for set hide true and false
  hideMeTrueFalse(id: string) {
    if (this.hideme[id]) {
      this.hideme[id] = false;
    } else {
      Object.keys(this.hideme).forEach(h => {
        this.hideme[h] = false;
      });
      this.hideme[id] = true;
    }
  }

}
