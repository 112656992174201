import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonConfig } from '@shared/common.config';
import { AuthService } from '@app/core/auth.service';
import { User } from '@app/core/model/user';
import { TdDialogService } from '@covalent/core/dialogs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  private user: User;
  private readonly commonConfig = CommonConfig;

  constructor(public auth: AuthService, private readonly _dialogService: TdDialogService,
    private readonly router: Router) { }

  /**
   * @description function to init subscription service.
   */
  init() {
    this.getUserInfo();
  }
  /**
   * @description get user's data
   */
  async getUserInfo() {
    const authSubscription = this.auth.user.subscribe(res => {
      this.user = res;
      authSubscription.unsubscribe();
    });
  }

  /**
   * @description function to check if user has basic plan.
   */
  public isBasicPlan = () => {
    return (this.user && this.user.subscription) ? (this.user.subscription.plan_id === CommonConfig.subscriptionPlanType.basic) : false;
  }

  /**
   * @description function to check if user has enterprise plan.
   */
  public isEnterpriseUser = () => {
    return ((this.user && this.user.subscription) ? (this.user.subscription.status === CommonConfig.subscriptionStatus.active &&
      this.user.subscription.plan_id === CommonConfig.subscriptionPlanType.enterprise) : false);
  }

  /**
   * @description function to check if user has premium plan.
   */
  public isPremiumUser = () => {
    return ((this.user && this.user.subscription) ? (this.user.subscription.status === CommonConfig.subscriptionStatus.active &&
      this.user.subscription.plan_id === CommonConfig.subscriptionPlanType.premium) : false);
  }

  /**
   * @description function to check if user has premium or enterprise plan.
   */
  public isPremumOrEnterpriseUser = () => {
    return (this.isPremiumUser() || this.isEnterpriseUser());
  }

  /**
   * @description function used to open popup to upgrade plan.
   */
  upgradePlanPopUp(alertMessage: string) {
    this._dialogService.openConfirm({
      message: alertMessage,
      acceptButton: this.commonConfig.dialogService.upgrade,
      cancelButton: this.commonConfig.dialogService.cancelService
    })
    .afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        const routerLink = `${this.commonConfig.routerName.settings}/${this.commonConfig.routerName.billing}/${this.commonConfig.routerName.upgradePlan}`;
        this.router.navigate([]).then(result => {  window.open(routerLink, '_blank'); });
      }
    });
  }

}
