export const APPLICATION_NOTIFICATION = {
    projectActivity: {
        create: { text: 'created a new project' },
        update: { text: 'edited a project' },
        view: { text: 'viewed a project' },
        bidplace: { text: 'placed a bid' },
        bidupdate: { text: 'updated the bid' },
        bidaccept: { text: 'accepted the bid on this project.' },
        quoteplace: { text: 'placed a quote' },
        quoteupdate: { text: 'updated the quote' },
        quoteaccept: { text: 'accepted the quote on this project.' },
        projectrevision: { text: 'created the project revision request' },
        projectaccept: { text: 'accepted the project request' },
        projectreject: { text: 'rejected the project request' },
        addmember: { text: 'added you as a member of this project.' },
        removemember: { text: 'member removed from project' },
        addcomment: { text: 'added a comment' },
        createprojectbyadmin: { text: 'created project for you.' },
        assignproject: { text: 'assigned this project to you.' },
        quoterevisionrequest: { text: 'requested for quote revision' },
        bidrevisionrequest: { text: 'requested for bid revision' },
        bidreject: { text: 'rejected bid on this project.' },
        quotereject: { text: 'rejected quote on this project.' },
        createprojectbyuser: { text: 'created new project.' },
        updaterevisionrequest: { text: 'updated the revison request.' },
        replycomment: { text: 'replied to a comment on this project' },
        walkthroughBooked: { text: 'booked walkthrough of the project'},
        walkthroughRequested: { text: 'requested walkthrough of the project'},
        walkthroughCancelled: { text: 'cancelled walkthrough of the project'},
        invitevendor: { text: 'invited to bid on project' },
        addendums: { text: 'new addendums added'},
        expressProjectReminder: { text: 'fill missing fields in express project' },
        favorite: { text: 'starred this project.' },
        unfavorite: { text:  'removed this project from starred list.' }
    },
    vendorActivity: {
        vendorrevision: { text: 'created the vendor revision request' },
        vendorprofileaccept: { text: 'accepted the vendor profile.' },
        vendorprofilereject: { text: 'rejected the vendor profile.' },
        assignvendorprofile: { text: 'assigned this vendor profile to you.' },
        createvendorbyadmin: { text: 'created this vendor profile for you.' },
        addmembervendorprofile: { text: 'added you as a member of this profile.' },
        createvendorbyuser: { text: 'created new vendor profile.' },
        createClaimVendorProfile: { text: 'claimed this vendor profile.' },
        commentvendorprofile: { text: 'commented on this vendor.' },
        updaterevisionrequestvendor: { text: 'updated the revision request.' },
        vendorprofilepending: { text: 'vendor profile status moved in pending.'},
        vendorprofiledisable: { text: 'disabled the vendor profile.'},
        vendorStarred: { text: '(Admin) starred vendors for you.'},
        vendorUnStarred: { text: '(Admin) unstarred vendors for you.'}
    },
    marketingActivity: {

    },
    systemActivity: {
        createOrganization: { text: 'created new organization' },
        acceptOrganization: { text: 'approved organization request' },
        rejectOrganization: { text: 'rejected organization request ' },
        inviteUserToOrganization: { text: 'invited to organization ' },
        joinUserToOrganization: { text: 'sent join organization request ' },
        joinOrgRequestApproved: { text: 'approved your request to join organization.' },
        joinOrgRequestRejected: { text: 'rejected your request to join organization.' },
        leaveOrganizationReq: {text: 'sent leave organization request '},
        leaveOrgRequestApproved: { text: 'approved your request to leave organization.' },
        leaveOrgRequestRejected: { text: 'rejected your request to leave organization.' },
    }
};
