import { Injectable } from '@angular/core';
// Config
import { CommonConfig } from '@shared/common.config';
// Model
import { Project } from '@app/core/model/project';
import { User } from '@app/core/model/user';
// Service
import { ProjectsService } from '@shared/service/projects/projects.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectEmailService {
  commonConfig = CommonConfig;
  constructor(
    private readonly projectsService: ProjectsService,
  ) { }
  /**
     * @description Functions used for send email to project owner for project accept and reject status
     */
  async sendAcceptRejectEmailToProjectOwner(project, dataObj, authUser) {
    if (project['userInformation'] && project['userInformation']['email']) {
      const userName = project['userInformation']['displayName'] ? project['userInformation']['displayName'] :
        project['userInformation']['email'];
      const data = {
        toEmail: project['userInformation']['email'],
        userName,
        objectId: project['id'],
        belongTo: this.commonConfig.belongTo.project,
        title: project['title'],
        subject: dataObj['subject'],
        templateId: dataObj['templateId']
      };
      this.projectsService.sendEmailData(data);
    }
    if (authUser['uid']) {
      const dataObject = {
        uid: authUser['uid'],
        projectId: project['id'],
        activityType: dataObj['activityType']
      };
      this.projectsService.saveProjectActivity(dataObject, project);
    }
  }

  /*Send email to project member on add and remove project member*/
  async sendEmailOnAddAndRemoveProjectMember(user: Partial<User>, projectId: string, project: Project, isMemberAdded: boolean) {
    if (user && user['email'] && projectId) {
      let subject = this.commonConfig.emailSubject.removeProjectMember;
      let templateId = this.commonConfig.emailTemplates.removeProjectMember;
      if (isMemberAdded) {
        subject = this.commonConfig.emailSubject.addProjectMember;
        templateId = this.commonConfig.emailTemplates.addProjectMember;
      }
      const userName = user['displayName'] ? user['displayName'] : user['email'];
      const data = {
        toEmail: user['email'],
        userName,
        objectId: projectId,
        belongTo: this.commonConfig.belongTo.project,
        title: project['title'],
        subject,
        templateId
      };
      this.projectsService.sendEmailData(data);
    }
  }
}
