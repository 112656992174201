import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import * as algoliasearch from 'algoliasearch';
import * as algoliasearchHelper from 'algoliasearch-helper';

import { CommonConfig } from '@shared/common.config';

const organizationSearch = environment.algoliya.algolia_index.organizationSearch;
const client = algoliasearch(environment.algoliya.applicationID, environment.algoliya.apiKey);

@Injectable({
  providedIn: 'root'
})
export class OrgAlgoliaService {

  constructor() { }

  /**
   * @description Function used for set default organization serach configuration
   * @param {string} algoliaIndices containt the organization algolia indices
   */
  defaultHelperConfigure(algoliaIndices = organizationSearch) {
    const helper = algoliasearchHelper(client, algoliaIndices, {
      facets: ['name', 'status', 'isRejected', 'isActive', 'isSoftDelete'],
      disjunctiveFacets: ['uid']
    });
    helper.setQueryParameter('restrictSearchableAttributes', ['name']);
    helper.addFacetRefinement('isActive', true);
    helper.addFacetRefinement('isSoftDelete', false);
    return helper;
  }

  /**
   * @description Function used for search organization
   * @param {object} data contain the filter data value
   * @param {object} helper contain the search helper refrence
   */
  async searchOrganization(data, callback: Function) {
    const helper = this.defaultHelperConfigure(data.algoliaIndices);
    this.addStatusFilter(data, helper);
    if (!data.skipPage) {
      helper.setQueryParameter('hitsPerPage', CommonConfig.pageSize);
    }
    if (data.page) {
      helper.setQueryParameter('page', data.page);
    }
    helper.search();
    helper.client.clearCache();
    helper.on('result', function (result: object) {
      callback(null, result);
    });
  }

  /**
   * @description Functions used for add status filter
   * @param {object} data contain the filter data value
   * @param {object} helper contain the search helper refrence
   */
  addStatusFilter(data, helper) {
    if (data.keyword) {
      helper.setQuery(data.keyword);
      helper.setQueryParameter('optionalWords', data.keyword);
    }
    if (data.status) {
      helper.addFacetRefinement('status', data.status);
    }
  }

}
