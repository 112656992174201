import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
// Config
import { CommonConfig } from '@shared/common.config';
// Validator
import { CustomValidator } from '@shared/validator/custom.validator';
// Service
import { CustomService } from '@shared/service/custom/custom.service';

@Component({
  selector: 'app-need-revision-form',
  templateUrl: './need-revision-form.component.html'
})
export class NeedRevisionFormComponent implements OnInit, OnDestroy {
  @Input() isSubmit;
  @Input() revisionType: string;
  @Input() actionDetail;
  @Output() closeDialogEvent = new EventEmitter();
  @Output() revisionSubmitEvent = new EventEmitter();
  commonConfig = CommonConfig;
  revisionForm: FormGroup;
  constructor(
    private readonly formBuilder: FormBuilder,
    public customService: CustomService,
  ) { }

  ngOnInit() {
    this.buildFrom();
  }

  buildFrom() {
    this.revisionForm = this.formBuilder.group({
      description: ['', CustomValidator.required]
    });
    if (this.revisionType === 'vendor') {
      CustomValidator.reSetFormValidation(this.revisionForm.controls.description, !!this.actionDetail.descriptionAllow);
    }

  }

  get getDescription() {
    return this.revisionForm.get('description');
  }

  closeDialog() {
    this.closeDialogEvent.emit();
  }

  submit() {
    this.revisionSubmitEvent.emit(this.revisionForm.get('description').value);
  }

  ngOnDestroy() {
  }

}
