export const MessageConfig = {
  validationMessage: {
    emailRequired: 'A valid email address is required.',
    emailInvalid: 'This email address is not valid.',
    uniqueEmail: 'The email address should be unique.',
    existsEmail: 'The email address is already in use by another account.',
    password: `A strong password is required.`,
    passwordInvalid: `This password is invalid, please use a different password.`,
    passwordPatternInvalid: `Your password must contain at least one letter, one number and a special character.`,
    ressetPasswordExpired: 'Your request to reset your password has expired or the link has already been used.',
    emailUnverified: 'Your email has not been verified. We sent you an email with a verification link. Please check your spam.',
    verifyEmail: 'Your request to verify your email has expired or the link has already been used.',
    passwordNotMatch: `The passwords you've entered do not match. Please try again.`,
    titleRequired: 'Project title is required.',
    addressRequired: 'Location is required.',
    mailingAddress: 'Mailing Address location is required.',
    bidStart: 'Bid start date is required.',
    materialDate: 'Material date date/time is required.',
    bidEnd: 'Bid end date is required.',
    quoteStart: 'Quote start date is required.',
    quoteEnd: 'Quote end date is required.',
    projectStart: 'Project start date is required.',
    estimatedCost: 'You must enter your estimated cost.',
    expiresAt: 'Project end date is required.',
    validAddress: 'The address you entered does not appear to be valid. Please try again',
    validAddressSmall: 'Please enter a valid address.',
    categoryRequired: 'Category is required.',
    publicationRequired: 'Publication is required.',
    someError: 'An error has occurred. Please try again.',
    question: 'You must enter a question.',
    answer: 'You must provide an answer to your question.',
    walkthroughDate: 'Walkthrough date is required.',
    walkthroughFrom: 'Walkthrough scheduler from is required.',
    walkthroughTo: 'Walkthrough scheduler to is required.',
    bidEndDateInvalid: 'Bid end date is invalid.',
    quoteEndDateInvalid: 'Quote end date is invalid.',
    projectStartDateInvalid: 'Start date is invalid.',
    expireDatedInvalid: 'End date is invalid.',
    walkthroughToInvalid: 'Walkthrough scheduler to time is invalid.',
    walkthroughFromInvalid: 'Walkthrough scheduler from time is invalid.',
    locationDenid: 'You blocked geolocation on this device. This will cause problems with the application, please enable geolocation. Thanks!',
    taxIdRequired: 'Vendor tax id is required.',
    taxIdValid: 'Please enter a valid vendor tax id.',
    taxIdUnique: 'Vendor tax id should be unique.',
    vendorName: 'Vendor name is required.',
    vendorWebsite: 'Vendor website url is required.',
    validUrl: 'Please enter a valid url.',
    vendorPhone: 'Vendor phone is required.',
    vendorEmail: 'Vendor email is required.',
    vendorLocation: 'Location is required.',
    validPhone: 'Please enter a valid phone number.',
    required: 'This field is required.',
    projectEndDate: 'Project end date is invalid.',
    userName: 'Name is required.',
    jobTitle: 'Job title is required.',
    phoneNumber: 'Phone number is required.',
    videoType: 'This video file format does not supported.',
    validRadious: 'Please enter a valid number of miles.',
    amount: 'Please enter a valid amount.',
    userEmpty: 'User information is missing.',
    vendorEmpty: 'Vendor information is missing.',
    vendorAlreadyAssigned: 'The vendor profile is already assigned to a different user.',
    isVendorProfile: 'This user has already a vendor profile.',
    userBlocked: 'Your profile is disabled or an account with that email does not exist. Please contact support. You can email us at support@ventract.com',
    alreadyMember: 'This user is already a member.',
    ownerUser: 'The owner user cannot added as member.',
    permissionDeny: 'Notification permissions have been denied on this device. Please enable notifcations to avoid missing important notifications. Thanks!',
    termsPolicy: 'Please review and then accept our Terms and Conditions and Privacy Policy if you would like to continue.',
    fileCsvUploadFailed: 'File import has failed. Please check your email for error details.',
    csvUploadFailed: 'File import has failed. Please upload csv with correct details.',
    reportGenerated: 'Your request to generate audit report is submitted successfully.',
    impressionsView: 'Number of impression cannot be less than number of ad views.',
    zipCode: 'Please enter a valid zipcode.',
    starredProjectMandatory: 'You must star this project before you are allowed to bid on this project.',
    notInvitedVendor: `You are not able to bid on this project. Please contact support.`,
    complianceNotMatch: 'Your vendor profile does not meet the compliance requirements for this project. Please update your profile into compliance so you can submit a bid.',
    inviteVendor: 'You must choose yes for at least one of these options.',
    basicUserCanNotBid: 'You need to become a premium subscriber to be able to bid on this project. Please upgrade!',
    paymentFailed: 'Payment Failed. Please Try Again.',
    accountUpdated: 'Error in updating profile',
    accountDeleted: 'Your profile was deleted, please contact administrator at support@ventract.com',
    updateAction: `You're not allowed to do this. Only an admin can update.`,
    deleteAction: `You're not allowed to do this. Only an admin can delete.`,
    invalidWorkEndDate: 'Work end date is invalid.',
    workStart: 'Project work start date is required.',
    workEnd: 'Project work end date is required.',
    isValidSubscription: 'The user does not have an enterprise subscription plan.',
    alreadyOrgMember: 'The user is already a member of an organiation.',
    alreadyInvited: 'The user is already invited to join this organization.',
    notRegisteredUser: 'The user has not yet registered on Ventract.',
    categoryExistWithName: 'Category already exist with name - ',
    suggestCategory: 'No category found. Click here to suggest a new category.',
    noCategoryFound: 'No category found.',
    restrictUserDelete: `The organization superadmin cannot be deleted as this would impact the users who are under the superadmin.`,
    vendorProfileExists: 'This user has already a vendor profile, so first, reject or delete that profile.',
    vendorExists: `The phone number or email address matched with existing system vendor.So please select vendor from the list.`,
    videoFormat: 'Video format not supported.',
    basicUserCreateProjectLimit: 'A basic user can create 2 projects in a month. Please update your subscription plan to Premium or Enterprise.',
    vendorDescription: 'Vendor description is required'
  },
  successMessage: {
    ressetPasswordSucess: 'Your password has been reset successfully. Now you can sign in with your new password.',
    verifyEmail: 'Your email has been verified.',
    accountCreated: 'The request to create account has been submitted successfully.',
    passwordChanged: `Your password has been changed successfully.`,
    verificationLink: 'We sent you an email with a verification link. Please go click on the link!',
    saveAsDraft: 'Project saved as a draft successfully.',
    projectPublish: 'Project published successfully.',
    photo: 'Photo deleted successfully.',
    video: 'Video deleted successfully.',
    files: 'File deleted successfully.',
    mediaGallery: 'Media gallery deleted successfully.',
    filesDoc: 'Files & Documents deleted successfully.',
    projectDeleted: 'Project deleted successfully.',
    projectPermission: 'Project permission deleted successfully.',
    projectPublication: 'Project advertisements deleted successfully.',
    questionAnswer: 'FAQs deleted successfully.',
    walkthrough: 'Walkthrough Scheduler deleted successfully.',
    projectUpdated: 'Project updated successfully.',
    vendorPublish: 'Vendor published successfully.',
    vendorPending: 'Vendor profile request successfully submited, we will now review it and approve it or ask for revisions. We will be in touch via email. Thanks!',
    testimonials: 'Testimonials deleted successfully.',
    licensesCertifications: 'Licenses & Certifications deleted successfully.',
    vendorProjects: 'Vendor Project deleted successfully.',
    projectArchived: 'The Project has been successfully archived.',
    projectUnArchived: 'The Project has been successfully unarchived.',
    connectInstagram: 'Connect With Instagram deleted successfully.',
    userInformation: 'Your information updated successfully.',
    addProduct: 'Your product information added successfully.',
    updateProduct: 'Your product information updated successfully.',
    addService: 'Your service information added successfully.',
    updateService: 'Your service information updated successfully.',
    deleteProduct: 'Your product information deleted successfully.',
    deleteService: 'Your service information deleted successfully.',
    bidCreated: 'You have placed your bid successfully!',
    bidUpdated: 'You have updated your bid successfully!',
    quoteCreated: 'You have placed your quote successfully!',
    quoteUpdated: 'You have updated your quote successfully!',
    bidRejected: 'The bid has been rejected successfully.',
    bidAccepted: 'The bid has been accepted successfully.',
    bidUnaccepted: 'The bid has been unaccepted successfully.',
    quoteRejected: 'The quote has been rejected successfully.',
    quoteAccepted: 'The quote has been accepted successfully.',
    quoteUnaccepted: 'The quote has been unaccepted successfully.',
    needRevision: 'The revision request has been sent successfully.',
    documentUpdate: 'The document information has been updated successfully.',
    projectAccepted: 'The project has been accepted successfully.',
    projectRejected: 'The project has been rejected successfully.',
    saveVendorProfile: 'Profile has been saved successfully.',
    vendorAccepted: 'The vendor has been accepted successfully.',
    vendorRejected: 'The vendor has been rejected successfully.',
    vendorStatus: 'The vendor status has been changed successfully.',
    vendorDisabled: 'The vendor has been disabled successfully.',
    claimProfile: 'The claim vendor profile request has been sent successfully.',
    claimProfileSuccess: 'The vendor profile has been successfully claimed.',
    blockUserByAdmin: 'User has been blocked successfully.',
    unBlockUserByAdmin: 'User has been unblocked successfully.',
    addProjectMember: 'Project member added successfully.',
    removeProjectMember: 'Project member removed successfully.',
    addVendorMember: 'Vendor member added successfully.',
    removeVendorMember: 'Vendor member removed successfully.',
    scheduleBooked: 'Project walkthrough schedule has been booked successfully.',
    scheduleCanceled: 'Project walkthrough schedule has been canceled successfully.',
    scheduleRequested: 'Project walkthrough schedule has been requested successfully.',
    csvUploadSuccess: 'File is uploaded successfully. ',
    importedUsers: ' Users have been imported.',
    importedVendors: ' Vendors have been imported.',
    importedProjects: ' Projects have been imported.',
    addAds: 'Your ads information added successfully.',
    updateAds: 'Your ads information updated successfully.',
    statusAds: 'Your ads status updated successfully.',
    settingAllowAccess: 'Setting has been updated.',
    dailyDigestSettingAccess: 'Daily digest setting has been updated.',
    subscriptionSuccess: 'Congrats! You\'ve successfully subscribed to Ventract.',
    projectAdvertisement: 'Project advertisement has been saved successfully.',
    advertisementsFeature: 'Advertisements feature deleted successfully.',
    complianceFeature: 'Compliance data has been saved successfully.',
    complianceFeatureDelete: 'Compliance feature deleted successfully.',
    permissionFeature: 'Permission data has been saved successfully.',
    permissionFeatureDelete: 'Permission feature deleted successfully.',
    vendorInvite: 'Vendor has been invited successfully.',
    updateRecord: 'The record has been updated successfully.',
    deleteRecord: 'The record has been deleted successfully.',
    paymentSuccess: 'Payment successfull. Ad will be shown within three days of bidding window opening.',
    createOrganization: 'The Organization request has been sent successfully.',
    joinOrgRequest: 'Join organization request sent successfully.',
    categoryAdded: 'Category Successfully Added.',
    categoryUpdated: 'Category updated Successfully.',
    projectShared: 'Project shared successfully.',
    leaveOrgRequest: 'Leave organization request sent successfully.',
    vendorsStarred: 'vendors starred successfully.',
    vendorsUnStarred: 'vendors unstarred successfully.',
    emailReminder: 'The email reminder has been unsubscribed successfully.',
    categorySuggested: 'The new category suggestion request has been sent successfully.',
    fileCsvUpload: 'File import request is in process. It will take some time, please check your email for file import status.',
    scrapeRequest: `The request to scrape vendors has been submitted successfully.`,
    projectDuplicate: 'Project duplicated successfully.',
    removeDuplicateRequest: 'The request to remove duplicate vendor has been submitted successfully.',
    fakerDataRequest: `The request to create fake datas has been submitted successfully.`,
    courierNotificationPreference: `The request to set courier preferences has been submitted successfully. `
  },
  popupAlertMessage: {
    leavePage: 'Are you sure you want to leave this page?',
    cancelProject: 'Are you sure you want to stop creating this project?',
    projectFeature: 'Are you sure you want to remove this feature?',
    deletePhoto: 'Are you sure you want to remove this photo?',
    deleteVideo: 'Are you sure you want to remove this video?',
    deleteFile: 'Are you sure you want to remove this file?',
    deleteProject: 'Are you sure you want to remove this project?',
    projectPermission: 'Are you sure you want to remove Permission feature?',
    projectPublication: 'Are you sure you want to remove Advertisements feature?',
    questionAnswer: 'Are you sure you want to remove FAQs feature?',
    questionSection: 'Are you sure you want to remove this question?',
    removeLineItem: 'Are you sure you want to remove this line item?',
    walkthroughFeature: 'Are you sure you want to remove the Walkthrough Scheduler?',
    walkthrough: 'Are you sure you want to remove this Walkthrough?',
    email: 'Are you sure you want to remove this contact email?',
    phone: 'Are you sure you want to remove this contact number?',
    address: 'Are you sure you want to remove this address?',
    testimonials: 'Are you sure you want to remove Testimonials?',
    singleTestimonials: 'Are you sure you want to remove this Testimonial?',
    licensesCertifications: 'Are you sure you want to remove Licenses & Certifications?',
    signleCertifications: 'Are you sure you want to remove this Licenses & Certifications?',
    vendorProjects: 'Are you sure you want to remove Vendor Project?',
    signleProject: 'Are you sure you want to remove this Project?',
    projectArchived: 'Are you sure you want to archive this project?',
    projectUnArchived: 'Are you sure you want to unarchive this project?',
    editProjectArchived: 'You can not edit the archive project. To edit you need to unarchive this first.',
    conectInstagram: 'Are you sure you want to remove Connect With Instagram?',
    cancel: 'Are you sure you want to cancel?',
    product: 'Are you sure you want to remove product?',
    service: 'Are you sure you want to remove service?',
    biddingClosed: 'Project bidding is closed.',
    quoteClosed: 'Project quote window is closed.',
    bidEdit: 'You have already placed a bid for this project! Would you like to edit your bid?',
    bidPlace: 'Submit or Edit your bid.',
    quoteEdit: 'You have already placed a quote for this project! Would you like to edit your Quote?',
    quotePlace: 'Submit or Edit your quote',
    bidVendorMandatory: `Oops!! looks like you're having trouble bidding on the project.
    Please create a vendor profile or contact support@ventract.com.`,
    bidUserMandatory: `Oops!! looks like you're having trouble adding bidding on the project.
    Please create a user profile or contact support@ventract.com.`,
    quoteVendorMandatory: `Oops!! looks like you're having trouble adding quote on the project.
    Please create a vendor profile or contact support@ventract.com.`,
    bidAccepted: 'Are you sure you want to accept this Bid?',
    bidUnaccepted: 'Are you sure you want to unaccept this Bid?',
    bidRejected: 'Are you sure you want to reject this Bid?',
    quoteAccepted: 'Are you sure you want to accept this Quote?',
    quoteUnaccepted: 'Are you sure you want to unaccept this Quote?',
    quoteRejected: 'Are you sure you want to reject this Quote?',
    bidAlreadyAccepted: 'A bid has already been accepted for this project.',
    quoteAlreadyAccepted: 'A quote has already been accepted for this project.',
    projectPublished: 'Are you sure you want to accept this project?',
    projectRejected: 'Are you sure you want to reject this project?',
    vendorPublished: 'Are you sure you want to accept this vendor?',
    vendorRejected: 'Are you sure you want to reject this vendor?',
    vendorRevision: 'Are you sure you want to request revision for this vendor profile request?',
    vendorDisable: 'Are you sure you want to disable this vendor?',
    vendorPending: 'Are you sure you want to change the status to pending for this vendor profile?',
    editRejectedProject: 'You can not edit this project because it was rejected by an admin.',
    bidRejectedProject: 'You can not submit a bid or quote because this project was rejected by an admin.',
    claimProfile: 'Are you sure you want to claim this profile?',
    blockUserByAdmin: 'Are you sure you want to Block this user.',
    unBlockUserByAdmin: 'Are you sure you want to unblock this user.',
    rejectedVendor: 'You can not edit the rejected vendor profile.',
    editClaimVendorRequest: 'The claim vendor profile request can not edit. you can only accept and reject this request.',
    instgramConnect: 'Only the vendor profile owner user can connect with instagram.',
    removeMember: 'Are you sure you want to remove this member?',
    projectMember: 'You can not add more then 10 project member.',
    vendorMember: 'You can not add more then 10 vendor member.',
    bidNotStart: 'The project bid/quote does not start yet.',
    userVendorDeactivated: 'This user vendor profile is deactivated. So you cannot take any action.',
    bidValidity: 'The bid validity period has been expired.',
    memberAlert: 'You will be removed from the member list of the vendor if you create your own vendor profile.',
    bidVendorAlert: 'The owner of project and vendor is same user so you can not add bid/quote.',
    walkthroughSchedule: 'Vendor profile is required for book project walkthrough schedule.',
    cancelWalkthrough: 'Are you sure you want to cancel this project walkthrough schedule.',
    activateAds: 'Are you sure you want to activate this ad?',
    deactivateAds: 'Are you sure you want to deactivate this ad?',
    activateAdAlert: 'Your number of impressions or end date has expired so update this ad to activate?',
    advertisementsFeature: 'Are you sure you want to remove advertisements feature?',
    complianceFeature: 'Are you sure you want to remove compliance feature?',
    importantFeature: 'The Compliance, Advertisements and Walkthrough are very important features for this project. Are you sure you want to continue?',
    chageProjectAlert: `If you change the project type then your Line Items, 
    Compliance, Advertisements, and Permission data will be lost! Are you sure you want to continue?`,
    projectTypeNotChange: 'Project type cannot be change after publish.',
    sealedBidType: 'Sealed bid type cannot be change after publish.',
    projectLineItem: 'Project line item cannot be blank for IFB.',
    permissionFeature: 'Are you sure you want to remove permission feature?',
    basicUserBidLimit: 'A Basic user can place 2 bids in a month. Please update your subscription plan to Premium or Enterprise.',
    deleteRecord: 'Are you sure you want to remove this record?',
    confirmCompetitiveBid: 'Your bid is higher than what the project creator expected it to be for this project. Would you like to continue with bid submission?',
    bidCanNotEdit: 'You need to upgrade your subscription to edit bids.',
    upgradePlanForExpressProject: 'Upgrade your Ventract subscription to unlock Express projects.',
    upgradePlanForSealedBidProject: 'Upgrade your Ventract subscription to unlock Sealed Bid projects.',
    upgradePlanForPublicProject: `Upgrade your Ventract subscription to view details about this Public Project. 
    Only premium and enterprise users have full access to the hundreds of Public Projects on Ventract.`,
    alertExpress: `It is advisable to add the walkthrough scheduler as this an express project. Do you want to continue?`,
    subscriptionRequest: 'Subscription request already in process.',
    bidCanNotRevised: 'Once you have bids, you cannot edit/revise.',
    leaveOrganization: 'Are you sure you want to leave an organization?',
    notAllowedToDOwnloadAuditReport: 'You can download audit report only twice in an hour. please try after some time.',
    promotionHelp: `This is an exclusive promotion offered through Ventract that has been specially selected for you.
     If you have any feedback, or would like more information about this please contact Ventract Support.`,
    scrapeVendor: `Are you sure you want to scrape vendors?`,
    removeDuplicateVendor: `Are you sure you want to remove duplicate vendors?`,
    publicProjectRedirectionTitle: `You are about to leave Ventract...`,
    publicProjectRedirectionMessage: `Public Projects like this are provided to Premium subscribers for added convenience. 
    Please use caution when inputting personal information on outside websites. Thank you for using Ventract!`,
    fakerProcess: 'Are you sure you want to delete all project,user,vendor, data on website and create a dummy data?',
    previewUserRedirectionTitle: `Please complete your profile!`,
    previewUserRedirectionMessage: `Until then, you can only view this project. 
    Once your profile is complete you can schedule walkthroughs, submit bids, find more projects, and more.`,
    basicUserCreateProjectLimit: 'A Basic user can create 2 projects in a month. Please update your subscription plan to Premium or Enterprise.',
  }
};
