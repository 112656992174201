
import {map, takeUntil, switchMap } from 'rxjs/operators';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
// Config
import { CommonConfig } from '@shared/common.config';
// Model
import { Project } from '@app/core/model/project';
import { Vendor } from '@app/core/model/vendor';
// Service
import { AuthService } from '@app/core/auth.service';
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { ProjectsService } from '@app/shared/service/projects/projects.service';
import { CustomService } from '@shared/service/custom/custom.service';
import { VendorsService } from '@app/shared/service/vendors/vendors.service';
import { TimestampService } from '@shared/service/timestamp/timestamp.service';

@Component({
  selector: 'app-project-question',
  templateUrl: './project-question.component.html',
  styleUrls: ['./project-question.component.scss', '../../../../assets/scss/details-pages.scss']
})
export class ProjectQuestionComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  commonConfig = CommonConfig;
  currentUser;
  public commentList = [];
  public loading = false;
  public totalDiscussion = 0;
  public totalReplyCount = 0;
  public showSubReplyView = false;
  public justNow = this.commonConfig.actionState.justNow;

  @Input() referenceId: string;
  @Input() belongTo: string;
  @Input() questionComments: number;
  @Input() project: Project;
  @Input() vendor: Vendor;
  @Input() parentObject;

  constructor(
    private firestoreService: FirestoreService,
    public auth: AuthService,
    private projectsService: ProjectsService,
    private customService: CustomService,
    private vendorsService: VendorsService,
    private timestampService: TimestampService
  ) {
    this.auth.user.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.currentUser = res;
    });
  }

  ngOnInit() {
    this.getComments();
  }

  /**
   * @description function used for get discussion list
   */
  getComments() {
    this.firestoreService.colWithIds$(
      this.commonConfig.collectionName.discussions,
      (ref) => ref
        .orderBy('createdAt', 'desc')
        .where('referenceId', '==', this.referenceId).where('belongTo', '==', this.belongTo)
    ).pipe(switchMap(comments => {
      const res = comments.map(comment => {
        return this.firestoreService
          .colWithIds$(
            this.commonConfig.collectionName.discussionsReplies,
            (ref) => ref
              .orderBy('createdAt', 'desc')
              .where('parentCommentId', '==', comment.id)
          ).pipe(
          map(replies => {
            if (this.parentObject && this.parentObject.isPermissionPrivate(this.parentObject.permissionsKeys.activity)
              && !this.parentObject.isProjectOwnerOrMember() && !this.parentObject.isAdmin) {
              replies = replies.filter(x => x.uid === this.project.uid || x.uid === this.parentObject.authUid ||
                (this.parentObject.projects.members && this.parentObject.projects.members.includes(x.uid)));
            }
            return Object.assign(comment, { 'replies': replies });
          }));
      });
      return combineLatest(res);
    }), takeUntil(this.ngUnsubscribe)).subscribe(comments => {
      if (this.parentObject && this.parentObject.isPermissionPrivate(this.parentObject.permissionsKeys.activity)
        && !this.parentObject.isProjectOwnerOrMember() && !this.parentObject.isAdmin) {
        comments = comments.filter(x => x.uid === this.project.uid || x.uid === this.parentObject.authUid ||
          (this.parentObject.projects.members && this.parentObject.projects.members.includes(x.uid)));
      }
      if (comments.length) {
        this.commentList = comments;
      }
      this.totalDiscussion = this.commentList.length;
      this.loading = false;
    });
  }
  /**
   * @description function used for post comments
   */
  addComment($event: HTMLButtonElement): boolean | undefined  {
    if ($event.value !== '') {
      const requireData = {
        'uid': this.currentUser.uid,
        'displayName': this.currentUser.displayName,
        'photoUrl': this.currentUser.profilePhoto.url,
        'referenceId': this.referenceId,
        'belongTo': this.belongTo,
        'comment': $event.value,
        'replyCount': 0
      };
      $event.value = '';
      this.firestoreService.add(this.commonConfig.collectionName.discussions, requireData).then(response => {
        this.addUpdateCommentsCount();
        this.addInNotificationList(requireData);
        if (this.belongTo === this.commonConfig.questionType.project) {
          const dataObject = {
            uid: this.currentUser.uid, projectId: this.referenceId,
            activityType: this.commonConfig.projectActivityType.addcomment
          };
          this.projectsService.saveProjectActivity(dataObject);
        }
      }).catch(error => {
        if (error.code) {
          this.customService.showSnackBarError(error.message);
        }
      });
      return false;
    }
  }


  /**
   * @description function used for reply on discussion
   */
  addCommentReply($event: HTMLButtonElement, parentCommentId: string, index: number): boolean | undefined {
    if ($event.value !== '') {
      const commentData = {
        'uid': this.currentUser.uid,
        'displayName': this.currentUser.displayName,
        'photoUrl': this.currentUser.profilePhoto.url,
        'referenceId': this.referenceId,
        'belongTo': this.belongTo,
        'comment': $event.value,
        'parentCommentId': parentCommentId
      };

      $event.value = '';
      this.firestoreService.add(this.commonConfig.collectionName.discussionsReplies, commentData).then(
        response => {
          this.addUpdateCommentsCount();
          this.addInNotificationList(commentData);
          if (this.belongTo === this.commonConfig.questionType.project) {
            const dataObject = {
              uid: this.currentUser.uid, projectId: this.referenceId,
              activityType: this.commonConfig.projectActivityType.replycomment
            };
            this.projectsService.saveProjectActivity(dataObject);
          }
        }
      ).catch(err => {
        if (err.code) {
          this.customService.showSnackBarError(err.message);
        }
      });

      // Updating the reply count
      const repCountSub = this.firestoreService.colWithIds$(
        this.commonConfig.collectionName.discussionsReplies,
        (ref) => ref
          .orderBy('createdAt', 'desc')
          .where('parentCommentId', '==', parentCommentId)
      ).subscribe(replies => {
        this.totalReplyCount = replies.length;
        this.showSubReplyView = replies.length > CommonConfig.five;
        this.firestoreService.update(
          `${this.commonConfig.collectionName.discussions}/${parentCommentId}`,
          { replyCount: replies.length }
        );
        repCountSub.unsubscribe();
      });

      return false;
    }
  }

  /**
   * @description function used for save notification
   * @param {object} commentInfo contain a comment informaion
   */
  addInNotificationList(commentInfo) {
    const payloadData = { 'activityType': '', 'uid': '', 'vendorId': '', 'projectId': '' };
    if (commentInfo.belongTo === this.commonConfig.questionType.project) {
      payloadData['activityType'] = this.commonConfig.projectActivityType.addcomment;
      payloadData['uid'] = commentInfo['uid'];
      payloadData['projectId'] = commentInfo['referenceId'];
      this.projectsService.saveInsideNotificationPayload(payloadData, this.project);
    }
    if (commentInfo.belongTo === this.commonConfig.questionType.vendor && this.vendor) {
      payloadData['activityType'] = this.commonConfig.vendorActivityType.commentvendorprofile;
      payloadData['uid'] = commentInfo['uid'];
      payloadData['vendorId'] = commentInfo['referenceId'];
      this.vendorsService.saveVendorActivity(payloadData, this.vendor);
      this.vendorsService.saveInsideNotificationPayload(payloadData, this.vendor);
    }
  }

  /**
   * @description function used for add and update comment
   */
  addUpdateCommentsCount() {
    if (this.questionComments) {
      this.questionComments = this.questionComments + CommonConfig.one;
    } else {
      this.questionComments = CommonConfig.one;
    }
    if (this.belongTo === this.commonConfig.questionType.vendor) {
      this.firestoreService.update(
        `${this.commonConfig.collectionName.vendors}/${this.referenceId}`,
        { discussionComments: this.questionComments }
      );
    } else {
      this.firestoreService.update(
        `${this.commonConfig.collectionName.project}/${this.referenceId}`,
        { discussionComments: this.questionComments }
      );
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
