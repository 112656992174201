import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AllMaterialModule } from '@app/core/material.module';
import { AllCovalentModule } from '@app/core/covalent.module';
import { ValidationErrorModule } from '@shared/module/validation-error/validation-error.module';

import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';

import { NgxMaskModule } from 'ngx-mask';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { SlickModule } from 'ngx-slick';
import { LightboxModule } from 'ngx-lightbox';
import { ClickOutsideModule } from 'ng-click-outside';
import { QuillModule } from 'ngx-quill';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
// Pipe
import { CapitalizePipe, EllipsisPipe, LinebreakPipe, TitleCasePipe } from '@shared/pipes/capitalize.pipe';
import { ShortAddressPipe } from '@shared/pipes/short-address.pipe';
import { ImageUrlPipe } from '@shared/pipes/image-url.pipe';
import { ImageDefaultClassPipe } from '@shared/pipes/image-default-class.pipe';
import { CommaSeperatedPipe, DisplayProjectTypePipe } from '@shared/pipes/comma-seperated.pipe';
import { CheckUserVerified } from '@shared/pipes/check-user-verified.pipe';
// Component
import { LazyLoaderComponent } from '@shared/lazy-loader/lazy-loader.component';
import { FavoriteProjectComponent } from '@app/+projects/favorite-project/favorite-project.component';
import { VendorFavoriteComponent } from '@app/+vendors/vendor-favorite/vendor-favorite.component';
import { ProjectQuestionComponent } from '@shared/component/question/project-question.component';
import { SelectCategoryComponent } from '@shared/select-category/select-category.component';
import { SelectPublicationComponent } from '@shared/select-publication/select-publication.component';
import { PublicationPreviewComponent } from '@shared/select-publication/preview-publication/preview-publication.component';
import { LoadingMaskComponent } from '@shared/component/loading-mask/loading-mask.component';
import { PromotionComponent } from '@shared/component/promotion/promotion.component';
import { StaticPromotionComponent } from '@shared/component/static-promotion/static-promotion.component';
import { MapViewComponent } from '@shared/component/map-view/map-view.component';
import { GridViewComponent } from '@shared/component/grid-view/grid-view.component';
import { ListViewComponent } from '@shared/component/list-view/list-view.component';
import { ProjectsnavComponent } from '@app/+projects/projectsnav/projectsnav.component';
import { OwnerBidListComponent } from '@shared/component/projects/owner-bid-list/owner-bid-list.component';
import { BidListComponent } from '@shared/component/projects/bid-list/bid-list.component';
import { MaterialBidsComponent } from '@shared/component/projects/owner-bid-list/material-bids/material-bids.component';
import { VendorMapComponent } from '@app/+vendors/vendor-map/vendor-map.component';
import { VendorGridComponent } from '@app/+vendors/vendor-grid/vendor-grid.component';
import { VendorListComponent } from '@app/+vendors/vendor-list/vendor-list.component';
import { VendorsnavComponent } from '@app/+vendors/vendorsnav/vendorsnav.component';
import { ConnectInstagramComponent } from '@app/+vendors/create-vendor/connect-instagram/connect-instagram.component';
import { RedirectInstagramComponent } from '@app/+vendors/create-vendor/redirect-instagram/redirect-instagram.component';
import { ToolbarActionButtonsComponent } from '@shared/component/toolbar-action-buttons/toolbar-action-buttons.component';
import { ProjectFiltersComponent } from '@shared/component/projects/filters/project-filters.component';
import { UserProfilePictureComponent } from '@shared/component/user-profile-picture/user-profile-picture.component';
import { UserNameComponent } from '@shared/component/user-name/user-name.component';
import { SharedSignupComponent } from '@shared/component/shared-signup/shared-signup.component';
import { AdminNavComponent } from '@app/+admin/admin-nav/admin-nav.component';

// start entry component
import { CustomSnackBarComponent } from '@app/shared/custom-snackbar/custom.snackbar.component';
import { CreateProductsComponent } from '@app/+vendors/products-services/create-products/create-products.component';
import { CreateServicesComponent } from '@app/+vendors/products-services/create-services/create-services.component';
import { NeedBidRevisionComponent } from '@app/shared/component/projects/owner-bid-list/need-bid-revision/need-bid-revision.component';
import { SelectUserComponent } from '@shared/component/select-user/select-user.component';
import { SelectMultipleUserComponent } from '@shared/component/select-multiple-users/select-multiple-user.component';
import { SuggestNewCategoryComponent } from '@shared/component/suggest-new-category/suggest-new-category.component';
import { NeedProjectRevisionComponent } from '@shared/component/projects/need-project-revision/need-project-revision.component';
import { NeedVendorRevisionComponent } from '@shared/component/vendors/need-vendor-revision/need-vendor-revision.component';
import { ClaimVendorDetailComponent } from '@shared/component/vendors/claim-vendor-detail/claim-vendor-detail.component';
import { ClaimVendorComponent } from '@shared/component/vendors/claim-vendor/claim-vendor.component';
import { FilterVendorComponent } from '@shared/component/vendors/filter-vendor/filter-vendor.component';
import { ConfirmDeleteComponent } from '@app/+admin/confirm-delete/confirm-delete.component';
import { CreateOrganizationComponent } from '@shared/component/organization/create-organization/create-organization.component';
import { InviteUserComponent } from '@shared/component/organization/invite-user/invite-user.component';
import { JoinOrgComponent } from '@shared/component/organization/join-org/join-org.component';
import { OrgPermissionComponent } from '@shared/component/organization/org-permission/org-permission.component';
import { ShareProjectComponent } from '@shared/component/projects/share-project/share-project.component';
import { PreviewDocComponent } from '@shared/component/preview-doc/preview-doc.component';
import { VideoModalComponent } from '@shared/component/video-modal/video-modal.component';
import { ImageModalComponent } from '@shared/component/image-modal/image-modal.component';
import { WalkthroughmodalComponent } from '@shared/component/projects/walkthroughmodal/walkthroughmodal.component';
import { WalkthroughrequestmodalComponent } from '@shared/component/projects/walkthroughrequestmodal/walkthroughrequestmodal.component';
import { PremiumSignupComponent } from '@shared/component/premium-signup/premium-signup.component';
import { NotificationAccessDialogComponent } from '@shared/component/notification-access-dialog/notification-access-dialog.component';

// end entry component

import { FireFormDirective } from '@app/directive/project/fire-form.directive';
import { VendorFormDirective } from '@app/directive/vendor/vendor-form.directive';
import { GooglePlacesDirective } from '@app/directive/google-place/google-places.directive';

import { environment } from 'environments/environment';
import { BreadcrumbComponent } from '../component/+breadcrumb/breadcrumb.component';
import { AdminProjectFiltersComponent } from '../component/admin-project-filters/admin-project-filters.component';
import { AdminProjectListingComponent } from '../component/admin-project-listing/admin-project-listing.component';
import { ChoosePlanListComponent } from '../component/choose-plan-list/choose-plan-list.component';
import { UnclaimVendorProfileListComponent } from '../component/vendors/unclaim-vendor-profile-list/unclaim-vendor-profile-list.component';
import { MapToggleListComponent } from '../component/map-toggle-list/map-toggle-list.component';
import { NeedRevisionFormComponent } from '../component/need-revision-form/need-revision-form.component';
import { MemberParticipantListComponent } from '../component/member-participant-list/member-participant-list.component';
import { ProductServicesFormComponent } from '../component/product-services/product-services-form.component';
import { RedirectURLComponent } from '@app/+projects/redirect-ads/redirect.ads.components';
import { ProjectLimitDialogComponent } from '../component/project-limit-dialog/project-limit-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AllMaterialModule,
    AllCovalentModule,
    InfiniteScrollModule,
    CurrencyMaskModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsKey
    }),
    AgmJsMarkerClustererModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    SlickModule.forRoot(),
    LightboxModule,
    CurrencyMaskModule,
    NgxMaskModule.forRoot(),
    ClickOutsideModule,
    QuillModule.forRoot(),
    NgxDaterangepickerMd.forRoot(
      {
        separator: ' - ',
        applyLabel: 'Apply'
      }
    ),
    ValidationErrorModule
  ],
  declarations: [
    CapitalizePipe,
    EllipsisPipe,
    LinebreakPipe,
    TitleCasePipe,
    ShortAddressPipe,
    ImageUrlPipe,
    ImageDefaultClassPipe,
    CheckUserVerified,
    CommaSeperatedPipe,
    DisplayProjectTypePipe,

    FireFormDirective,
    VendorFormDirective,
    GooglePlacesDirective,

    LazyLoaderComponent,
    FavoriteProjectComponent,
    VendorFavoriteComponent,
    SelectCategoryComponent,
    SelectPublicationComponent,
    PublicationPreviewComponent,
    ProjectQuestionComponent,
    LoadingMaskComponent,
    PromotionComponent,
    StaticPromotionComponent,
    MapViewComponent,
    GridViewComponent,
    ListViewComponent,
    ProjectsnavComponent,
    OwnerBidListComponent,
    BidListComponent,
    MaterialBidsComponent,
    ToolbarActionButtonsComponent,
    ProjectFiltersComponent,
    VendorMapComponent,
    VendorGridComponent,
    VendorListComponent,
    VendorsnavComponent,
    ConnectInstagramComponent,
    RedirectInstagramComponent,
    RedirectURLComponent,
    UserProfilePictureComponent,
    UserNameComponent,
    SharedSignupComponent,
    AdminNavComponent,
    // start entry component
    CustomSnackBarComponent,
    CreateProductsComponent,
    CreateServicesComponent,
    NeedBidRevisionComponent,
    SelectUserComponent,
    SelectMultipleUserComponent,
    SuggestNewCategoryComponent,
    NeedProjectRevisionComponent,
    NeedVendorRevisionComponent,
    ClaimVendorDetailComponent,
    ClaimVendorComponent,
    FilterVendorComponent,
    ConfirmDeleteComponent,
    CreateOrganizationComponent,
    InviteUserComponent,
    JoinOrgComponent,
    OrgPermissionComponent,
    ShareProjectComponent,
    PreviewDocComponent,
    VideoModalComponent,
    ImageModalComponent,
    WalkthroughmodalComponent,
    WalkthroughrequestmodalComponent,
    PremiumSignupComponent,
    // end entry components
    BreadcrumbComponent,
    AdminProjectFiltersComponent,
    AdminProjectListingComponent,
    ChoosePlanListComponent,
    UnclaimVendorProfileListComponent,
    MapToggleListComponent,
    NeedRevisionFormComponent,
    MemberParticipantListComponent,
    ProductServicesFormComponent,
    NotificationAccessDialogComponent,
    ProjectLimitDialogComponent
  ],
  exports: [
    CurrencyMaskModule,
    NgxMaskModule,
    AllMaterialModule,
    AllCovalentModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    InfiniteScrollModule,
    AgmCoreModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    SlickModule,
    LightboxModule,
    ClickOutsideModule,
    QuillModule,
    NgxDaterangepickerMd,

    FireFormDirective,
    VendorFormDirective,
    GooglePlacesDirective,

    CapitalizePipe,
    EllipsisPipe,
    LinebreakPipe,
    TitleCasePipe,
    ShortAddressPipe,
    ImageUrlPipe,
    ImageDefaultClassPipe,
    CheckUserVerified,
    CommaSeperatedPipe,
    DisplayProjectTypePipe,

    LazyLoaderComponent,
    FavoriteProjectComponent,
    VendorFavoriteComponent,
    SelectCategoryComponent,
    SelectPublicationComponent,
    PublicationPreviewComponent,
    ProjectQuestionComponent,
    LoadingMaskComponent,
    PromotionComponent,
    StaticPromotionComponent,
    MapViewComponent,
    GridViewComponent,
    ListViewComponent,
    ProjectsnavComponent,
    OwnerBidListComponent,
    BidListComponent,
    MaterialBidsComponent,
    AdminProjectFiltersComponent,
    AdminProjectListingComponent,
    ToolbarActionButtonsComponent,
    ProjectFiltersComponent,
    ChoosePlanListComponent,
    UnclaimVendorProfileListComponent,
    MapToggleListComponent,
    NeedRevisionFormComponent,
    MemberParticipantListComponent,
    ProductServicesFormComponent,
    VendorMapComponent,
    VendorGridComponent,
    VendorListComponent,
    VendorsnavComponent,
    ConnectInstagramComponent,
    RedirectInstagramComponent,
    RedirectURLComponent,
    UserProfilePictureComponent,
    UserNameComponent,
    SharedSignupComponent,
    AdminNavComponent,
    // start entry components
    CustomSnackBarComponent,
    CreateProductsComponent,
    CreateServicesComponent,
    NeedBidRevisionComponent,
    SelectUserComponent,
    SelectMultipleUserComponent,
    SuggestNewCategoryComponent,
    NeedProjectRevisionComponent,
    NeedVendorRevisionComponent,
    ClaimVendorDetailComponent,
    ClaimVendorComponent,
    FilterVendorComponent,
    CreateOrganizationComponent,
    InviteUserComponent,
    JoinOrgComponent,
    OrgPermissionComponent,
    ShareProjectComponent,
    PreviewDocComponent,
    VideoModalComponent,
    ImageModalComponent,
    WalkthroughmodalComponent,
    WalkthroughrequestmodalComponent,
    PremiumSignupComponent,
    // end entry components
    BreadcrumbComponent,
    NotificationAccessDialogComponent,
    ProjectLimitDialogComponent
  ],
  providers: [GoogleMapsAPIWrapper, CapitalizePipe],
  entryComponents: [
    CustomSnackBarComponent,
    CreateProductsComponent,
    CreateServicesComponent,
    NeedBidRevisionComponent,
    SelectUserComponent,
    SelectMultipleUserComponent,
    SuggestNewCategoryComponent,
    NeedProjectRevisionComponent,
    NeedVendorRevisionComponent,
    ClaimVendorDetailComponent,
    ClaimVendorComponent,
    FilterVendorComponent,
    ConfirmDeleteComponent,
    PublicationPreviewComponent,
    CreateOrganizationComponent,
    InviteUserComponent,
    JoinOrgComponent,
    OrgPermissionComponent,
    ShareProjectComponent,
    PreviewDocComponent,
    VideoModalComponent,
    ImageModalComponent,
    WalkthroughmodalComponent,
    WalkthroughrequestmodalComponent,
    PremiumSignupComponent,
    NotificationAccessDialogComponent,
    ProjectLimitDialogComponent,
  ]
})
export class CommonModuleModule { }
