import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Subject, BehaviorSubject } from 'rxjs';
// Config
import { CommonConfig } from '@shared/common.config';
import { trackEvent } from '@shared/common.constants';
// Service
import { AuthService } from '@app/core/auth.service';
import { CustomService } from '@shared/service/custom/custom.service';
import { GanalyticsService } from '@shared/service/ganalytics/ganalytics.service';
import { ProjectsService } from '@shared/service/projects/projects.service';
import { ProjectActivityService } from '@shared/service/project-activity/project-activity.service';
import { ProjectWtService } from '@shared/service/projects/project-wt.service';

@Injectable({
  providedIn: 'root'
})
export class ChooseProjectFeatureService {
  private readonly commonConfig = CommonConfig;
  public addRelatedCategory = new Subject();
  addRelatedCategoryStatus = this.addRelatedCategory.asObservable();

  public removeRelatedCategory = new Subject();
  removeRelatedCategoryData = this.removeRelatedCategory.asObservable();

  private readonly walkthrough = new BehaviorSubject({});
  walkthroughStatus = this.walkthrough.asObservable();

  private readonly questionAnswers = new BehaviorSubject({});
  questionAnswersStatus = this.questionAnswers.asObservable();

  private readonly chooseFeature = new BehaviorSubject({});
  chooseFeatureLength = this.chooseFeature.asObservable();

  constructor(
    public dialog: MatDialog,
    public auth: AuthService,
    private readonly customService: CustomService,
    private readonly ganalyticsService: GanalyticsService,
    private readonly projectsService: ProjectsService,
    private readonly projectActivityService: ProjectActivityService,
    private readonly projectWtService: ProjectWtService
  ) { }

  chooseFeatures(thisObj, type: string) {
    if (type === thisObj.commonConfig.projectFeatures.media) {
      this.setMediaGalleryFeature(thisObj);
    } else if (type === this.commonConfig.projectFeatures.files) {
      this.setFilesDocumentsFeature(thisObj);
    } else if (type === this.commonConfig.projectFeatures.walkthrough) {
      this.setWalkthroughSchedulerFeature(thisObj);
    } else if (type === this.commonConfig.projectFeatures.questionAnswer) {
      this.setQuestionAnswerFeature(thisObj);
    } else {
      this.setCompliancePermissionAdvertisements(type, thisObj);
    }
  }

  setMediaGalleryFeature(thisObj) {
    thisObj.mediaGallery = !thisObj.mediaGallery;
  }

  setFilesDocumentsFeature(thisObj) {
    thisObj.filesDocuments = !thisObj.filesDocuments;
  }

  setWalkthroughSchedulerFeature(thisObj) {
    thisObj.walkthroughScheduler = !thisObj.walkthroughScheduler;
  }

  setQuestionAnswerFeature(thisObj) {
    thisObj.questionAnswer = !thisObj.questionAnswer;
  }

  setCompliancePermissionAdvertisements(type, thisObj) {
    if (type === this.commonConfig.projectFeatures.compliance) {
      this.setComplianceRequirementFeature(thisObj);
    } else if (type === this.commonConfig.projectFeatures.permission) {
      this.setPermissionFeature(thisObj);
    } else if (type === this.commonConfig.projectFeatures.advertisements) {
      this.setAdvertisementsFeature(thisObj);
    }
  }

  setComplianceRequirementFeature(thisObj) {
    thisObj.complianceRequirement = !thisObj.complianceRequirement;
  }

  setPermissionFeature(thisObj) {
    thisObj.permission = !thisObj.permission;
  }

  setAdvertisementsFeature(thisObj) {
    thisObj.advertisements = !thisObj.advertisements;
  }

  // Used when select choose feature and click on next
  chooseFeaturesContinue(thisObj) {
    thisObj.projectBasicInfo.get('features').get('mediaGallery').setValue(thisObj.mediaGallery);
    thisObj.projectBasicInfo.get('features').get('filesDocuments').setValue(thisObj.filesDocuments);
    thisObj.projectBasicInfo.get('features').get('walkthroughScheduler').setValue(thisObj.walkthroughScheduler);
    thisObj.projectBasicInfo.get('features').get('complianceRequirement').setValue(thisObj.complianceRequirement);
    thisObj.projectBasicInfo.get('features').get('permission').setValue(thisObj.permission);
    thisObj.projectBasicInfo.get('features').get('advertisements').setValue(thisObj.advertisements);
    thisObj.projectBasicInfo.get('features').get('questionAnswer').setValue(thisObj.questionAnswer);
    setTimeout(() => {
      this.createProjectFeatureSteps(thisObj);
    }, this.commonConfig.delay2000);
    setTimeout(() => {
      if (thisObj.mediaGallery) {
        thisObj.step4Ele.active = true;
      } else if (thisObj.filesDocuments) {
        thisObj.step6Ele.active = true;
      } else if (thisObj.walkthroughScheduler) {
        thisObj.step7Ele.active = true;
      } else if (thisObj.complianceRequirement) {
        thisObj.complianceStep.active = true;
      } else {
        this.setActivePermissionAdvertisementsQuestion(thisObj);
      }
    }, this.commonConfig.zero);

  }

  setActivePermissionAdvertisementsQuestion(thisObj) {
    if (thisObj.permission) {
      thisObj.permissionStep.active = true;
    } else if (thisObj.advertisements) {
      thisObj.advertisementsStep.active = true;
    } else if (thisObj.questionAnswer) {
      thisObj.step8Ele.active = true;
    }
  }

  // Function used for show the project feature selected or not
  setProjectFeatureStatus(that) {
    that.invitedVendorIds = that.projectInfo.invitedVendors || [];
    if (that.projectInfo.features.mediaGallery) {
      that.mediaGallery = that.projectInfo.features.mediaGallery;
      that.projectBasicInfo.get('features').get('mediaGallery').setValue(that.mediaGallery);
    }
    if (that.projectInfo.features.filesDocuments) {
      that.filesDocuments = that.projectInfo.features.filesDocuments;
      that.projectBasicInfo.get('features').get('filesDocuments').setValue(that.filesDocuments);
    }
    if (that.projectInfo.features.walkthroughScheduler) {
      that.walkthroughScheduler = that.projectInfo.features.walkthroughScheduler;
      that.projectBasicInfo.get('features').get('walkthroughScheduler').setValue(that.walkthroughScheduler);
    }
    if (that.projectInfo.features.complianceRequirement) {
      that.complianceRequirement = that.projectInfo.features.complianceRequirement;
      that.projectBasicInfo.get('features').get('complianceRequirement').setValue(that.complianceRequirement);
    }
    if (that.projectInfo.features.permission) {
      that.permission = that.projectInfo.features.permission;
      that.projectBasicInfo.get('features').get('permission').setValue(that.permission);
    }
    if (that.projectInfo.features.advertisements) {
      that.advertisements = that.projectInfo.features.advertisements;
      that.projectBasicInfo.get('features').get('advertisements').setValue(that.advertisements);
    }
    if (that.projectInfo.features.questionAnswer) {
      that.questionAnswer = that.projectInfo.features.questionAnswer;
      that.projectBasicInfo.get('features').get('questionAnswer').setValue(that.questionAnswer);
    }
    setTimeout(() => {
      this.createProjectFeatureSteps(that);
    }, CommonConfig.thousand);
  }

  updateProjectStepState(that, isRequired: boolean): void {
    this.setProjectBasicState(that, isRequired);
    this.lineItemState(that);
    this.setProjectFeaturesState(that);
    if (that.mediaList.length > this.commonConfig.zero) {
      that.state4 = this.commonConfig.actionState.complete;
    } else {
      that.state4 = this.commonConfig.actionState.none;
    }
    if (that.filesDocumentsList.length > this.commonConfig.zero) {
      that.state6 = this.commonConfig.actionState.complete;
    } else {
      that.state6 = this.commonConfig.actionState.none;
    }
    this.walkthroughSchedulerState(that);
    this.questionAnswersState(that);
  }

  setProjectBasicState(that, isRequired) {
    if (that.projectType > this.commonConfig.zero) {
      that.state1 = this.commonConfig.actionState.complete;
    } else {
      that.state1 = this.commonConfig.actionState.none;
    }
    if (that.projectId !== '') {
      that.state2 = this.commonConfig.actionState.complete;
    } else {
      if (isRequired) {
        that.state2 = this.commonConfig.actionState.required;
      } else {
        that.state2 = this.commonConfig.actionState.none;
      }
    }
  }

  setProjectFeaturesState(that) {
    that.chooseSealedBidState();
    this.lineItemState(that);
    if (that.mediaGallery || that.filesDocuments || that.walkthroughScheduler || that.questionAnswer || that.complianceRequirement
      || that.advertisements || that.permission) {
      that.state3 = this.commonConfig.actionState.complete;
    } else {
      that.state3 = this.commonConfig.actionState.none;
    }
  }

  /**
   * @description Function for set choose line item complete or not
   * @param {any} that contain the component object
   */
  lineItemState(that) {
    that.lineItemState = (that.projectInfo && that.projectInfo.lineItems && that.projectInfo.lineItems.length)
      ? this.commonConfig.actionState.complete : this.commonConfig.actionState.none;
  }

  /**
    * @description Function for set walkthrough schedule or not
    * @param {any} that contain the component object
  */
  walkthroughSchedulerState(that) {
    this.walkthroughStatus.subscribe((status: { isComplete?: boolean }) => {
      if (status && status.isComplete) {
        that.state7 = this.commonConfig.actionState.complete;
      } else {
        that.state7 = this.commonConfig.actionState.none;
      }
    });
  }

  /**
    * @description Function for set question & answers complete or not
    * @param {any} that contain the component object
  */
  questionAnswersState(that) {
    if (that.projectInfo && that.projectInfo.questionAnswers && that.projectInfo.questionAnswers.length > 0) {
      that.state8 = this.commonConfig.actionState.complete;
    } else {
      that.state8 = this.commonConfig.actionState.none;
      this.questionAnswersStatus.subscribe((status: { isComplete?: boolean }) => {
        if (status && status.isComplete && that.questionAnswer) {
          that.state8 = this.commonConfig.actionState.complete;
        }
      });
    }
  }

  walkthroughUpdate(status: boolean) {
    this.walkthrough.next({ isComplete: status });
  }

  questionAnswersUpdate(status: boolean) {
    this.questionAnswers.next({ isComplete: status });
  }

  chooseFeatureLengthUpdate(list) {
    this.chooseFeature.next({ items: list });
  }

  removeProjectPermissions(that) {
    that._dialogService.openConfirm({
      message: this.commonConfig.popupAlertMessage.projectPermission,
      acceptButton: this.commonConfig.dialogService.accept,
      cancelButton: this.commonConfig.dialogService.cancel
    })
      .afterClosed().subscribe((accept: boolean) => {
        if (accept) {
          that.permission = false;
          that.projectBasicInfo.get('features').get('permission').setValue(that.permission);
          that.projectInfo['permission'] = null;
          this.createProjectFeatureSteps(that);
          that.setIsAddendums();
          this.customService.showSnackBarSuccess(this.commonConfig.successMessage.projectPermission);
          this.ganalyticsService.analyticsTrackEvent(trackEvent.removeProjectPermission, that.projectId);
        }
      });
  }

  removeProjectPublications(that) {
    that._dialogService.openConfirm({
      message: this.commonConfig.popupAlertMessage.projectPublication,
      acceptButton: this.commonConfig.dialogService.accept,
      cancelButton: this.commonConfig.dialogService.cancel
    })
      .afterClosed().subscribe((accept: boolean) => {
        if (accept) {
          const features = that.projectInfo.features;
          Object.assign(features, { advertisements: false });
          this.projectsService.updateProjectData(that.projectId, { features, publication: [], publicationDescription: '', publicationTitle: '' });
          that.advertisements = false;
          that.projectInfo.advertisements = null;
          that.projectInfo.publication = [];
          that.projectInfo.publicationDescription = '';
          that.projectInfo.publicationTitle = '';
          that.setIsAddendums();
          this.customService.showSnackBarSuccess(this.commonConfig.successMessage.projectPublication);
          this.ganalyticsService.analyticsTrackEvent(trackEvent.removeProjectPublication, that.projectId);
        }
      });
  }

  /**
   * @description Function used for remove question and answer
   * @param {any} that contain the component object
   */
  removeQuestionAnswer(that) {
    that._dialogService.openConfirm({
      message: this.commonConfig.popupAlertMessage.questionAnswer,
      acceptButton: this.commonConfig.dialogService.accept,
      cancelButton: this.commonConfig.dialogService.cancel
    })
      .afterClosed().subscribe((accept: boolean) => {
        if (accept) {
          that.questionAnswer = false;
          that.projectBasicInfo.get('features').get('questionAnswer').setValue(that.questionAnswer);
          this.createProjectFeatureSteps(that);
          that.projectInfo.questionAnswers = [];
          that.firestoreService.update(`${this.commonConfig.collectionName.project}/${that.projectId}`, { questionAnswers: [] });
          that.setIsAddendums();
          this.customService.showSnackBarSuccess(this.commonConfig.successMessage.questionAnswer);
        }
      });
  }

  /**
   * @description Function used for remove all walkthrough
   * @param {any} that contain the component object
   */
  removeWalkthroughScheduler(that) {
    that._dialogService.openConfirm({
      message: this.commonConfig.popupAlertMessage.walkthroughFeature,
      acceptButton: this.commonConfig.dialogService.accept,
      cancelButton: this.commonConfig.dialogService.cancel
    })
      .afterClosed().subscribe((accept: boolean) => {
        if (accept) {
          const walkthroughSubscribe = this.projectWtService.getWalkthroughScheduler(that.projectId).subscribe(results => {
            if (results.length) {
              that.setIsAddendums();
              this.walkthroughUpdate(false);
              this.deleteAllWalkthroughData(that, results);
            }
            walkthroughSubscribe.unsubscribe();
          });
          that.walkthroughScheduler = false;
          that.projectBasicInfo.get('features').get('walkthroughScheduler').setValue(that.walkthroughScheduler);
          this.projectWtService.updateProjectVendorWtRequest(that.projectId, false);
          this.createProjectFeatureSteps(that);
          this.customService.showSnackBarSuccess(that.commonConfig.successMessage.walkthrough);
        }
      });
  }

  deleteAllWalkthroughData(that, results) {
    results.forEach(async (walkthrough) => {
      if (walkthrough && walkthrough.id) {
        const isDeleted = await this.projectActivityService.deleteWalkthroughScheduler(walkthrough.id);
        if (isDeleted && walkthrough.bookVendorId) {
          for (const vendorId of walkthrough.bookVendorId) {
            const requireData = {
              authUid: that.authUid,
              vendorId,
              projects: that.projectInfo
            };
            (walkthrough.from.toDate() > new Date()) && this.projectActivityService.sendCancelCalendarNotification(walkthrough, requireData);
          }
        }
      }
    });
  }

  // Create project feature steps for managing next button feature
  createProjectFeatureSteps(thisObj) {
    const selectedFeatures = thisObj.projectBasicInfo.get('features').value;
    thisObj.projectFeatureArr = [];
    if (selectedFeatures.mediaGallery) {
      thisObj.projectFeatureArr.push(thisObj.step4Ele);
    }
    if (selectedFeatures.filesDocuments) {
      thisObj.projectFeatureArr.push(thisObj.step6Ele);
    }
    if (selectedFeatures.walkthroughScheduler) {
      thisObj.projectFeatureArr.push(thisObj.step7Ele);
    }
    if (selectedFeatures.complianceRequirement) {
      thisObj.projectFeatureArr.push(thisObj.complianceStep);
    }
    if (selectedFeatures.permission) {
      thisObj.projectFeatureArr.push(thisObj.permissionStep);
    }
    if (selectedFeatures.advertisements) {
      thisObj.projectFeatureArr.push(thisObj.advertisementsStep);
    }
    if (selectedFeatures.questionAnswer) {
      thisObj.projectFeatureArr.push(thisObj.step8Ele);
    }
    this.chooseFeatureLengthUpdate(thisObj.projectBasicInfo.get('features').value);
  }

  manageProjectFeatureSteps(thisObj, stepFrom) {
    const projectFeatureArr = thisObj.projectFeatureArr;
    const currentProjectFeatureIndex = projectFeatureArr.indexOf(stepFrom);
    if (projectFeatureArr.length > CommonConfig.zero
      && currentProjectFeatureIndex !== -1
      && currentProjectFeatureIndex !== projectFeatureArr.length - CommonConfig.one
    ) {
      const theIndx = Number(currentProjectFeatureIndex) + CommonConfig.one;
      thisObj.projectFeatureArr[theIndx].active = true;
    }
  }

}

