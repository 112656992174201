import { Component, OnInit } from '@angular/core';
import { CategoryService } from '@shared/service/category/category.service';
import { CustomService } from '@shared/service/custom/custom.service';
import { CommonConfig } from '@shared/common.config';

@Component({
  selector: 'app-suggest-new-category',
  templateUrl: './suggest-new-category.component.html',
  styleUrls: ['./suggest-new-category.component.scss']
})
export class SuggestNewCategoryComponent implements OnInit {

  dialogRef;
  parentObject;
  name: string;
  commonConfig = CommonConfig;

  constructor(private readonly categoryService: CategoryService,
    private readonly customService: CustomService) { }

  ngOnInit() {
  }

  continue() {
    const data = {};
    data['categoryName'] = this.name;
    data['authUser'] = this.parentObject.authUser ? this.parentObject.authUser.displayName : this.parentObject.authUser.email;
    this.categoryService.suggestNewCategory(data);
    this.customService.showSnackBarSuccess(this.commonConfig.successMessage.categorySuggested);
    this.dialogRef.close();
  }

}
