import { Injectable } from '@angular/core';
// Config
import { CommonConfig } from '@shared/common.config';
import { OrganizationConfig } from '@shared/organization.config';
// Model
import { ImageInformation } from '@app/core/model/image-information';
import { FileInformation } from '@app/core/model/file-information';
// Service
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { CustomService } from '@shared/service/custom/custom.service';
import { FilestackService } from '@shared/service/filestack/filestack.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectFileService {
  private readonly commonConfig = CommonConfig;
  public organizationConfig = OrganizationConfig;

  constructor(
    private readonly fs: FirestoreService,
    private readonly customService: CustomService,
    private readonly filestackService: FilestackService
  ) { }

  fetchMediaList(that) {
    that.photoSubscribe = that.firestoreService.colWithIds$(this.commonConfig.collectionName.document,
      this.fileDocumentQueryRef(that.projectId, this.commonConfig.projectFeatures.media))
      .subscribe((response: ImageInformation) => {
        that.mediaList = response;
        const photoList = this.filterMediaGallery(this.commonConfig.mimeType.image, that.mediaList);
        if (photoList.length > CommonConfig.zero) {
          let photoUrl;
          if (that.projectInfo.imageUrl) {
            photoUrl = that.projectInfo.imageUrl;
          } else {
            photoUrl = photoList[CommonConfig.zero].url;
          }
          that.updateProjectPhotoUrl(photoUrl);
        }
        that.update(this.commonConfig.steps.one, false);
      });
  }

  fetchFileDocumentsList(that) {
    that.fileSubscribe = that.firestoreService.colWithIds$(this.commonConfig.collectionName.document,
      this.fileDocumentQueryRef(that.projectId, this.commonConfig.projectFeatures.files))
      .subscribe((response: FileInformation) => {
        that.filesDocumentsList = response;
        that.update(this.commonConfig.steps.one, false);
      });
  }

  /**
   * @description Function to for set query to get all type file documents
   * @param {string} projectId contain a project id value
   * @param {string} type contain a type of project documents
   */
   fileDocumentQueryRef(projectId: string, type: string) {
    return (ref) => ref.where('referenceId', '==',
      projectId).where('documentFileType', '==', type).
      where('belongTo', '==', this.commonConfig.belongTo.project);
  }

  /**
   * @description Function to filter media for get image or video
   * @param {string} mimeType contain a mime type value
   * @param {array} mediaList contain a list of media in array of object
   */
   filterMediaGallery(mimeType: string, mediaList) {
    return mediaList.filter(media => (media.mimetype.includes(mimeType)));
  }

  updatePhotoVideoFileList(that, type: string) {
    switch (type) {
      case this.commonConfig.projectFeatures.media:
        this.fetchMediaList(that);
        break;
      case this.commonConfig.projectFeatures.files:
        this.fetchFileDocumentsList(that);
        break;
    }
  }

  uploadPhotoVideoFiles(that, type: string) {
    switch (type) {
      case this.commonConfig.projectFeatures.media:
        that.documentFileType = this.commonConfig.projectFeatures.media;
        that.maxFiles = this.commonConfig.maxProjectMedia - that.mediaList.length;
        that.fileAccept = this.commonConfig.acceptProjectMedia;
        break;
      case this.commonConfig.projectFeatures.files:
        that.documentFileType = this.commonConfig.projectFeatures.files;
        that.maxFiles = this.commonConfig.maxProjectFiles - that.filesDocumentsList.length;
        that.fileAccept = this.commonConfig.acceptProjectFiles;
        break;
    }
    this.filestackService.openPicker(that);
  }

  removePhotoVideoFiles(that, dataObject, message: string) {
    if (dataObject && dataObject.documentFileType) {
      that.documentFileType = dataObject.documentFileType;
      this.setRemoveMediaAlertMsg(that, dataObject);
      that._dialogService.openConfirm({
        message,
        acceptButton: this.commonConfig.dialogService.accept,
        cancelButton: this.commonConfig.dialogService.cancel
      }).
        afterClosed().subscribe((accept: boolean) => {
          if (accept) {
            this.removeDocuments(that, dataObject, that.alertMessage, true);
          }
        });
    }
  }

  setRemoveMediaAlertMsg(that, dataObject) {
    switch (dataObject.documentFileType) {
      case this.commonConfig.projectFeatures.media:
        that.alertMessage = this.commonConfig.successMessage.mediaGallery;
        break;
      case this.commonConfig.projectFeatures.files:
        that.alertMessage = this.commonConfig.successMessage.files;
        break;
      default:
        that.alertMessage = this.commonConfig.successMessage.photo;
        break;
    }
  }

  removeAllPhotoVideoFiles(that, type: string, isMessageShow: boolean) {
    that._dialogService.openConfirm({
      message: this.commonConfig.popupAlertMessage.projectFeature,
      acceptButton: this.commonConfig.dialogService.accept,
      cancelButton: this.commonConfig.dialogService.cancel
    }).
      afterClosed().subscribe((accept: boolean) => {
        if (accept) {
          this.deleteAllPhotoVideoFiles(that, type, isMessageShow);
        }
      });
  }

  deleteAllPhotoVideoFiles(that, type: string, isMessageShow: boolean) {
    that.documentFileType = type;
    switch (type) {
      case this.commonConfig.projectFeatures.media:
        this.deleteAllMediaData(that, isMessageShow);
        break;
      case this.commonConfig.projectFeatures.files:
        this.deleteAllFileData(that, isMessageShow);
        break;
    }
  }

  deleteAllMediaData(that, isMessageShow: boolean) {
    if (that.mediaList.length > 0) {
      const last = that.mediaList[that.mediaList.length - 1];
      for (const data of that.mediaList) {
        let isShowMessage = false;
        if (last.id === data.id && isMessageShow) {
          isShowMessage = true;
        }
        this.removeDocuments(that, data, this.commonConfig.successMessage.mediaGallery, isShowMessage);
      }
    }
    that.mediaGallery = false;
    that.projectBasicInfo.get('features').get('mediaGallery').setValue(that.mediaGallery);
  }

  deleteAllFileData(that, isMessageShow: boolean) {
    if (that.filesDocumentsList.length > 0) {
      const last = that.filesDocumentsList[that.filesDocumentsList.length - 1];
      for (const data of that.filesDocumentsList) {
        let isShowMessage = false;
        if (last.id === data.id && isMessageShow) {
          isShowMessage = true;
        }
        this.removeDocuments(that, data, this.commonConfig.successMessage.filesDoc, isShowMessage);
      }
    }
    that.filesDocuments = false;
    that.projectBasicInfo.get('features').get('filesDocuments').setValue(that.filesDocuments);
  }

  removeDocuments(that, dataObject, message: string, showMessage: boolean) {
    this.fs.delete(`${this.commonConfig.collectionName.document}/${dataObject.id}`)
      .then(res => {
        if (showMessage) {
          that.setIsAddendums();
          this.customService.showSnackBarSuccess(message);
          this.updatePhotoVideoFileList(that, that.documentFileType);
        }
      }).catch(error => {
        if (showMessage) {
          this.customService.showSnackBarError(this.commonConfig.validationMessage.someError);
        }
      });
  }

}
