export const EMAIL_NOTIFICATION = {
  project: {
    bidStartAlert: {
      subject: 'Project bidding has started',
      template: 'd-91dd9ec869f74722baedddeced9eb6fd'
    },
    bidEndAlert: {
      subject: 'Project bidding has been ended',
      template: 'd-e61c11af86974115af3510713416d540'
    },
    projectActivityAlert: {
      subject: 'Project Activity Alert',
      template: 'd-a14fddc2ba414f0e963f89faff55ae9e'
    },
    needRevision: {
      subject: 'Project needs revision request',
      template: 'd-d8038252fa124c4fb4710b7a6f5b8e95'
    },
    projectAccept: {
      subject: 'Project Accepted',
      template: 'd-82b891d0971744e790b807bb2fe6b30b'
    },
    projectReject: {
      subject: 'Project Rejected',
      template: 'd-bd42bcea177e4e6c9fb4cbb7e05b0784'
    },
    projectCreate: {
      subject: 'Project Created',
      template: 'd-bb305ade9cc1437cb36e3561a2385a76'
    },
    projectUpdate: {
      subject: 'Project Updated',
      template: 'd-1273c4ca46e74773aeaf232397aca11a'
    },
    addProjectMember: {
      subject: 'Project Member Added',
      template: 'd-d4036d6db51e439993a845939fa1026e'
    },
    removeProjectMember: {
      subject: 'Project Member Removed',
      template: 'd-327a19a9b7d54aae94f235e6ba43b4e3'
    },
    projectCreatedEmailToAdmin: {
      subject: 'New Project Request Created',
      template: 'd-af73634a0de34dcea01b5d0fe656336f'
    },
    bidAcceptEmailToAdmin: {
      subject: 'Bid Accepted On Ventract Platform',
      template: 'd-05ed718a2576413b869cf5561a4eec5b'
    },
    inviteVendorsForBid: {
      subject: 'New project has been created, You can bid on it',
      template: 'd-7881e01ad17d4afeb799a920ed2844fc'
    },
    projectPublication: {
      subject: 'Project Publication',
      template: 'd-18f9c279999a48fd9eb4b03bc4a79a05'
    },
    addendumsEmailSubject: {
      subject: 'New Addendums Added',
      template: 'd-cb0951bd17104502943690eb8851a091'
    },
    expressProjectReminder: {
      subject: 'Express Project Missing Field Reminder',
      template: 'd-f26b25388d5b424e889d4c33a0172ab2'
    },
    bidLineItemsChange: {
      subject: 'Material bid line items change',
      template: 'd-82ed50a514214f96ad447a759fa4dc60'
    }
  },
  vendor: {
    vendorAccept: {
      subject: 'Vendor Accepted',
      template: 'd-374fdc1ab1894a49a00ce11fc5d311cf'
    },
    vendorReject: {
      subject: 'Vendor Rejected',
      template: 'd-66c3b520ef104420a2b8bc9015cefe20'
    },
    vendorCreate: {
      subject: 'Vendor Created',
      template: 'd-976796888b5b4647bf06da7960100ac8'
    },
    vendorCreateRequest: {
      subject: 'Vendor Create Request',
      template: 'd-4943bf02c5974bab9e93c4b3d942e181'
    },
    vendorUpdate: {
      subject: 'Vendor Updated',
      template: 'd-713638a688cd4fb7b171436aeb7a87d4'
    },
    vendorRevision: {
      subject: 'Vendor Profile Needs Revision Request',
      template: 'd-32b2e1f6d911465dbdd20004325a0640'
    },
    vendorPending: {
      subject: 'Vendor Profile Status Moved In Pending',
      template: 'd-6f4384792b264ff79295dd9299a321de'
    },
    vendorDisable: {
      subject: 'Vendor Profile Disabled',
      template: 'd-2f178a559424480ea43844fce810c8c8'
    },
    addVendorMember: {
      subject: 'Vendor Member Added',
      template: 'd-d6a24acd8c4d48c7adbc0a99afe85d63'
    },
    removeVendorMember: {
      subject: 'Vendor Member Removed',
      template: 'd-e4432fdee70a45cc94d0ad2baf90e781'
    },
    vendorCreatedEmailToAdmin: {
      subject: 'New Vendor Request Created',
      template: 'd-7cee22465c324ad1ad60e389e616b943'
    },
    claimVendorRequestReceived: {
      subject: 'New Claim Vendor Request Received',
      template: 'd-85e7621a95d44567814c7de6c36e22f9'
    },
  },
  system: {
    welcomeEmail: {
      subject: 'Welcome to Ventract Platform',
      template: 'd-ab575a19e8e74c609e14550b8994fb9b'
    },
    newUserRegistered: {
      subject: 'New User Registered on Ventract Platform',
      template: 'd-1942801bfb6744c3a17e93e980224d54'
    },
    createOrganization: {
      subject: 'New organization created',
      template: ''
    },
    acceptOrganization: {
      subject: 'Your organization request approved',
      template: ''
    },
    rejectOrganization: {
      subject: 'Your organization request rejected',
      template: ''
    },
    inviteUserToOrganization: {
      subject: 'Invitated To Organization',
      template: ''
    },
    joinUserToOrganization: {
      subject: 'Organization Join Request',
      template: ''
    },
    leaveOrganizationReq: {
      subject: 'Organization Leave Request',
      template: 'd-ca8e9de307f3478f99c94beaefd10b81'
    }
  }
};
