const eventName = {
    signUp: 'Sign Up',
    loggedIn: 'Logged In',
    forgotPassword: 'Forgot Password',
    editProfile: 'Edit Profile',
    changePassword: 'Change Password',
    completeProfile: 'Complete Profile',
    publishProject: 'Publish Project',
    projectSaveAsDraft: 'Project Save As a Draft',
    projectUpdated: 'Project Updated',
    bidCreated: 'Bid Created',
    bidUpdated: 'Bid Updated',
    quoteCreated: 'Quote Created',
    quoteUpdated: 'Quote Updated',
    bidPlaced: 'Bid Placed',
    upgradeSubscription: 'Upgrade Subscription',
    projectViewed: 'Project Viewed'
};
export const trackMessages = {
    signUp: {
        category: eventName.signUp,
        label: 'New user signed up successfully',
        eventName: eventName.signUp
    },
    signUpError: {
        category: 'Error In Sign Up',
        label: 'Error in signup',
        eventName: eventName.signUp
    },
    login: {
        category: 'Login',
        label: 'Logged in successfully',
        eventName: eventName.loggedIn
    },
    loginError: {
        category: 'Error In Login',
        label: 'Error in login',
        eventName: eventName.loggedIn
    },
    loginByGoogle: {
        category: 'Login By Google',
        label: 'Logged in by google successfully',
        eventName: eventName.loggedIn
    },
    loginErrorByGoogle: {
        category: 'Error In Login By Google ',
        label: 'Error in login by google',
        eventName: eventName.loggedIn
    },
    forgotPassword: {
        category: eventName.forgotPassword,
        label: 'Forgot password request sent successfully',
        eventName: eventName.forgotPassword
    },
    forgotPasswordError: {
        category: 'Error In Forgot Password',
        label: 'Error in Forgot password',
        eventName: eventName.forgotPassword
    },
    editProfile: {
        category: eventName.editProfile,
        label: 'Updated profile info successfully',
        eventName: eventName.editProfile
    },
    editProfileError: {
        category: 'Error In Edit Profile ',
        label: 'Error in updating profile info',
        eventName: eventName.editProfile
    },
    changePassword: {
        category: eventName.changePassword,
        label: 'Password updated successfully',
        eventName: eventName.changePassword
    },
    changePasswordError: {
        category: 'Error In Change Password',
        label: 'Error in change password',
        eventName: eventName.changePassword
    },
    completeProfile: {
        category: eventName.completeProfile,
        label: 'Completed profile info successfully',
        eventName: eventName.completeProfile
    },
    completeProfileError: {
        category: 'Error In Complete Profile',
        label: 'Error in complete profile info',
        eventName: eventName.completeProfile
    },
    selectSubscription: {
        category: 'Subscription Type',
        label: 'Subscription type selected',
        eventName: 'Subscription Selection'
    },
    projectPublish: {
        category: eventName.publishProject,
        label: 'New project {projectId} has been published successfully',
        eventName: eventName.publishProject
    },
    projectSaveAsDraft: {
        category: eventName.projectSaveAsDraft,
        label: 'Project {projectId} saved as a draft successfully',
        eventName: eventName.projectSaveAsDraft
    },
    projectUpdated: {
        category: eventName.projectUpdated,
        label: 'Project {projectId} has been updated successfully',
        eventName: eventName.projectUpdated
    },
    projectPublishError: {
        category: 'Error In Publish Project',
        label: 'Error in publish project {projectId}',
        eventName: eventName.publishProject
    },
    vendorProfilePublish: {
        category: 'Publish Vendor Profile',
        label: 'Vendor profile published successfully',
        eventName: 'Publish Vendor Profile'
    },
    saveVendorProfile: {
        category: 'Save Vendor Profile',
        label: 'Vendor profile saved successfully',
        eventName: 'Save Vendor Profile'
    },
    vendorProfileRequest: {
        category: 'Vendor Profile Request',
        label: 'Vendor profile request has been successfully created but it is under review.',
        eventName: 'Vendor Project Request'
    },
    vendorProjectsDelete: {
        category: 'Delete Vendor Project',
        label: 'Vendor project deleted successfully',
        eventName: 'Delete Vendor Project'
    },
    vendorPublishError: {
        category: 'Error In Publish Vendor',
        label: 'Error in publish vendor',
        eventName: 'Publish Vendor'
    },
    bidCreated: {
        category: eventName.bidCreated,
        label: 'Bid created for project {projectId}',
        eventName: eventName.bidCreated
    },
    bidUpdate: {
        category: eventName.bidUpdated,
        label: 'Bid updated for project {projectId}',
        eventName: eventName.bidUpdated
    },
    bidCreatedError: {
        category: 'Error In Create Bid',
        label: 'Error in bid creation for project {projectId}',
        eventName: eventName.bidCreated
    },
    bidUpdateError: {
        category: 'Error In Update Bid',
        label: 'Error in bid update for project {projectId}',
        eventName: eventName.bidUpdated
    },
    quoteCreated: {
        category: eventName.quoteCreated,
        label: 'Quote created for project {projectId}',
        eventName: eventName.quoteCreated
    },
    quoteUpdate: {
        category: eventName.quoteUpdated,
        label: 'Quote updated for project {projectId}',
        eventName: eventName.quoteUpdated
    },
    quoteCreatedError: {
        category: 'Error In Creating Quote',
        label: 'Error in quote create for project {projectId}',
        eventName: eventName.quoteCreated
    },
    quoteUpdateError: {
        category: 'Error In Quote Updated',
        label: 'Error in quote updated for project {projectId}',
        eventName: eventName.quoteUpdated
    },
    needRevision: {
        category: 'Revision For Bid',
        label: 'Revision request for project {projectId}',
        eventName: 'Revision For Bid'
    },
    acceptBid: {
        category: 'Bid Accepted',
        label: 'Bid accepted for project {projectId}',
        eventName: 'Bid Accepted'
    },
    unacceptBid: {
        category: 'Bid Unaccepted',
        label: 'Bid unaccepted for project {projectId}',
        eventName: 'Bid Unaccepted'
    },
    rejectBid: {
        category: 'Bid Rejected',
        label: 'Bid rejected for project {projectId}',
        eventName: 'Bid Rejected'
    },
    acceptQuote: {
        category: 'Quote Accepted',
        label: 'Quote accepted for project {projectId}',
        eventName: 'Quote Accepted'
    },
    unacceptQuote: {
        category: 'Quote Unaccepted',
        label: 'Quote unaccepted for project {projectId}',
        eventName: 'Quote Unaccepted'
    },
    rejectQuote: {
        category: 'Quote Rejected',
        label: 'Quote rejected for project {projectId}',
        eventName: 'Quote Rejected'
    },
    bidError: {
        category: 'Error In Bid',
        label: 'Error in create/update bid for project {projectId}',
        eventName: 'Bid Error'
    },
    quoteError: {
        category: 'Error In Quote',
        label: 'Error in create/update Quote for project {projectId}',
        eventName: 'Quote Error'
    },
    addService: {
        category: 'Add Service',
        label: 'New service added successfully',
        eventName: 'Add Service'
    },
    addServiceError: {
        category: 'Error In Add Service',
        label: 'Error in adding a new service',
        eventName: 'Add Service Error'
    },
    updateService: {
        category: 'Update Service',
        label: 'Service updated successfully',
        eventName: 'Update Service'
    },
    updateServiceError: {
        category: 'Error In Update Service',
        label: 'Error in updating service',
        eventName: 'Update Service Error'
    },
    addProduct: {
        category: 'Add Product',
        label: 'New Product added successfully',
        eventName: 'Add Product'
    },
    addProductError: {
        category: 'Error In Add Product',
        label: 'Error in adding a new Product',
        eventName: 'Add Product Error'
    },
    updateProduct: {
        category: 'Update Product',
        label: 'Product updated successfully',
        eventName: 'Update Product'
    },
    updateProductError: {
        category: 'Error In Update Product',
        label: 'Error in updating Product',
        eventName: 'Update Product Error'
    },
    addProjectMember: {
        category: 'Add Project Member',
        label: 'Project {projectId} member added successfully',
        eventName: 'Add Project Member'
    },
    addProjectMemberError: {
        category: 'Error In Adding Project Member',
        label: 'Error in adding a project member {projectId}',
        eventName: 'Add Project Member Error'
    },
    addVendorMember: {
        category: 'Add Vendor Member',
        label: 'Vendor member added successfully',
        eventName: 'Add Vendor'
    },
    addVendorMemberError: {
        category: 'Add Vendor Member',
        label: 'Error in adding a vendor member',
        eventName: 'Add Vendor Error'
    },
    removeProjectMember: {
        category: 'Remove Project Member',
        label: 'Project {projectId} member removed successfully',
        eventName: 'Remove Project Member'
    },
    removeProjectMemberError: {
        category: 'Error In Removing Project Member',
        label: 'Error in removing project member {projectId}',
        eventName: 'Remove Project Member Error'
    },
    removeVendorMember: {
        category: 'Remove Vendor Member',
        label: 'Vendor member removed successfully',
        eventName: 'Remove Vendor'
    },
    removeVendorMemberError: {
        category: 'Remove Vendor Member',
        label: 'Error in removing a vendor member',
        eventName: 'Remove Vendor Error'
    },
    projectAdvertisement: {
        category: 'Project Advertisement',
        label: 'Selected project {projectId} advertisement option',
        eventName: 'Added Project Advertisement'
    },
    projectAdvertisementError: {
        category: 'Project Advertisement',
        label: 'Error during save of project {projectId} advertisement option',
        eventName: 'Error Project Advertisement'
    },
    addOnPayment: {
        category: 'Addon Added',
        label: 'Addon payment successfull',
        eventName: 'Add Addon'
    },
    addOnPaymentError: {
        category: 'Addon Error',
        label: 'Error in addon payment',
        eventName: 'Add Addon Error'
    },
    addProjectCompliance: {
        category: 'Project Compliance Added',
        label: 'Project {projectId} compliance added successfully',
        eventName: 'Add Project Compliance'
    },
    addProjectComplianceError: {
        category: 'Project Compliance Error',
        label: 'Error in project {projectId} compliance feature',
        eventName: 'Add Project Compliance Error'
    },
    removeProjectCompliance: {
        category: 'Project Compliance Remove',
        label: 'Project {projectId} compliance removed successfully',
        eventName: 'Remove Project Compliance'
    },
    removeProjectPermission: {
        category: 'Project Permission Remove',
        label: 'Project {projectId} permission removed successfully',
        eventName: 'Remove Project Permission'
    },
    removeProjectPublication: {
        category: 'Project Publication Remove',
        label: 'Project {projectId} publication removed successfully',
        eventName: 'Remove Project Publication'
    },
    addWalkThrough: {
        category: 'WalkThrough Added',
        label: 'WalkThrough added successfully {projectId}',
        eventName: 'Add WalkThrough'
    },
    addWalkThroughError: {
        category: 'WalkThrough Error',
        label: 'Error in walkThrough feature {projectId}',
        eventName: 'Add WalkThrough Error'
    },
    editWalkThrough: {
        category: 'Edit WalkThrough',
        label: 'WalkThrough updated successfully {projectId}',
        eventName: 'Edit WalkThrough'
    },
    editWalkThroughError: {
        category: 'Edit WalkThrough Error',
        label: 'Error in edit walkThrough feature {projectId}',
        eventName: 'Edit WalkThrough Error'
    },
    removeWalkThrough: {
        category: 'WalkThrough Remove',
        label: 'WalkThrough removed successfully {projectId}',
        eventName: 'Remove WalkThrough'
    },
    removeWalkThroughError: {
        category: 'WalkThrough Remove Error',
        label: 'Error in walkThrough scheduler feature {projectId}',
        eventName: 'Remove WalkThrough Error'
    },
    addAddendums: {
        category: 'Addendums Added',
        label: 'Addendum added successfully {projectId}',
        eventName: 'Add Addendums'
    },
    addAddendumsError: {
        category: 'Addendums Error',
        label: 'Error in addendums {projectId}',
        eventName: 'Addendums Error'
    },
    upgradeSubscription: {
        category: eventName.upgradeSubscription,
        label: 'Upgraded subscription plan successfully',
        eventName: eventName.upgradeSubscription
    },
    bidPlaced: {
        category: eventName.bidPlaced,
        label: 'Bid placed for project {projectId}',
        eventName: eventName.bidPlaced
    },
    bidPlacedError: {
        category: 'Error In Bid Placed',
        label: 'Error in placing a bid for the project {projectId}',
        eventName: eventName.bidPlaced
    },
    upgradeSubscriptionError: {
        category: 'Error In Upgrade Subscription Plan',
        label: 'Error in upgrade subscription plan',
        eventName: eventName.upgradeSubscription
    },
    addVendorProject: {
        category: 'Vendor Project Added',
        label: 'Vendor project saved successfully',
        eventName: 'Add Vendor Project'
    },
    removeVendorProject: {
        category: 'Vendor Project Remove',
        label: 'Vendor project removed successfully',
        eventName: 'Remove Vendor Project'
    },
    addVendorCompliance: {
        category: 'Vendor Compliance Added',
        label: 'Vendor compliance added successfully',
        eventName: 'Add Vendor Compliance'
    },
    addVendorComplianceError: {
        category: 'Vendor Compliance Error',
        label: 'Error in vendor compliance feature',
        eventName: 'Add Vendor Compliance Error'
    },
    removeVendorCompliance: {
        category: 'Vendor Compliance Remove',
        label: 'Vendor compliance removed successfully',
        eventName: 'Remove Vendor Compliance'
    },
    addVendorTestimonial: {
        category: 'Vendor Testimonial',
        label: ' Vendor testimonial saved successfully',
        eventName: 'Vendor Testimonial'
    },
    removeVendorTestimonial: {
        category: 'Vendor Testimonial Remove',
        label: 'Vendor testimonial removed successfully',
        eventName: 'Remove Vendor Testimonial'
    },
    addVendorLicenses: {
        category: 'Vendor Licenses Added',
        label: 'Vendor Licenses added successfully',
        eventName: 'Add Vendor Licenses'
    },
    removeVendorLicenses: {
        category: 'Vendor Licenses Remove',
        label: 'Vendor licenses removed successfully',
        eventName: 'Remove Vendor Licenses'
    },
    connectVendorToInstagram: {
        category: 'Vendor Connect to Instagram',
        label: ' Vendor connected to instagram successfully',
        eventName: 'Connect to Instagram'
    },
    connectVendorToInstagramError: {
        category: 'Vendor Error In Connect to Instagram',
        label: 'Error in vendor connect to instagram feature',
        eventName: 'Connect to Instagram Error'
    },
    removeConnectVendorToInstagram: {
        category: 'Remove Connect to Instagram',
        label: 'Vendor removed instagram successfully',
        eventName: 'Remove Connect to Instagram'
    },
    publicProjectClicked: {
        category: 'Clicked the call to action',
        eventName: 'Clicked the call to action'
    },
    projectViewed: {
        category: 'Project Viewed',
        label: 'Project {projectId} has been viewed successfully',
        eventName: eventName.projectViewed
    },
    logout: {
        category: 'Logout',
        label: 'Logged out successfully',
        eventName: 'Logout'
    }
};

export const trackEvent = {
    signUp: 'signUp',
    signUpError: 'signUpError',
    login: 'login',
    loginError: 'loginError',
    loginByGoogle: 'loginByGoogle',
    loginErrorByGoogle: 'loginErrorByGoogle',
    forgotPassword: 'forgotPassword',
    forgotPasswordError: 'forgotPasswordError',
    editProfile: 'editProfile',
    editProfileError: 'editProfileError',
    changePassword: 'changePassword',
    changePasswordError: 'changePasswordError',
    completeProfile: 'completeProfile',
    completeProfileError: 'completeProfileError',
    selectSubscription: 'selectSubscription',
    projectPublish: 'projectPublish',
    projectSaveAsDraft: 'projectSaveAsDraft',
    projectUpdated: 'projectUpdated',
    projectPublishError: 'projectPublishError',
    vendorProfilePublish: 'vendorProfilePublish',
    saveVendorProfile: 'saveVendorProfile',
    vendorProfileRequest: 'vendorProfileRequest',
    vendorProjectsDelete: 'vendorProjectsDelete',
    vendorPublishError: 'vendorPublishError',
    bidCreated: 'bidCreated',
    bidUpdate: 'bidUpdate',
    bidCreatedError: 'bidCreatedError',
    bidUpdateError: 'bidUpdateError',
    quoteCreated: 'quoteCreated',
    quoteUpdate: 'quoteUpdate',
    quoteCreatedError: 'quoteCreatedError',
    quoteUpdateError: 'quoteUpdateError',
    needRevision: 'needRevision',
    acceptBid: 'acceptBid',
    unacceptBid: 'unacceptBid',
    rejectBid: 'rejectBid',
    acceptQuote: 'acceptQuote',
    unacceptQuote: 'unacceptQuote',
    rejectQuote: 'rejectQuote',
    bidError: 'bidError',
    quoteError: 'quoteError',
    addService: 'addService',
    addServiceError: 'addServiceError',
    updateService: 'updateService',
    updateServiceError: 'updateServiceError',
    addProduct: 'addProduct',
    addProductError: 'addProductError',
    updateProduct: 'updateProduct',
    updateProductError: 'updateProductError',
    addProjectMember: 'addProjectMember',
    addProjectMemberError: 'addProjectMemberError',
    addVendorMember: 'addVendorMember',
    addVendorMemberError: 'addVendorMemberError',
    removeProjectMember: 'removeProjectMember',
    removeProjectMemberError: 'removeProjectMemberError',
    removeVendorMember: 'removeVendorMember',
    removeVendorMemberError: 'removeVendorMemberError',
    projectAdvertisement: 'projectAdvertisement',
    projectAdvertisementError: 'projectAdvertisementError',
    addOnPayment: 'addOnPayment',
    addOnPaymentError: 'addOnPaymentError',
    addProjectCompliance: 'addProjectCompliance',
    addProjectComplianceError: 'addProjectComplianceError',
    removeProjectPermission: 'removeProjectPermission',
    removeProjectPublication: 'removeProjectPublication',
    removeProjectCompliance: 'removeProjectCompliance',
    addWalkThrough: 'addWalkThrough',
    addWalkThroughError: 'addWalkThroughError',
    editWalkThrough: 'editWalkThrough',
    editWalkThroughError: 'editWalkThroughError',
    removeWalkThrough: 'removeWalkThrough',
    removeWalkThroughError: 'removeWalkThroughError',
    addAddendums: 'addAddendums',
    addAddendumsError: 'addAddendumsError',
    upgradeSubscription: 'upgradeSubscription',
    bidPlaced: 'bidPlaced',
    bidPlacedError: 'bidPlacedError',
    upgradeSubscriptionError: 'upgradeSubscriptionError',
    addVendorProject: 'addVendorProject',
    removeVendorProject: 'removeVendorProject',
    addVendorCompliance: 'addVendorCompliance',
    addVendorComplianceError: 'addVendorComplianceError',
    removeVendorCompliance: 'removeVendorCompliance',
    addVendorTestimonial: 'addVendorTestimonial',
    removeVendorTestimonial: 'removeVendorTestimonial',
    addVendorLicenses: 'addVendorLicenses',
    removeVendorLicenses: 'removeVendorLicenses',
    connectVendorToInstagram: 'connectVendorToInstagram',
    connectVendorToInstagramError: 'connectVendorToInstagramError',
    removeConnectVendorToInstagram: 'removeConnectVendorToInstagram',
    publicProjectClicked: 'publicProjectClicked',
    projectViewed: 'projectViewed',
    logout: 'logout'
};

export const headingText = {
    projectHeading: {
        bids: 'bids',
        bid: 'BID',
        bidding: 'Bidding',
        quotes: 'quotes',
        quote: 'Quote',
        competitive: 'Competitive Bid Details',
        sealedifb: 'IFB Bid Details',
        sealedrfp: 'RFP Bid Details',
        sealedrfq: 'RFQ Bid Details',
        ifb: 'IFB',
        rfp: 'RFP',
        rfq: 'RFQ',
        express: 'Express Bid Details',
        material: 'Material Bid Details',
    }
};

export const assetsData = {
    images: {
        check: 'assets/images/check.svg',
        testimonialsPlaceholder: '../../../../assets/images/testimonials-placeholder.png',
        intercomChat: '../../assets/images/intercom-chat.svg',
        userAvatar: 'assets/images/no-user-image-avatar.png',
        projectCoverPlaceholder: 'assets/images/project-details-cover-placeholder.png',
    }
};

export const ComplianceOptions = {
    bondingType: [{ key: 1, value: 'Performance' }, { key: 2, value: 'Material' },
    { key: 3, value: 'Payment' }, { key: 4, value: 'Bid' }],
    prevailingWage: [{ key: 1, value: 'Federal' }, { key: 2, value: 'State' },
    { key: 3, value: 'Davis Bacon' }]
};

export const AdvertisingProject = {
    data: [{ key: 1, value: 'Local' }, { key: 2, value: 'Regional' },
    { key: 3, value: 'Statewide' }, { key: 4, value: 'National' }]
};

export const OrgUserPermissions = {
    data: [{ key: 1, value: 'vendors' }, { key: 2, value: 'projects' },
    { key: 3, value: 'bids' }, { key: 4, value: 'users' }]
};

export const calEventTabs = {
    today: 'today',
    next: 'next',
    previous: 'previous'
};

export const colors = {
    red: {
        primary: '#ed0202',
        secondary: '#FAE3E3'
    },
    blue: {
        primary: '#4184f3',
        secondary: '#D1E8FF'
    },
    yellow: {
        primary: '#ffca28',
        secondary: '#FDF1BA'
    }
};

export const calendarIcsStatus = {
    tentative: 'TENTATIVE',
    confirmed: 'CONFIRMED',
    cancelled: 'CANCELLED'
};

export const projectTabLabel = {
    projectDetails: 'Project Details',
    questions: 'Questions',
    biddingHistory: 'Bidding History',
    addendums: 'Addendums',
    participants: 'Participants',
    viewActivity: 'View activity',
    mediaGallery: 'Media Gallery',
    filesDocuments: 'Files & Documents',
    scheduleWalkthrough: 'Schedule Walkthrough',
    faq: `FAQ's`,
    materialLineItems: `Material Line Items`,
    promotions: `Promotions`,
};

export const calendarInviteSubject = {
    addEvent: 'Calendar Invite: Project Walkthrough',
    cancelEvent: 'Cancelled Event: Project Walkthrough',
    updateEvent: 'Updated Event: Project Walkthrough ',
    requestEvent: 'Calendar Invite: Request Project Walkthrough',
};

export const icsMethods = {
    publish: 'PUBLISH',
    request: 'REQUEST',
    cancel: 'CANCEL'
};

export const compliancePermission = {
    yes: 'Yes',
    no: 'No'
};

export const PROJECT_PERMISSION = {
    PROJECT_PERMISSION_PUBLIC: 'public',
    PROJECT_PERMISSION_PRIVATE: 'private',
    PROJECT_PERMISSION_HIDDEN: 'hidden'
};

export const DeleteText = {
    deleteAd: 'Delete Ad',
    deleteAdTitle: 'ad',
    deleteVendor: 'Delete Vendor',
    deleteVendorTitle: 'vendor'
};

export const addonId = {
    advertisement: 'pf-advertisement'
};
export const DeleteProject = {
    deleteAd: 'Delete Project',
    deleteAdTitle: 'project'
};
export const DeleteUser = {
    deleteUser: 'Delete User',
    deleteUserTitle: 'user'
};
export const DISABLE_ADMIN_NOTIFICATIONS = 'Disable admin notifications for Bulk import';
export const NOTIFICATION_MODULES = {
    admin: 'admin',
    vendorInvite: 'vendorInvite'
};
export const BULK_IMPORT_ERROR_TYPE = {
    singleError: 'singleError',
    mailErrors: 'mailErrors'
};
const projectPreviewAltMsg = `You need to sign up and claim your Vendor profile before you can view project details and submit your bid.`;
export const ProjectPreviewAlert = {
    star: projectPreviewAltMsg,
    bid: projectPreviewAltMsg,
    common: projectPreviewAltMsg
};

export const auditReport = {
    alertMessage: 'Audit Report contains project info , project Bids Details. Are you sure, you want to generate audit report.',
    title: 'Generate Audit Report'
};

export const milliSecondInHour = 3600000;

export const TOTAL_PROJECT_PRICE = 'Total Project Price';

export const scrapeSourceType = {
    googlePlace: 1,
    serp: 2,
    bing: 3,
    yelpRest: 4,
};

export const ScrapeSources = {
    data: [
        { key: 1, value: 'Google Place' },
        { key: 2, value: 'Serp' }
    ]
};

export const DaysArr = [
    'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'
];

export const DaysObject = {
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
};
