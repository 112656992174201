import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
// Config
import { environment } from 'environments/environment';
import { CommonConfig } from '@shared/common.config';
// Model
import { User } from '@app/core/model/user';
import { UserActivity } from '@app/core/model/user-activity';
// Service
import { AuthService } from '@app/core/auth.service';
import { FirestoreService } from '@app/shared/service/firestore/firestore.service';
import { VendorsService } from '@app/shared/service/vendors/vendors.service';

@Injectable({
  providedIn: 'root'
})
export class UserActivityService {

  constructor(public fs: FirestoreService, public auth: AuthService, public vendorService: VendorsService) { }

  getHashKey(userId) {
    return CryptoJS.HmacSHA256(userId, environment.interComSecretKey).toString(CryptoJS.enc.Hex);
  }

  getUserActivity(that, userId: string): Promise<Partial<UserActivity[]>> {
    if (!that.userActivityLastKey) {
      that.userActivityLastKey = '';
    }
    return new Promise((resolve) => {
      const userRef = this.fs.fetchCollection(
        CommonConfig.collectionName.userActivity).ref
        .limit(CommonConfig.activityPageSize)
        .orderBy('createdAt', 'desc')
        .startAfter(that.userActivityLastKey)
        .where('allMentors', 'array-contains', userId).get();
      userRef.then((snapshot) => {
        const docs = snapshot.docs;
        const activityData = [];
        if (docs.length) {
          that.userActivityLastKey = docs[docs.length - 1];
          this.pushActivityData(docs, activityData);
          that.loadingUserActivity = true;
          resolve(activityData);
        } else {
          that.loadingUserActivity = true;
          resolve([]);
        }
      });
    });
  }

  pushActivityData(docs, activityData) {
    docs.forEach((result) => {
      if (result.exists) {
        const data = result.data();
        const noNeedToGet = (data.hasOwnProperty('isSoftDelete') && data['isSoftDelete']) || false;
        if (!noNeedToGet) {
          this.getSetUserActivityData(result, data);
          activityData.push(data);
        }
      }
    });
  }

  /**
   * @description Function used for get and set user activity data
   * @param {object} result contain the user activity data
   * @param {object} data contain new data object
   */
  getSetUserActivityData(result, data) {
    Object.assign(data, { id: result.id });
    this.auth.getSenderInfo(data['uid']).then((senderInfo) => {
      Object.assign(data, { userInfo: senderInfo });
    });
    if (data['projectId']) {
      this.getProjectInfo(data['projectId']).then((projectData) => {
        Object.assign(data, { projectData });
      });
    }
    if (data['vendorId']) {
      this.getVendorInfo(data['vendorId']).then((vendorData) => {
        Object.assign(data, { vendorData });
      });
    }
    if (data['organizationId']) {
      this.getOrganizationInfo(data['organizationId']).then((orgData) => {
        Object.assign(data, { orgData });
      });
    }
 }

  async getProjectInfo(projectId: string) {
    return new Promise((resolve) => {
      const projectSubscribe = this.fs.doc$(`${CommonConfig.collectionName.project}/${projectId}`).subscribe((projectData) => {
        const requireProjectData = {
          title: projectData ? projectData['title'] : '',
          imageUrl: projectData ? projectData['imageUrl'] : ''
        };
        resolve(requireProjectData);
        projectSubscribe.unsubscribe();
      });
    });
  }

  async getVendorInfo(vendorId: string) {
    return new Promise((resolve) => {
      const vendorSubscribe = this.fs.doc$(`${CommonConfig.collectionName.vendors}/${vendorId}`).subscribe((vendorData) => {
        const requireProjectData = {
          name: vendorData['name'],
          logoImage: vendorData['logoImage']
        };
        resolve(requireProjectData);
        vendorSubscribe.unsubscribe();
      });
    });
  }

  /**
   * @description Function used for get the organization detail
   * @param {string} orgId contain the value of organization id
   */
  async getOrganizationInfo(orgId: string) {
    return new Promise((resolve) => {
      const orgSubscribe = this.fs.doc$(`${CommonConfig.collectionName.organizations}/${orgId}`).subscribe((orgData) => {
        const requireorgDataData = {
          name: orgData['name'],
          logoImage: ''
        };
        resolve(requireorgDataData);
        orgSubscribe.unsubscribe();
      });
    });
  }

  saveUserAccountActivity(data: Partial<UserActivity>, user?: User) {
    if (data.uid !== '' && data.activityType !== '') {
      data['allMentors'] = data.allMentors ? data.allMentors : [data.uid];
      if (!data['activityBelongsTo']) {
        data['activityBelongsTo'] = CommonConfig.belongTo.user;
      }
      const allowed = ['uid', 'activityType', 'allMentors', 'ownerId', 'activityBelongsTo', 'organizationId'];
      const filterObject = this.vendorService.filterObject(allowed, data);
      this.fs.add(CommonConfig.collectionName.userActivity, filterObject);
    }
  }


}
