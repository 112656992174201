import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { map, debounceTime } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from 'angularfire2/firestore';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import * as _moment from 'moment';
// Config
import { environment } from 'environments/environment';
import { CommonConfig } from '@shared/common.config';
import { CommonUtils } from '@shared/common.util';
import { projectTabLabel } from '@shared/common.constants';
// Model
import { Category } from '@app/core/model/category';
import { UserActivity } from '@app/core/model/user-activity';
import { ProjectMember } from '@app/core/model/project-member';
import { Project } from '@app/core/model/project';
import { User } from '@app/core/model/user';
import { CommonEmail } from '@app/core/model/common-email';
// Component
import { ShareProjectComponent } from '@shared/component/projects/share-project/share-project.component';
import { PremiumSignupComponent } from '@shared/component/premium-signup/premium-signup.component';
// Service
import { AuthService } from '@app/core/auth.service';
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { CustomService } from '@shared/service/custom/custom.service';
import { SubscriptionService } from '@shared/service/subscription/subscription.service';
import { MessagingService } from '@shared/service/messaging/messaging.service';
import { OrganizationService } from '@shared/service/organization/organization.service';
import { ProjectFormService } from '@shared/service/projects/project-form.service';
import { ProjectFileService } from '@shared/service/projects/project-file.service';
import { Router } from '@angular/router';
import { TdLoadingService } from '@covalent/core/loading';


const moment = (_moment)['default'] ? (_moment)['default'] : _moment;

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  private _setPaymentDone;
  private readonly commonConfig = CommonConfig;

  private readonly openSearchForm = new BehaviorSubject({});
  openSearchFormStatus = this.openSearchForm.asObservable();

  private readonly openSearchDetails = new BehaviorSubject({});
  openSearchDetailStatus = this.openSearchDetails.asObservable();

  private readonly prifileTabIndex = new BehaviorSubject({ tabLable: projectTabLabel.projectDetails });
  getPrifileTabIndex = this.prifileTabIndex.asObservable();

  private readonly changeSegmentCategory = new BehaviorSubject({});
  changeSegmentCategoryValue = this.changeSegmentCategory.asObservable();

  projectsUpdated = new Subject();

  authUser: Partial<User>;
  constructor(
    public dialog: MatDialog,
    private readonly router: Router,
    private readonly http: HttpClient,
    private readonly auth: AuthService,
    private readonly afs: AngularFirestore,
    private readonly fs: FirestoreService,
    private readonly customService: CustomService,
    private readonly messagingService: MessagingService,
    private readonly projectFormService: ProjectFormService,
    private readonly projectFileService: ProjectFileService,
    private readonly organizationService: OrganizationService,
    public subscriptionService: SubscriptionService,
    private readonly _loadingService: TdLoadingService
  ) { }


  set isPublicationPaymentDone(paymentRef) {
    this._setPaymentDone = paymentRef;
  }
  get isPublicationPaymentDone() {
    return this._setPaymentDone;
  }

  getProjectInfo(customId: string): Observable<Project[]> {
    return this.afs.collection('projects', ref => ref.where('customId', '==', customId)).snapshotChanges().pipe(map(actions => {
      return actions.map(a => {
        const data = a.payload.doc.data() as Project;
        data['$key'] = a.payload.doc.id;
        return data;
      });
    }));
  }

  archiveProject(projectId: string, flag: boolean) {
    return this.fs.update(`${this.commonConfig.collectionName.project}/${projectId}`, { isArchive: flag });
  }

  /**
   * @description Function for update project data
   * @param {number} projectId contain project unique id
   * @param {object} dataObject contain project data object
   */
  updateProjectData(projectId: string, dataObject: object) {
    this.fs.update(`${this.commonConfig.collectionName.project}/${projectId}`, dataObject);
  }


  /**
   * @description Function to update project image
   * @param projectId for which the image hava to be updated
   * @param type (google map or unsplash)
   */
  updateProjectImage(projectId: string, type: string, userId: string) {
    return new Promise(async (resolve, reject) => {
      this._loadingService.register();
      let data;
      if (type === this.commonConfig.projectImageType.unsplash) {
        data = { projectId, type, userId };
      } else if (type === this.commonConfig.projectImageType.googleMap) {
        const projectDetails = await this.getProjectById(projectId);
        const coord = projectDetails['location'];
        data = { projectId, type, userId, coord };
      }
      const updateProject = this.http.post(`${environment.cloudEndPoint}updateProjectImage`, data).subscribe(
        (result) => {
          if (result['status'] === this.commonConfig.status.success) {
            resolve(true);
          } else {
            this.customService.showSnackBarError(this.commonConfig.validationMessage.someError);
            reject(false);
          }
          this._loadingService.resolve();
          updateProject.unsubscribe();
        }, error => {
          this._loadingService.resolve();
          this.customService.showSnackBarError(this.commonConfig.validationMessage.someError);
          updateProject.unsubscribe();
          reject(false);
        });
    });
  }

  findAllCategories(): Observable<Array<Category>> {
    return this.afs.collection('categories', ref => ref.where('status', '==', true).orderBy('name', 'asc')).snapshotChanges().pipe(map(actions => {
      return actions.map(a => {
        const data = a.payload.doc.data() as Category;
        data.$key = a.payload.doc.id;
        return data;
      });
    }));
  }

  findAllCategoriesBySegment(segment: string): Observable<Array<Category>> {
    return this.afs.collection('categories', ref => ref.where('status', '==', true).where('segment', '==', segment).orderBy('name', 'asc')).snapshotChanges().pipe(map(actions => {
      return actions.map(a => {
        const data = a.payload.doc.data() as Category;
        data.$key = a.payload.doc.id;
        return data;
      });
    }));
  }

  guid() {
    return this.customService.guid();
  }

  getFavouriteActivity(data) {
    return ((data.activityType === this.commonConfig.projectActivityType.favorite) ||
      (data.activityType === this.commonConfig.projectActivityType.unfavorite) ||
      (data.activityType === this.commonConfig.projectActivityType.fileDownloaded) || this.isClainOrUnclaimWalkthrough(data));
  }
  /**
   * @description Function used for save project activity and send notificatoins
   * @param {object} data contain notificaton data
   * @param {object} project contain project data
   */
  async saveProjectActivity(data: Partial<UserActivity>, project?: Project) {
    const isFavUnfavActivity = this.getFavouriteActivity(data);
    const isAddendumsActivity = data.activityType === this.commonConfig.projectActivityType.addendums;
    // addendum notification sending fron backend cloude functions
    if (data && project && !isAddendumsActivity) {
      this.saveInsideNotificationPayload(data, project);
    }
    if (data.uid !== '' && data.projectId !== '' && data.activityType !== '') {
      const members = [];
      await this.getAndSetProjectMembers(project, data, members, isFavUnfavActivity);
      if (data.activityType === this.commonConfig.projectActivityType.publicProjectActionUrl) {
        data['allMentors'] = [data.uid];
      } else {
        data['allMentors'] = members;
      }
      data['activityBelongsTo'] = this.commonConfig.belongTo.project;
      this.fs.add(this.commonConfig.collectionName.userActivity, data);
    }
  }

  async getAndSetProjectMembers(project, data, members, isFavUnfavActivity) {
    let projectDetail: Partial<Project>;
    if (!project) {
      projectDetail = await this.getProjectById(data.projectId);
    } else {
      projectDetail = project;
    }
    if (projectDetail && projectDetail.members) {
      members = projectDetail.members;
    }
    if (!members.includes(data.uid) && isFavUnfavActivity) {
      members.push(data.uid);
    }
    if (projectDetail && projectDetail['uid']) {
      const uid = projectDetail['uid'];
      data['ownerId'] = uid;
      members.push(uid);
    }
  }

  isClainOrUnclaimWalkthrough(data) {
    return ((data.activityType === this.commonConfig.projectActivityType.walkthroughBooked) ||
      (data.activityType === this.commonConfig.projectActivityType.walkthroughCancelled));
  }

  getProjectById(projectId: string) {
    return new Promise((resovle, reject) => {
      this.fs.getCollection(this.commonConfig.collectionName.project, projectId).get().then((project) => {
        if (project.exists) {
          resovle(project.data());
        } else {
          resovle([]);
        }
      }).catch(err => {
        resovle([]);
      });
    });
  }

  async saveInsideNotificationPayload(data, project: Project) {
    const category = this.commonConfig.notificationActivityType.projectActivity;
    const activityType = data.activityType;
    const sendBy = data.uid;
    const id = data.projectId;
    const readFlag = false;
    const forAdmin = data.forAdmin || false;
    const isActive = true;
    forAdmin && await this.setAdminIds(data, project);
    const userIds = this.setUserIdsToSendNotification(data, project);
    for (const uId of userIds) {
      if (data.uid && data.uid !== uId) {
        const userId = uId;
        const payloadData = { category, userId, activityType, sendBy, id, readFlag, forAdmin, isActive };
        project.organization && (payloadData['orgId'] = project.organization.id);
        this.messagingService.saveNotificationPayload(payloadData);
      }
    }
  }

  /**
   * @description Function used to set admin and organization admin ids.
   * @param {object} data contain notificaton data
   * @param {object} project contain project data
   */
  async setAdminIds(data, project) {
    const allAdmins = await this.auth.getAllAdmins();
    const allAdminIds = allAdmins.map((obj: Object) => obj['uid']);
    data['sendToUsers'] = allAdminIds;
    if (project.organization) {
      const orgMembers: any = await this.organizationService.getOrganizationMembersByOrgId(project.organization.id);
      const orgAdmins = [];
      orgMembers.map(member => {
        if (member.role === 'superAdmin') {
          orgAdmins.push(member['uid']);
        } else if (member.role === 'admin') {
          this.organizationService.isProjectPermissions(member) && orgAdmins.push(member['uid']);
        }
      });
      data['sendToUsers'] = data['sendToUsers'].concat(orgAdmins);
    }
  }

  /**
   * @description Function used to set user ids in an array to whome sending notification.
   * @param {object} data contain notificaton data
   * @param {object} project contain project data
   */
  setUserIdsToSendNotification(data, project) {
    let usersId = [];
    if (data['sendToUsers'] && data['sendToUsers'].length) {
      usersId = data['sendToUsers'];
    } else if (project.members) {
      const members = project.members;
      const uid = project.uid;
      members.push(uid);
      const allUserId = members;
      usersId = allUserId;
    } else {
      usersId = [project.uid];
    }
    return usersId;
  }
  saveProjectMembers(data: ProjectMember) {
    if (data.uid !== '' && data.projectId !== '' && data.role !== '') {
      this.fs.add(this.commonConfig.collectionName.projectmembers, data);
    }
  }

  openSearchFrom(status: boolean) {
    this.openSearchForm.next({ isOpen: status });
  }

  openSearchDetail(status: boolean) {
    this.openSearchDetails.next({ isOpen: status });
  }

  setPrifileTabIndex(tabLable: string) {
    this.prifileTabIndex.next({ tabLable });
  }

  /**
   * @description Function to check if activity is of file downloaded type.
   */
  isFileViwedActivity(activityData) {
    return (activityData['activityType'] === this.commonConfig.projectActivityType.fileDownloaded &&
      activityData['activityContent'] &&
      activityData['activityContent']['fileName']);
  }

  isWalkthroughActivity(activityType) {
    const projectWalkthroughActivities = [
      this.commonConfig.projectActivityType.createWalkthrough,
      this.commonConfig.projectActivityType.updateWalkthrough,
      this.commonConfig.projectActivityType.walkthroughBooked,
      this.commonConfig.projectActivityType.walkthroughCancelled,
      this.commonConfig.projectActivityType.walkthroughRequested
    ];
    if (projectWalkthroughActivities.includes(activityType)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @description Function to get configuration data form api
   */
  async configurationInformation() {
    const configurationSubscriber = await this.fs.fetchCollection(CommonConfig.collectionName.configuration).ref.get();
    let configureData;
    configurationSubscriber.forEach(element => {
      configureData = element.data();
    });
    return Promise.resolve(configureData);
  }

  /**
   * @description Function for set project owner detail and location
   * @param {any} that contain component object
   * @param {any} user contain user data
   */
  setUserInformation(that, user: Partial<User>) {
    that.projectLocations = user.projectLocations || [];
    if (user['location']) {
      that.projectLocations.unshift(user['location']);
    }
    if (that.projectLocations.length) {
      that.setProjectLocation(that.projectLocations[0]);
    }
    that.userInformation = { uid: user.uid, email: user.email };
    that.userInformation['displayName'] = user['displayName'] || user['email'];
    that.userInformation['photoUrl'] = user['profilePhoto']['url'] || '';
    that.subscriptionInformation = user['subscription'];
    that.userDetail = user;
    that.projectBasicInfo.get('uid').setValue(user.uid);
    that.projectBasicInfo.get('timeZone').setValue(user.timeZone || this.commonConfig.defaultTimeZone.americaNewYork);
    that.projectBasicInfo.get('userInformation').setValue(that.userInformation);
    this.projectFormService.setOrgInProject(that);
  }

  deleteProject(that) {
    this.projectFileService.deleteAllPhotoVideoFiles(that, this.commonConfig.projectFeatures.media, false);
    this.projectFileService.deleteAllPhotoVideoFiles(that, this.commonConfig.projectFeatures.files, false);
    that.firestoreService.delete(`${this.commonConfig.collectionName.project}/${that.projectId}`)
      .then((res: object) => {
        this.customService.showSnackBarSuccess(this.commonConfig.successMessage.projectDeleted);
        that.router.navigate(['/projects/my-project', this.commonConfig.mapType]);
      }).catch((error: object) => {
        this.customService.showSnackBarError(this.commonConfig.validationMessage.someError);
      });
  }

  async sendEmailData(data) {
    const commonUtil = new CommonUtils(this.fs);
    const isAllowed = await commonUtil.isEmailAllow(data['toEmail'], data['belongTo']);
    if (isAllowed) {
      this.sendEmail(data);
    }
  }

  sendEmail(data: CommonEmail) {
    const unsubscriber = this.http.post(`${environment.cloudEndPoint}emailSend`, data).subscribe(res => { unsubscriber.unsubscribe(); });
  }

  projectViewByBidBy(uid: string, projectId: string, type: string) {
    if (uid) {
      let cond;
      if (type === this.commonConfig.actionState.viewby) {
        cond = { viewby: this.fs.firebaseFirestoreFieldValue().arrayUnion(uid) };
      }
      if (type === this.commonConfig.actionState.bidby) {
        cond = { bidby: this.fs.firebaseFirestoreFieldValue().arrayUnion(uid) };
      }
      if (cond) {
        this.fs.setmerge(`${this.commonConfig.collectionName.project}/${projectId}`, cond)
          .then(results => {
          })
          .catch(error => {
            if (error.code) {
              this.customService.showSnackBarError(error.message);
            }
          });
      }
    }
  }

  isProjectMember(project: Project, authUid: string) {
    if (project.members) {
      const userIndex = project.members.indexOf(authUid);
      return userIndex !== -1;
    } else {
      return false;
    }
  }

  changeSegmentCategoryForm(segment: string) {
    this.changeSegmentCategory.next({ segment });
  }

  // Function used for share project link
  shareProjectDialog(projectId: string, project, authUser) {
    const dialogRef = this.dialog.open(ShareProjectComponent, { width: this.commonConfig.slelectUserDialogWidth });
    const instance = dialogRef.componentInstance;
    instance.dialogRef = dialogRef;
    instance.project = project;
    instance.project['id'] = projectId;
    instance.authUser = authUser;
  }

  // Function used for share project link
  projectPremiumSignupDialog(premiumProjectId: string, project) {
    const dialogRef = this.dialog.open(PremiumSignupComponent, { width: this.commonConfig.slelectUserDialogWidth });
    const instance = dialogRef.componentInstance;
    instance.dialogRef = dialogRef;
    instance.project = project;
    instance.project['id'] = premiumProjectId;
  }

  isWalkthroughExpired(startFrom) {
    return new Date().getTime() > startFrom;
  }

  navigateToProject(userDetail, routerLink: string, projectId: string) {
    const projectCreation = this.customService.projectCreation(userDetail);
    if (this.customService.checkProjectCreation(userDetail, projectCreation, false)) {
      this.router.navigate([routerLink, projectId]);
    }
  }
}
