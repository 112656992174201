
import {take, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from 'angularfire2/firestore';
import { Observable } from 'rxjs';






import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/messaging';
import 'firebase/storage';

import { TimestampService } from '../../service/timestamp/timestamp.service';

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {


  constructor(public afs: AngularFirestore, private readonly timeStampService: TimestampService) { }

  /// **************
  /// Get a Reference
  /// **************

  col<T>(ref: CollectionPredicate<T>, queryFn?): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.afs.collection<T>(ref, queryFn) : ref;
  }

  doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.afs.doc<T>(ref) : ref;
  }



  /// **************
  /// Get Data
  /// **************


  doc$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref).snapshotChanges().pipe(map(doc => {
      return doc.payload.data() as T;
    }));
  }

  col$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<T[]> {
    return this.col(ref, queryFn).snapshotChanges().pipe(map(docs => {
      return docs.map(a => a.payload.doc.data()) as T[];
    }));
  }



  /// with Ids
  colWithIds$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<any[]> {
    return this.col(ref, queryFn).snapshotChanges().pipe(map(actions => {
      return actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        const doc = a.payload.doc;
        return { id, ...data, doc };
      });
    }));
  }

  fetchCollection(ref: string) {
    return this.afs.collection(ref);
  }

  /// **************
  /// Write Data
  /// **************


  /// Firebase Server Timestamp
  get timestamp() {
    return this.timeStampService.getTimestamp;
  }

  set<T>(ref: DocPredicate<T>, data) {
    const timestamp = this.timestamp;
    return this.doc(ref).set({
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp
    });
  }

  setmerge<T>(ref: DocPredicate<T>, data) {
    const timestamp = this.timestamp;
    return this.doc(ref).set({
      ...data,
      updatedAt: timestamp
    }, { merge: true });
  }

  update<T>(ref: DocPredicate<T>, data) {
    return this.doc(ref).update({
      ...data,
      updatedAt: this.timestamp
    });
  }


  delete<T>(ref: DocPredicate<T>) {
    return this.doc(ref).delete();
  }

  /**
   * @description Function used for remove a filedname from database
   * @param collectionName Name of the collection
   * @param docId document id
   * @param keyObject contain the key object which need to remove
   */
  deleteKey<T>(collectionName, docId, keyObject) {
    this.afs.collection(collectionName).doc(docId).update(keyObject);
  }

  add<T>(ref: CollectionPredicate<T>, data) {
    const timestamp = this.timestamp;
    return this.col(ref).add({
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp
    });
  }


  geopoint(lat: number, lng: number) {
    return new firebase.firestore.GeoPoint(lat, lng);
  }


  /// If doc exists update, otherwise set
  upsert<T>(ref: DocPredicate<T>, data) {
    const doc = this.doc(ref).snapshotChanges().pipe(take(1)).toPromise();

    return doc.then(snap => {
      return snap.payload.exists ? this.update(ref, data) : this.set(ref, data);
    });
  }

  /// create a reference between two documents
  connect(host: string, key: string, doc) {
    return this.doc(host).update({ [key]: this.doc(doc).ref });
  }


  /// returns a documents references mapped to AngularFirestoreDocument
  docWithRefs$<T>(ref: DocPredicate<T>) {
    return this.doc$(ref).pipe(map((doc) => {
      for (const k of Object.keys(doc)) {
        if (doc[k] instanceof firebase.firestore.DocumentReference) {
          doc[k] = this.doc(doc[k].path);
        }
      }
      return doc;
    }));
  }

  // for get by collection by doc id
  getCollection<T>(ref: string, docId: string) {
    return this.afs.collection(ref).doc(docId).ref;
  }

  /// **************
  /// Atomic batch example
  /// **************


  /// Just an example, you will need to customize this method.
  atomic() {
    const batch = firebase.firestore().batch();
    /// add your operations here

    const itemDoc = firebase.firestore().doc('items/myCoolItem');
    const userDoc = firebase.firestore().doc('users/userId');

    const currentTime = this.timestamp;

    batch.update(itemDoc, { timestamp: currentTime });
    batch.update(userDoc, { timestamp: currentTime });

    /// commit operations
    return batch.commit();
  }

  /**
   * @description function used for return firebase firestore field value
   */
  firebaseFirestoreFieldValue = () => firebase.firestore.FieldValue;

  /**
   * @description function used for return firebase lib object
   */
  firebaseObj = () => firebase;

  /**
   * @description function used for get file download url
   */
  async getDownloadURL(dir: string, fileName: string) {
    const fileRef = firebase.storage().ref(`${dir}/${fileName}`);
    return fileRef.getDownloadURL();
  }

}
