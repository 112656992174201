import { Component, Input, ElementRef, } from '@angular/core';
import { AbstractControlDirective, AbstractControl } from '@angular/forms';
@Component({
  selector: 'app-show-errors',
  template: `
    <span *ngIf="shouldShowErrors()" class="tc-red-600">{{listOfErrors()[0]}}</span>
  `,
})
export class ShowErrorsComponent {
  constructor(private elRef: ElementRef) { }
  private static readonly errorMessages = {
    required: (params) => `Please ${params.name === 'image' ? 'upload ' : 'enter '} ${params.name}.`,
    minlength: (params) => `${params.name} must have at least ${params.params.requiredLength} characters.`,
    maxlength: (params) => `The max allowed number of characters is ${params.params.requiredLength}.`,
    pattern: (params) => params.message ? params.message : `Please enter a valid  ${params.name}.`,
    email: () => `Please enter a valid email.`,
    max: (params) => `The max allowed ${params.name} is ${params.params.max}.`,
    min: (params) => `The min allowed ${params.name} is ${params.min}.`,
    mask_error: (params) => `Please enter a valid  ${params.name}.`,
    Maskerror: (params) => `Please enter a valid  ${params.name}.`,
    owlDateTimeMin: () => `End date cannot be less than start date.`,
  };

  @Input() private readonly control: AbstractControlDirective | AbstractControl;
  @Input() private readonly name: string;
  @Input() private readonly min: number;
  @Input() private readonly message: string;

  shouldShowErrors(): boolean {
    return this.control && this.control.errors && this.control.touched;
  }

  listOfErrors(): string[] {
    return Object.keys(this.control.errors)
      .map(field => this.getMessage(field, this.control.errors[field]));
  }

  private getMessage(type: string, params) {
    type = type.replace(/\s/g, '');
    return ShowErrorsComponent.errorMessages[type]({ name: this.name, message: this.message, params, min: this.min });
  }

}
