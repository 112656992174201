import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
// Config
import { OrganizationConfig } from '@shared/organization.config';

@Component({
  selector: 'app-org-permission',
  templateUrl: './org-permission.component.html'
})
export class OrgPermissionComponent implements OnInit {
  @Input() roleValue: string;
  @Input() orgUserPermissions;
  @Input() parentForm: FormGroup;
  public organizationConfig = OrganizationConfig;
  constructor(
  ) { }

  ngOnInit() {
  }

}
