import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// Config
import { environment } from 'environments/environment';
import { CommonConfig } from '@shared/common.config';
// Model
import { User } from '@app/core/model/user';
import { OrganizationMembers } from '@app/core/model/organization-members';
// Service
import { AuthService } from '@app/core/auth.service';
import { OrganizationService } from '@app/shared/service/organization/organization.service';

@Component({
  selector: 'app-admin-nav',
  templateUrl: './admin-nav.component.html'
})

export class AdminNavComponent implements OnInit, OnDestroy {
  private readonly ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  commonConfig = CommonConfig;
  @Input() currentPage = '';
  public orgId = '';
  public orgMember: Partial<OrganizationMembers> = null;
  authUser: Partial<User>;
  public isGlobalAdmin = true;
  public isHideMenu = true;
  public isProjectsPermission = false;
  public isVendorsPermission = false;
  public isBidPermission = false;
  public isUsersPermission = false;
  public isOrgSuperAdmin = false;
  public enableOrgFeature = environment.enableOrgFeature;
  public resetEnvironmentPermissions = false;
  public allowEnvironmentPermissions = environment.allowFakerData;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public readonly auth: AuthService,
    private readonly organizationService: OrganizationService
  ) {
    try {
      this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe(val => {
        if (val instanceof NavigationEnd) {
          this.getQueryParams();
        }
      });
    } catch (e) { }
  }

  ngOnInit() {
    this.getQueryParams();
    this.getLoginUserOrgData();
    if (this.currentPage === this.commonConfig.routerName.projectdetail) {
      this.currentPage = this.commonConfig.routerName.manageprojects;
    } else if (this.currentPage === this.commonConfig.routerName.vendorprofile) {
      this.currentPage = this.commonConfig.routerName.managevendors;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * @description get url query params
   */
  async getQueryParams() {
    this.authUser = await this.auth.currentUser();
    this.isGlobalAdmin = !(!this.authUser || !this.authUser.isAdmin);
    this.resetEnvironmentPermissions = this.authUser.resetEnvironmentPermissions;
    this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
      if (params['orgId']) {
        this.orgId = params['orgId'];
      } else {
        this.orgId = '';
      }
      this.isHideMenu = !!this.orgId;
    });
  }

  /**
   * @description Function used for get login user organization data
   */
  getLoginUserOrgData() {
    this.organizationService.authUserOrganizationData.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.orgMember = res;
      this.isOrgSuperAdmin = this.organizationService.isOrgSuperAdmin(this.orgMember);
      this.isProjectsPermission = this.organizationService.isProjectPermissions(this.orgMember);
      this.isVendorsPermission = this.organizationService.isVendorPermissions(this.orgMember);
      this.isBidPermission = this.organizationService.isBidsPermissions(this.orgMember);
      this.isUsersPermission = this.organizationService.isUserPermissions(this.orgMember);
    });
  }

  /**
   * @description hide extra menu that can access by admin user only
   */
  openRouterLink(routerName: string) {
    const queryParams = {};
    if (this.orgId) {
      queryParams['orgId'] = this.orgId;
    }
    this.router.navigate([`${this.commonConfig.routerName.admin}/${routerName}`], { queryParams });
  }

}
