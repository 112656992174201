
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Component, NgZone, ViewChild, ElementRef, OnInit, OnDestroy, HostListener } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { MatIconRegistry, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material';
import { TdDialogService } from '@covalent/core/dialogs';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  ActivatedRoute,
  Router,
  RoutesRecognized
} from '@angular/router';
import { version } from '../../package.json';
// Config
import { APPLICATION_NOTIFICATION } from '../../notification-utils';
import { environment } from 'environments/environment';
import { CommonConfig } from '@shared/common.config';
import { PLATFORM_TITLES, DYNAMIC_KEY_NAMES } from '@shared/platform-title.config';
import { trackEvent, assetsData } from '@shared/common.constants';
// Model
import { User } from '@app/core/model/user';
import { Vendor } from '@app/core/model/vendor';
import { OrganizationMembers } from '@app/core/model/organization-members';
import { NotificationPayload } from '@app/core/model/notification-payload';
// Service
import { AuthService } from '@app/core/auth.service';
import { AlgoliaService } from '@shared/service/algolia/algolia.service';
import { ProjectsService } from '@shared/service/projects/projects.service';
import { DataStorageService } from '@shared/service/storage/data-storage.service';
import { MessagingService } from '@shared/service/messaging/messaging.service';
import { UserActivityService } from '@shared/service/user-activity/user-activity.service';
import { OrganizationService } from '@shared/service/organization/organization.service';
import { GanalyticsService } from '@shared/service/ganalytics/ganalytics.service';
import { VendorsService } from '@shared/service/vendors/vendors.service';

declare let window;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public version: string = version;
  public addr: object;
  loading = false;
  public commonConfig = CommonConfig;
  public platformTitles = PLATFORM_TITLES;
  currentPage: string;
  currentRouter: string;
  currentRouterPage: string;
  currentLat: number;
  currentLng: number;
  lat: number;
  lng: number;
  searchStuff = {};
  showHide = true;
  globalSearchForm: FormGroup;
  suggestData = [];
  page: number;
  nbHits: number;
  nbPages: number;
  queryEncodeData: object;
  querydncodeData: object;
  valueSearch = '';
  isAdmin: boolean;
  categories = [];
  focus = false;
  viewType: string;
  queryData;
  searchInputCtrl = new FormControl();
  adminSubscription: Subscription;
  private authSubscription: Subscription;
  notifications = [];
  openNotificationBar = false;
  lastKey = '';
  isDialogFlowVisible = false;
  isPublicProject = false;
  private readonly customId: string;
  userAvatar = assetsData.images.userAvatar;
  allBidActivity: Array<string> = ['bidplace', 'bidupdate', 'bidaccept', 'quoteplace', 'quoteupdate',
    'quoteaccept', 'bidrevisionrequest', 'bidreject', 'quotereject'];
  @ViewChild('categoryInput', { static: false }) categoryInput: ElementRef;
  @ViewChild(MatAutocompleteTrigger, { static: false }) autocomplete: MatAutocompleteTrigger;
  @ViewChild('box', { static: false }) searchBox: ElementRef;
  adminRouter: Array<string> = [this.commonConfig.routerName.admin, this.commonConfig.routerName.manageprojects,
  this.commonConfig.routerName.managevendors, this.commonConfig.routerName.manageusers,
  this.commonConfig.routerName.managebids, this.commonConfig.routerName.manageorg];
  hideHeader: boolean;
  homeRoute = `${this.commonConfig.routerName.routePrefix}${this.commonConfig.routerName.home}`;
  isCompleteProfile: boolean;
  public APPLICATION_NOTIFICATION = APPLICATION_NOTIFICATION;
  private userDetail: Partial<User>;
  private iAmMemberOf: Partial<Vendor>;
  public isVendorOwner: boolean;
  private myVendorId: string;
  public chatBtn = false;
  public assetsData = assetsData;
  private readonly ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  public orgId = '';
  public orgParamsId;
  public orgMember: Partial<OrganizationMembers>;
  public orgMenuName: string;
  searchLocation;
  public headerText = environment.headerText;
  public enableOrgFeature = environment.enableOrgFeature;
  isLoaded = true;
  constructor(
    public auth: AuthService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly _iconRegistry: MatIconRegistry,
    private readonly _domSanitizer: DomSanitizer,
    private readonly ngZone: NgZone,
    private readonly _dialogService: TdDialogService,
    private readonly projectsService: ProjectsService,
    private readonly _algoliya: AlgoliaService,
    private readonly fb: FormBuilder,
    public storage: DataStorageService,
    public _messagingService: MessagingService,
    private readonly userActivityService: UserActivityService,
    private readonly ganalyticsService: GanalyticsService,
    private readonly vendorsService: VendorsService,
    private readonly organizationService: OrganizationService,
    private readonly titleService: Title) {
    this._iconRegistry.addSvgIcon('tlogo', _domSanitizer.bypassSecurityTrustResourceUrl(environment.logoblack));
    this._iconRegistry.addSvgIcon('headlogo', _domSanitizer.bypassSecurityTrustResourceUrl(environment.logowhite));
    this.customId = this.projectsService.guid();
    try {
      this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe(val => {
        this.notifications = [];
        this.openNotificationBar = false;
        this.currentRouter = this.router.url.split('/')[1] ? this.router.url.split('/')[1] : '';
        this.currentRouterPage = this.router.url.split('/')[CommonConfig.two] ? this.router.url.split('/')[CommonConfig.two] : '';
        this.hideHeader = (this.currentRouter === '' || (this.commonConfig.routerName.home === this.currentRouter));
        this.searchLocation = this.storage.location;
        this.storage.searchFilterLocation.pipe(takeUntil(this.ngUnsubscribe)).subscribe(location => {
          this.searchLocation = location;
          this.setSearchFormLocation();
        });
        this.setCompleteProfilePageOrNot();
        this.setCurrentPageActive(this.currentRouter, this.currentRouterPage);
        this.switchOnOffAdmin(this.currentRouter);
        this.displayLoadingIndicator(val);
        this.setViewType(val);
        this.decodeUriData(val);
        this.resetSearchFormData(val);
        window.Intercom('hide');
        this.isDialogFlowVisible = false;
        this.setTitle();
      });
    } catch (e) { }
  }
  ngOnInit() {
    this.getOrgQueryParams();
    this.adminSubscription = this.storage.adminPanelStatus.subscribe(status => {
      if (status) {
        this.isAdmin = status.isAdmin;
      }
    });
    this.searchInputCtrl.valueChanges.pipe(debounceTime(CommonConfig.delay300)).subscribe((value) => {
      if (value) {
        this.hitAutoSuggest(value);
      } else {
        this.searchStuff['keyword'] = '';
        this.suggestData = [];
      }
    });
    this.buildFrom();
    this.getAuthUser();
    this.projectsService.openSearchFormStatus.subscribe(status => {
      this.showHide = !this.showHide;
    });
    this.subscribeToggleDialogFlow();
  }

  ngOnDestroy() {
    if (this.adminSubscription) {
      this.adminSubscription.unsubscribe();
    }
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public async setTitle() {
    let currentRoutePath = `${this.currentRouter}`;
    if (this.currentRouterPage) {
      currentRoutePath = `${this.currentRouter}/${this.currentRouterPage}`;
    }
    let newTitle = this.platformTitles['default'];
    if (this.platformTitles[currentRoutePath]) {
      newTitle = this.platformTitles[currentRoutePath];
    }
    if (this.isCurrentRouteMatch(currentRoutePath)) {
      const vendorId = this.router.url.split('/')[CommonConfig.three] ? this.router.url.split('/')[CommonConfig.three] : '';
      const vednorObj = await this.vendorsService.getVendorDataById(vendorId);
      if (vednorObj && vednorObj.name) {
        newTitle = this.platformTitles[currentRoutePath].replace(DYNAMIC_KEY_NAMES.vendorName, vednorObj.name);
      }
    } else if (this.router.url.includes(this.commonConfig.routerName.preview)) {
      const projectId = this.router.url.split('/')[CommonConfig.two] ? this.router.url.split('/')[CommonConfig.two] : '';
      const projectObj = await this.projectsService.getProjectById(projectId);
      if (projectObj && projectObj['projectSubscriberType']) {
        this.isPublicProject = true;
      }
      if (projectObj && projectObj['title']) {
        newTitle = projectObj['title'];
      }
    }
    this.titleService.setTitle(newTitle);
  }

  isCurrentRouteMatch = (currentRoutePath) => currentRoutePath === `${this.commonConfig.routerName.vendors}/${this.commonConfig.routerName.vendorprofile}`;

  /**
   * @description Function used for decode uri query params data
   * @param {object} val contain router event object
   */
  decodeUriData(val) {
    if (val instanceof RoutesRecognized && val.state.root.firstChild.queryParams['data']) {
      const queryParams = val.state.root.firstChild.queryParams['data'];
      this.queryEncodeData = queryParams;
      this.querydncodeData = JSON.parse(decodeURI(queryParams));
      this.buildFrom();
    } else {
      if (this.isLoaded && (!this.querydncodeData || this.isEmpty(this.querydncodeData))) {
        this.isLoaded = false;
        setTimeout(() => {
          this.getUserLocation();
        }, CommonConfig.delay1000);
      }
    }
  }

  /**
   * @description Function used for decode uri query params data
   * @param {object} val contain router event object
   */
  resetSearchFormData(val) {
    if (val instanceof RoutesRecognized && !(val.url && val.url.split('/') && val.url.split('/')[1] === this.commonConfig.routerName.search)) {
      this.resetForm();
    }
  }

  /**
   * @description Function used for set page view type
   * @param {object} val contain router event object
   */
  setViewType(val) {
    if (val instanceof NavigationEnd && val.url && val.url.split('/') && val.url.split('?')[0] && val.url.split('?')[0].split('/')[CommonConfig.two]) {
      this.viewType = val.url.split('?')[0].split('/')[CommonConfig.two];
      this.searchStuff['viewType'] = this.viewType;
    }
  }

  /**
   * @description function to hide intercom chat
   */
  @HostListener('window:click', ['$event'])
  chatClickHandler() {
    window.Intercom('onHide', () => {
      this.chatBtn = false;
    });
  }

  /**
   * @description function to get current user detail
   */
  getAuthUser() {
    this.authSubscription = this.auth.user.subscribe(res => {
      if (res) {
        this.userDetail = res;
        if (!this.orgId) {
          this.allowAdminAccess();
        }
        this.vendorSubscribe();
        this.getOrganizationData();
      } else {
        this.userDetail = null;
      }
    });
  }

  /**
   * @description Functions used for authtication admin section
   */
  allowAdminAccess() {
    if (this.currentPage === this.commonConfig.routerName.admin && !this.userDetail['isAdmin']) {
      this.router.navigate([`/${CommonConfig.routerName.dashboard}`]);
    }
  }

  /**
   * @description function to check vendor profile in created or not
   */
  async vendorSubscribe() {
    this.iAmMemberOf = await this.vendorsService.getVendorByUid(this.userDetail['uid'], true);
    if (this.iAmMemberOf['id'] && this.iAmMemberOf['isActive']) {
      if (this.userDetail['uid'] !== this.iAmMemberOf['uid']) {
        this.isVendorOwner = false;
      }
      this.myVendorId = this.iAmMemberOf['id'];
    } else {
      const iAmOwnerOf = await this.vendorsService.getVendorByUid(this.userDetail['uid']);
      if (iAmOwnerOf['id']) {
        this.isVendorOwner = true;
        this.myVendorId = iAmOwnerOf['id'];
      } else {
        this.isVendorOwner = false;
      }
    }
  }

  /**
   * @description function to redirect on vendor profile according to the condition
   */
  vendorProfile() {
    if (this.isVendorOwner) {
      this.router.navigate([`${this.commonConfig.routerName.vendors}/${this.commonConfig.routerName.vendorprofile}`, this.myVendorId]);
    } else if (this.iAmMemberOf['id']) {
      this.router.navigate([`${this.commonConfig.routerName.vendors}/${this.commonConfig.routerName.vendorprofile}`, this.iAmMemberOf['id']]);
    } else {
      this.router.navigate([`${this.commonConfig.routerName.vendors}/${this.commonConfig.routerName.createvendor}`, this.customId, this.userDetail['uid']]);
    }
  }

  setCompleteProfilePageOrNot() {
    this.isCompleteProfile = this.commonConfig.routerName.completeprofile === this.currentRouterPage;
  }
  async getAllNotification($event, userId: string) {
    this.lastKey = '';
    const data = await this._messagingService.getAllNotification(this, userId);
    this.notifications = data;
    this._messagingService.resetUnreadMessageCount(this.isAdmin, userId);
  }
  async viewMoreNotification($event, userId: string) {
    $event.stopPropagation();
    const data = await this._messagingService.getAllNotification(this, userId);
    this.notifications = this.notifications.concat(data);
  }
  openPage(notifications: NotificationPayload) {
    if (notifications['doc'].id) {
      this._messagingService.setReadFlagOfNotification(notifications['doc'].id);
    }
    if (notifications['category'] === this.commonConfig.notificationActivityType.projectActivity) {
      this.navigateToProject(notifications);
    }
    if (notifications['category'] === this.commonConfig.notificationActivityType.vendorActivity) {
      if (notifications['forAdmin']) {
        this.router.navigate(['admin/vendor-profile', notifications['id']]);
      } else if (notifications['activityType'] === this.commonConfig.vendorActivityType.vendorStarred ||
        notifications['activityType'] === this.commonConfig.vendorActivityType.vendorUnStarred) {
        this.router.navigate(['vendors/my-vendor']);
      } else {
        this.router.navigate(['vendors/vendor-profile', notifications['id']]);
      }
    }
    if (notifications['category'] === this.commonConfig.notificationActivityType.systemActivity) {
      this.navigateOnOrganizationNotifications(notifications);
    }
  }

  navigateOnOrganizationNotifications(notifications) {
    const activityType = ['createOrganization', 'joinUserToOrganization', 'leaveOrganizationReq'];
    if (activityType.includes(notifications.activityType)) {
      this.router.navigate([`admin/manage-users`], { queryParams: { orgId: notifications['id'] } });
    }
  }

  /**
   * @description Function used for navigate to project detail page
   */
  navigateToProject(notifications) {
    if (notifications['forAdmin']) {
      const ownerId = notifications['itemInfo'] && notifications['itemInfo']['ownerId'];
      this.router.navigate(['admin/project-detail', notifications['id'], ownerId]);
    } else {
      if (this.allBidActivity.indexOf(notifications['activityType']) > -1) {
        this.router.navigate([`${this.commonConfig.routerName.dashboard}/${this.commonConfig.routerName.projectdetail}`, notifications['id']], {
          queryParams: { from: this.commonConfig.routerName.managebids }
        });
      } else if (notifications['activityType'] === this.commonConfig.projectActivityType.addcomment) {
        this.router.navigate([`${this.commonConfig.routerName.dashboard}/${this.commonConfig.routerName.projectdetail}`, notifications['id']], {
          queryParams: { from: this.commonConfig.projectActivityType.addcomment }
        });
      } else if (notifications['activityType'] === this.commonConfig.projectActivityType.addmember) {
        this.router.navigate([`${this.commonConfig.routerName.dashboard}/${this.commonConfig.routerName.projectdetail}`, notifications['id']], {
          queryParams: { from: this.commonConfig.projectActivityType.addmember }
        });
      } else {
        this.router.navigate([`${this.commonConfig.routerName.dashboard}/${this.commonConfig.routerName.projectdetail}`, notifications['id']]);
      }
    }
  }

  async logout() {
    const authSubscribe = this.auth.user.subscribe(usersInfo => {
      if (usersInfo) {
        const dataObject = { uid: usersInfo['uid'], activityType: '' };
        dataObject['activityType'] = CommonConfig.userActivityType.signOut;
        dataObject['ownerId'] = usersInfo['uid'];
        this.userActivityService.saveUserAccountActivity(dataObject);
      }
      authSubscribe.unsubscribe();
    });
    await this.auth.signOut();
    window.Intercom('shutdown');
    this.ganalyticsService.analyticsTrackEvent(trackEvent.logout);
    return this.router.navigate(['/login']);
  }

  /**
   * @description check the obj contain the key or not
   * @param {object} obj contain the object
   */
  isEmpty = (obj: object) => obj && !(Object.keys(obj).length);

  buildFrom() {
    this.globalSearchForm = this.fb.group({
      location: this.fb.group({
        address: ['', Validators.required],
        lat: ['', Validators.required],
        long: ['', Validators.required]
      }),
      miles: ['', Validators.min(1)],
      category: [''],
      resultType: [this.commonConfig.belongTo.project],
    }
    );
    if (this.querydncodeData && !this.isEmpty(this.querydncodeData)) {
      this.globalSearchForm.patchValue(this.querydncodeData);
      this.globalSearchForm.markAsPristine();
      this.categories = this.globalSearchForm.get('category').value || [];
      if (this.querydncodeData['keyword']) {
        setTimeout(() => {
          try {
            this.searchBox.nativeElement.value = this.querydncodeData['keyword'];
          } catch (error) { }
        }, CommonConfig.delay3000);
      }
    }
  }

  setSearchFormLocation() {
    if (this.searchLocation && Object.keys(this.searchLocation).length) {
      this.globalSearchForm.get('location').get('lat').setValue(this.searchLocation.lat);
      this.globalSearchForm.get('location').get('long').setValue(this.searchLocation.long);
      this.globalSearchForm.get('location').get('address').setValue(this.searchLocation.address);
    }
  }

  toggleSideNav(sideNav) {
    sideNav.toggle();
  }
  searchForm() {
    if (this.globalSearchForm.valid) {
      if (typeof this.searchStuff['keyword'] !== 'string') {
        this.searchStuff['keyword'] = '';
      }
      this.storage.setSearchLocation(this.globalSearchForm.get('location').value);
      this.setSearchFormLocation();
      this.searchStuff = { ...this.searchStuff, ...this.globalSearchForm.value };
      const queryEncodeData = encodeURI(JSON.stringify(this.searchStuff));
      this.showHide = false;
      this.searchStuff['viewType'] = this.commonConfig.gridType;
      this.router.navigate([this.commonConfig.routerName.search, this.commonConfig.gridType], { queryParams: { data: queryEncodeData } });
    }
  }
  get getLat() {
    return this.globalSearchForm.get('location').get('lat');
  }
  get locationAddress() {
    return this.globalSearchForm.get('location').get('address');
  }
  get getRadious() {
    return this.globalSearchForm.get('miles');
  }
  clearLocation() {
    this.globalSearchForm.get('location').get('lat').setValue(null);
    this.globalSearchForm.get('location').get('long').setValue(null);
  }
  addClass(flag: boolean) {
    this.focus = flag;
  }
  keywordSearch(keyword: string) {
    this.autocomplete.closePanel();
    this.searchStuff['keyword'] = keyword;
    this.searchStuff['viewType'] = this.viewType || '';
    this.showSearchDetails();
    this.searchForm();
  }
  clearSearchBox() {
    this.searchBox.nativeElement.value = '';
    this.searchStuff['keyword'] = '';
    this.searchForm();
  }
  hideSuggest() {
    this.autocomplete.closePanel();
  }
  hitAutoSuggest(keywordValue: string) {
    this.valueSearch = keywordValue;
    this.searchStuff['keyword'] = keywordValue;
    const keyword = { keyword: keywordValue };
    this.suggestData = [];
    this._algoliya.getAutoSuggest(this, keyword, (err, result) => {
      if (result.hits) {
        this.suggestData = [...this.suggestData, ...result.hits];
      }
      this.page = result.page;
      this.nbHits = result.nbHits;
      this.nbPages = result.nbPages;
    });
  }
  selectedSuggest(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value) {
      const keyValue = event.option.value;
      if (keyValue.type === 'project' && keyValue.objectID) {
        this.router.navigate(['/dashboard/project-detail', keyValue.objectID]);
      } else if (keyValue.type === 'vendor' && keyValue.objectID) {
        this.router.navigate(['/dashboard/vendor-profile', keyValue.objectID]);
      }
    }
  }
  filterTitle(obj: Object) {
    let title;
    if (obj && Object.keys(obj).length !== 0) {
      title = obj['title'];
    }
    return title;
  }
  showSearchDetails() {
    this.projectsService.openSearchDetail(true);
  }
  private getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.currentLat = position.coords.latitude;
        this.currentLng = position.coords.longitude;
        this.setDefaultLatLng();
        const currgeocoder = new google.maps.Geocoder();
        const myLatlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
        currgeocoder.geocode({ 'location': myLatlng }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && !(this.searchLocation && Object.keys(this.searchLocation).length)) {
            this.globalSearchForm.get('location').get('address').setValue(results[0].formatted_address);
            this.globalSearchForm.get('location').get('lat').setValue(position.coords.latitude);
            this.globalSearchForm.get('location').get('long').setValue(position.coords.longitude);
            this.storage.setSearchLocation(this.globalSearchForm.get('location').value);
          } else {
            this.setSearchFormLocation();
          }
        });
      }, error => {
        this.setDefaultLatLng();
      });
    }
  }
  resetSearchFormValue() {
    this.globalSearchForm.get('category').setValue('');
    this.globalSearchForm.get('miles').setValue(this.commonConfig.twoThousand);
    this.searchStuff['keyword'] = '';
    if (this.searchBox && this.searchBox.nativeElement) {
      this.searchBox.nativeElement.value = '';
    }
  }
  setAddress(addrObj) {
    this.ngZone.run(() => {
      this.addr = addrObj;
      this.globalSearchForm.get('location').get('address').setValue(addrObj.address);
      this.globalSearchForm.get('location').get('lat').setValue(addrObj.lat);
      this.globalSearchForm.get('location').get('long').setValue(addrObj.lng);
    });
  }
  setDefaultLatLng() {
    if (this.currentLat && this.currentLng) {
      this.lat = this.currentLat;
      this.lng = this.currentLng;
    } else {
      this.lat = CommonConfig.US.lat;
      this.lng = CommonConfig.US.lon;
      this.globalSearchForm.get('location').get('address').setValue(CommonConfig.US.address);
      this.globalSearchForm.get('location').get('lat').setValue(CommonConfig.US.lat);
      this.globalSearchForm.get('location').get('long').setValue(CommonConfig.US.lon);
    }
    Object.assign(this.searchStuff, { lat: this.lat, lng: this.lng });
  }

  resetForm(isReset = false) {
    isReset && this.storage.removeSearchLocation();
    this.getUserLocation();
    this.resetSearchFormValue();
    this.categories = [];
  }
  onClickedOutside(event) {
    const difineArray = ['mat-option-text', 'mat-chip-remove', 'mat-option', 'link'];
    const classArray = (event.target as Element).className.split(' ');
    if (!difineArray.some(r => classArray.indexOf(r) >= 0)) {
      this.showHide = false;
    }

  }

  setCurrentPageActive(currentRouter: string, currentRouterPage: string) {
    this.currentPage = '';
    const vendorRouter = [this.commonConfig.routerName.vendors, this.commonConfig.routerName.myvendor,
    this.commonConfig.routerName.vendorprofile, this.commonConfig.routerName.vendorfeature];
    const projectRouter = [this.commonConfig.routerName.projects, this.commonConfig.routerName.myprojects,
    this.commonConfig.routerName.favoriteprojects, this.commonConfig.routerName.archiveProject];
    const dashboardRouter = [this.commonConfig.routerName.dashboard];
    const changePassRouter = [this.commonConfig.routerName.changepassword];
    if (vendorRouter.indexOf(currentRouter) > -1) {
      this.currentPage = this.commonConfig.routerName.vendors;
    } else if (projectRouter.indexOf(currentRouter) > -1) {
      this.currentPage = this.commonConfig.routerName.projects;
    } else if (dashboardRouter.indexOf(currentRouter) > -1) {
      this.currentPage = this.commonConfig.routerName.dashboard;
    } else if (changePassRouter.indexOf(currentRouterPage) > -1) {
      this.currentPage = this.commonConfig.routerName.changepassword;
    } else if (this.adminRouter.indexOf(currentRouter) > -1 || this.adminRouter.indexOf(currentRouterPage) > -1) {
      this.currentPage = this.commonConfig.routerName.admin;
    }
  }

  async displayLoadingIndicator(event) {
    switch (true) {
      case event instanceof NavigationStart: {
        this.loading = true;
        break;
      }
      case event instanceof NavigationEnd: {
        let newTitle = this.currentRouter;
        if (this.router.url.includes(this.commonConfig.routerName.preview)) {
          const projectId = this.router.url.split('/')[CommonConfig.two] ? this.router.url.split('/')[CommonConfig.two] : '';
          const projectObj = await this.projectsService.getProjectById(projectId);
          if (projectObj && projectObj['title']) {
            newTitle = projectObj['title'];
          }
        }
        // removing so pageview doesn't get tracked each time loading is done.
        this.ganalyticsService.analyticsPageTrack(newTitle, this.router.url);
        this.loading = false;
        break;
      }
      case event instanceof NavigationCancel:
      case event instanceof NavigationError: {
        this.loading = false;
        break;
      }
    }
  }

  /**
   * @description Function for switch between admin and front end user
   * @param {string} currentRouter contain a router name value
   */
  switchOnOffAdmin(currentRouter: string) {
    if (currentRouter) {
      this.storage.setAdminPanel(false);
      if (this.adminRouter.indexOf(currentRouter) > -1) {
        this.storage.setAdminPanel(true);
      }
    }
  }
  removeNotification(index: number, notificationId: string) {
    this.notifications.splice(index, 1);
    this._messagingService.removeNotification(notificationId);
  }
  trackByFn(item: object) {
    return item['notificationId'];
  }

  /**
   * @description Function used for make a different view depending on user segment
   * @param {string} type contain router type
   */
  onRouterNavigate(type: string) {
    const routerName = this.commonConfig.routerName;
    if (this.commonConfig.belongTo.project === type) {
      let projectUrl = `/${routerName.projects}/${routerName.browse_project}`;
      if ((this.isVendorAndProjectSegment()) || this.userDetail.isProjectSegment) {
        projectUrl = `/${routerName.projects}/${routerName.myprojects}`;
      }
      this.router.navigate([projectUrl, this.commonConfig.gridType]);
    } else if (this.commonConfig.belongTo.vendor === type) {
      if ((this.isVendorAndProjectSegment()) || this.userDetail.isVendorSegment) {
        this.vendorProfile();
      } else {
        this.router.navigate([`${routerName.vendors}/${routerName.browse_vendor}`, this.commonConfig.gridType]);
      }
    }
  }

  isVendorAndProjectSegment = () => this.userDetail.isProjectSegment && this.userDetail.isVendorSegment;

  /**
   * @description function used for toggle the dialog flow from other component
   */
  subscribeToggleDialogFlow() {
    this.storage.toggleDialogFlow.pipe(takeUntil(this.ngUnsubscribe)).subscribe(status => {
      this.toggleDialogFlow();
    });
  }

  /**
   * @description function call when user click on intercom button
   */
  openIntercom() {
    this.isDialogFlowVisible = false;
  }

  /**
   * @description function used for toggle dialog flow
   */
  toggleDialogFlow() {
    window.Intercom('hide');
    this.isDialogFlowVisible = !this.isDialogFlowVisible;
  }

  /**
   * @description function used get user organization id
   */
  getOrganizationData() {
    const orgMemberSubscribe = this.organizationService.getApprovedOrganizationMember(this.userDetail.uid).subscribe(member => {
      this.orgId = '';
      this.orgMenuName = this.commonConfig.menuLabel.orgAdmin;
      if (member.length && this.organizationService.isOrgAdmin(member[0])) {
        this.orgMember = member[0];
        this.orgId = this.orgMember.orgId;
        this.organizationService.setAuthUserOrganizationData(this.orgMember);
        if (this.organizationService.isOrgSuperAdmin(this.orgMember)) {
          this.orgMenuName = this.commonConfig.menuLabel.orgSuperAdmin;
        }
      } else {
        this.organizationService.setAuthUserOrganizationData(null);
      }
      if (this.organizationService.isOrgSuperAdmin(this.orgMember)) {
        this.orgMenuName = this.commonConfig.menuLabel.orgSuperAdmin;
      }
      orgMemberSubscribe.unsubscribe();
    });
  }

  /**
   * @description function used for open the org admin section
   */
  openOrgAdmin() {
    const routerName = this.organizationService.getOrgAdminRouterName(this.orgMember);
    if (routerName) {
      this.router.navigate([`${this.commonConfig.routerName.admin}/${routerName}`], { queryParams: { orgId: this.orgId } });
    }
  }
  /**
   * @description function used for check do we need to show dialog flow or not
   */
  isShowDialogFlow = () => (this.currentRouter && this.commonConfig.routerName.home !== this.currentRouter);

  /**
   * @description function used for check organization exists or not
   */
  orgIdExists = () => !!this.orgId;

  /**
   * @description get url org id query params
   */
  getOrgQueryParams() {
    this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
      this.orgParamsId = '';
      if (params['orgId']) {
        this.orgParamsId = params['orgId'];
      }
    });
  }

  checkPremiumProfile(event) {
    if (
      this.userDetail
      && this.userDetail.isPremiumSignup
      && this.userDetail.premiumProjectId === ''
      && this.userDetail.category.length === CommonConfig.zero
      && this.currentRouterPage !== this.commonConfig.routerName.login
      && this.currentRouterPage !== this.commonConfig.routerName.completeprofile
      && event.target.className !== 'mat-tab-label-content'
      && event.target.className !== 'mat-tab-header-pagination-chevron'
    ) {
      const currentUrl = this.router.url;
      const currentUrlSpt = currentUrl.split('/');
      const currentUrlSptProjectFrmId = currentUrlSpt[CommonConfig.four];
      const currentUrlSptProjectId = currentUrlSptProjectFrmId.split('?');
      const currentProjectId = currentUrlSptProjectId[CommonConfig.zero];

      this.router.navigate(
        [`${CommonConfig.routerName.projects}/${CommonConfig.routerName.browse_project}/${CommonConfig.routerName.projectdetail}`, currentProjectId],
        { queryParams: { from: 'projects' } }
      );

      this.openProfileCompleteDialog();
    }
  }

  openProfileCompleteDialog() {
    this._dialogService.openConfirm({
      title: CommonConfig.popupAlertMessage.previewUserRedirectionTitle,
      message: CommonConfig.popupAlertMessage.previewUserRedirectionMessage,
      acceptButton: 'Complete Profile Now',
      cancelButton: 'Later'
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        this.router.navigate([`/${CommonConfig.routerName.profile}/${CommonConfig.routerName.completeprofile}`]);
      } else {
        this._dialogService.closeAll();
      }
    });
  }

  /**
   * @description function used for check params organization id exists
   */
  paramsOrgIdExists = () => !!this.orgParamsId;

}
