import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleMapsAPIWrapper } from '@agm/core';
import { TdMediaService } from '@covalent/core/media';
import { Subscription } from 'rxjs';
// Config
import { CommonConfig } from '@shared/common.config';
import { CommonUtils } from '@shared/common.util';
import { MapStyles } from '@shared/common-json/map-config';
// Model
import { Project } from '@app/core/model/project';
// Service
import { AuthService } from '@app/core/auth.service';
import { ProjectListService } from '@shared/service/projects/project-list.service';

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['../../../../assets/scss/map-view.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MapViewComponent implements OnInit, AfterViewInit {
  @Input() lat: number;
  @Input() lng: number;
  @Input() projects;
  @Input() loadingFinished = true;
  @Output() scrollEmitter: EventEmitter<boolean> = new EventEmitter();
  @Input() currentPage: string;
  commonConfig = CommonConfig;
  isSidebar = true;
  zoom = CommonConfig.mapZoomLevel;
  imagePath = CommonConfig.mapClusterImagePath;
  hide = false;
  uid: string;
  private readonly authSubscription: Subscription;
  starClass = 'map-star';
  styles: Array<object> = MapStyles;
  public currentURL: string;
  constructor(
    private readonly router: Router,
    private readonly mapsWrapper: GoogleMapsAPIWrapper,
    public media: TdMediaService,
    private readonly auth: AuthService,
    public projectListService: ProjectListService,
    ) {
    this.authSubscription = auth.user.subscribe(res => {
      this.uid = res['uid'];
      this.authSubscription.unsubscribe();
    });

    this.currentURL = new CommonUtils().makePrefixURL(this.router.url, 'map');
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.mapsWrapper.getNativeMap().then((map) => {
    });
  }

  projectMouseOver(project: Project) {
    project.mapIcon = CommonConfig.mapIcons.project.large;
    if (project.id) {
      this.getMapListElement(project.id).classList.add('map-list-hover');
    }
  }

  projectMouseOut(project: Project) {
    project.mapIcon = CommonConfig.mapIcons.project.small;
    if (project.id) {
      this.getMapListElement(project.id).classList.remove('map-list-hover');
    }
  }

  getMapListElement(projectId: string) {
    return document.getElementById('pid_' + projectId);
  }

  onScroll() {
    this.scrollEmitter.emit(true);
  }

  trackByProjectFn(index: number) {
    return index;
  }
}
