import { CommonConfig } from '@app/shared/common.config';

const commonConfig = CommonConfig;
export const BREADCRUMB_KEY_VALUE = {
  dashboard: { text: 'Dashboard', url: ['/dashboard'], queryParams: {} },
  projects: { text: 'Projects', url: ['/projects/browse-project', commonConfig.gridType], queryParams: {} },
  browse_project: { text: 'Browse Project', url: ['/projects/browse-project', commonConfig.gridType], queryParams: {} },
  my_project: { text: 'My Project', url: ['/projects/my-project', commonConfig.gridType], queryParams: {} },
  map: { text: 'Map', url: [], queryParams: {} },
  project_favorite: { text: 'Starred', url: ['/projects/project-favorite'], queryParams: {} },
  archive_project: { text: 'Archived', url: ['/projects/archive-project'], queryParams: {} },
  grid: { text: 'Grid', url: [], queryParams: {} },
  list: { text: 'List', url: [], queryParams: {} },
  create_project: { text: 'Create New project', url: [], queryParams: {} },
  project_detail: { text: 'Project Details', url: [], queryParams: {} },
  vendors: { text: 'Vendors', url: ['/vendors/browse-vendor', commonConfig.gridType], queryParams: {} },
  browse_vendor: { text: 'Browse Vendor', url: ['/vendors/browse-vendor', commonConfig.gridType], queryParams: {} },
  my_vendor: { text: 'Starred', url: ['/vendors/my-vendor'], queryParams: {} },
  vendor_profile: { text: 'Vendor Profile', url: [], queryParams: {} },
  vendor_feature: { text: 'Vendor Features', url: [], queryParams: {} },
  products: { text: 'Products', url: [], queryParams: {} },
  services: { text: 'Services', url: [], queryParams: {} },
  detail: { text: 'Detail', url: [], queryParams: {} },
  profile: { text: 'Profile', url: ['/profile'], queryParams: {} },
  edit_project: { text: 'Edit Project', url: [], queryParams: {} },
  settings: { text: 'Settings', url: ['/settings'], queryParams: {} },
  upgrade_plan: { text: 'Upgrade Plan', url: [], queryParams: {} },
  billing: { text: 'Billing', url: ['/settings'], queryParams: {} },
  edit_vendor: { text: `Edit Vendor's Profile`, url: [], queryParams: {} },
  admin: { text: 'Admin', url: ['/admin/manage-projects'], queryParams: {} },
  manage_projects: { text: 'Manage Projects', url: ['/admin/manage-projects'], queryParams: {} },
  manage_vendors: { text: 'Manage Vendors', url: ['/admin/manage-vendors'], queryParams: {} },
  create_vendor: { text: 'Create vendor', url: [], queryParams: {} },
  manage_bids: { text: 'Manage Bids', url: ['/admin/manage-bids'], queryParams: {} },
  manage_users: { text: 'Manage Users', url: ['/admin/manage-users'], queryParams: {} },
  bulk_imports: { text: 'Bulk Import', url: ['/admin/bulk-imports'], queryParams: {} },
  manage_ads: { text: 'Promotion', url: ['/admin/manage-ads'], queryParams: {} },
  ads_detail: { text: 'Details', url: ['/admin/manage-ads'], queryParams: {} },
  manage_organization: { text: 'Manage Organization', url: ['/admin/manage-organization'], queryParams: {} },
  edit_profile: { text: 'Edit Profile', url: [], queryParams: {} },
  complete: { text: 'Complete', url: [], queryParams: {} },
  categories: { text: 'Category', url: ['/admin/categories'], queryParams: {} },
  category_detail: { text: 'Detail', url: ['/admin/categories/category-detail'], queryParams: {} },
};
