import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Config
import { environment } from 'environments/environment';
import { CommonConfig } from '@shared/common.config';
import { trackEvent } from '@shared/common.constants';
// Model
import { ProjectBid } from '@app/core/model/project-bid';
import { User } from '@app/core/model/user';
// Service
import { AuthService } from '@app/core/auth.service';
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { CustomService } from '@shared/service/custom/custom.service';
import { ProjectsService } from '@shared/service/projects/projects.service';
import { GanalyticsService } from '@app/shared/service/ganalytics/ganalytics.service';

@Component({
  selector: 'app-need-bid-revision',
  templateUrl: './need-bid-revision.component.html'
})
export class NeedBidRevisionComponent implements OnInit {
  commonConfig = CommonConfig;
  isSubmit = false;
  bidData: ProjectBid;
  dialogRef;
  projectsData;
  currentUser: User;
  constructor(
    public fs: FirestoreService,
    private readonly customService: CustomService,
    private readonly http: HttpClient,
    public auth: AuthService,
    public projectsService: ProjectsService,
    private readonly ganalyticsService: GanalyticsService
  ) {
    const authSubscribe = this.auth.user.subscribe(res => {
      this.currentUser = res;
      authSubscribe.unsubscribe();
    });
  }

  ngOnInit() {
  }

  submit(revisionReasonValue: string) {
    this.isSubmit = true;
    const revisionReason = revisionReasonValue;
    this.fs.update(`${this.commonConfig.collectionName.projectbid}/${this.bidData['id']}`,
      { status: this.commonConfig.projectBidStatus.revision, revisionReason }).then(res => {
        this.isSubmit = false;
        // send email to user for bid revise
        const userSubscriber = this.fs.doc$(`${this.commonConfig.collectionName.users}/${this.bidData['uid']}`)
          .subscribe((usersInfo: Partial<User>) => {
            if (usersInfo && usersInfo['email']) {
              const toName = usersInfo['displayName'] ? usersInfo['displayName'] : '';
              const data = {
                toEmail: usersInfo['email'], toName, revisionReason, projectId: this.bidData['projectId'],
                projectType: this.projectsData['projectType'], projectTitle: this.projectsData['title']
              };
              this.sendDataInNOtificationList();
              const sendEmail = this.http.post(`${environment.cloudEndPoint}reviseBidEmail`, data).subscribe(results => {
                sendEmail.unsubscribe();
              });
            }
            userSubscriber.unsubscribe();
          });
        this.customService.showSnackBarSuccess(this.commonConfig.successMessage.needRevision);
        this.ganalyticsService.analyticsTrackEvent(trackEvent.needRevision, this.bidData['projectId']);
        this.bidData['status'] = this.commonConfig.projectBidStatus.revision;
        this.dialogRef.close();
      }).catch(err => {
        this.customService.showSnackBarError(this.commonConfig.validationMessage.someError);
      });
  }

  sendDataInNOtificationList() {
    const bidData = this.bidData;
    const requiredData = {};
    requiredData['uid'] = this.currentUser['uid'];
    if (bidData['projectType'] === this.commonConfig.projectType.quote) {
      requiredData['activityType'] = this.commonConfig.projectActivityType.quoterevisionrequest;
    } else {
      requiredData['activityType'] = this.commonConfig.projectActivityType.bidrevisionrequest;
    }
    requiredData['projectId'] = bidData['projectId'];
    requiredData['sendToUsers'] = [bidData['uid']];
    this.projectsService.saveInsideNotificationPayload(requiredData, this.projectsData);
  }
}
