import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonConfig } from '@shared/common.config';
import { AuthService } from '@app/core/auth.service';
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { InstagramService } from '@shared/service/instagram/instagram.service';
import { CustomService } from '@shared/service/custom/custom.service';

@Component({
  selector: 'app-redirect-instagram',
  templateUrl: './redirect-instagram.component.html'
})
export class RedirectInstagramComponent implements OnInit, OnDestroy {
  uid: string;
  vendorId: string;
  private instagramDetail: Subscription;
  constructor(
    private router: Router,
    private route: ActivatedRoute, public auth: AuthService, public fs: FirestoreService,
    private instagramService: InstagramService, private customService: CustomService) {
    const accessToken = this.router.url.split('access_token=')[1];
    this.vendorId = this.route.snapshot.queryParams['vid'] || '';
    if (this.vendorId && accessToken) {
      this.instagramDetail = this.instagramService.getUsersDetail(accessToken).subscribe(
        (result) => {
          if (result && result['data'].id) {
            const userId = result['data'].id;
            this.fs.update(`${CommonConfig.collectionName.vendors}/${this.vendorId}`,
              { instagramData: { accessToken, userId } }).then(
                updateResonse => {
                  this.closeTab();
                }
              ).catch(
                error => {
                  this.customService.showSnackBarError(CommonConfig.validationMessage.someError);
                  this.closeTab();
                }
              );
          }
        },
        error => {
          if (error.code) {
            this.customService.showSnackBarError(CommonConfig.validationMessage.someError);
            this.closeTab();
          }
        }
      );
    } else {
      this.closeTab();
    }
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.instagramDetail) {
      this.instagramDetail.unsubscribe();
    }
  }

  closeTab() {
    window.close();
  }

}
