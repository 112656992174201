import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TdLoadingService } from '@covalent/core/loading';
// Config
import { environment } from 'environments/environment';
import { CommonConfig } from '@shared/common.config';
// Service
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { CustomService } from '@shared/service/custom/custom.service';

@Injectable({
  providedIn: 'root'
})
export class ShareProjectService {

  private readonly commonConfig = CommonConfig;

  constructor(
    private readonly http: HttpClient,
    private readonly fs: FirestoreService,
    private readonly customService: CustomService,
    private readonly _loadingService: TdLoadingService
    ) { }

  /**
  * @description function to share project by emails.
  * @param projectId id of project.
  * @param toEmails array of emails with whom sharing project.
  */
  getProjectDetail(projectId) {
    return new Promise((resolve, reject) => {
      this.fs.doc$(`${this.commonConfig.collectionName.project}/${projectId}`).subscribe(projects => {
        if (projects && !(projects.hasOwnProperty('isSoftDelete') && projects['isSoftDelete'])) {
          resolve(projects);
        } else {
          resolve(null);
        }
      });
    });
  }

  /**
  * @description function to share project by emails.
  * @param projectId id of project.
  * @param toEmails array of emails with whom sharing project.
  */
  shareProject(projectId, toEmails) {
    this._loadingService.register();
    const data = { projectId, toEmails };
    const shareProjectPreview = this.http.post(`${environment.cloudEndPoint}shareProject`, data).subscribe(
      (result) => {
        if (result['status'] === this.commonConfig.status.success) {
          this.customService.showSnackBarSuccess(this.commonConfig.successMessage.projectShared);
        } else {
          this.customService.showSnackBarError(this.commonConfig.validationMessage.someError);
        }
        this._loadingService.resolve();
        shareProjectPreview.unsubscribe();
      }, error => {
        this._loadingService.resolve();
        this.customService.showSnackBarError(this.commonConfig.validationMessage.someError);
        shareProjectPreview.unsubscribe();
      });
  }

  /**
   * @description Function used for save project view activity for outside user
   */
  saveProjectViewActivity (dataObj) {
    const pasub = this.http.post(`${environment.cloudEndPoint}saveProjectViewActivity`, dataObj).subscribe( pres => {
      pasub.unsubscribe();
    });
  }

}
