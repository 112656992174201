import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '@app/shared/validator/custom.validator';

import { CommonConfig } from '@app/shared/common.config';
import { Vendor } from '@app/core/model/vendor';
import { User } from '@app/core/model/user';

import { VendorsService } from '@app/shared/service/vendors/vendors.service';
import { AuthService } from '@app/core/auth.service';

@Component({
  selector: 'app-claim-vendor',
  templateUrl: './claim-vendor.component.html',
  styleUrls: ['./claim-vendor.component.scss']
})
export class ClaimVendorComponent implements OnInit {
  claimVendorForm: FormGroup;
  commonConfig = CommonConfig;
  vendor: Vendor;
  isVendorProfile: Object;
  dialogRef;
  userInformation: User;
  isBrowseVendor = true;
  loading: boolean;

  constructor(public vendorsService: VendorsService, private formBuilder: FormBuilder,
    private auth: AuthService) { }

  ngOnInit() {
    this.getAuthUser();
  }

  async getAuthUser() {
    this.loading = true;
    const authSubscription = this.auth.user.subscribe((user: Partial<User>) => {
      if (user) {
        this.userInformation = { uid: user.uid, email: user.email };
        this.userInformation['displayName'] = user.displayName ? user.displayName : user.email;
        this.userInformation['photoUrl'] = user.profilePhoto.url ? user.profilePhoto.url : '';
        this.buildFrom();
        this.loading = false;
      }
      authSubscription.unsubscribe();
    });
  }

  buildFrom() {
    this.claimVendorForm = this.formBuilder.group({
      uid: [this.userInformation['uid']],
      userInformation: [this.userInformation],
      taxId: [''],
      name: [''],
      website: [''],
      logoImage: [null],
      coverImage: [null],
      profileType: [this.commonConfig['vendorProfileType']['claim']],
      category: [[]],
      contactEmails: [[]],
      contactAddress: [[]],
      phoneNumbers: this.formBuilder.array([this.createPhone()]),
    });
  }

  createPhone(): FormGroup {
    return this.formBuilder.group({
      phone: ['', [
        Validators.required,
        Validators.minLength(this.commonConfig['maxPhoneLength'])
      ]]
    });
  }

  getPhone(index: number) {
    return this.claimVendorForm.controls['phoneNumbers']['controls'][index].controls.phone;
  }
  submit() {
    this.vendorsService.vendorProfileClaim(this, this.vendor, this.claimVendorForm.value);
    this.dialogRef.close();
  }

}
