import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TdLoadingService } from '@covalent/core/loading';
// Config
import { CommonConfig } from '@app/shared/common.config';
import { trackEvent } from '@shared/common.constants';
// Model
import { Project } from '@app/core/model/project';
// Service
import { AuthService } from '@app/core/auth.service';
import { CustomService } from '@shared/service/custom/custom.service';
import { UserService } from '@shared/service/user/user.service';
import { UserActivityService } from '@shared/service/user-activity/user-activity.service';
import { GanalyticsService } from '@shared/service/ganalytics/ganalytics.service';

declare let Chargebee;

@Component({
  selector: 'app-premium-signup',
  templateUrl: './premium-signup.component.html',
  styleUrls: ['./premium-signup.component.scss']
})
export class PremiumSignupComponent implements OnInit {
  CommonConfig = CommonConfig;
  dialogRef;
  project: Partial<Project>;
  premiumProjectId;
  premiumVendorId;
  uid: string;
  email: string;
  public chargebeeIns;

  constructor(
    private readonly router: Router,
    private readonly _loadingService: TdLoadingService,
    private readonly auth: AuthService,
    private readonly customService: CustomService,
    private readonly userService: UserService,
    private readonly userActivityService: UserActivityService,
    private readonly ganalyticsService: GanalyticsService
  ) { }

  ngOnInit() {
    if(this.premiumProjectId) {
      this.premiumProjectId = this.project.customId;
    }
  }

  googleLogin() {
    this.auth.googleLogin(true, this.premiumProjectId, this.premiumVendorId)
      .then(credential => {
        if (credential.user) {
          this.uid = credential.user.uid;
          this.email = credential.user.email;
          this.auth.setDefaultKeyForUser(credential.user);
          this.ganalyticsService.analyticsTrackEvent(trackEvent.loginByGoogle);
          this.dialogRef.close();
          this.chargebeeIns = Chargebee.getInstance();
          this.userService.selectSubscription(this.email, this);
        }
      })
      .catch(error => {
        this.ganalyticsService.analyticsTrackEvent(trackEvent.loginErrorByGoogle);
        this.customService.showSnackBarError(error.message);
      });
  }

  async submit(isSubmitSubscription, subscriptionDetail?: object) {
    this._loadingService.register();
    this.updateUser(isSubmitSubscription, subscriptionDetail);
    this._loadingService.resolve();
  }

  async updateUser(isSubmitSubscription, subscriptionDetail) {
    const userSubscriptionData = {
      email: this.email,
      premiumProjectId: '',
      premiumVendorId: this.premiumVendorId,
      isVendor: this.premiumVendorId ? true : false,
      timeZone: CommonConfig.defaultTimeZone.americaNewYork,
      userHashKey: this.userActivityService.getHashKey(this.uid),
      subscription: subscriptionDetail['subscription'],
      companyName: subscriptionDetail['companyName'] || '',
      phone: subscriptionDetail['phone'] || '',
      updatedAt: new Date()
    };
    this.userService.updateUserSubscription(isSubmitSubscription, this.uid, this.premiumProjectId, this.premiumVendorId, userSubscriptionData, this.chargebeeIns);
  }


}
