import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy, Input, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatRadioChange, MatCheckboxChange } from '@angular/material';
import { MapsAPILoader } from '@agm/core';
// Config
import { CommonConfig } from '@app/shared/common.config';
// Service
import { ProjectListService } from '@shared/service/projects/project-list.service';
import { GeolocationService } from '@shared/service/geolocation/geolocation.service';
import { DataStorageService } from '@shared/service/storage/data-storage.service';

@Component({
  selector: 'app-project-filters',
  templateUrl: './project-filters.component.html',
  styleUrls: [
    './project-filters.component.scss'
  ]
})
export class ProjectFiltersComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() currentPage;
  @Input() currentProjectObj;
  @ViewChild('address', { static: false }) searchElementRef: ElementRef;
  commonConfig = CommonConfig;
  projectFilterForm: FormGroup;
  public pStatus: Array<object> = [{
    key: '', value: 'All Requests'
  }, {
    key: this.commonConfig.projectStatus.published,
    value: this.commonConfig.actionState.accepted
  }, {
    key: this.commonConfig.projectStatus.draft,
    value: this.commonConfig.projectStatus.draft
  }, {
    key: this.commonConfig.projectStatus.pending,
    value: this.commonConfig.projectStatus.pending
  }];

  constructor(
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly ngZone: NgZone,
    private readonly mapsAPILoader: MapsAPILoader,
    public projectListService: ProjectListService,
    private readonly locationService: GeolocationService,
    public storage: DataStorageService
  ) { }

  ngOnInit() {
    if (this.currentPage === this.commonConfig.routerName.projects) {
      this.buildFrom();
    }
    this.projectListService.defaultLatLongStaus.subscribe(latLongStatus => {
      if (latLongStatus['isDefaultSet']) {
        this.setDefaultLatLng(false);
      }
    });
  }

  ngAfterViewInit() {
    if (this.currentPage === this.commonConfig.routerName.projects) {
      this.locationService.addressAutoComplete(this.searchElementRef, this.projectFilterForm.get('location'));
    }
  }

  ngOnDestroy() {
  }

  // Create filter form
  buildFrom() {
    this.projectFilterForm = this.fb.group({
      keyword: [''],
      projectType: [''],
      location: this.locationService.createLocation(),
      category: [''],
      radious: ['', Validators.min(1)],
      RdoSel: [''],
      bidEndDateRange: [''],
      oldProjects: [''],
      publicProjects: [true]
    }
    );
    this.projectFilterForm.get('radious').setValue(CommonConfig.mapRadious);
    if (this.currentProjectObj.querydncodeData) {
      this.projectFilterForm.patchValue(this.currentProjectObj.querydncodeData);
      this.projectFilterForm.markAsPristine();
      this.currentProjectObj.categories = this.projectFilterForm.get('category').value ? this.projectFilterForm.get('category').value : [];
    }
    this.currentProjectObj.authenticateUser();
    this.setDefaultLatLng(false);
    this.projectListService.projectFilterFrm.next(this.projectFilterForm.value);
    this.currentProjectObj.getProjectList();
  }

  get getLat() {
    return this.projectFilterForm.get('location').get('lat');
  }

  get locationAddress() {
    return this.projectFilterForm.get('location').get('address');
  }

  get getRadious() {
    return this.projectFilterForm.get('radious');
  }

  // On checked old/public projects filter
  onChecked(event, projectKeyName: string) {
    this.projectFilterForm.get(projectKeyName).setValue(event);
  }

  // On choose bid end projects filter
  choosedBidEndDate(event) {
    this.projectFilterForm.get('bidEndDateRange').setValue(event);
  }

  addressAutoComplete() {
    this.locationService.addressAutoComplete(this.searchElementRef, this.projectFilterForm.get('location'));
  }

  // Set default latitude and longitude
  setDefaultLatLng(isClear) {
    if (this.currentProjectObj.querydncodeData && !isClear) {
      this.currentProjectObj.lat = this.projectFilterForm.get('location').get('lat').value;
      this.currentProjectObj.lng = this.projectFilterForm.get('location').get('long').value;
      this.currentProjectObj.address = this.projectFilterForm.get('location').get('address').value;
    } else if (this.currentProjectObj.searchLocation && Object.keys(this.currentProjectObj.searchLocation).length && !isClear) {
      this.currentProjectObj.lat = this.currentProjectObj.searchLocation.lat;
      this.currentProjectObj.lng = this.currentProjectObj.searchLocation.long;
      this.currentProjectObj.address = this.currentProjectObj.searchLocation.address;
      this.setLatLng();
    } else if (isClear) {
      this.currentProjectObj.lat = this.currentProjectObj.currentLat;
      this.currentProjectObj.lng = this.currentProjectObj.currentLng;
      this.currentProjectObj.address = this.currentProjectObj.currentAddress;
      this.setLatLng();
      this.storage.setSearchLocation(this.projectFilterForm.get('location').value);
    } else {
      this.currentProjectObj.lat = CommonConfig.US.lat;
      this.currentProjectObj.lng = CommonConfig.US.lon;
      this.currentProjectObj.address = CommonConfig.US.address;
      this.setLatLng();
    }
  }

  // Set latitude and logitude
  setLatLng() {
    this.projectFilterForm.get('location').get('lat').setValue(this.currentProjectObj.lat);
    this.projectFilterForm.get('location').get('long').setValue(this.currentProjectObj.lng);
    this.projectFilterForm.get('location').get('address').setValue(this.currentProjectObj.address);
  }

  // Clear location data
  clearLocation() {
    this.projectFilterForm.get('location').get('lat').setValue(null);
    this.projectFilterForm.get('location').get('long').setValue(null);
  }

  // Apply project filter
  applyFilter() {
    this.storage.setSearchLocation(this.projectFilterForm.get('location').value);
    this.currentProjectObj.queryEncodeData = encodeURI(JSON.stringify(this.projectFilterForm.value));
    this.router.navigate([`${this.commonConfig.routerName.projects}/${this.commonConfig.routerName.browse_project}`,
    this.currentProjectObj.viewType], { queryParams: { data: this.currentProjectObj.queryEncodeData } });
    this.projectListService.projectFilterMenuTrigger.next(true);
    this.currentProjectObj.resetProjectData();
    this.projectListService.projectFilterFrm.next(this.projectFilterForm.value);
    this.currentProjectObj.getProjectList();
  }

  // Clear project filter
  clearFilter() {
    this.projectFilterForm.reset();
    this.currentProjectObj.categories = [];
    this.projectFilterForm.controls['RdoSel'].reset();
    this.projectFilterForm.controls['oldProjects'].reset();
    this.projectFilterForm.controls['publicProjects'].reset();
    this.projectFilterForm.get('radious').setValue(CommonConfig.mapRadious);
    this.setDefaultLatLng(true);
    this.projectListService.projectFilterMenuTrigger.next(true);
    this.currentProjectObj.resetProjectData();
    this.projectListService.projectFilterFrm.next(this.projectFilterForm.value);
    this.currentProjectObj.getProjectList();
  }

  // Change my project filter options
  changeMyProjectOptions(event, value: string) {
    if (event.checked) {
      this.currentProjectObj.selectedValue[value] = true;
    } else {
      this.currentProjectObj.selectedValue[value] = false;
    }
  }

  // submit my project filter data
  submitMyProjectFilter() {
    const queryEncodeData = encodeURI(JSON.stringify(this.currentProjectObj.selectedValue));
    this.router.navigate(
      [`${this.commonConfig.routerName.projects}/${this.commonConfig.routerName.myprojects}`, this.currentProjectObj.viewType],
      { queryParams: { data: queryEncodeData } }
    );
    this.currentProjectObj.projects = [];
    this.currentProjectObj.getProjectList();
  }

  disabledSubmit = () => (!this.currentProjectObj.selectedValue.viewed && !this.currentProjectObj.selectedValue.bidby && !this.currentProjectObj.selectedValue.member
    && !this.currentProjectObj.selectedValue.owner)

}
