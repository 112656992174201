export const PlatformMetricsConfig = {
  platformMetricsText: {
    activityType: 'Activity Type',
    bidAmountAverage: 'Bid Amount Average',
    bidsCurrentLiveProject: 'Bid Current Live Project',
    newBids: 'New Bids',
    totalBids: 'Total Bids',
    bidEnd: 'Bid End',
    bidStart: 'Bid Start',
    bidUpcoming: 'Bid Upcoming',
    bidWindowDurationAverage: 'Bid Window Duration Average',
    currentLiveProjects: 'Current Live Projects',
    estimatedCostAverage: 'Estimate Cost Average',
    newProjects: 'New Projects',
    projectCategory: 'Project Category',
    projectStatus: 'Project Status',
    projectType: 'Project Type',
    projectViewsAverage: 'Project Views Average',
    projectViews: 'Project Views',
    totalProjects: 'Total Projects',
    daUsers: 'DA Users',
    dailyActiveUsers: 'Daily Active Users',
    maUsers: 'MA Users',
    newPm: 'New PM',
    newProfiles: 'New Profiles',
    newUsers: 'New Users',
    subscriptionType: 'Subscription Type',
    totalPm: 'Total PM',
    totalUsers: 'Total Users',
    userCategory: 'User Category',
    claimedVendors: 'Claimed Vendors',
    newVendors: 'New Vendors',
    totalVendors: 'Total Vendors',
    unclaimedVendors: 'Unclaimed Vendors',
    vendorCategory: 'Vendor Category',
  },
  activityType: {
    create: 'Create',
    update: 'Update',
    view: 'View',
    bidplace: 'Bid Place',
    bidupdate: 'Bid Update',
    bidaccept: 'Bid Accept',
    quoteplace: 'Quote Place',
    quoteupdate: 'Quote Update',
    quoteaccept: 'Quote Accept',
    projectrevision: 'Project Revision',
    projectaccept: 'Project Accept',
    projectreject: 'Project Reject',
    addmember: 'Add Member',
    removemember: 'Remove Member',
    addcomment: 'Add Comment',
    replycomment: 'Reply Comment',
    createprojectbyadmin: 'Create Project By Admin',
    assignproject: 'Assign Project',
    quoterevisionrequest: 'Quote Revision Request',
    bidrevisionrequest: 'Bid Revision Request',
    bidreject: 'Bid Reject',
    quotereject: 'Quote Reject',
    createprojectbyuser: 'Create Project By User',
    updaterevisionrequest: 'Update Revision Request',
    walkthroughschedule: 'Walkthrough Schedule',
    walkthroughcancel: 'Walkthrough cancel',
    favorite: 'Favorite',
    unfavorite: 'UnFavorite',
    invitevendor: 'Invite Vendor',
    addendums: 'Addendums',
    expressProjectReminder: 'Express Project Reminder',
    anonymousView: 'Anonymous View',
    createUserAccount: 'Create User Account',
    profileCompleted: 'Profile Completed',
    subscriptionAdded: 'Subscription Added',
    updateAccount: 'Update Account',
    adminUpdateAccount: 'Admin Update Account',
    changePassword: 'Change Password',
    coverPicUploaded: 'cover Pic Uploaded',
    profilePicUploaded: 'Profile Pic Uploaded',
    signIn: 'Sign In',
    signOut: 'Sign Out'
  },
};

