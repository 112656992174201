import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-limit-dialog',
  templateUrl: './project-limit-dialog.component.html'
})
export class ProjectLimitDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
