import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ICalendarService {

  constructor(private http: HttpClient) { }

  send_iCalendar(data) {
    const unSubscriber = this.http.post(`${environment.cloudEndPoint}createIcs`, data).subscribe(res => { unSubscriber.unsubscribe(); });
  }
}
