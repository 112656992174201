import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material';

import { Vendor } from '@app/core/model/vendor';
import { CommonConfig } from '@app/shared/common.config';

import { ClaimVendorComponent } from '@shared/component/vendors/claim-vendor/claim-vendor.component';
import { Router } from '@angular/router';
import { CommonUtils } from '@app/shared/common.util';
import { User } from '@app/core/model/user';

@Component({
  selector: 'app-vendor-grid',
  templateUrl: './vendor-grid.component.html',
  styleUrls: ['./vendor-grid.component.scss', '../../../assets/scss/grid-view.scss']
})
export class VendorGridComponent implements OnInit, AfterViewInit {

  @Input() vendors;
  @Input() loadingFinished = true;
  @Input() currentPage: string;
  @Input() uid: string;
  @Input() isVendorProfile: Object;
  @Input() userDetail: Partial<User>;
  commonConfig = CommonConfig;
  starClass = 'grid-star';
  public currentURL: string;
  constructor(public dialog: MatDialog, private router: Router) {
    this.currentURL = new CommonUtils().makePrefixURL(this.router.url, 'grid');
  }

  ngOnInit() { }
  ngAfterViewInit() { }
  trackByFn(index: number) {
    return index;
  }
  claimVendorProfile($event: Event, vendor: Vendor, isVendorProfile: Object) {
    $event.stopPropagation();
    const dialogRef = this.dialog.open(ClaimVendorComponent, {
      width: '600px'
    });
    const instance = dialogRef.componentInstance;
    instance.dialogRef = dialogRef;
    instance.vendor = vendor;
    instance.isVendorProfile = isVendorProfile;
  }
}

