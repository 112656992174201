import { Component, OnInit, Input } from '@angular/core';

import { ProjectBid } from '@app/core/model/project-bid';

import { CommonConfig } from '@shared/common.config';

@Component({
  selector: 'app-bid-list',
  templateUrl: './bid-list.component.html',
  styleUrls: ['./bid-list.component.scss']
})
export class BidListComponent implements OnInit {
  commonConfig = CommonConfig;
  @Input() totalBid: number;
  @Input() bidList: ProjectBid[] = [];
  @Input() uid: string;
  @Input() tabListLoading: boolean;
  constructor() { }

  ngOnInit() {
  }

  /**
   * @description function used for track DOM element change
   * @param {number} index contain array index value
   * @param {object} bid contain bid data object
   */
  tracByBidListFn(index: number, bid: ProjectBid) {
    return bid.uid || index;
  }
}
