import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material';

import { Vendor } from '@app/core/model/vendor';
import { CommonConfig } from '@shared/common.config';

import { VendorFavoriteComponent } from '../vendor-favorite/vendor-favorite.component';
import { ClaimVendorComponent } from '@shared/component/vendors/claim-vendor/claim-vendor.component';

@Component({
  selector: 'app-vendor-list',
  templateUrl: './vendor-list.component.html',
  styleUrls: ['../../../assets/scss/list-view.scss']
})
export class VendorListComponent implements OnInit, OnChanges {

  @Input() vendors: Vendor;
  @Input() loadingFinished = true;
  @Input() currentPage: string;
  @Input() uid: string;
  @Input() isVendorProfile: Object;
  commonConfig = CommonConfig;
  starClass = 'list-star';

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }
  ngOnChanges() { }

  trackByFn(index: number) {
    return index;
  }
  claimVendorProfile($event: Event, vendor: Vendor, isVendorProfile: Object) {
    $event.stopPropagation();
    const dialogRef = this.dialog.open(ClaimVendorComponent, {
      width: '600px'
    });
    const instance = dialogRef.componentInstance;
    instance.dialogRef = dialogRef;
    instance.vendor = vendor;
    instance.isVendorProfile = isVendorProfile;
  }
}
