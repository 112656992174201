import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Location } from '@angular/common';
import { TdDialogService } from '@covalent/core/dialogs';
import { BehaviorSubject } from 'rxjs';
import * as _moment from 'moment';
import * as _ from 'lodash';
// Config
import { CommonConfig } from '@shared/common.config';
// Component
import { CustomSnackBarComponent } from '@shared/custom-snackbar/custom.snackbar.component';

const moment = (_moment)['default'] ? (_moment)['default'] : _moment;

@Injectable({
  providedIn: 'root'
})
export class CustomService {
  private readonly commonConfig = CommonConfig;
  private readonly loadingMask = new BehaviorSubject(false);
  loadingMaskStatus = this.loadingMask.asObservable();

  constructor(
    private readonly _snackBarService: MatSnackBar,
    private readonly _dialogService: TdDialogService,
    private readonly location: Location
  ) { }

  guid() {
    return `${this.s4()}${this.s4()}-${this.s4()}-${this.s4()}-${this.s4()}-${this.s4()}${this.s4()}${this.s4()}`;
  }

  s4() {
    return Math.floor((CommonConfig.one + Math.random()) * CommonConfig.hexaRandomNumber)
      .toString(CommonConfig.sixteen)
      .substring(CommonConfig.one);
  }

  encryptKey(key: string) {
    return atob(key);
  }

  decryptKey(key: string) {
    return btoa(key);
  }

  showSnackBarSuccess(message: string) {
    this._snackBarService.openFromComponent(CustomSnackBarComponent, {
      data: {
        messageText: message, messageType: CommonConfig.typeSuccess
      }, panelClass: CommonConfig.snackBarClassSuccess, verticalPosition: 'top'
    });
  }

  showSnackBarError(message: string) {
    this._snackBarService.openFromComponent(CustomSnackBarComponent, {
      data: {
        messageText: message, messageType: CommonConfig.typeError
      }, panelClass: CommonConfig.snackBarClassError, verticalPosition: 'top'
    });
  }

  /**
   * @description Function to get current date
   * @param {number} subtract contain number of days if to subtract from current date
   */
  getCurrentDate(subtract?: number) {
    if (subtract) {
      return moment().subtract(subtract, CommonConfig.days)._d;
    } else {
      return moment()._d;
    }
  }

  /**
   * @description Function to get current time stapm
   * @param {boolean} isToDate contain true or false. if timestamp need only date without hours then pass it true
   */
  getCurrentTimestamp(isToDate?) {
    if (isToDate) {
      return moment(this.getToDateString()).valueOf();
    } else {
      return moment().valueOf();
    }
  }

  /**
   * @description Function to get the date portion of a Date object into a readable string
   */
  getToDateString() {
    return moment().format(CommonConfig.YYYYMMDD);
  }

  /**
   * @description Function to return text only from html
   */
  stripHtml(html) {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  /**
   * @description Function used for show loading mask
   */
  loadingMaskOn() {
    this.loadingMask.next(true);
  }

  /**
   * @description Function used for hide loading mask
   */
  loadingMaskOff(delay = this.commonConfig.delay1000) {
    setTimeout(() => {
      this.loadingMask.next(false);
    }, delay);
  }

  /**
   * @description Function used for immediate hide loading mask
   */
  immediateLoadingOff() {
    this.loadingMask.next(false);
  }

  getDocType = (doc) => doc.filename.substr(Number(doc.filename.lastIndexOf('.')) + Number(this.commonConfig.one));

  sumData(objData) {
    const objDataArr = [];
    for (const key in objData) {
      if (objData.hasOwnProperty(key)) {
        objDataArr.push(objData[key]);
      }
    }
    if (objDataArr.length) {
      return objDataArr.reduce(function (accumulator, current) {
        return Number(accumulator) + Number(current);
      });
    } else {
      return Number(CommonConfig.zero);
    }

  }

  objectToArray(objData) {
    return Object.entries(objData).map(([key, value]) => ({ key, value }));
  }

  uniqueArrayByKey(objData, fieldName) {
    objData.relatedCategory = _.uniqBy(objData.relatedCategory, fieldName);
  }

  sidebarPorjectsToggle(thisObj, eleObj) {
    if (thisObj.isSidebar) {
      thisObj.isSidebar = false;
    } else {
      thisObj.isSidebar = true;
    }
    eleObj.toggle();
  }

  manageChartDateFormat(updateAt) {
    const updatedDate = new Date(updateAt.seconds * CommonConfig.thousand);
    const updatedDateFmt = moment(updatedDate, CommonConfig.YYYYMMDD);
    return `${updatedDateFmt.format(CommonConfig.MMMM)}/${updatedDateFmt.format(CommonConfig.DD)}`;
  }

  getUniqueArray(uniqueArr, compare) {
    uniqueArr = uniqueArr.map(e => e[compare])
      // store the indexes of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the false indexes & return unique objects
      .filter((e) => uniqueArr[e]).map(e => uniqueArr[e]);
    return uniqueArr;
  }

  projectCreation(userDetail) {
    let projectCreation;
    if (userDetail.projectCreation) {
      projectCreation = Number(userDetail.projectCreation) + CommonConfig.one;
    } else {
      projectCreation = CommonConfig.one;
    }
    return projectCreation;
  }

  projectBidSubmission(usersInfo) {
    let projectBidSubmission;
    if (usersInfo.projectBidSubmission) {
      projectBidSubmission = Number(usersInfo.projectBidSubmission) + CommonConfig.one;
    } else {
      projectBidSubmission = CommonConfig.one;
    }
    return projectBidSubmission;
  }

  checkProjectCreation(userDetail, projectCreation, isLocationBack = true) {
    if (userDetail.subscription && userDetail.subscription.plan_id === CommonConfig.subscriptionPlanType.basic && projectCreation > CommonConfig.two) {
      this._dialogService.openAlert({
        message: CommonConfig.popupAlertMessage.basicUserCreateProjectLimit
      });
      if (isLocationBack) {
        this.location.back();
      }
      return false;
    } else {
      return true;
    }
  }

  checkProjectBidSubmission(userDetail, projectBidSubmission, isLocationBack = true) {
    if (userDetail.subscription && userDetail.subscription.plan_id === CommonConfig.subscriptionPlanType.basic && projectBidSubmission > CommonConfig.two) {
      this._dialogService.openAlert({
        message: CommonConfig.popupAlertMessage.basicUserBidLimit
      });
      if (isLocationBack) {
        this.location.back();
      }
      return false;
    } else {
      return true;
    }
  }

}
