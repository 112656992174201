import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TdLoadingService } from '@covalent/core/loading';
// Config
import { CommonConfig } from '@shared/common.config';
import { trackEvent } from '@shared/common.constants';
// Validator
import { CustomValidator } from '@app/shared/validator/custom.validator';
// Model
import { User } from '@app/core/model/user';
// Service
import { AuthService } from '@app/core/auth.service';
import { CustomService } from '@shared/service/custom/custom.service';
import { UserService } from '@shared/service/user/user.service';
import { UserActivityService } from '@shared/service/user-activity/user-activity.service';
import { GanalyticsService } from '@shared/service/ganalytics/ganalytics.service';

declare let Chargebee;

@Component({
  selector: 'app-shared-signup',
  templateUrl: './shared-signup.component.html'
})
export class SharedSignupComponent implements OnInit {
  @Input() isPremiumSignup: boolean;
  @Input() premiumProjectId = '';
  @Input() premiumVendorId = '';
  @Input() dialogRef;
  CommonConfig = CommonConfig;
  private readonly ngUnsubscribe = new Subject();
  public chargebeeIns;
  public confirm_password;
  usersInfo: Partial<User>;
  signupForm: FormGroup;
  signupSuccess = false;
  hide = true;
  termsConditionsUrl = `/${CommonConfig.routerName.termsconditions}`;
  privacyPolicyUrl = `/${CommonConfig.routerName.privacypolicy}`;

  constructor(
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly _loadingService: TdLoadingService,
    private readonly auth: AuthService,
    private readonly customService: CustomService,
    private readonly userService: UserService,
    private readonly userActivityService: UserActivityService,
    private readonly ganalyticsService: GanalyticsService
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.signupForm = this.fb.group({
      'email': ['', [
        Validators.required,
        Validators.email,
        CustomValidator.validEmail
      ]],
      'password': ['', [
        Validators.minLength(CommonConfig.passwordMinLength),
        Validators.maxLength(CommonConfig.passwordMaxLength),
        Validators.pattern(CommonConfig.passwordPattern),
      ]],
      'confirm_password': ['', [
        Validators.minLength(CommonConfig.passwordMinLength),
        Validators.maxLength(CommonConfig.passwordMaxLength),
        Validators.pattern(CommonConfig.passwordPattern),
      ]],
      termsPolicy: [false, Validators.pattern('true')],
    }, {
        validator: CustomValidator.matchPassword.bind(this)
      });
  }

  get email() {
    return this.signupForm.get('email');
  }

  get password() {
    return this.signupForm.get('password');
  }

  get confirmPassword() {
    return this.signupForm.get('confirm_password');
  }

  async signup() {
    this.dialogRef.close();
    this._loadingService.register();
    const userData = await this.auth.getUserByEmail(this.signupForm.value['email']);
    if (userData['length'] > CommonConfig.zero) {
      this.customService.showSnackBarError(CommonConfig.validationMessage.existsEmail);
    } else {
      const premiumSignupReqData = {
        displayName: this.signupForm.value['email'],
        email: this.signupForm.value['email'],
        password: this.signupForm.value['password'],
        premiumProjectId: this.premiumProjectId,
        premiumVendorId: this.premiumVendorId,
        isPremiumSignup: this.isPremiumSignup
      };
      const responseData = await this.auth.premiumSignup(premiumSignupReqData);
      if (responseData['success']) {
        this.chargebeeIns = Chargebee.getInstance();
        this.userService.selectSubscription(this.signupForm.value['email'], this);
      } else {
        this.customService.showSnackBarError(CommonConfig.validationMessage.someError);
        this.ganalyticsService.analyticsTrackEvent(trackEvent.signUpError);
      }
    }
    this._loadingService.resolve();
  }

  async submit(isSubmitSubscription, subscriptionDetail?: object) {
    this._loadingService.register();
    const userData = await this.auth.getUserByEmail(this.signupForm.value['email']);
    if (userData['length'] > CommonConfig.zero) {
      this.userService.premiumLogin(isSubmitSubscription, subscriptionDetail, this.premiumProjectId, this.premiumVendorId, this.signupForm.value, this.chargebeeIns);
    } else {
      this.createUser(isSubmitSubscription, subscriptionDetail);
    }
    this._loadingService.resolve();
  }

  async createUser(isSubmitSubscription, subscriptionDetail) {
    const userSubscriptionData = {
      email: this.signupForm.value['email'],
      premiumProjectId: this.premiumProjectId,
      premiumVendorId: this.premiumVendorId,
      timeZone: CommonConfig.defaultTimeZone.americaNewYork,
      subscription: subscriptionDetail['subscription'],
      companyName: subscriptionDetail['companyName'] || '',
      phone: subscriptionDetail['phone'] || '',
      isSoftDelete: false,
      isHardDelete: false,
      updatedAt: new Date()
    };
    this.userService.createUserSubscription(isSubmitSubscription, subscriptionDetail,
      this.premiumProjectId, this.premiumVendorId, this.signupForm.value, userSubscriptionData, this.chargebeeIns);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
