import { Component, OnInit, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
// Config
import { CommonConfig } from '@shared/common.config';

@Component({
  selector: 'app-user-profile-picture',
  templateUrl: './user-profile-picture.component.html',
  styleUrls: ['../../../../assets/scss/details-pages.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserProfilePictureComponent implements OnInit, OnDestroy {
  commonConfig = CommonConfig;

  @Input() userId: string;
  @Input() photoUrl: string;

  constructor(
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

}
