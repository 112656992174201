
import { of as observableOf, Observable } from 'rxjs';
import { switchMap, share, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TdLoadingService } from '@covalent/core/loading';
import { firebase } from '@firebase/app';
import '@firebase/auth';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { FirestoreService } from '@app/shared/service/firestore/firestore.service';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { TdDialogService } from '@covalent/core/dialogs';
import { CustomService } from '@app/shared/service/custom/custom.service';
import { CommonEmail } from '@app/core/model/common-email';
import { User } from './model/user';
import { Category } from './model/category';
import { SelectCategory } from './model/select-category';
import { CommonConfig } from '@app/shared/common.config';
import { TaskService } from '@app/shared/service/task/task.service';
import { TimestampService } from '@shared/service/timestamp/timestamp.service';
import { DataStorageService } from '@app/shared/service/storage/data-storage.service';
import { HttpService } from '@app/shared/service/http/http.service';
import { CommonUtils } from '@app/shared/common.util';
import { APP_NOTIFICATION, EMAIL_NOTIFICATIONS } from '../../../notification-utils';

@Injectable()
export class AuthService {

  user: Observable<User>;
  userInfo: User;
  commonConfig = CommonConfig;
  constructor(private readonly afAuth: AngularFireAuth,
    private readonly afs: AngularFirestore,
    private readonly router: Router,
    public fs: FirestoreService,
    private readonly http: HttpClient,
    private readonly _dialogService: TdDialogService,
    private readonly customService: CustomService,
    public _taskService: TaskService,
    private readonly _loadingService: TdLoadingService,
    private readonly timestampService: TimestampService,
    public storage: DataStorageService,
    private readonly httpService: HttpService) {

    //// Get auth data, then get firestore user document || null
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          if (user.emailVerified) {
            return this.afs.doc<User>(`users/${user.uid}`).valueChanges().pipe(share());
          } else {
            return observableOf(null).pipe(share());
          }
        } else {
          return observableOf(null).pipe(share());
        }
      }));
  }

  googleLogin(isPremiumSignup = false, premiumProjectId = '', premiumVendorId = '') {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.oAuthLogin(provider, isPremiumSignup, premiumProjectId, premiumVendorId);
  }

  manageUser(status: boolean, userInfo: User) {
    let alertMessage = '';
    let successMessage = '';
    let title = '';
    let templateId = '';
    let subject = '';
    switch (status) {
      case true:
        alertMessage = CommonConfig.popupAlertMessage.blockUserByAdmin;
        successMessage = CommonConfig.successMessage.blockUserByAdmin;
        title = 'Block User';
        templateId = CommonConfig.emailTemplates.blockUserByAdmin;
        subject = CommonConfig.emailSubject.blockUserByAdmin;
        break;
      case false:
        alertMessage = CommonConfig.popupAlertMessage.unBlockUserByAdmin;
        successMessage = CommonConfig.successMessage.unBlockUserByAdmin;
        title = 'Unblock User';
        templateId = CommonConfig.emailTemplates.unBlockUserByAdmin;
        subject = CommonConfig.emailSubject.unBlockUserByAdmin;
        break;
      default:
        break;
    }
    this._dialogService.openConfirm({
      message: alertMessage,
      title,
      acceptButton: CommonConfig.dialogService.accept,
      cancelButton: CommonConfig.dialogService.cancel
    })
      .afterClosed().subscribe((accept: boolean) => {
        if (accept) {
          this.updateUserStatus(userInfo, { status, successMessage, subject, templateId });
        }
      });
  }

  /**
   * @description function used for save user activate and deactivate status
   * @param userInfo conation user information
   * @param data contatin user update data
   * @param optionData contaion message, subject and template id
   */
  updateUserStatus(userInfo, optionData) {
    const data = {
      id: userInfo.id || userInfo.uid,
      status: optionData.status
    };
    this._loadingService.register();
    this.http.post(`${environment.cloudEndPoint}manageUser`, data).subscribe(
      async (res) => {
        this._loadingService.resolve();
        if (res['status'] === CommonConfig.statusCode.success) {
          userInfo['isActive'] = !status;
          this.customService.showSnackBarSuccess(optionData.successMessage);
          /*Send email to user for vendor accept and reject status*/
          const dataForEmail = {
            toEmail: userInfo['email'],
            userName: userInfo['displayName'] || userInfo['email'],
            subject: optionData.subject,
            templateId: optionData.templateId,
            belongTo: CommonConfig.belongTo.system
          };
          // stoping system email if not subscribed by user
          const commonUtil = new CommonUtils(this.fs);
          await commonUtil.isEmailAllow(userInfo['email'], CommonConfig.belongTo.system) && this.sendEmail(dataForEmail);
        } else {
          this.customService.showSnackBarError(CommonConfig.validationMessage.someError);
        }
      },
      error => {
        this._loadingService.resolve();
        this.customService.showSnackBarError(CommonConfig.validationMessage.someError);
      } // error path
    );
  }

  private oAuthLogin(provider, isPremiumSignup = false, premiumProjectId = '', premiumVendorId = '') {
    return this.afAuth.auth.signInWithPopup(provider)
      .then(async (credential) => {
        await this.updateUserData(credential.user, isPremiumSignup, premiumProjectId, premiumVendorId);
        return credential;
      });
  }
  sendEmail(dataForEmail: CommonEmail) {
    const unSubscriber = this.http.post(`${environment.cloudEndPoint}emailSend`, dataForEmail).subscribe(res => { unSubscriber.unsubscribe(); });
  }
  private async updateUserData(user: object, isPremiumSignup = false, premiumProjectId = '', premiumVendorId = '', isLogout?) {
    const userResults = await this.fs.getCollection(CommonConfig.collectionName.users, user['uid']).get();
    // Sets user data to firestore on login
    const userRef = this.afs.doc(`users/${user['uid']}`);
    let category = [];
    let profilePhoto = { url: (user['photoURL']) ? user['photoURL'] : '' };
    let isAdmin = false;
    let isSoftDelete = false;
    let isHardDelete = false;
    let fullName;

    if (userResults.exists) {
      const userData = userResults.data();
      isAdmin = userData.isAdmin;
      fullName = userData.displayName;
      category = (userData.category && userData.category.length) ? userData.category : [];
      profilePhoto = (userData.profilePhoto && userData.profilePhoto.url) ? userData.profilePhoto : profilePhoto;
      isPremiumSignup = userData.isPremiumSignup ? userData.isPremiumSignup : false;
      premiumProjectId = userData.premiumProjectId ? userData.premiumProjectId : '';
      premiumProjectId = userData.premiumVendorId ? userData.premiumVendorId : '';
      isSoftDelete = !!userData.isSoftDelete;
      isHardDelete = !!userData.isHardDelete;
    }

    const lastSignInTime: Object = this.timestampService.dateInTimestamp(user['metadata'].lastSignInTime);
    const createdAt: Object = this.timestampService.dateInTimestamp(user['metadata'].creationTime);
    const data: User = {
      uid: user['uid'],
      email: user['email'],
      displayName: fullName ? fullName : user['email'],
      profilePhoto,
      createdAt,
      lastSignInTime,
      isActive: true,
      isAdmin,
      category,
      isPremiumSignup,
      premiumProjectId,
      premiumVendorId,
      isSoftDelete,
      isHardDelete
    };
    this.logoutUser(isLogout, userRef, data);
  }

  async updateFirstPremiumUserData(user: object) {
    // Sets user data to firestore on login
    const userRef = this.afs.doc(`users/${user['uid']}`);
    const lastSignInTime: Object = this.timestampService.dateInTimestamp(user['metadata'].lastSignInTime);
    const createdAt: Object = this.timestampService.dateInTimestamp(user['metadata'].creationTime);
    const data: User = {
      uid: user['uid'],
      email: user['email'],
      displayName: user['email'],
      createdAt,
      lastSignInTime,
      isActive: true,
      isAdmin: false,
      category: [],
      isPremiumSignup: true,
      isSoftDelete: false,
      isHardDelete: false
    };
    return userRef.set(data, { merge: true });
  }

  /**
   * @description function to check user is logout or not
   * @param {boolean} isLogout contain a boolean value
   * @param {object} userRef contain a object values
   * @param {object} data contain a object values
   */
  logoutUser(isLogout, userRef, data) {
    if (isLogout) {
      return userRef.set(data, { merge: true }).then(res => {
        this.signOut();
      });
    } else {
      return userRef.set(data, { merge: true });
    }
  }

  setDefaultKeyForUser(user: User) {
    return new Promise((resolve) => {
      if (user && user['uid']) {
        this.fs.getCollection(CommonConfig.collectionName.users, user['uid']).get().then((res) => {
          if (res.exists) {
            this.setNotificationDataInUser(res, user);
          }
          resolve(true);
        }).catch((err) => {
          if (err.code) {
            this.customService.showSnackBarError(err.message);
          }
          resolve(true);
        });
      }
    });
  }

  /**
   * @description Function to set notification data in user
   * @param {object} res contain a object values
   * @param {object} user contain a object values
   */
  setNotificationDataInUser(res, user) {
    const data = {};
    if (!res.data().isVendor) {
      data['isVendor'] = false;
    }
    if (data && (Object.keys(data).length > 0)) {
      if (!data.hasOwnProperty('appNotification') || !data.hasOwnProperty('emailNotification')) {
        data['appNotification'] = APP_NOTIFICATION;
        data['emailNotification'] = EMAIL_NOTIFICATIONS;
      }
      this.afs.doc(`users/${user['uid']}`).set(data, { merge: true });
    }
  }

  signOut() {
    this.storage.removeSearchLocation();
    return this.afAuth.auth.signOut();
  }

  emailLogin(email: string, password: string, isFirstPremiumLogin = false) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password).then(async (credential) => {
      if (credential['user'] && credential['user'].emailVerified) {
        if (credential['user']) {
          if (isFirstPremiumLogin) {
            await this.updateFirstPremiumUserData(credential['user']);
          } else {
            await this.updateUserData(credential['user']);
          }
          return credential;
        }
      } else {
        return credential;
      }
    });
  }

  resetPassword(email: string) {
    const fbAuth = firebase.auth();
    return fbAuth.sendPasswordResetEmail(email);
  }

  resetNewPassword(code: string, password: string) {
    return firebase.auth().confirmPasswordReset(code, password);
  }

  verifyPasswordResetCode(code: string) {
    return firebase.auth().verifyPasswordResetCode(code);
  }

  emailSignUp(email: string, password: string) {
    return this.afAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then(credential => {
        const user = firebase.auth().currentUser;
        user.sendEmailVerification();
        this.updateUserData(user, false, '', '', true);
        this._taskService.performIn(this.minutes(CommonConfig.two), 'sendWelcomeEmail', { userId: user.uid, email: user.email });
        return user;
      });
  }
  minutes(v: number) {
    return v * CommonConfig.sixty * CommonConfig.thousand;
  }

  handleVerifyEmail(code: string) {
    return firebase.auth().applyActionCode(code);
  }

  checkOldPassword(oldPassword: string) {
    return firebase.auth().currentUser.reauthenticateAndRetrieveDataWithCredential(firebase.auth.EmailAuthProvider.credential(
      firebase.auth().currentUser.email, oldPassword));
  }

  changePassword(newPassword: string) {
    return firebase.auth().currentUser.updatePassword(newPassword);
  }

  /**
   * @description Function for check user is admin or not
   * @param {User} userInfo contain a user data
   */
  checkIsUserAdmin = (userInfo?: Partial<User>) => userInfo && userInfo['isAdmin'];

  isValidUserId(uid: string) {
    this.fs.getCollection(CommonConfig.collectionName.users, uid).get().then((user) => {
      if (user.exists) {
        return true;
      } else {
        this.router.navigate(['/dashboard']);
      }
    });
  }

  getUserInformation(uid: string) {
    return new Promise((resovle, reject) => {
      if (uid) {
        this.fs.getCollection(CommonConfig.collectionName.users, uid).get().then(function (user) {
          if (user.exists) {
            resovle(user.data());
          } else {
            resovle('');
          }
        });
      } else {
        resovle('');
      }
    });
  }
  getAllAdmins(): Promise<Object[]> {
    return new Promise((resovle, reject) => {
      this.fs.colWithIds$(CommonConfig.collectionName.users, (ref) => ref.where('isActive', '==', true)
        .where('isAdmin', '==', true)).subscribe(allAdmins => {
          resovle(allAdmins);
        });
    });
  }

  checkAuthState(uid: string) {
    const authSubscribe = this.fs.colWithIds$(CommonConfig.collectionName.users, (ref) =>
      ref.where('uid', '==', uid).where('isActive', '==', true)).subscribe(data => {
        authSubscribe.unsubscribe();
        if (!data.length) {
          this.signOut();
          this.user = observableOf(null).pipe(share());
          this.customService.showSnackBarError(CommonConfig.validationMessage.userBlocked);
          this.router.navigate(['/login']);
        }
      });
  }

  getUserByEmail(email: string) {
    return new Promise((resolve, reject) => {
      this.fs.colWithIds$(CommonConfig.collectionName.users, (ref) =>
        ref.where('email', '==', email)).subscribe(data => {
          resolve(data);
        });
    });
  }

  /**
   * @description Function for get user information
   * @param {string} userId contain user id
   */
  async getSenderInfo(userId: string) {
    return new Promise((resolve) => {
      if (!userId) {
        resolve({});
      }
      const userSubscribe = this.fs.doc$(`${CommonConfig.collectionName.users}/${userId}`).subscribe((userData) => {
        if (userData) {
          const requireUserData = {
            displayName: userData['displayName'],
            uid: userData['uid'],
            profilePhoto: userData['profilePhoto']
          };
          resolve(requireUserData);
        } else {
          resolve({});
        }
        userSubscribe.unsubscribe();
      });
    });
  }

  /**
   * @description Function to check user profile is complete or not
   * @param {object} user contain a object value of user
   */
  isProfileCompleted(user: Partial<User>) {
    return user.subscription && user.subscription.customer_id && user.category && user.category.length > CommonConfig.zero;
  }

  isPremiumSignup(user: Partial<User>) {
    return user.isPremiumSignup && user.premiumProjectId;
  }

  /**
   * @description Function for update user information
   * @param {string} uid contain user id
   * @param {number} userData contain user data object
   */
  updateUserInformaion(uid: string, userData: object) {
    this.fs.update(`${CommonConfig.collectionName.users}/${uid}`, userData);
  }

  /**
   * @description Function used for get project and vendor segement status
   * @param {object} allCategories contain all system categories
   * @param {object} userCategories contain user selected categories
   */
  getUserSegmentStatus(allCategories: Array<Category>, userCategories: Array<SelectCategory>) {
    const segementStatus = { isProjectSegment: false, isVendorSegment: false };
    userCategories.forEach((x) => {
      const categoryData = allCategories.filter(y => y.$key === x.key);
      if (categoryData && categoryData.length && categoryData[0].segment === this.commonConfig.segment.vendor) {
        segementStatus.isVendorSegment = true;
      }
      if (categoryData && categoryData.length && categoryData[0].segment === this.commonConfig.segment.projectCreator) {
        segementStatus.isProjectSegment = true;
      }
    });
    return segementStatus;
  }

  /**
   * @description function used for get current loged in user information
   */
  currentUser() {
    return new Promise((resolve) => {
      const authSubscribe = this.user.subscribe((user) => {
        resolve(user);
        authSubscribe.unsubscribe();
      });
    });
  }

  /**
   * @description Function used for add category in user profile data
   * @param categoryData contain selected category data
   * @param userData contain auth user data
   */
  async addUserCategory(categoryData: Array<SelectCategory>, userData: User) {
    if (userData && userData['uid']) {
      const userCategory = userData.category;
      for (const cat of categoryData) {
        if (!this.isCategoryExists(userCategory, cat.key)) {
          userCategory.push(cat);
        }
      }
      const allCategories = await this.getAllActiveCategory();
      const segementStatus = this.getUserSegmentStatus(allCategories, userCategory);
      const dataObject = {
        isProjectSegment: segementStatus.isProjectSegment, isVendorSegment: segementStatus.isVendorSegment,
        category: userCategory
      };
      this.updateUserInformaion(userData['uid'], dataObject);
    }
  }

  /**
   * @description Function used for check category exists or not
   * @param userCategory contain category data object
   * @param key contain category id
   */
  isCategoryExists(userCategory, key: string) {
    return userCategory.some(function (cd) {
      return cd.key === key;
    });
  }

  /**
   * @description Function used for get all active category data
   */
  getAllActiveCategory() {
    return new Promise<Array<Category>>((resolve) => {
      const categoryRef = this.afs.collection('categories', ref => ref.where('status', '==', true).orderBy('name', 'asc')).snapshotChanges().pipe(map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Category;
          data.$key = a.payload.doc.id;
          return data;
        });
      })).subscribe(res => {
        categoryRef.unsubscribe();
        resolve(res);
      });
    });
  }

  premiumSignup(requestData) {
    return this.httpService.httpPost(`${environment.cloudEndPoint}premiumSignup`, requestData).toPromise();
  }

  /**
   * @description function used for get time zone list
   */
  async getTimeZoneList() {
    const zoneList = await this.httpService.httpGet(`${environment.cloudEndPoint}/getTimeZones`).toPromise();
    return zoneList['timeZoneList'] || [];
  }

}
