import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CustomValidator } from '@shared/validator/custom.validator';
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { FilestackService } from '@shared/service/filestack/filestack.service';
import { CustomService } from '@shared/service/custom/custom.service';
import { CommonConfig } from '@shared/common.config';

@Component({
  selector: 'app-preview-doc',
  templateUrl: './preview-doc.component.html'
})
export class PreviewDocComponent implements OnInit {
  public commonConfig = CommonConfig;
  dialogRef;
  public doc;
  public documentForm: FormGroup;
  public isSubmit = false;
  public isView = false;
  public documentData = {title: '', description: ''};
  constructor(private readonly filestackService: FilestackService,
    private readonly fs: FirestoreService,
    private readonly formBuilder: FormBuilder,
    private readonly customService: CustomService) { }

  ngOnInit() {
    this.buildFrom();
    this.filestackService.filePreview(this.doc.handle);
  }

  buildFrom() {
    this.documentForm = this.formBuilder.group({
      title: ['', CustomValidator.required],
      description: ['']
    });
    this.patchFormValue();
  }

  patchFormValue() {
    this.documentData.title = this.doc.title || this.doc.filename;
    this.documentData.description = this.doc.description || '';
    this.documentForm.patchValue(this.documentData);
    this.documentForm.markAsPristine();
  }

  close() {
    this.dialogRef.close();
  }

  /**
   * @description function used for update document information
   */
  updateDocument() {
    this.isSubmit = true;
    const id = this.doc.id;
    this.fs.update(`${this.commonConfig.collectionName.document}/${id}`, this.documentForm.value).then(res => {
      this.isSubmit = false;
      this.customService.showSnackBarSuccess(this.commonConfig.successMessage.documentUpdate);
    }).catch(err => {
      this.customService.showSnackBarError(this.commonConfig.validationMessage.someError);
    });
  }

}
