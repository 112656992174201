import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TdMediaService } from '@covalent/core/media';
import { GoogleMapsAPIWrapper } from '@agm/core';
// Config
import { CommonConfig } from '@shared/common.config';
import { MapStyles } from '@shared/common-json/map-config';
// Component
import { ClaimVendorComponent } from '@shared/component/vendors/claim-vendor/claim-vendor.component';
// Model
import { Vendor } from '@app/core/model/vendor';

@Component({
  selector: 'app-vendor-map',
  templateUrl: './vendor-map.component.html',
  styleUrls: ['../../../assets/scss/map-view.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VendorMapComponent implements OnInit, AfterViewInit {
  zoom = 12;
  @Input() lat: number;
  @Input() lng: number;
  isSidebar = true;
  'vendorAdd.mapIcon' = null;
  @Input() vendors;
  @Output() scrollEmitter: EventEmitter<boolean> = new EventEmitter();
  @Input() loadingFinished = true;
  @Input() currentPage: string;
  @Input() isVendorProfile: Object;
  hide = false;
  @Input() uid: string;
  starClass = 'map-star';
  styles: Array<object> = MapStyles;
  commonConfig = CommonConfig;

  constructor(
    private readonly mapsWrapper: GoogleMapsAPIWrapper,
    public media: TdMediaService,
    public dialog: MatDialog
    ) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.mapsWrapper.getNativeMap().then((map) => {
    });
  }

  vendorMouseOver(vendorAdd: Vendor, vendor: Vendor) {
    vendorAdd.mapIcon = CommonConfig.mapIcons.vendor.large;
    if (vendor['id'] && vendorAdd['lat'] && vendorAdd['long']) {
      this.getMapListElement(vendor['id'], vendorAdd['lat'], vendorAdd['long']).classList.add('map-list-hover');
    }
  }

  vendorMouseOut(vendorAdd: Vendor, vendor: Vendor) {
    vendorAdd.mapIcon = CommonConfig.mapIcons.vendor.small;
    if (vendor['id'] && vendorAdd['lat'] && vendorAdd['long']) {
      this.getMapListElement(vendor['id'], vendorAdd['lat'], vendorAdd['long']).classList.remove('map-list-hover');
    }
  }

  getMapListElement(vendorId: string, lat: string, long: string) {
    return document.getElementById(`id_lat_long_${vendorId}_${lat}_${long}`);
  }

  onScroll() {
    this.scrollEmitter.emit(true);
  }
  trackByFn(index: number) {
    return index;
  }
  claimVendorProfile($event: Event, vendor: Vendor, isVendorProfile: Object) {
    $event.stopPropagation();
    const dialogRef = this.dialog.open(ClaimVendorComponent, {
      width: '600px'
    });
    const instance = dialogRef.componentInstance;
    instance.dialogRef = dialogRef;
    instance.vendor = vendor;
    instance.isVendorProfile = isVendorProfile;
  }
}
