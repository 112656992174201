import { Component, OnInit, Input } from '@angular/core';
import { CreateProjectService } from '@app/shared/service/projects/create-project.service';
import { ProjectBid } from '@app/core/model/project-bid';
import { MaterialLineItems } from '@app/core/model/material-line-items';
import { CommonConfig } from '@shared/common.config';

@Component({
  selector: 'app-material-bids',
  templateUrl: './material-bids.component.html',
  styleUrls: ['./material-bids.component.scss']
})
export class MaterialBidsComponent implements OnInit {
  @Input() bidData: ProjectBid;
  public materialLineItemBidList: MaterialLineItems[] = [];
  commonConfig = CommonConfig;
  public isFetched = false;
  constructor(private readonly createProjectService: CreateProjectService) { }

  ngOnInit() {
    this.getMaterialLineItems();
  }

  async getMaterialLineItems() {
    this.materialLineItemBidList = await this.createProjectService.getMaterialLineItemData(this.bidData.projectId);
    this.isFetched = true;
    const materialLineItems = this.bidData['materialLineItems'] || [];
    this.materialLineItemBidList = this.materialLineItemBidList.map(e => {
      const bidDetail = this.filteredLineItemByItemId(materialLineItems, e.id);
      if (bidDetail) {
        e['bidBudget'] = bidDetail.budget || this.commonConfig.zero;
        e['bidNote'] = bidDetail.note || '';
      }
      return e;
    });
  }

  filteredLineItemByItemId(materialLineItemsBids, itemId) {
    return materialLineItemsBids.filter(item => item.itemId === itemId)[0];
  }

}
