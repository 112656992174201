import { Component, OnInit, OnDestroy, Input } from '@angular/core';
// Config
import { CommonConfig } from '@shared/common.config';
// Service
import { CustomService } from '@shared/service/custom/custom.service';

@Component({
  selector: 'app-map-toggle-list',
  templateUrl: './map-toggle-list.component.html'
})
export class MapToggleListComponent implements OnInit, OnDestroy {
  @Input() thisObj;
  @Input() hide;
  @Input() sidebarPorjects;
  commonConfig = CommonConfig;
  constructor(
    public customService: CustomService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

}
