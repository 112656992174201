import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TdDialogService } from '@covalent/core/dialogs';
// Config
import { CommonConfig } from '@shared/common.config';
// Validator
import { CustomValidator } from '@shared/validator/custom.validator';
// Service
import { FilestackService } from '@shared/service/filestack/filestack.service';

@Component({
  selector: 'app-product-services-form',
  templateUrl: './product-services-form.component.html'
})
export class ProductServicesFormComponent implements OnInit, OnDestroy {
  @Input() uid;
  @Input() vendorId;
  @Input() product;
  @Input() isSubmit;
  @Output() productServiceFrmSubmitEvent = new EventEmitter();
  commonConfig = CommonConfig;
  productServiceForm: FormGroup;
  submitValue = 'ADD';
  fileAccept: string[];

  constructor(
    private readonly _dialogService: TdDialogService,
    private readonly formBuilder: FormBuilder,
    private readonly filestackService: FilestackService,
  ) { }

  ngOnInit() {
    this.buildFrom();
  }

  ngOnDestroy() {
  }

  buildFrom() {
    this.productServiceForm = this.formBuilder.group({
      title: ['', CustomValidator.required],
      vendorId: ['', Validators.required],
      uid: ['', Validators.required],
      website: ['', [
        CustomValidator.validUrl
      ]],
      description: ['', CustomValidator.required],
      photo: [''],
      status: [this.commonConfig.documentStatus.active]
    });
    this.productServiceForm.get('vendorId').setValue(this.vendorId);
    this.productServiceForm.get('uid').setValue(this.uid);
    if (this.product && this.product['id']) {
      this.submitValue = 'UPDATE';
      this.productServiceForm.patchValue(this.product);
      this.productServiceForm.markAsPristine();
    }
  }

  get getTitle() {
    return this.productServiceForm.get('title');
  }
  get getDescription() {
    return this.productServiceForm.get('description');
  }
  get getWebsite() {
    return this.productServiceForm.get('website');
  }

  get getPhoto() {
    return this.productServiceForm.get('photo').value;
  }

  browseImage() {
    this.fileAccept = this.commonConfig.acceptPhoto;
    this.filestackService.openPicker(this);
  }

  filestackCallback(response) {
    if (response.filesUploaded && response.filesUploaded.length > 0) {
      this.productServiceForm.get('photo').setValue(response.filesUploaded[0]);
    }
  }

  removePhoto($event: Event) {
    this._dialogService.openConfirm({
      message: this.commonConfig.popupAlertMessage.deletePhoto,
      acceptButton: this.commonConfig.dialogService.accept,
      cancelButton: this.commonConfig.dialogService.cancel
    })
      .afterClosed().subscribe((accept: boolean) => {
        if (accept) {
          this.productServiceForm.get('photo').setValue('');
        }
      });
  }

  submit() {
    this.productServiceFrmSubmitEvent.emit(this.productServiceForm.value);
  }

}
