
import {take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';
import { CustomService } from '@app/shared/service/custom/custom.service';
import { AuthService } from '@app/core/auth.service';
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { CommonConfig } from '@app/shared/common.config';
import { NotificationPayload } from '@app/core/model/notification-payload';

import { BehaviorSubject } from 'rxjs';

@Injectable()
export class MessagingService {
  commonConfig = CommonConfig;
  currentMessage = new BehaviorSubject(null);
  pageSize = this.commonConfig.pageSize;
  notifications = [];
  messaging;
  constructor(
    private readonly db: AngularFireDatabase,
    private readonly afAuth: AngularFireAuth,
    public firestoreService: FirestoreService,
    private readonly customService: CustomService,
    private readonly auth: AuthService
  ) {
    try {
      this.messaging =  this.firestoreService.firebaseObj().messaging();
    } catch (error) {
    }
  }


  updateToken(token: string) {
    const tokenSubscription = this.afAuth.authState.pipe(take(1)).subscribe(user => {
      if (!user) { return true; }
      const data = { [user.uid]: token };
      this.db.object('fcmTokens/').update(data);
      this.firestoreService.update(`${this.commonConfig.collectionName.users}/${user.uid}`, { token }).then();
      tokenSubscription.unsubscribe();
    });
  }

  getPermission() {
    this.messaging.requestPermission()
      .then(() => {
        return this.messaging.getToken();
      })
      .then((token: string) => {
        this.updateToken(token);
      })
      .catch((err) => {
      });
  }

  receiveMessage() {
    this.messaging.onMessage((payload) => {
      this.currentMessage.next(payload);
    });

  }

  /**
   * @description sending the notification to user on bases of allowed or not for payload cagtegory
   * @param {Object} notificationData contain the notification payload
   */
  saveNotificationPayload(notificationData: NotificationPayload) {
    const saveNotificationSubscribe = this.firestoreService.colWithIds$(this.commonConfig.collectionName.users,
      (ref) => ref.where('uid', '==', notificationData.userId).limit(1))
      .subscribe(res => {
        if ((res.length && res[0]['appNotification']) && res[0].appNotification.includes(notificationData.category)) {
          this.firestoreService.add(this.commonConfig.collectionName.notification, notificationData)
            .then();
        }
        saveNotificationSubscribe.unsubscribe();
      });
  }

  getAllNotification(that, userId: string): Promise<Object[]> {
    const forAdmin = that.isAdmin || false;
    return new Promise((resolve) => {
      const notificationSubscriber = this.firestoreService.colWithIds$(
        this.commonConfig.collectionName.notification,
        (ref) => ref
          .limit(this.pageSize)
          .orderBy('createdAt', 'desc')
          .startAfter(that.lastKey)
          .where('userId', '==', userId)
          .where('forAdmin', '==', forAdmin)
      ).subscribe((notifications) => {
        if (notifications.length) {
          that.lastKey = notifications[notifications.length - 1].doc;
          this.getNotificationRelationData(that, resolve, notifications);
          notificationSubscriber.unsubscribe();
        } else {
          that.openNotificationBar = true;
          resolve(notifications);
        }
      });
    });
  }

  /**
   * @description Function used get notification relation data
   * @param that contain the parent object
   * @param resolve containg the promises resolve
   * @param notifications contain the notification data
   */
  getNotificationRelationData(that, resolve, notifications) {
    const promises = [];
    notifications.forEach(async (notificationData) => {
      Object.assign(notificationData, { notificationId: notificationData.doc.id });
      try {
        promises.push(
          this.auth.getSenderInfo(notificationData['sendBy']).then((senderInfo) => {
            Object.assign(notificationData, { userInfo: senderInfo });
          }),
          this.getItemInfo(that, notificationData).then((itemInfo) => {
            Object.assign(notificationData, { itemInfo });
          })
        );
      } catch (error) { }
    });
    Promise.all(promises).then(() => {
      that.openNotificationBar = true;
      for (let i = 0; i < notifications.length; i++) {
        if (notifications[i].hasOwnProperty('isSoftDelete') && notifications[i]['isSoftDelete']) {
          notifications.splice(i, 1);
        }
      }
      resolve(notifications);
    });
  }

  setCategoryData(categoryType, itemResult) {
    const requireItemData = { title: '', ownerId: '', itemImageUrl: '' };
    requireItemData['ownerId'] = itemResult['uid'] || '';
    if (categoryType === this.commonConfig.notificationActivityType.projectActivity) {
      requireItemData['title'] = itemResult['title'] || 'Unknown';
      requireItemData['imageUrl'] = itemResult['imageUrl'] || this.commonConfig.defaultProjectImage;
    } else {
      requireItemData['title'] = itemResult['name'] || 'Unknown';
      requireItemData['imageUrl'] = itemResult['logoImage'] && itemResult['logoImage']['url'] || this.commonConfig.defaultVendorImage;
    }
    return requireItemData;
  }

  async getItemInfo(that, itemData: NotificationPayload) {
    const collectionName = this.getDataCollectionName(itemData);
    return new Promise((resolve) => {
      try {
        const itemSubscribe = this.firestoreService.doc$(`${collectionName}/${itemData['id']}`).subscribe((itemResult) => {
          let requireItemData = {};
          if (itemResult) {
            requireItemData = this.setCategoryData(itemData['category'], itemResult);
          }
          resolve(requireItemData);
          itemSubscribe.unsubscribe();
        });
      } catch (error) {
        resolve({});
      }
    });
  }

  /**
   * @description function used for get collection name
   */
  getDataCollectionName(itemData) {
    let collectionName = this.commonConfig.collectionName.vendors;
    if (itemData['category'] === this.commonConfig.notificationActivityType.projectActivity) {
      collectionName = this.commonConfig.collectionName.project;
    } else if (itemData['category'] === this.commonConfig.notificationActivityType.systemActivity) {
      collectionName = this.commonConfig.collectionName.organizations;
    }
    return collectionName;
  }

  setReadFlagOfNotification(notificationId: string) {
    this.firestoreService.setmerge(`${this.commonConfig.collectionName.notification}/${notificationId}`, { readFlag: true });
  }

  resetUnreadMessageCount(isAdmin: boolean, userId: string) {
    let cond;
    if (isAdmin) {
      cond = { unreadNotificationAdmin: 0 };
    } else {
      cond = { unreadNotification: 0 };
    }
    this.firestoreService.setmerge(`${this.commonConfig.collectionName.users}/${userId}`, cond);
  }

  removeNotification(notificationId: string) {
    this.firestoreService.delete(`${this.commonConfig.collectionName.notification}/${notificationId}`).catch((err) => {
      this.customService.showSnackBarError(this.commonConfig.validationMessage.someError);
    });
  }
}
