import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthService } from '@app/core/auth.service';
import { FirestoreService } from '@app/shared/service/firestore/firestore.service';
import { VendorsService } from '@app/shared/service/vendors/vendors.service';
import { InstagramService } from '@app/shared/service/instagram/instagram.service';
import { CommonConfig } from '@app/shared/common.config';
import { Vendor } from '@app/core/model/vendor';
import { trackEvent } from '@app/shared/common.constants';
import { GanalyticsService } from '@app/shared/service/ganalytics/ganalytics.service';

@Component({
  selector: 'app-connect-instagram',
  templateUrl: './connect-instagram.component.html',
  styleUrls: ['./connect-instagram.component.scss', '../../../../assets/scss/create-project.scss']
})
export class ConnectInstagramComponent implements OnInit, OnDestroy {
  private authSubscription: Subscription;
  private instafeedSubscription: Subscription;
  instagramToken: string;
  userId: number;
  instagramUrl: string;
  instagramMedias = [];
  nextMaxId: string;
  totalFeeds = 0;
  loading = true;
  feedLoading = true;
  @Output() childEvent = new EventEmitter();
  // for show instagram feed on vendor profile
  @Input() vendorUserId: string;
  @Input() authUserId: string;
  @Input() currentPage: string;
  @Input() vendorId: string;
  constructor(public auth: AuthService, private vendorsService: VendorsService, private instagramService: InstagramService,
    public fs: FirestoreService, private ganalyticsService: GanalyticsService) {
  }

  ngOnInit() {
    this.instagramUrl = `${environment.instagram.auth_url}client_id=${environment.instagram.client_id}&redirect_uri=${window.location.origin}
    /redirect-instagram?vid=${this.vendorId}&response_type=${environment.instagram.response_type}`;
    this.loading = true;
    if (this.vendorId) {
      const vendorSubscriber = this.fs.doc$(`${CommonConfig.collectionName.vendors}/${this.vendorId}`)
        .subscribe((vendorInfo: Partial<Vendor>) => {
          if (vendorInfo['instagramData'] && vendorInfo['instagramData'].user_id) {
            this.instagramToken = vendorInfo['instagramData'].access_token;
            this.userId = vendorInfo['instagramData'].user_id;
            this.instagramMedias = [];
            this.totalFeeds = 0;
            this.fetchInstagramData(false);
            vendorSubscriber.unsubscribe();
          } else {
            this.instagramToken = '';
          }
          this.loading = false;
        });
    } else {
      this.instagramToken = '';
    }
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.instafeedSubscription) {
      this.instafeedSubscription.unsubscribe();
    }
    this.vendorsService.instagramStatusUpdate(false);
    this.vendorsService.updateInstagramFeeds(0);
  }

  fetchInstagramData(isLoad: boolean, maxId?: string) {
    if (!maxId) {
      maxId = '';
    }
    this.instafeedSubscription = this.instagramService.fetchSelfMedia(this.instagramToken, maxId).subscribe(
      (result) => {
        if (result['meta']['code'] === CommonConfig.statusCode.badRequest) {
          this.instagramToken = '';
          this.userId = 0;
        } else {
          if (result['pagination']['next_max_id']) {
            this.nextMaxId = result['pagination']['next_max_id'];
          }
          this.totalFeeds = Number(this.totalFeeds) + Number(result['data'].length);
          this.instagramMedias.push(result['data']);
          this.vendorsService.instagramStatusUpdate(true);
        }
        this.feedLoading = false;
        this.vendorsService.updateInstagramFeeds(this.totalFeeds);
        this.instafeedSubscription.unsubscribe();
        this.ganalyticsService.analyticsTrackEvent(trackEvent.connectVendorToInstagram);
      },
      error => {
        this.feedLoading = false;
        this.ganalyticsService.analyticsTrackEvent(trackEvent.connectVendorToInstagramError);
      }
    );
  }

  loadMoreFeed(maxId: string) {
    this.fetchInstagramData(true, maxId);
  }

  removeInstagramConnect() {
    this.childEvent.emit();
    this.ganalyticsService.analyticsTrackEvent(trackEvent.removeConnectVendorToInstagram);
  }

  isCreateVendorPage() {
    return this.currentPage === CommonConfig.routerName.createvendor;
  }

  connectInstagram() {
    window.open(this.instagramUrl, '_blank');
  }

}
