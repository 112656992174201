import { Component, OnInit, Input } from '@angular/core';
import { Router, Event, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BREADCRUMB_KEY_VALUE } from './breadcrumb';
import { CommonConfig } from '@app/shared/common.config';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() inputStr;
  public BREADCRUMB_KEY_VALUE = BREADCRUMB_KEY_VALUE;
  public breadcrumArray = [];
  public currentPage;
  private url: string;
  public projectTitle = '';
  private queryParams = {};
  private readonly vendorProfile = 'vendor-profile';
  private readonly projectDetail = 'project-detail';
  private readonly manageProject = 'manage-projects';
  constructor(
    private readonly router: Router,
    private readonly activatedRoutes: ActivatedRoute
  ) {
    this.url = this.router.url;
    this.routerEventsSubscribe();
    this.activatedRoutesQueryParams();
    this.activatedRoutesParamsSubscribe();
    !this.inputStr && (this.inputStr = '');
  }

  activatedRoutesParamsSubscribe () {
    // update the routes on changes of routes
    this.activatedRoutes.params.subscribe(param => {
      if (this.isVendorFeatureProducts() || this.isVendorProfileServices() || this.isVendorFeatureServices()) {
        this.assignURL(param['id']);
      } else if (this.isManageVendors()) {
        this.BREADCRUMB_KEY_VALUE['vendors']['url'] = ['/admin/manage-vendors'];
      } else if (this.isManageProject()) {
        this.BREADCRUMB_KEY_VALUE['project_detail']['url'] = ['/admin/project-detail/', param.id, param.uid];
        this.BREADCRUMB_KEY_VALUE['project_detail']['queryParams'] = { from: this.manageProject };
      } else {
        this.setProjectAndVendorDetialUrl(param);
      }
    });
  }

  isVendorFeatureProducts = () => this.url.search('vendors') !== -1 && this.url.search('vendor-feature') !== -1 && this.url.search('products') !== -1;

  isVendorProfileServices = () => this.url.search('vendors') !== -1 && this.url.search(this.vendorProfile) !== -1 && this.url.search('products') !== -1;

  isVendorFeatureServices = () => this.url.search('vendors') !== -1 && this.url.search('vendor-feature') !== -1 && this.url.search('services') !== -1;

  isManageVendors = () => this.url.search('admin') !== -1 && this.url.search('vendors') !== -1 && this.url.search(this.vendorProfile) !== -1;

  isManageProject = () => this.url.search('admin') !== -1 && this.url.search(this.projectDetail) !== -1 && this.activatedRoutes.snapshot.queryParams['from'] === this.manageProject;

  isEditProject = (param) => this.url.search('admin') !== -1 && this.url.search(this.projectDetail) !== -1 && this.url.search('edit-project') !== -1 && param.customId && param.uid;

  isManageBids = (param) => (this.url.search('admin') !== -1 && this.url.search('manage-bids') !== -1 &&
  this.url.search(this.projectDetail) !== -1 && this.url.search(this.vendorProfile)
  && param.projectid && param.uid)

  routerEventsSubscribe() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
        this.manageBreadcrumb();
      }
    });
  }

  activatedRoutesQueryParams() {
    this.activatedRoutes.queryParams.subscribe(query => {
      this.queryParams = query;
    });
  }

  /**
   * @description Function used for set vendor profile url and project detail url
   * @param param contain the query parameter data
   */
  setProjectAndVendorDetialUrl(param) {
    if (this.isEditProject(param)) {
      this.BREADCRUMB_KEY_VALUE['project_detail']['url'] = ['/admin/project-detail/', param.customId, param.uid];
      this.BREADCRUMB_KEY_VALUE['project_detail']['queryParams'] = { from: this.manageProject };
    } else if (this.isManageBids(param)) {
      this.BREADCRUMB_KEY_VALUE['project_detail']['url'] = ['/admin/manage-bids/project-detail/', param.projectid, param.uid];
      this.BREADCRUMB_KEY_VALUE['project_detail']['queryParams'] = { from: 'manage-bids' };
    } else if (this.isVendorProfile()) {
      if (Object.keys(this.queryParams).length && Object.keys(this.queryParams).includes('docId')) {
        this.assignURL(this.queryParams['docId']);
      } else {
        this.assignURL(param['id']);
      }
      this.BREADCRUMB_KEY_VALUE['services']['url'] = ['/vendors/vendor-profile/', param['id']];
    }
  }

  isVendorProfile = () => this.url.search('vendors') !== -1 && this.url.search(this.vendorProfile) !== -1;

  ngOnInit(): void {
    this.manageBreadcrumb();
  }

  /**
   * @description assign the default URL to breadcrumb urls
   * @param {string} vendorId contain the vendor id
   */

  assignURL(vendorId) {
    const vendorFeature = '/vendors/vendor-feature';
    this.BREADCRUMB_KEY_VALUE['products']['url'] = [vendorFeature, CommonConfig.vendorFeatures.products, vendorId, CommonConfig.vendorFeatureView.list];
    this.BREADCRUMB_KEY_VALUE['vendor_feature']['url'] = [vendorFeature, CommonConfig.vendorFeatures.products, vendorId, CommonConfig.vendorFeatureView.list];
    this.BREADCRUMB_KEY_VALUE['services']['url'] = [vendorFeature, CommonConfig.vendorFeatures.services, vendorId, CommonConfig.vendorFeatureView.list];
    this.BREADCRUMB_KEY_VALUE['vendor_profile']['url'] = ['/vendors/vendor-profile/', vendorId];
  }

  /**
   * @description making the breadcrumb url
   */
  manageBreadcrumb() {
    const splitArr = this.url.replace(/-/g, '_').split('/'),
      splitQueryStr = splitArr[splitArr.length - 1].split('?')[0];
    splitArr.pop();
    this.breadcrumArray = [...splitArr, splitQueryStr];
    this.breadcrumArray = this.breadcrumArray.filter(e => e.trim().length && this.BREADCRUMB_KEY_VALUE[e.trim()]);
    this.currentPage = this.breadcrumArray[this.breadcrumArray.length - 1];
    if (this.currentPage && this.currentPage.indexOf('?') !== -1) {
      const queryParams = this.currentPage.split('?');
      this.currentPage = queryParams[0];
    }
    this.setCurrentPageAndBreadCrum();
    this.breadcrumArray = this.breadcrumArray.filter(x => this.BREADCRUMB_KEY_VALUE[x]);
  }

  /**
   * @description function used for set current page and breadcrum array
   */
  setCurrentPageAndBreadCrum () {
    const indexOfCreateProject = this.breadcrumArray.indexOf('create_project');
    const indexOfEditVendor = this.breadcrumArray.indexOf('edit_vendor');
    if (indexOfCreateProject !== -1) {
      this.currentPage = this.breadcrumArray[indexOfCreateProject];
      this.breadcrumArray = this.breadcrumArray.splice(0, indexOfCreateProject);
    } else if (indexOfEditVendor !== -1) {
      this.currentPage = this.breadcrumArray[indexOfEditVendor];
      this.breadcrumArray = this.breadcrumArray.splice(0, indexOfEditVendor);
    } else if (this.breadcrumArray.length === 1) {
      this.currentPage = this.breadcrumArray[0];
      this.breadcrumArray.length = 0;
    } else {
      this.breadcrumArray = this.breadcrumArray.splice(0, this.breadcrumArray.length - 1);
    }
  }

  /**
   * @description open breadcrum router link
   */
  openBreadcrumLink(item) {
    if (this.queryParams['orgId']) {
      BREADCRUMB_KEY_VALUE[item]['queryParams']['orgId'] = this.queryParams['orgId'];
    }
    this.router.navigate(BREADCRUMB_KEY_VALUE[item]['url'], { queryParams: BREADCRUMB_KEY_VALUE[item]['queryParams'] });
  }

}
