import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from 'angularfire2/firestore';
import { Observable, combineLatest, Subject } from 'rxjs';
import { takeUntil, switchMap, map, debounceTime } from 'rxjs/operators';
import * as _moment from 'moment';
// Config
import { environment } from 'environments/environment';
import { CommonConfig } from '@shared/common.config';
import { CommonUtils } from '@shared/common.util';
import { headingText } from '@shared/common.constants';
// Component
import { PreviewDocComponent } from '@shared/component/preview-doc/preview-doc.component';
import { VideoModalComponent } from '@shared/component/video-modal/video-modal.component';
import { ImageModalComponent } from '@shared/component/image-modal/image-modal.component';
// Model
import { Project } from '@app/core/model/project';
import { ProjectMember } from '@app/core/model/project-member';
import { User } from '@app/core/model/user';
import { PublicationList } from '@app/core/model/publication.model';
import { MaterialLineItems } from '@app/core/model/material-line-items';
// Validator
import { CustomValidator } from '@shared/validator/custom.validator';
// Service
import { AuthService } from '@app/core/auth.service';
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { HttpService } from '@shared/service/http/http.service';
import { CustomService } from '@shared/service/custom/custom.service';
import { TimestampService } from '@shared/service/timestamp/timestamp.service';
import { ProjectsService } from '@shared/service/projects/projects.service';
import { CheckProjectService } from '@shared/service/projects/check-project.service';
import { ProjectFormService } from '@shared/service/projects/project-form.service';
import { ProjectFileService } from '@shared/service/projects/project-file.service';
import { ChooseProjectFeatureService } from '@shared/service/projects/choose-project-feature.service';
import { CategoryService } from '@shared/service/category/category.service';

const moment = (_moment)['default'] ? (_moment)['default'] : _moment;

@Injectable({
  providedIn: 'root'
})
export class CreateProjectService {
  private readonly commonConfig = CommonConfig;
  public addRelatedCategory = new Subject();
  addRelatedCategoryStatus = this.addRelatedCategory.asObservable();
  public removeRelatedCategory = new Subject();
  removeRelatedCategoryData = this.removeRelatedCategory.asObservable();

  constructor(
    public dialog: MatDialog,
    public auth: AuthService,
    private readonly fs: FirestoreService,
    private readonly afs: AngularFirestore,
    private readonly http: HttpClient,
    private readonly httpService: HttpService,
    private readonly customService: CustomService,
    private readonly timeStampService: TimestampService,
    private readonly projectsService: ProjectsService,
    private readonly checkProjectService: CheckProjectService,
    public projectFormService: ProjectFormService,
    public projectFileService: ProjectFileService,
    public chooseProjectFeatureService: ChooseProjectFeatureService,
    private readonly categoryService: CategoryService
  ) { }

  // Function used to retrun is quote type project or not
  isQuoteType(createProjectObj) {
    return createProjectObj.projectType === this.commonConfig.projectType.quote ||
      createProjectObj.projectBasicInfo.get('subProjectType').value === this.commonConfig.subProjectType.sealed.ice.key;
  }

  isMaterialBidType(projectType) {
    return projectType === this.commonConfig.projectType.materialBid;
  }

  // Function for show and hide estimated cost and expire at field
  isShowEstimatedExpires(createProjectObj) {
    let subProjectType = createProjectObj.projectBasicInfo.get('subProjectType').value;
    if (!subProjectType && createProjectObj.projectInfo && createProjectObj.projectInfo.subProjectType) {
      subProjectType = createProjectObj.projectInfo.subProjectType;
    }
    return this.checkProjectService.isExpressTypeProject(createProjectObj.projectType) ||
      createProjectObj.projectType === this.commonConfig.projectType.competitive || (createProjectObj.projectType === this.commonConfig.projectType.sealedbid
        && subProjectType !== this.commonConfig.subProjectType.sealed.ice.key);
  }

  // Function for change field lable heading
  setProjectLabel(createProjectObj) {
    createProjectObj.lableHeading = headingText.projectHeading.bid;
    createProjectObj.formLable = headingText.projectHeading.bids;
    createProjectObj.estimatedHeading = headingText.projectHeading.competitive;
    createProjectObj.textFieldLable = headingText.projectHeading.bidding;
    if (this.isQuoteType(createProjectObj)) {
      createProjectObj.formLable = headingText.projectHeading.quotes;
      createProjectObj.lableHeading = headingText.projectHeading.quote;
      createProjectObj.textFieldLable = headingText.projectHeading.quote;
    }
    this.setProjectEstimatedHeading(createProjectObj);
    this.resetProjectValidaton(createProjectObj);
  }

  // Function used to set project estimated heading
  setProjectEstimatedHeading(createProjectObj) {
    if (createProjectObj.projectBasicInfo.get('subProjectType').value === this.commonConfig.subProjectType.sealed.ifb.key) {
      createProjectObj.lableHeading = headingText.projectHeading.ifb;
      createProjectObj.estimatedHeading = headingText.projectHeading.sealedifb;
    } else if (createProjectObj.projectBasicInfo.get('subProjectType').value === this.commonConfig.subProjectType.sealed.rfp.key) {
      createProjectObj.lableHeading = headingText.projectHeading.rfp;
      createProjectObj.estimatedHeading = headingText.projectHeading.sealedrfp;
    } else if (createProjectObj.projectBasicInfo.get('subProjectType').value === this.commonConfig.subProjectType.sealed.rfq.key) {
      createProjectObj.lableHeading = headingText.projectHeading.rfq;
      createProjectObj.estimatedHeading = headingText.projectHeading.sealedrfq;
    } else if (this.checkProjectService.isExpressTypeProject(createProjectObj.projectType)) {
      createProjectObj.estimatedHeading = headingText.projectHeading.express;
    } else if (this.isMaterialBidType(createProjectObj.projectType)) {
      createProjectObj.estimatedHeading = headingText.projectHeading.material;
    }
  }

  // Function used for reset project form validation
  resetProjectValidaton(createProjectObj) {
    if (this.isShowEstimatedExpires(createProjectObj) && !this.checkProjectService.isExpressTypeProject(createProjectObj.projectType)) {
      CustomValidator.reSetFormValidation(createProjectObj.projectBasicInfo.controls.estimatedCost, true, true);
      CustomValidator.reSetFormValidation(createProjectObj.projectBasicInfo.controls.projectStartAt, true);
      CustomValidator.reSetFormValidation(createProjectObj.projectBasicInfo.controls.expiresAt, true);
    } else if (this.isMaterialBidType(createProjectObj.projectType)) {
      CustomValidator.reSetFormValidation(createProjectObj.projectBasicInfo.get('location').get('address'), false);
      CustomValidator.reSetFormValidation(createProjectObj.projectBasicInfo.get('location').get('lat'), false);
      CustomValidator.reSetFormValidation(createProjectObj.projectBasicInfo.get('location').get('long'), false);
      CustomValidator.reSetFormValidation(createProjectObj.projectBasicInfo.controls.bidStartAt, false);
      CustomValidator.reSetFormValidation(createProjectObj.projectBasicInfo.controls.projectStartAt, false);
      CustomValidator.reSetFormValidation(createProjectObj.projectBasicInfo.controls.expiresAt, false);
      CustomValidator.reSetFormValidation(createProjectObj.projectBasicInfo.controls.estimatedCost, false, false);
    } else {
      CustomValidator.reSetFormValidation(createProjectObj.projectBasicInfo.controls.estimatedCost, false);
      CustomValidator.reSetFormValidation(createProjectObj.projectBasicInfo.controls.projectStartAt, false);
      CustomValidator.reSetFormValidation(createProjectObj.projectBasicInfo.controls.expiresAt, false);
    }
  }

  /**
   * @description Function for remove sealed bid feature when user switch between project type
   */
  removeSealedBidFeature(createProjectObj) {
    createProjectObj.projectBasicInfo.get('subProjectType').setValue('');
    createProjectObj.complianceRequirement = false;
    createProjectObj.projectBasicInfo.get('features').get('complianceRequirement').setValue(createProjectObj.complianceRequirement);
    createProjectObj.permission = true;
    createProjectObj.projectBasicInfo.get('features').get('permission').setValue(createProjectObj.permission);
    createProjectObj.advertisements = false;
    createProjectObj.projectBasicInfo.get('features').get('advertisements').setValue(createProjectObj.advertisements);
    (createProjectObj.projectId) &&
      this.projectsService.updateProjectData(createProjectObj.projectId, { lineItems: null, advertisements: null, compliance: null });
  }

  setDefaultProjectValue(createProjectObj) {
    createProjectObj.projectBasicInfo.get('projectType').setValue(createProjectObj.projectType);
    createProjectObj.projectBasicInfo.get('customId').setValue(createProjectObj.customId);
    if (!createProjectObj.projectStatus) {
      createProjectObj.projectBasicInfo.get('status').setValue(this.commonConfig.projectStatus.draft);
    } else {
      createProjectObj.projectBasicInfo.get('status').setValue(createProjectObj.projectStatus);
    }
  }

  /**
   * @description function used to preview doc files in document viewer.
   * @param {object} doc contain filestack document handle
   */
  previewDoc(doc: string, isView = true) {
    const dialogRef = this.dialog.open(PreviewDocComponent, {
      panelClass: 'preview-doc-modal'
    });
    const instance = dialogRef.componentInstance;
    instance.dialogRef = dialogRef;
    instance.doc = doc;
    instance.isView = isView;
  }

  /**
   * @description Function used for open video modal popup
   * @param {object} videoData contain video data object
   */
  openVideoModalPopup(videoData) {
    if (videoData && videoData['mimetype'] === this.commonConfig.videoType.mp4) {
      const dialogRef = this.dialog.open(VideoModalComponent);
      const instance = dialogRef.componentInstance;
      instance.data = videoData;
    }
  }

  /**
   * @description Function used for open full view image
   * @param {object} imageData contain image data object
   */
  imageFullSizePreview(imageData) {
    const dialogRef = this.dialog.open(ImageModalComponent, { panelClass: 'full-size-image-view' });
    const instance = dialogRef.componentInstance;
    instance.data = imageData;
  }

  fetchUserInformation(that) {
    const userSubscriber = this.fs.doc$(`${this.commonConfig.collectionName.users}/${that.uid}`)
      .subscribe((user: Partial<User>) => {
        this.projectFormService.setUserInformation(that, user);
        userSubscriber.unsubscribe();
      });
  }

  setAuthUserInformation(that) {
    return new Promise((resovle) => {
      that.authSubscription = that.auth.user.subscribe((user: User) => {
        if (user) {
          that.authUser = user;
          that.authUid = user.uid;
          if (!that.uid) {
            that.uid = that.authUid;
            this.projectFormService.setUserInformation(that, user);
          }
        }
      });
      resovle(true);
    });
  }

  getParticipantInformation(classRef) {
    const memberSubscribe = this.fs.colWithIds$(this.commonConfig.collectionName.projectmembers,
      (ref) => ref.orderBy('createdAt', 'desc').where('projectId', '==',
        classRef.projectid)).pipe(switchMap(activity => {
          const res = activity.map(result => {
            return classRef.fs.doc$(`${this.commonConfig.collectionName.users}/${result['uid']}`).pipe(map(memberInfo =>
              Object.assign(result, { 'user': memberInfo })));
          });
          return combineLatest(res);
        })).subscribe((response: ProjectMember[]) => {
          classRef.participantsList = response;
          memberSubscribe.unsubscribe();
        });
  }

  // Function used for set project data
   setProjectData(that) {
    const projectInfoSubscription = this.getProjectInfo(that.customId).subscribe(res => {
      let createdBy = that.authUid;
      if (res.length) {
        that.projectInfo = res[0];
        const isProjectMember = this.projectsService.isProjectMember(that.projectInfo, that.authUid);
        if (that.authUid !== that.projectInfo.uid && !that.authUser['isAdmin'] && !isProjectMember) {
          that.router.navigate(['dashboard']);
        }
        that.projectType = that.projectInfo.projectType;
        that.isAddendums = that.projectInfo.isAddendums;
        this.setProjectLabel(that);
        this.chooseProjectFeatureService.setProjectFeatureStatus(that);
        this.setProjectBasicData(that);
        if (that.projectInfo.createdBy) {
          createdBy = that.projectInfo.createdBy;
        }
        this.projectFileService.fetchMediaList(that);
        this.projectFileService.fetchFileDocumentsList(that);
        projectInfoSubscription.unsubscribe();
      } else {
        that.isNewProject = true;
      }
      this.projectFormService.setCreatedUpdatedBy(that, createdBy, that.authUid);
      this.resetProjectValidaton(that);
      this.updateProjectReviseStatus(that);
      that.loding = false;
    });
  }

  /**
   * @description Function used for set project basic form data
   * @param {any} that contain the component object
   */
   setProjectBasicData(that) {
    that.projectId = that.projectInfo.$key;
    that.projectPath = `projects/${that.projectId}`;
    that.projectStatus = that.projectInfo.status;
    if (that.projectInfo.category.length) {
      that.categories = that.projectInfo.category;
    }
    if (that.projectInfo.bidStartAt) {
      that.bidStartAtDate = that.projectInfo.bidStartAt.toDate();
      if (that.bidMinDate > that.bidStartAtDate) {
        that.bidMinDate = that.bidStartAtDate;
      }
      that.bidEndMin = that.bidStartAtDate;
    }
    if (that.projectInfo.bidEndAt) {
      that.bidEndAtDate = that.projectInfo.bidEndAt.toDate();
      that.projectStartMin = this.checkProjectService.addDayInDate(new Date(moment(that.bidEndAtDate).format('YYYY/MM/DD')),
        this.commonConfig.steps.one);
      this.setDataForMaterialTypeProject(that);
    }
    if (that.projectInfo.projectStartAt) {
      that.projectStartAtDate = that.projectInfo.projectStartAt.toDate();
      that.expiresAtMin = that.projectStartAtDate;
    }
    if (that.projectInfo.radius) {
      that.projectBasicInfo.get('radius').setValue(that.projectInfo.radius);
    }
  }

  setDataForMaterialTypeProject(that) {
    if (this.isMaterialBidType(that.projectType) && that.bidEndAtDate < that.bidEndMin) {
      that.bidEndMin = that.bidEndAtDate;
    }
  }

  /**
   * @description Function used for update project revise status
   * @param {any} that contain the component object
   */
   updateProjectReviseStatus(that) {
    let validationStatus;
    that.projectBasicInfo.statusChanges.pipe(debounceTime(CommonConfig.delay100)).subscribe((status: string) => {
      if (!!validationStatus && status.toLocaleLowerCase() === this.commonConfig.actionState.valid) {
        that.isProjectUpdate = true;
        if (that.projectBasicInfo.get('projectAction').value && that.projectBasicInfo.get('projectAction').value.isRevised) {
          that.projectBasicInfo.get('projectAction').setValue({ isRevised: false });
        }
      }
      validationStatus = status;
    });
  }

  getProjectInfo(customId: string): Observable<Project[]> {
    return this.afs.collection('projects', ref => ref.where('customId', '==', customId)).snapshotChanges().pipe(map(actions => {
      return actions.map(a => {
        const data = a.payload.doc.data() as Project;
        data['$key'] = a.payload.doc.id;
        return data;
      });
    }));
  }

  /**
   * @description function used for fetch activity list
   * @param {string} key contain last key of the list
   * * @param {object} classRef contain contain class object
   */
  getProjectActivityList(classRef, key = '') {
    const activitySubscribe = this.fs.colWithIds$(this.commonConfig.collectionName.userActivity,
      (ref) => ref.limit(classRef.activityPageSize).orderBy('updatedAt', 'desc').startAfter(key).where('projectId', '==',
        classRef.projectid)).pipe(switchMap(activity => {
          if (!activity.length) {
            classRef.tabListLoading = false;
          }
          const res = activity.map(result => {
            return this.fs.doc$(`${this.commonConfig.collectionName.users}/${result['uid']}`).pipe(map(vendorInfo =>
              Object.assign(result, { 'user': vendorInfo })));
          });
          return combineLatest(res);
        })).subscribe(response => {
          classRef.tabListLoading = false;
          classRef.lastKey = response[response.length - 1].doc;
          this.concatActivityListData(classRef, response, activitySubscribe);
        });
  }

  /**
   * @description function used for concat the activity list data
   * @param classRef contain the class refrence
   * @param response contain the query response
   */
  concatActivityListData(classRef, response, activitySubscribe) {
    if (classRef.isPermissionPrivate(classRef.permissionsKeys.activity) && !classRef.isProjectOwnerOrMember() && !classRef.isAdmin) {
      response = response.filter(x => x.uid === classRef.projects.uid || x.uid === classRef.authUid || (classRef.projects.members && classRef.projects.members.includes(x.uid)));
    }
    classRef.activityList = classRef.activityList.concat(response);
    activitySubscribe.unsubscribe();
  }

  /**
   * description function used for get material line item data
   * @param projectId contain the project id
   */
  getMaterialLineItemData(projectId: string) {
    return new Promise<MaterialLineItems[]>((resovle, reject) => {
      const subscribeRef = this.fs.colWithIds$(CommonConfig.collectionName.materialLineItem, (ref) => ref.orderBy('createdAt', 'desc')
        .where('projectId', '==', projectId)).subscribe(lineItem => {
          resovle(lineItem as MaterialLineItems[]);
          subscribeRef.unsubscribe();
        });
    });
  }

  /**
   * @description Function to check express project type is shown or not
   * @param {date} launchDate contain a express project launch date
   * @param {date} userCreatedDate contain auser created date
   */
  isExpressProjectShown(launchDate, userCreatedDate) {
    const currentDateTimeStamp = new Date().getTime();
    let projectShownLastDate;
    if (launchDate['seconds'] > userCreatedDate['seconds']) {
      const expressLaunchDate = new Date(launchDate['seconds'] * CommonConfig.thousand);
      projectShownLastDate = expressLaunchDate.setMonth(expressLaunchDate.getMonth() + CommonConfig.three);
    } else {
      const createdDate = new Date(userCreatedDate['seconds'] * CommonConfig.thousand);
      projectShownLastDate = createdDate.setMonth(createdDate.getMonth() + CommonConfig.three);
    }
    return projectShownLastDate > currentDateTimeStamp;
  }

  /**
   * @description getting the all publication list
   */
  findAllPublication(): Observable<PublicationList[]> {
    return this.afs.collection(CommonConfig.collectionName.publication, ref => ref.orderBy('name', 'asc'))
      .snapshotChanges().pipe(map(actns => {
        return actns.map(a => {
          const data = a.payload.doc.data() as PublicationList;
          data.$key = a.payload.doc.id;
          return Object.freeze(data);
        });
      }));
  }
  /**
   * @description getting the match url project
   */
  findProjectByAdsId(url): Observable<PublicationList[]> {
    return this.afs.collection(CommonConfig.collectionName.project, ref => ref.where('shortURL', '==', url))
      .snapshotChanges().pipe(map(actn => {
        return actn.map(action => {
          const data = action.payload.doc.data() as PublicationList;
          data.$key = action.payload.doc.id;
          return Object.freeze(data);
        });
      }));
  }

  /**
   * @description Function used for send notification on material items change
   * @param projectData contaion the project information title and project id
   */
  materialItemsNotification(projectData) {
    if (projectData) {
      const data = {
        projectId: projectData['$key'],
        projectTitle: projectData['title']
      };
      const unsubscriber = this.http.post(`${environment.cloudEndPoint}materialItemsChange`, data).subscribe(res => {
        unsubscriber.unsubscribe();
      });
    }
  }

  // Assign related category to project
  async manageRelatedCategory(createProjectObj) {
    const userCategory = [];
    createProjectObj.userDetail.category.forEach(async userCategoryRes => {
      userCategory.push(userCategoryRes.name);
    });
    userCategory.forEach(async categoryName => {
      const categorySubscribe = this.categoryService.getCategoryByName(categoryName).subscribe(categoryResult => {
        if (categoryResult && categoryResult.length) {
          categoryResult.forEach(async relatedCategoryRes => {
            if (relatedCategoryRes.relatedCategory && relatedCategoryRes.relatedCategory.length) {
              relatedCategoryRes.relatedCategory.forEach(async relatedCategoryData => {
                createProjectObj.relatedCategory.push({ 'key': relatedCategoryData.key, 'name': relatedCategoryData.name });
              });
              // Create unique related category array
              this.customService.uniqueArrayByKey(createProjectObj, 'key');
            }
          });
        }
        categorySubscribe.unsubscribe();
      });
    });
  }

  updateProjectOwner(user: Partial<User>, projectId: string, projectInfo: Project) {
    if (user['uid']) {
      const userInformation = {
        uid: user['uid'], email: user['email'], displayName: '', photoUrl: '',
        createdAt: this.timeStampService.getTimestamp
      };
      userInformation['displayName'] = user['displayName'] ? user['displayName'] : user['email'];
      userInformation['photoUrl'] = user['profilePhoto'] && user['profilePhoto']['url'] ? user['profilePhoto']['url'] : '';
      this.fs.update(`${this.commonConfig.collectionName.project}/${projectId}`,
        { uid: user['uid'], userInformation });
      this.sendEmailOnCreateUpdateProject(user, projectId, projectInfo, false, true);
      if (projectId) {
        const data = {};
        data['activityType'] = this.commonConfig.projectActivityType.assignproject;
        data['uid'] = projectInfo['actionByUid'];
        data['projectId'] = projectId;
        data['sendToUsers'] = [user['uid']];
        this.projectsService.saveInsideNotificationPayload(data, projectInfo);
      }
    }
  }

  async sendEmailOnCreateUpdateProject(user: Partial<User>, projectId: string, project: Project, isNewProject: boolean, isProjectUpdate: boolean) {
    /*Send email to project owner on create and update project*/
    if (user && user['email'] && projectId && isProjectUpdate) {
      let subject = this.commonConfig.emailSubject.projectUpdate;
      let templateId = this.commonConfig.emailTemplates.projectUpdate;
      if (isNewProject) {
        subject = this.commonConfig.emailSubject.projectCreate;
        templateId = this.commonConfig.emailTemplates.projectCreate;
      }
      const userName = user['displayName'] ? user['displayName'] : user['email'];
      const data = {
        toEmail: user['email'],
        userName,
        objectId: projectId,
        belongTo: this.commonConfig.belongTo.project,
        title: project['title'],
        subject,
        templateId
      };
      const commonUtil = new CommonUtils(this.fs);
      const isAllowed = await commonUtil.isEmailAllow(data['toEmail'], data['belongTo']);
      if (isAllowed) {
        const unsubscriber = this.http.post(`${environment.cloudEndPoint}emailSend`, data).subscribe(res => { unsubscriber.unsubscribe(); });
      }
    }
  }

}
