import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
// Config
import { CommonConfig } from '@shared/common.config';
import { OrganizationConfig } from '@shared/organization.config';
import { PROJECT_PERMISSION, ComplianceOptions } from '@shared/common.constants';
// Validator
import { CustomValidator } from '@app/shared/validator/custom.validator';
// Model
import { User } from '@app/core/model/user';
// Service
import { CheckProjectService } from '@shared/service/projects/check-project.service';
import { ProjectFileService } from '@shared/service/projects/project-file.service';
import { OrganizationService } from '@shared/service/organization/organization.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectFormService {
  private readonly commonConfig = CommonConfig;
  complianceOptions = ComplianceOptions;
  public organizationConfig = OrganizationConfig;

  constructor(
    private readonly fb: FormBuilder,
    private readonly checkProjectService: CheckProjectService,
    private readonly projectFileService: ProjectFileService,
    private readonly organizationService: OrganizationService
  ) { }

  buildFrom() {
    return this.fb.group({
      isPaymentDone: [false],
      customId: ['', Validators.required],
      uid: ['', Validators.required],
      createdBy: ['', Validators.required],
      updatedBy: ['', Validators.required],
      userInformation: [null],
      organization: [null],
      title: ['', Validators.required],
      projectType: ['', Validators.required],
      subProjectType: [''],
      location: this.fb.group({
        address: [null, Validators.required],
        lat: [null, Validators.required],
        long: [null, Validators.required]
      }),
      imageUrl: [''],
      category: [[], Validators.required],
      estimatedCost: [0],
      projectStartAt: [null, Validators.required],
      expiresAt: [null],
      bidStartAt: [null, Validators.required],
      bidEndAt: [null, Validators.required],
      features: this.fb.group({
        mediaGallery: [false],
        filesDocuments: [false],
        walkthroughScheduler: [false],
        complianceRequirement: [false],
        permission: [false],
        advertisements: [false],
        questionAnswer: [false]
      }),
      description: [''],
      status: [''],
      isArchive: [false],
      isActive: [true],
      projectAction: [null],
      inviteAllVendor: [false],
      inviteRadius: [false],
      inviteSpecificVendor: [false],
      radius: [this.commonConfig.defaultRadius],
      invitedVendors: [[]],
      permission: [{
        projectVisible: PROJECT_PERMISSION.PROJECT_PERMISSION_PUBLIC,
        participants: PROJECT_PERMISSION.PROJECT_PERMISSION_PUBLIC,
        biddingHistory: PROJECT_PERMISSION.PROJECT_PERMISSION_PUBLIC,
        discussions: PROJECT_PERMISSION.PROJECT_PERMISSION_PUBLIC,
        activity: PROJECT_PERMISSION.PROJECT_PERMISSION_PUBLIC,
        documents: PROJECT_PERMISSION.PROJECT_PERMISSION_PUBLIC,
        mediaGallery: PROJECT_PERMISSION.PROJECT_PERMISSION_PUBLIC,
        walkthrough: PROJECT_PERMISSION.PROJECT_PERMISSION_PUBLIC,
        faq: PROJECT_PERMISSION.PROJECT_PERMISSION_PUBLIC,
        compliance: PROJECT_PERMISSION.PROJECT_PERMISSION_PUBLIC,
        advertisement: PROJECT_PERMISSION.PROJECT_PERMISSION_PUBLIC
      }],
      isSoftDelete: [false],
      isHardDelete: [false],
      isAddendums: [false],
      timeZone: ['', Validators.required]
    });
  }

  createEmail(): FormGroup {
    return this.fb.group({
      email: ['', [
        Validators.required,
        Validators.email,
        CustomValidator.validEmail
      ]]
    });
  }

  createPhoneNumbers(): FormGroup {
    return this.fb.group({
      phone: ['', [
        Validators.required,
        Validators.minLength(this.commonConfig['maxPhoneLength'])
      ]]
    });
  }

  onKey(createProjectObj) {
    createProjectObj.projectBasicInfo.get('location').get('lat').setValue(null);
    createProjectObj.projectBasicInfo.get('location').get('long').setValue(null);
  }

  setCreatedUpdatedBy(createProjectObj, createdBy: string, updatedBy: string) {
    createProjectObj.projectBasicInfo.get('createdBy').setValue(createdBy);
    createProjectObj.projectBasicInfo.get('updatedBy').setValue(updatedBy);
  }

  setUserInformation(createProjectObj, user: Partial<User>) {
    createProjectObj.projectLocations = user.projectLocations || [];
    if (user['location']) {
      createProjectObj.projectLocations.unshift(user['location']);
    }
    if (createProjectObj.projectLocations.length) {
      createProjectObj.setProjectLocation(createProjectObj.projectLocations[this.commonConfig.zero]);
    }
    createProjectObj.userInformation = { uid: user.uid, email: user.email };
    createProjectObj.userInformation['displayName'] = user['displayName'] || user['email'];
    createProjectObj.userInformation['photoUrl'] = user['profilePhoto']['url'] || '';
    createProjectObj.subscriptionInformation = user['subscription'];
    createProjectObj.userDetail = user;
    createProjectObj.projectBasicInfo.get('uid').setValue(user.uid);
    createProjectObj.projectBasicInfo.get('timeZone').setValue(user.timeZone || this.commonConfig.defaultTimeZone.americaNewYork);
    createProjectObj.projectBasicInfo.get('userInformation').setValue(createProjectObj.userInformation);
    this.setOrgInProject(createProjectObj);
  }

  setProjectImageUrl(createProjectObj) {
    const photoList = this.projectFileService.filterMediaGallery(this.commonConfig.mimeType.image, createProjectObj.mediaList);
    if (photoList && photoList.length > this.commonConfig.zero) {
      createProjectObj.projectBasicInfo.get('imageUrl').setValue(createProjectObj.defaultImageUrl);
    } else {
      createProjectObj.projectBasicInfo.get('imageUrl').setValue('');
    }
  }

  // Function used to set organization in project.
  setOrgInProject(createProjectObj) {
    if (createProjectObj.userDetail && createProjectObj.userDetail.organization && Object.keys(createProjectObj.userDetail.organization).length) {
      const orgMembersSubscriber = this.organizationService.getOrganizationMember(createProjectObj.userDetail.uid).subscribe(orgMembers => {
        if (orgMembers.length && orgMembers[0].status === this.organizationConfig.orgMemberStatus.approved) {
          createProjectObj.projectBasicInfo.get('organization').setValue(createProjectObj.userDetail.organization);
        }
        orgMembersSubscriber.unsubscribe();
      });
    }
  }

  setProjectLocation(createProjectObj, location) {
    createProjectObj.projectBasicInfo.get('location').get('address').setValue(location.address);
    createProjectObj.projectBasicInfo.get('location').get('lat').setValue(location.lat);
    createProjectObj.projectBasicInfo.get('location').get('long').setValue(location.long);
  }

  dateTimeChangeBidStart($evt, createProjectObj) {
    let tempDate = $evt;
    tempDate = this.increaseBidEndMin(tempDate.toDate().getTime());
    createProjectObj.projectBasicInfo.controls.bidStartAt.valueChanges.subscribe(data => {
      if (data && createProjectObj.projectBasicInfo.value.projectType === CommonConfig.projectType.sealedbid) {
        tempDate = new Date(data);
        tempDate.getTime() < createProjectObj.bidMinDate.getTime() && createProjectObj.projectBasicInfo.controls.bidStartAt.setValue(createProjectObj.bidMinDate);
      }
    });
    setTimeout(() => {
      createProjectObj.bidEndMin = tempDate;
    }, this.commonConfig.delay100);
  }

  // add one minute in bid end minimmum date
  increaseBidEndMin(timeVal) {
    return new Date(Number(timeVal) + Number(this.commonConfig.oneMinute));
  }

  dateTimeChangeBidEnd($evt, createProjectObj) {
    createProjectObj.projectStartMin = $evt;
    this.reSetProjectStartDate(createProjectObj);
  }

  reSetProjectStartDate(createProjectObj) {
    const projectStartAt = createProjectObj.projectBasicInfo.get('projectStartAt').value;
    if (this.checkProjectService.isExpressTypeProject(createProjectObj.projectType) && (projectStartAt && projectStartAt < createProjectObj.projectStartMin)) {
      createProjectObj.projectBasicInfo.get('projectStartAt').setValue(null);
      createProjectObj.projectBasicInfo.get('expiresAt').setValue(null);
    }
  }

  dateTimeChangeProjectStart($evt, createProjectObj) {
    createProjectObj.expiresAtMin = this.checkProjectService.addDayInDate($evt.value, this.commonConfig.steps.one);
    this.reSetProjectEndDate(createProjectObj);
  }

  reSetProjectEndDate(createProjectObj) {
    const expiresAt = createProjectObj.projectBasicInfo.get('expiresAt').value;
    if (this.checkProjectService.isExpressTypeProject(createProjectObj.projectType) && (expiresAt && expiresAt < createProjectObj.expiresAtMin)) {
      createProjectObj.projectBasicInfo.get('expiresAt').setValue(null);
    }
  }

  // Function used for set form active step on edit
  setFormStepDeactive(createProjectObj) {
    setTimeout(() => {
      if (!createProjectObj.activeStep) {
        createProjectObj.step1Ele.active = true;
      }
    }, this.commonConfig.zero);
  }

}
