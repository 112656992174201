import { Injectable } from '@angular/core';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class TimestampService {

  constructor() { }

  get getTimestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  /**
   * @description Function to convert date string in to timestamp
   * @param {string} date contain a valid date string
   * @returns a valid date in timestamp
   */
  dateInTimestamp(date: string) {
    return new Date(date);
  }

}

