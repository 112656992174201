import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataStorageService {

  private readonly adminPanel = new BehaviorSubject({ isAdmin: false });
  adminPanelStatus = this.adminPanel.asObservable();

  private readonly toggleDialog = new BehaviorSubject({});
  toggleDialogFlow = this.toggleDialog.asObservable();

  searchFilterLocation = new Subject();
  location;

  constructor() {
    this.location = JSON.parse(localStorage.getItem('location'));
  }

  setAdminPanel(value) {
    this.adminPanel.next({ isAdmin: value });
  }

  forToggleDialogFlow(value: boolean) {
    this.toggleDialog.next(value);
  }

  setSearchLocation(addrObj) {
    localStorage.setItem('location', JSON.stringify(addrObj));
    this.location =  JSON.parse(localStorage.getItem('location'));
    this.searchFilterLocation.next(this.location);
  }

  removeSearchLocation() {
    localStorage.removeItem('location');
    this.location = localStorage.getItem('location');
    this.searchFilterLocation.next(this.location);
  }

}
