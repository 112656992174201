import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { TdDialogService } from '@covalent/core/dialogs';
// Config
import { CommonConfig } from '@shared/common.config';
import { calendarIcsStatus, calendarInviteSubject, icsMethods } from '@shared/common.constants';
// Model
import { ProjectWalkthrough } from '@app/core/model/project-walkthrough';
import { Project } from '@app/core/model/project';
// Service
import { CustomService } from '@shared/service/custom/custom.service';
import { ProjectsService } from '@shared/service/projects/projects.service';
import { ProjectActivityService } from '@shared/service/project-activity/project-activity.service';

@Component({
  selector: 'app-walkthroughrequestmodal',
  templateUrl: './walkthroughrequestmodal.component.html',
  styleUrls: ['./walkthroughrequestmodal.component.scss']
})
export class WalkthroughrequestmodalComponent implements OnInit {

  constructor(
    private readonly formBuilder: FormBuilder,
    private projectActivityService: ProjectActivityService,
    private customService: CustomService,
    private readonly _dialogService: TdDialogService,
    private readonly projectsService: ProjectsService
  ) { }
  walkthroughRequestFrom: FormGroup;
  data: Partial<ProjectWalkthrough>;
  walkthroughId: string;
  vendorId: string;
  dialogRef;
  projects: Project;
  authUid: string;
  commonConfig =  CommonConfig;
  dateFrom = new Date(new Date().toDateString());
  timeFrom = new Date();
  minTimeFrom: object = new Date();

  ngOnInit() {
    this.walkthroughRequestFrom = this.formBuilder.group({
      projectId: ['', Validators.required],
      description: ['', Validators.required],
      date: [null, Validators.required],
      from: [null, Validators.required],
      createdBy: [this.authUid],
      updatedBy: [this.authUid],
      isActive: [true],
      isDeleted: [false]
    });
    this.walkthroughRequestFrom.get('projectId').setValue(this.data.projectId);
  }

  get getDateVal() {
    return this.walkthroughRequestFrom.get('date');
  }

  get getFrom() {
    return this.walkthroughRequestFrom.get('from');
  }

  /**
   * @description Function to reset from minimum date
   */
  dateTimeChangeDate($evt) {
    this.walkthroughRequestFrom.get('from').setValue(null);
    this.minTimeFrom = $evt.value;
  }

  async confirmWtRequest() {
    this.data = this.walkthroughRequestFrom.value;
    this.walkthroughId = (this.data && this.data['id']) ? this.data['id'] : '';
    if (this.vendorId !== '') {
      const isRequested = await this.projectActivityService.requestlWalkthroughScheduler(this.walkthroughId, this.vendorId, this.walkthroughRequestFrom.value);
      if (isRequested) {
        const activityDataObject = {
          uid: this.authUid, projectId: this.data.projectId,
          activityType: CommonConfig.projectActivityType.walkthroughRequested, walkthroughId: this.walkthroughId,
          walkthroughTime: this.walkthroughRequestFrom.value.from,
          walkthroughDescription: this.walkthroughRequestFrom.value.description
        };
        this.projectsService.saveProjectActivity(activityDataObject);
        this.customService.showSnackBarSuccess(CommonConfig.successMessage.scheduleRequested);
         this.sendCalendarNotification();
         this.sendMailAndWebNotification();
      } else {
        this.customService.showSnackBarError(CommonConfig.validationMessage.someError);
      }
      this.dialogRef.close();
    } else {
      this.dialogRef.close();
      this._dialogService.openAlert({
        message: CommonConfig.popupAlertMessage.walkthroughSchedule
      });
    }
  }

  sendMailAndWebNotification() {
    let allUsers: Array<Object> = [];
    if (this.projects.members) {
      allUsers = allUsers.concat(this.projects.members);
    }
    allUsers.push(this.projects.uid);
    const requireData = {
      authUid: this.authUid,
      action: CommonConfig.walkthroughStatus.request,
      vendorId: this.vendorId,
      allUsers
    };

    this.projectActivityService.sendMailAndWebNotification(this.projects, requireData);
  }

  /**
   * @description send calendar invite notification mail.
   */
  async sendCalendarNotification() {
    const walkthroughData = this.data;
    const requireData = {
      authUid: this.authUid,
      status: calendarIcsStatus.confirmed,
      method: icsMethods.publish,
      sequence: 0,
      isRequestWalthrough: true,
      fromRequest: this.walkthroughRequestFrom.value.from,
      vendorId: this.vendorId,
      projects: this.projects,
      subject: calendarInviteSubject.requestEvent
    };
    this.projectActivityService.sendIcsCalendarNotification(walkthroughData, requireData, this.walkthroughRequestFrom.value.description);
  }
}
