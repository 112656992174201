import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { TdDialogService } from '@covalent/core/dialogs';
import { CommonConfig } from '@shared/common.config';

@Component({
  selector: 'app-static-promotion',
  templateUrl: './static-promotion.component.html',
  styleUrls: ['../../../../assets/scss/grid-view.scss'],
  host: { 'class': 'ui-app-static-promotion' }
})
export class StaticPromotionComponent implements OnInit {
  public commonConfig = CommonConfig;
  public staticPromotion = this.commonConfig.staticPromotion.one;
  @Input() key: string;
  @ViewChild('intercomLauncher', {static: false}) intercomLauncher: ElementRef;
  constructor(private readonly _dialogService: TdDialogService) {
    if (this.key) {
      this.staticPromotion = this.commonConfig.staticPromotion[this.key];
    }
   }

  ngOnInit() {
  }

  /**
   * @description used for show promotion help message and open intercom for contact support
   */
  helpPromotion() {
    this._dialogService.openConfirm({
      message: this.commonConfig.popupAlertMessage.promotionHelp,
      acceptButton: this.commonConfig.dialogService.contactSupport,
      cancelButton: this.commonConfig.dialogService.ok
    })
      .afterClosed().subscribe((accept: boolean) => {
        if (accept) {
          this.intercomLauncher.nativeElement.click();
        }
      });
   }

}
