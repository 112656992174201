import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TdDialogService } from '@covalent/core/dialogs';
import { BehaviorSubject, Subject } from 'rxjs';
// Config
import { environment } from 'environments/environment';
import { CommonConfig } from '@shared/common.config';
import { trackEvent } from '@shared/common.constants';
// Model
import { Project } from '@app/core/model/project';
import { User } from '@app/core/model/user';
// Service
import { HttpService } from '@shared/service/http/http.service';
import { AuthService } from '@app/core/auth.service';
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { CustomService } from '@shared/service/custom/custom.service';
import { GanalyticsService } from '@shared/service/ganalytics/ganalytics.service';
import { ProjectsService } from '@shared/service/projects/projects.service';
import { ProjectEmailService } from '@shared/service/projects/project-email.service';
import { SubscriptionService } from '@shared/service/subscription/subscription.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectListService {
  commonConfig = CommonConfig;
  authUser: Partial<User>;
  public defaultLatLong = new BehaviorSubject({});
  defaultLatLongStaus = this.defaultLatLong.asObservable();

  public projectFilterFrm = new Subject();
  projectFilterFrmValue = this.projectFilterFrm.asObservable();

  public projectFilterMenuTrigger = new Subject<boolean>();
  projectFilterMenuTriggerStatus = this.projectFilterMenuTrigger.asObservable();

  public deleteProjectRecord = new BehaviorSubject({ isUpdateRecord: false });
  deleteProjectRecordValue = this.deleteProjectRecord.asObservable();

  constructor(
    private readonly router: Router,
    private readonly http: HttpClient,
    private readonly httpService: HttpService,
    private readonly auth: AuthService,
    private readonly _dialogService: TdDialogService,
    private readonly fs: FirestoreService,
    private readonly customService: CustomService,
    private readonly ganalyticsService: GanalyticsService,
    private readonly projectsService: ProjectsService,
    private readonly projectEmailService: ProjectEmailService,
    public subscriptionService: SubscriptionService
  ) { }

  // Archive/Unarchive projects
  archiveProject(projectId: string, isArchive: boolean, index: number, that) {
    this._dialogService.openConfirm({
      message: isArchive ? this.commonConfig.popupAlertMessage.projectUnArchived : this.commonConfig.popupAlertMessage.projectArchived,
      acceptButton: this.commonConfig.dialogService.accept,
      cancelButton: this.commonConfig.dialogService.cancel
    })
      .afterClosed().subscribe((accept: boolean) => {
        if (accept) {
          this.projectsService.archiveProject(projectId, !isArchive).then(res => {
            const message = isArchive ? CommonConfig.successMessage.projectUnArchived : CommonConfig.successMessage.projectArchived;
            this.customService.showSnackBarSuccess(message);
            if (index > -1) {
              that.projects.splice(index, 1);
            }
          })
            .catch(err => {
              this.customService.showSnackBarError(CommonConfig.validationMessage.someError);
            });
        }
      });
  }

  async projectDetailNavigation(projectId, projectSubscriberType, currentURL, currentPage) {
    this.authUser = await this.auth.currentUser();
    if (projectSubscriberType === this.commonConfig.projectSubscribeType.public
      && (!this.authUser.subscription || (this.authUser.subscription && this.authUser.subscription.plan_id === this.commonConfig.subscriptionPlanType.basic))
      ) {
      this.subscriptionService.upgradePlanPopUp(this.commonConfig.popupAlertMessage.upgradePlanForPublicProject);
    } else {
      this.ganalyticsService.analyticsTrackEvent(trackEvent.projectViewed, projectId);
      this.router.navigate([`${currentURL}/project-detail`, projectId], { queryParams: { from: currentPage } });
    }
  }

  updateProjectStatus(statusType: string, project: Project, authUser?: Partial<User>) {
    let alertMessage = '';
    let successMessage = '';
    let title = '';
    let templateId = '';
    let subject = '';
    let activityType = '';
    if (statusType === this.commonConfig.projectStatus.published) {
      alertMessage = this.commonConfig.popupAlertMessage.projectPublished;
      successMessage = this.commonConfig.successMessage.projectAccepted;
      title = 'Accept Project';
      templateId = this.commonConfig.emailTemplates.projectAccept;
      subject = this.commonConfig.emailSubject.projectAccept;
      activityType = this.commonConfig.projectActivityType.projectaccept;
    } else if (statusType === this.commonConfig.projectStatus.rejected) {
      alertMessage = this.commonConfig.popupAlertMessage.projectRejected;
      successMessage = this.commonConfig.successMessage.projectRejected;
      title = 'Reject Project';
      templateId = this.commonConfig.emailTemplates.projectReject;
      subject = this.commonConfig.emailSubject.projectReject;
      activityType = this.commonConfig.projectActivityType.projectreject;
    }
    this._dialogService.openConfirm({
      message: alertMessage,
      title,
      acceptButton: this.commonConfig.dialogService.accept,
      cancelButton: this.commonConfig.dialogService.cancel
    })
      .afterClosed().subscribe((accept: boolean) => {
        if (accept) {
          this.updateProjectFsStatus(authUser, project, statusType, templateId, subject, activityType, successMessage);
        }
      });
  }

  updateProjectFsStatus(authUser: Partial<User>, project: Project, statusType: string, templateId: string, subject: string, activityType: string, successMessage: string) {
    const cond = { status: statusType };
    this.fs.update(`${this.commonConfig.collectionName.project}/${project['id']}`,
      cond).then(async (res) => {
        this.customService.showSnackBarSuccess(successMessage);
        project['status'] = statusType;
        const dataObj = { templateId, subject, activityType };
        this.projectEmailService.sendAcceptRejectEmailToProjectOwner(project, dataObj, authUser);
      }).catch(err => {
        this.customService.showSnackBarError(this.commonConfig.validationMessage.someError);
      });
  }

  // Fetch archived projects
  getArchivedProjects(uid: string, pageSize, key) {
    return this.fs.colWithIds$(
      this.commonConfig.collectionName.project,
      (ref) => ref.limit(pageSize)
        .where('uid', '==', uid)
        .where('isArchive', '==', true)
        .where('isSoftDelete', '==', false)
        .where('isActive', '==', true)
        .orderBy('createdAt', 'desc')
        .startAfter(key));
  }

  // Fetch favorite projects
  getFavoriteProjects(uid: string, pageSize, key) {
    return this.fs.colWithIds$(
      this.commonConfig.collectionName.project,
      (ref) => ref.limit(pageSize)
        .where('favorites', 'array-contains', uid)
        .where('isArchive', '==', false)
        .where('isSoftDelete', '==', false)
        .orderBy('createdAt', 'desc')
        .startAfter(key));
  }

  /**
  * @description Function used for scrap vendor data
  */
  scrapeVendors(requestData) {
    return this.httpService.httpPost(`${environment.cloudEndPoint}scrapeVendors`, requestData).toPromise();
  }

  reIndexProjectInvites(requestData) {
    return this.httpService.httpPost(`${environment.cloudEndPoint}projectInvites`, requestData).toPromise();
  }
}
