
import {debounceTime} from 'rxjs/operators';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { CommonConfig } from '@app/shared/common.config';
import { OrganizationConfig } from '@app/shared/organization.config';
import { OrgUserPermissions } from '@app/shared/common.constants';
import { CustomValidator } from '@app/shared/validator/custom.validator';
import { User } from '@app/core/model/user';
import { UserInformation } from '@app/core/model/user-information';
import { Organization } from '@app/core/model/organization';
import { OrganizationMembers } from '@app/core/model/organization-members';

import { CustomService } from '@app/shared/service/custom/custom.service';
import { OrganizationService } from '@app/shared/service/organization/organization.service';
import { OrgAlgoliaService } from '@app/shared/service/org-algolia/org-algolia.service';
import { FirestoreService } from '@app/shared/service/firestore/firestore.service';

@Component({
  selector: 'app-join-org',
  templateUrl: './join-org.component.html',
  styleUrls: ['./join-org.component.scss']
})
export class JoinOrgComponent implements OnInit {
  public commonConfig = CommonConfig;
  public organizationConfig = OrganizationConfig;
  public inviteUserToOrgRoles = [this.organizationConfig.roles.admin, this.organizationConfig.roles.teamMember];
  public orgUserPermissions = OrgUserPermissions.data;
  public joinOrgForm: FormGroup;
  public userInfo: User;
  public organizationData: Partial<Organization>;
  public dialogRef;
  public isSubmit;
  public orgList;
  @ViewChild('orgSearchBox', {static: false}) orgSearchBox: ElementRef;

  constructor(private readonly formBuilder: FormBuilder, private readonly customService: CustomService,
     private readonly organizationService: OrganizationService, public readonly fs: FirestoreService,
     public readonly orgAlgoliaService: OrgAlgoliaService) { }

  ngOnInit() {
    this.buildFrom();
    this.joinOrgForm.get('organizationName').valueChanges.pipe(debounceTime(CommonConfig.delay300)).subscribe((value) => {
      this.getOrgList(value);
      if (!value) {
        this.orgList = [];
        this.setOrganizationData();
      }
    });
    this.onChanges();
  }

  /**
   * @description Function used for checked and uncheck role permissions
   */
  onChanges(): void {
    // Subscribe to changes on the selectAll checkbox
    this.joinOrgForm.get('all').valueChanges.subscribe(bool => {
      this.joinOrgForm
        .get('permissions')
        .patchValue(Array(this.orgUserPermissions.length).fill(bool), { emitEvent: false });
    });

    // Subscribe to changes on the permissions checkboxes
    this.joinOrgForm.get('permissions').valueChanges.subscribe(val => {
      const allSelected = val.every(bool => bool);
      if (this.joinOrgForm.get('all').value !== allSelected) {
        this.joinOrgForm.get('all').patchValue(allSelected, { emitEvent: false });
      }
    });
  }

  /**
   * @description Function used for create reactive form to invite organization
   */
  buildFrom() {
    const selectAllControl = new FormControl(false);
    const formControls = this.orgUserPermissions.map(control => this.formBuilder.control(false));
    this.joinOrgForm = this.formBuilder.group({
      role: ['', CustomValidator.required],
      uid: [this.userInfo.uid],
      orgId: ['', CustomValidator.required],
      organization: [null],
      organizationName: [''],
      userInformation: [this.getUserBriefDetails(this.userInfo)],
      invitedBy: [null],
      status: [this.organizationConfig.orgMemberStatus.pending],
      isActive: [true],
      isSoftDelete: [false],
      isHardDelete: [false],
      request: [this.organizationConfig.orgMemberRequest.join],
      all: selectAllControl,
      permissions: this.formBuilder.array(formControls)
    });
  }

  /**
   * @description get role from form
   */
  get role() {
    return this.joinOrgForm.get('role');
  }

  /**
   * @description Function used for submit the join organization request form data
   */
  async submit() {
    this.isSubmit = true;
    const payload = this.joinOrgForm.value;
    this.setOrgPermission(payload);
     if (!this.isEnterpriseUser(this.userInfo)) {
        this.customService.showSnackBarError(this.commonConfig.validationMessage.isValidSubscription);
     } else {
        const isAlreadyOrgMember = await this.organizationService.isAlreadyOrgMember(this.userInfo.uid);
        if (isAlreadyOrgMember) {
          this.customService.showSnackBarError(this.commonConfig.validationMessage.alreadyOrgMember);
        } else {
          delete payload.organizationName;
          delete payload.all;
          this.joinUserAsOrgMember(payload);
        }
      }
      this.isSubmit = false;
  }

  /**
   * @description function to get checked permissions.
   */
  getOrgPermission() {
    return this.joinOrgForm.value.permissions
      .map((checked, index) => checked ? this.orgUserPermissions[index].value : null)
      .filter(value => value !== null);
  }


  /**
   * @description function to add permissions with user. If invited user is admin only then permissions available.
   * team member doesn't have any permissions.
   * @param payload contains organization member data
   */
  setOrgPermission(payload) {
    if (payload.role === this.commonConfig.role.admin.toLocaleLowerCase()) {
      const selectedPermissions = this.getOrgPermission();
      payload['permissions'] = selectedPermissions;
    } else {
      payload['permissions'] = [];
    }
  }

  /**
   * @description function to set organization data with user, to be updated in user collection..
   * @param payload contains organization member data
   */
  setOrganizationData(organizationData?) {
    if (organizationData) {
      this.joinOrgForm.get('organization').setValue({id: organizationData.objectID, name: organizationData.name});
      this.joinOrgForm.get('orgId').setValue(organizationData.objectID);
    } else {
      this.joinOrgForm.get('organization').setValue(null);
      this.joinOrgForm.get('orgId').setValue(null);
    }
  }

  /**
   * @description function to check if the user to whom inviting is valid or not. user must have enterprise subscription
   * @param userRes contains user data
   */
  isEnterpriseUser(userRes) {
    return (userRes && userRes.subscription && userRes.subscription.plan_id === this.commonConfig.subscriptionPlanType.enterprise);
  }

  /**
   * @description Function used to get brief user details
   * @param userInfo contains user data
   */
  getUserBriefDetails(userInfo) {
    return {
      uid: userInfo.uid,
      displayName: userInfo.displayName || '',
      email: userInfo.email || '',
      photoUrl: (userInfo.profilePhoto && userInfo.profilePhoto.url) ? userInfo.profilePhoto.url : ''
    };
  }

  /**
   * @description Function used to add data in organization-members collection.
   * @param payload contains data of organization member to be saved.
   */
  joinUserAsOrgMember(payload: OrganizationMembers) {
    this.fs.add(`${this.commonConfig.collectionName.organizationMembers}`, payload).then(async (res) => {
      this.updateOrgInUser(payload);
      this.dialogRef.close();
      this.isSubmit = false;
      this.customService.showSnackBarSuccess(this.commonConfig.successMessage.joinOrgRequest);
    }).catch(err => {
      this.customService.showSnackBarError(this.commonConfig.validationMessage.someError);
    });
  }

  /**
   * @description Function used to update organization details associated with users in user collection
   * @param data contains data of organization member.
   */
  updateOrgInUser(data: OrganizationMembers) {
    this.organizationService.updateOrgInUser(data);
  }

  /**
   * @Description Function used for get organization list data
   * @param keyword contains data of organization member
   */
  getOrgList(keyword: string) {
    const data = {skipPage: true, status: this.organizationConfig.orgStatus.published};
    if (keyword) { data['keyword'] = keyword; }
    this.orgList = [];
    this.orgAlgoliaService.searchOrganization(data, (err, result) => {
      if (result.hits) {
        this.orgList = [...this.orgList, ...result.hits];
      }
    });
  }

  /**
   * @Description Function used for select organization for join request
   * @param event contains data of mat autocomplete selected event
   */
  selectOrg(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value) {
      this.orgSearchBox.nativeElement.value = event.option.value.name;
      this.setOrganizationData(event.option.value);
    }
  }

}
