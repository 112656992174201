import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { TdDialogService } from '@covalent/core/dialogs';
import { CommonConfig } from '@shared/common.config';
import { AuthService } from '@app/core/auth.service';
import { AdvertisementService } from '@shared/service/advertisement/advertisement.service';
import { CustomService } from '@shared/service/custom/custom.service';
import { User } from '@app/core/model/user';
import { Advertisement } from '@app/core/model/advertisement';

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  host: {'class': 'promotion-card-container'},
  styleUrls: ['../../../../assets/scss/grid-view.scss']
})
export class PromotionComponent implements OnInit {
  public commonConfig = CommonConfig;
  private lastKey;
  private adPageSize: number = this.commonConfig.adPageSize;
  @Input() userDetail: Partial<User>;
  public advertisementList: Advertisement[] = [];
  @Input() className = '';
  @ViewChild('intercomLauncher', {static: false}) intercomLauncher: ElementRef;

  constructor(public auth: AuthService, private readonly adsservice: AdvertisementService,
    private readonly customService: CustomService, private readonly _dialogService: TdDialogService) { }

  ngOnInit() {
    this.getAuthUser();
  }

  /**
   * @description function used for get login user data
   */
  async getAuthUser() {
    if (!this.userDetail) {
      this.userDetail = await this.auth.currentUser();
    }
    this.lastKey = this.userDetail.lastAdKey ? this.userDetail.lastAdKey : '';
    this.fetchAdvertisement();
  }

  /**
   * @description Function to get advertisement list
   * @param {boolean} isRecursiveCall contain contain true if function call from inside
   */
  fetchAdvertisement(isRecursiveCall?) {
    this.adsservice.fetchAdvertisement(this.lastKey, this.adPageSize).then((snapshot) => {
      const docs = snapshot.docs;
      const currentTimestamp = this.customService.getCurrentTimestamp(true);
      if (docs.length) {
        this.ifAdsDocExist(docs, currentTimestamp);
      } else {
        if (this.lastKey && !this.advertisementList.length && !isRecursiveCall) {
          this.auth.updateUserInformaion(this.userDetail.uid, { lastAdKey: '' });
          this.lastKey = '';
          this.fetchAdvertisement(true);
        }
      }
      this.addsFetch(docs);
    });
  }

  /**
   * @description Handle the fetchAdvertisement if and else condition
   * @param docs {Object} contain the object
   */
  addsFetch(docs) {
    if (docs.length && this.advertisementList.length < CommonConfig.adPageSize) {
      this.adPageSize = CommonConfig.adPageSize - this.advertisementList.length;
      this.fetchAdvertisement(true);
    }
  }

  /**
   * @description If document exist handle ads
   * @param docs
   * @param currentTimestamp
   */
  ifAdsDocExist(docs, currentTimestamp) {
    this.lastKey = docs[docs.length - 1].data().createdAt;
    this.auth.updateUserInformaion(this.userDetail.uid, { lastAdKey: this.lastKey });
    docs.forEach((result) => {
      if (result.exists) {
        const adsData = [];
        const adData = result.data();
        if (this.adsservice.isAdDisplay(currentTimestamp, adData, this.advertisementList) &&
         this.adsservice.isLocationValid(adData, this.userDetail) && this.adsservice.isUserCategoryValid(adData, this.userDetail)) {
          const adId = result.id;
          Object.assign(adData, { id: adId });
          adsData.push(adData);
          this.advertisementList = this.advertisementList.concat(adsData);
          this.adsservice.viewImpressionCount(adId, adData.viewCount);
        }
      }
    });
  }

  /**
   * @description open url in new tab
   * @param {string} url contain the URL
   */
  openNewTab = (url?) => url && window.open(url, '_blank');

  /**
   * @description used for show promotion help message and open intercom for contact support
   */
  promotionHelp() {
    this._dialogService.openConfirm({
      message: this.commonConfig.popupAlertMessage.promotionHelp,
      acceptButton: this.commonConfig.dialogService.contactSupport,
      cancelButton: this.commonConfig.dialogService.ok
    })
      .afterClosed().subscribe((accept: boolean) => {
        if (accept) {
          this.intercomLauncher.nativeElement.click();
        }
      });
   }

}
