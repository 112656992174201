import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
// Config
import { CommonConfig } from '@shared/common.config';
// Model
import { Project } from '../../core/model/project';
// Service
import { AuthService } from '../../core/auth.service';
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { CustomService } from '@shared/service/custom/custom.service';
import { ProjectsService } from '@shared/service/projects/projects.service';

@Component({
  selector: 'app-favorite-project',
  templateUrl: './favorite-project.component.html',
  styleUrls: ['./favorite-project.component.scss']
})
export class FavoriteProjectComponent implements OnInit {
  commonConfig = CommonConfig;
  @Input() uid;
  @Input() project: Project[];
  @Input() starClass: string;
  @Input() projectId: string;
  isFavorite = false;
  favoritesUserIds = [];
  cond: {};
  private authSubscription: Subscription;
  constructor(
    private readonly auth: AuthService,
    private readonly firestoreService: FirestoreService,
    private readonly customService: CustomService,
    private readonly projectsService: ProjectsService
  ) { }

  ngOnInit() {
    if (this.project && this.project[this.commonConfig.zero]) {
      if (this.project[this.commonConfig.zero]['id']) {
        this.projectId = this.project[this.commonConfig.zero]['id'];
      }
      if (this.project[this.commonConfig.zero]['favorites']) {
        this.favoritesUserIds = this.project[this.commonConfig.zero]['favorites'];
      }
    }

    this.authSubscription = this.auth.user.subscribe(res => {
      if (res) {
        this.uid = res['uid'];
        if (this.favoritesUserIds && this.favoritesUserIds.includes(this.uid)) {
          this.isFavorite = true;
        }
      }
      this.authSubscription.unsubscribe();
    });
  }

  favoriteUnfavorite($event, isFavorite: boolean, projectId: string): boolean | undefined {
    $event.stopPropagation();
    // if user make project favorites and project type sealbid so user can't make un favorite the project
    if (this.hasFavoritesPropertyExists() &&
      this.project[0]['favorites'].includes(this.uid) && this.project[0].projectType === CommonConfig.projectType.sealedbid && this.isFavorite) {
      return false;
    }
    this.isFavorite = !isFavorite;
    this.setUpdateDataObject();
    this.firestoreService.update(`${CommonConfig.collectionName.project}/${projectId}`, this.cond)
      .then(results => {
        const dataObject = { uid: this.uid, activityType: '' };
        dataObject['projectId'] = projectId;
        dataObject['activityType'] = this.isFavorite ? CommonConfig.projectActivityType.favorite :
          CommonConfig.projectActivityType.unfavorite;
        this.projectsService.saveProjectActivity(dataObject);
      })
      .catch(error => {
        if (error) {
          this.isFavorite = isFavorite;
          this.customService.showSnackBarError(CommonConfig.validationMessage.someError);
        }
      });
  }

  hasFavoritesPropertyExists = () => this.uid && this.project[0].hasOwnProperty('favorites');

  /**
   * @description Function used for set update data object
   */
  setUpdateDataObject() {
    if (this.isFavorite) {
      this.cond = { favorites: this.firestoreService.firebaseFirestoreFieldValue().arrayUnion(this.uid) };
    } else {
      const index = this.favoritesUserIds.indexOf(this.uid);
      if (index > -1) {
        this.cond = { favorites: this.firestoreService.firebaseFirestoreFieldValue().arrayRemove(this.uid) };
      }
    }
  }

}
