import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
// Config
import { CommonConfig } from '@shared/common.config';

@Component({
  selector: 'app-unclaim-vendor-profile-list',
  templateUrl: './unclaim-vendor-profile-list.component.html'
})
export class UnclaimVendorProfileListComponent implements OnInit, OnDestroy {
  @Input() unclaimVendors;
  @Output() selectProfileEvent = new EventEmitter();
  commonConfig = CommonConfig;
  constructor(
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  selectUnclaimProfile(unclaimVendor) {
    this.selectProfileEvent.emit(unclaimVendor);
  }

}
