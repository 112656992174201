export const OrganizationConfig = {
    roles: {
        superAdmin: {
            key: 'superAdmin',
            value: 'Super Admin'
        },
        admin: {
            key: 'admin',
            value: 'Admin'
        },
        teamMember: {
            key: 'teamMember',
            value: 'Team Member'
        }
    },
    responsibilities: {
        project: 'project',
        vendor: 'vendor',
        bid: 'bids',
        user: 'user'
    },
    organizationNameLength: 150,
    orgStatus: {
        pending: 'pending',
        published: 'published',
        rejected: 'rejected',
    },
    orgAction: {
        create: 'create',
        invite: 'invite',
        join: 'join',
        leave: 'leave',
    },
    orgMemberStatus: {
        pending: 'pending',
        approved: 'approved',
        rejected: 'rejected',
    },
    popupTitle: {
        rejectOrg: 'Reject organization',
        approveOrg: 'Approve organization'
    },
    alertMsg: {
        rejectOrg: 'Are you sure you want to reject this Organization?',
        approveOrg: 'Are you sure you want to approve this Organization?',
        joinApprove: 'Are you sure you want to approve this join organization request?',
        joinReject: 'Are you sure you want to reject this join organization request?',
        leaveApprove: 'Are you sure you want to approve this leave organization request?',
        leaveReject: 'Are you sure you want to reject this leave organization request?'
    },
    successMsg: {
        rejectOrg: 'The organization request has been rejected successfully.',
        approveOrg: 'The organization request has been approved successfully.',
        joinApprove: 'The join organization request has been approved successfully.',
        joinReject: 'The join organization request has been rejected successfully.',
        leaveApprove: 'The leave organization request has been approved successfully.',
        leaveReject: 'The leave organization request has been rejected successfully.'
    },
    orgMemberRequest: {
        join: 'join',
        joined: 'joined',
        leave: 'leave',
        left: 'left',
        reject: 'rejected',
    }
};
