import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Config
import { environment } from 'environments/environment';
import { CommonConfig } from '@shared/common.config';
import { CommonUtils } from '@shared/common.util';
// Model
import { Vendor } from '@app/core/model/vendor';
import { User } from '@app/core/model/user';
// Service
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { CustomService } from '@shared/service/custom/custom.service';
import { VendorsService } from '@shared/service/vendors/vendors.service';

@Component({
  selector: 'app-need-vendor-revision',
  templateUrl: './need-vendor-revision.component.html'
})
export class NeedVendorRevisionComponent implements OnInit {
  commonConfig = CommonConfig;
  isSubmit = false;
  dialogRef;
  vendor: Vendor;
  authUser: Partial<User>;
  isRevised: boolean;
  public actionDetail;
  private isActive = false;
  constructor(
    private readonly http: HttpClient,
    public readonly fs: FirestoreService,
    private readonly customService: CustomService,
    private readonly vendorsService: VendorsService
    ) { }

  ngOnInit() {
  }

  submit(revisionReasonValue: string) {
    this.isSubmit = true;
    this.isRevised = this.isRevisionRequest();
    this.isActive = this.isDisableRequest();
    const isRejected = this.isRequestRejected();
    const revisionReason = revisionReasonValue;
    const dataObject = { status: this.actionDetail.key, isRevised: this.isRevised,
      isActive: this.isActive, revisionReason, isRejected };
    this.fs.update(`${this.commonConfig.collectionName.vendors}/${this.vendor['id']}`, dataObject).then(async (res) => {
        this.isSubmit = false;
        /*Send email to vendor owner for profile revise*/
        this.sendVendorStatusEmail(dataObject);
        this.customService.showSnackBarSuccess(this.actionDetail.successMessage);
        this.vendor['isRevised'] = this.isRevised;
        this.vendor['isActive'] = this.isActive;
        this.vendor['status'] = this.actionDetail.key;
        this.vendor['displayStatus'] = this.actionDetail.key;
        this.vendorsService.setUserIsVendor(this.vendor['userInformation']['uid'], !isRejected);
        this.dialogRef.close();
        this.setVendorActivityData();
      }).catch(err => {
        this.customService.showSnackBarError(this.commonConfig.validationMessage.someError);
      });
  }

  /**
   * @description Function used for check is revision request
   */
  isRevisionRequest = () => this.actionDetail.key === this.commonConfig.vendorStatus.revision;

  /**
   * @description Function used for check is disable vendor request
   */
  isDisableRequest = () => this.actionDetail.key !== this.commonConfig.vendorStatus.disable;

  /**
   * @description Function used for check is disable vendor request
   */
  isRequestRejected = () => this.actionDetail.key === CommonConfig.vendorStatus.rejected;

  /**
   * @description function used for send email to vendor owner
   */
  async sendVendorStatusEmail(dataObject) {
    /*Send email to vendor owner for profile revise*/
    if (this.vendor['userInformation'] && this.vendor['userInformation']['email']) {
      const userName = this.vendor['userInformation']['displayName'] ? this.vendor['userInformation']['displayName'] :
        this.vendor['userInformation']['email'];
      const dataForEmail = {
        toEmail: this.vendor['userInformation']['email'],
        userName, revisionReason: dataObject.revisionReason, objectId: this.vendor['id'],
        belongTo: this.commonConfig.belongTo.vendor, title: this.vendor['title'], subject:
        this.actionDetail.subject, templateId: this.actionDetail.templateId
      };
      const commonUtil = new CommonUtils(this.fs);
      const isAllowed = await commonUtil.isEmailAllow(dataForEmail['toEmail'], dataForEmail['belongTo']);
      if (isAllowed) {
        const sendEmail = this.http.post(`${environment.cloudEndPoint}emailSend`, dataForEmail).subscribe(results => {
          sendEmail.unsubscribe();
        });
      }
    }
  }

  /**
   * @description set vendor activity data
   */
  setVendorActivityData() {
    if (this.actionDetail.activityType) {
      const dataForNotification = {
        activityType: this.actionDetail.activityType,
        uid: this.authUser.uid,
        vendorId: this.vendor.id
      };
      this.vendorsService.saveInsideNotificationPayload(dataForNotification, this.vendor);
      if (this.vendor['parentVendorId'] && this.vendor['parentVendorId'] !== '' && this.actionDetail.key === CommonConfig.vendorStatus.published) {
        dataForNotification['vendorId'] = this.vendor['parentVendorId'];
      }
      this.vendorsService.saveVendorActivity(dataForNotification, this.vendor);
    }
  }

  /**
   * @description function used for close dialog popup
   */
  closeDialog () {
    this.resetCurrentStatus();
    this.dialogRef.close();
  }

  /**
   * @discription function used for reset current status
   */
  resetCurrentStatus() {
    if (!this.vendor['isActive']) {
      this.vendor['displayStatus'] = this.commonConfig.vendorStatus.disable;
    } else if (this.vendor['isRevised']) {
      this.vendor['displayStatus'] = this.commonConfig.vendorStatus.revision;
    } else {
      this.vendor['displayStatus'] = this.vendor.status;
    }
  }

}
