import { Component, OnInit, Input, Output, EventEmitter, HostListener, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// Config
import { CommonConfig } from '@shared/common.config';
import { CommonUtils } from '@shared/common.util';
// Model
import { Project } from '@app/core/model/project';
import { User } from '@app/core/model/user';
// Service
import { AuthService } from '@app/core/auth.service';
import { CustomService } from '@shared/service/custom/custom.service';
import { SubscriptionService } from '@shared/service/subscription/subscription.service';
import { ProjectListService } from '@shared/service/projects/project-list.service';

@Component({
  selector: 'app-grid-view',
  templateUrl: './grid-view.component.html',
  styleUrls: [
    '../../../+projects/projects.component.scss',
    '../../../../assets/scss/grid-view.scss'
  ]
})
export class GridViewComponent implements OnInit, OnDestroy {
  @Input() projects;
  @Input() loadingFinished = true;
  @Output() projectEmitter = new EventEmitter<Project>();
  @Input() currentPage: string;
  @Input() isArchive: boolean;
  @Input() userDetail: Partial<User>;
  commonConfig = CommonConfig;
  customId: string;
  uid: string;
  private readonly authSubscription: Subscription;
  starClass = 'grid-star';
  public currentURL: string;
  private readonly ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly auth: AuthService,
    private readonly customService: CustomService,
    private readonly subscriptionService: SubscriptionService,
    public projectListService: ProjectListService,
    ) {
    this.authSubscription = auth.user.subscribe(res => {
      this.uid = res['uid'];
      this.authSubscription.unsubscribe();
    });
    this.currentURL = new CommonUtils().makePrefixURL(this.router.url, 'grid');
    this.getQueryParameter();
  }

  ngOnInit() {
    this.customId = this.customService.guid();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getQueryParameter() {
    this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
      if (params['allowUpgrade']) {
      this.subscriptionService.upgradePlanPopUp(this.commonConfig.popupAlertMessage.upgradePlanForPublicProject);
      }
    });
  }

  trackByProjectFn(index: number) {
    return index;
  }
}
