
import {debounceTime} from 'rxjs/operators';
import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { MatAutocompleteSelectedEvent, MatChipInputEvent, MatAutocompleteTrigger, MatRadioChange } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
// Config
import { CommonConfig } from '@shared/common.config';
import { OrganizationConfig } from '@shared/organization.config';
import { trackEvent, projectTabLabel } from '@shared/common.constants';
// Model
import { Project } from '@app/core/model/project';
import { Vendor } from '@app/core/model/vendor';
import { User } from '@app/core/model/user';
// Service
import { AuthService } from '@app/core/auth.service';
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { AlgoliaService } from '@shared/service/algolia/algolia.service';
import { CustomService } from '@shared/service/custom/custom.service';
import { ProjectsService } from '@shared/service/projects/projects.service';
import { CreateProjectService } from '@shared/service/projects/create-project.service';
import { ProjectEmailService } from '@shared/service/projects/project-email.service';
import { VendorsService } from '@shared/service/vendors/vendors.service';
import { GanalyticsService } from '@shared/service/ganalytics/ganalytics.service';

@Component({
  selector: 'app-select-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.scss']
})
export class SelectUserComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger, {static: false}) autocompleteUser: MatAutocompleteTrigger;
  @ViewChild('selectUser', {static: false}) selectUser: ElementRef;
  commonConfig = CommonConfig;
  valueSearch: string = null;
  suggestData = [];
  categoryCtrl = new FormControl();
  searchInputCtrl = new FormControl();
  page: number;
  nbHits: number;
  nbPages: number;
  userData = {};
  projectId: string;
  projectInfo: Project;
  vendor: Vendor;
  vendorId: string;
  dialogRef;
  currentPage: string;
  state: string;
  isOpen = false;
  heading = 'Create New Project';
  userSelectionType: number = this.commonConfig.steps.one;
  isClaim = false;
  isAddNewProjectMember = false;
  isAddNewVendorMember = false;
  parentObject;
  skipPage = true;
  currentUser: User;
  public organizationConfig = OrganizationConfig;
  public orgId = '';
  public memberStatus = this.organizationConfig.orgMemberStatus.approved;
  constructor(
    private readonly router: Router,
    public auth: AuthService,
    private readonly firestoreService: FirestoreService,
    private readonly _algoliya: AlgoliaService,
    private readonly customService: CustomService,
    private readonly projectsService: ProjectsService,
    private readonly createProjectService: CreateProjectService,
    private readonly projectEmailService: ProjectEmailService,
    private readonly vendorsService: VendorsService,
    private readonly ganalyticsService: GanalyticsService
    ) {

    const authSubscribe = this.auth.user.subscribe(res => {
      this.currentUser = res;
      authSubscribe.unsubscribe();
    });

  }

  ngOnInit() {
    this.setPopupHeading();
    if (!this.projectId) {
      this.projectId = this.projectsService.guid();
    }
    if (this.vendor && this.vendor['customId']) {
      this.vendorId = this.vendor['customId'];
    } else {
      this.vendorId = this.projectsService.guid();
    }
    this.searchInputCtrl.valueChanges.pipe(debounceTime(CommonConfig.delay300)).subscribe((value) => {
      if (value) {
        this.hitAutoSuggest(value);
      } else {
        this.suggestData = [];
      }
    });
  }

  setPopupHeading() {
    if (this.isAddNewProjectMember || this.isAddNewVendorMember) {
      this.heading = 'Add New Member';
    } else if (this.currentPage === this.commonConfig.routerName.createproject && this.state) {
      this.heading = 'Edit Project';
    } else if (this.currentPage === this.commonConfig.routerName.createvendor) {
      this.heading = this.state ? 'Edit Vendor' : 'Create Vendor';
    }
  }

  hitAutoSuggest(keywordValue: string) {
    this.valueSearch = keywordValue;
    const keyword = { keyword: keywordValue, orgId: this.orgId, memberStatus: this.memberStatus };
    this.suggestData = [];
    if (this.isOpen) {
      this._algoliya.getUserAutoSuggest(this, keyword, (err, result) => {
        if (result.hits) {
          this.suggestData = [...this.suggestData, ...result.hits];
        }
        this.page = result.page;
        this.nbHits = result.nbHits;
        this.nbPages = result.nbPages;
      });
    }
    this.isOpen = true;
  }

  openAutoSuggest($event) {
    $event.stopPropagation();
    this.selectUser.nativeElement.focus();
  }

  selectedSuggest(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value) {
      let isUserExists = false;
      if (this.isAddNewProjectMember || this.isAddNewVendorMember) {
        isUserExists = this.checkAlreadyMember(event.option.value['id']);
      }
      if (!isUserExists) {
        this.userData = event.option.value;
        this.userData['uid'] = this.userData['id'];
        this.userData['photoUrl'] = this.userData['profilePhoto'] ? this.userData['profilePhoto'] : '';
      }
    }
  }

  filterData(obj: Object): boolean | undefined {
    if (obj && Object.keys(obj).length !== 0) {
      return obj['title'];
    }
    return;
  }

  removeUser() {
    this.userData = {};
    this.selectUser.nativeElement.focus();
  }

  async continue() {
    this.dialogRef.close();
    if (this.currentPage === this.commonConfig.routerName.createproject) {
      this.navigateToCreateProject();
    } else {
      // check if user have already create request for a vendor profile
      const isVendorProfile = await this.vendorsService.isVendorProfileExists(this.userData['uid']);
      if (isVendorProfile) {
        this.customService.showSnackBarError(CommonConfig.validationMessage.isVendorProfile);
      } else {
        const memberVendorInfo = await this.vendorsService.getVendorByUid(this.userData['uid'], true);
        if (memberVendorInfo['id'] && memberVendorInfo['isActive']) {
          this.customService.showSnackBarError(CommonConfig.validationMessage.isVendorProfile);
        } else {
          if (memberVendorInfo['id']) {
            // remove member from disabled vendor
            this.vendorsService.removeDisabledVendorMember(this.userData['uid'], memberVendorInfo['id']);
          }
          this.navigateToCreateVendor();
        }
      }
    }
  }

  /**
   * @description Function used for navigate to create project
   */
  navigateToCreateProject() {
    const queryParams = { queryParams: { orgId: this.orgId } };
    if (this.state) {
      queryParams['queryParams']['state'] = this.state;
    }
    const navigateRouter = [`/${this.commonConfig.routerName.admin}/${this.commonConfig.routerName.createproject}`, this.projectId, this.userData['uid']];
    if (this.projectInfo && this.projectInfo['uid']) {
      this.projectInfo['actionByUid'] = this.currentUser['uid'] || '';
      this.createProjectService.updateProjectOwner(this.userData, this.projectId, this.projectInfo);
    }
    this.router.navigate(navigateRouter, queryParams);
  }

  /**
   * @description Function used for navigate to create vendor
   */
  navigateToCreateVendor() {
    const queryParams = { queryParams: { state: this.state, orgId: this.orgId } };
    let navigateRouter = [`/${this.commonConfig.routerName.admin}/${this.commonConfig.routerName.createvendor}`, this.vendorId];
    if (this.commonConfig.steps.one === this.userSelectionType) {
      navigateRouter = [`/${this.commonConfig.routerName.admin}/${this.commonConfig.routerName.createvendor}`, this.vendorId, this.userData['uid']];
    }
    if (this.vendor && this.vendor['id']) {
      this.vendor['actionByUid'] = this.currentUser['uid'];
      this.vendorsService.updateVendorProfileOwner(this.userData, this.vendor['id'], this.vendor);
      this.router.navigate(navigateRouter, queryParams);
    } else {
      this.router.navigate(navigateRouter);
    }
  }

  onProfileTypeChange(mrChange: MatRadioChange) {
    this.userSelectionType = mrChange.value;
    if (this.commonConfig.steps.two.toString() === this.userSelectionType.toString()) {
      this.isClaim = true;
      this.userData = {};
    } else {
      this.isClaim = false;
    }
  }

  async addVedorAndProjectMember() {
    this.dialogRef.close();
    if (this.isAddNewProjectMember) {
      this.addMemberInProject();
    } else {
      // check if user have already create request for a vendor profile
      const isVendorProfile = await this.vendorsService.isVendorProfileExists(this.userData['uid']);
      if (isVendorProfile) {
        this.customService.showSnackBarError(CommonConfig.validationMessage.isVendorProfile);
      } else {
        const memberVendorInfo = await this.vendorsService.getVendorByUid(this.userData['uid'], true);
        if (memberVendorInfo['id'] && memberVendorInfo['isActive']) {
          this.customService.showSnackBarError(CommonConfig.validationMessage.isVendorProfile);
        } else {
          if (memberVendorInfo['id']) {
            // remove member from disabled vendor
            this.vendorsService.removeDisabledVendorMember(this.userData['uid'], memberVendorInfo['id']);
          }
          this.addMemberInVendor();
        }
      }
    }
  }

  addMemberInProject() {
    this.vendorsService.vendorProjectAddMember(this.userData['uid'], `${CommonConfig.collectionName.project}/${this.projectId}`).then(res => {
      const data = {
        uid: this.userData['uid'], projectId: this.projectId, role: this.commonConfig.role.member,
        createdBy: this.parentObject.usersInfo['uid']
      };
      this.projectsService.saveProjectMembers(data); // save project member for display participant list
      // save project activity
      const activityData = {
        uid: this.userData['uid'], projectId: this.projectId,
        activityType: CommonConfig.projectActivityType.addmember, createdBy: this.parentObject.usersInfo['uid']
      };
      const dataForNotification = {};
      dataForNotification['uid'] = this.parentObject.usersInfo['uid'];
      dataForNotification['activityType'] = CommonConfig.projectActivityType.addmember;
      dataForNotification['projectId'] = this.projectId;
      dataForNotification['sendToUsers'] = [this.userData['uid']];
      this.projectsService.saveInsideNotificationPayload(dataForNotification, this.projectInfo);
      this.projectsService.saveProjectActivity(activityData);
      this.projectEmailService.sendEmailOnAddAndRemoveProjectMember(this.userData, this.projectId, this.parentObject.projects, true);
      this.customService.showSnackBarSuccess(CommonConfig.successMessage.addProjectMember);
      this.ganalyticsService.analyticsTrackEvent(trackEvent.addProjectMember, this.projectId);
      this.setProjectMemberUid();
      this.projectsService.setPrifileTabIndex(projectTabLabel.participants);
    }).catch((err) => {
      this.customService.showSnackBarError(CommonConfig.validationMessage.someError);
      this.ganalyticsService.analyticsTrackEvent(trackEvent.addProjectMemberError, this.projectId);
    });
  }

  setProjectMemberUid() {
    if (this.projectInfo.members) {
      this.projectInfo.members.push(this.userData['uid']);
    } else {
      this.projectInfo['members'] = [this.userData['uid']];
    }
    if (this.parentObject.headerTabIndex === this.commonConfig.steps.three) {
      this.parentObject.getParticipantInformation();
    }
  }

  addMemberInVendor() {
    this.vendorsService.vendorProjectAddMember(this.userData['uid'], `${CommonConfig.collectionName.vendors}/${this.vendor['id']}`).then(res => {
      this.setVendorMemberUid();
      const data = {
        uid: this.userData['uid'], vendorId: this.vendor['id'], role: this.commonConfig.role.member,
        createdBy: this.parentObject.authUser['uid']
      };
      const dataForNotification = { 'uid': '', 'vendorId': '', 'activityType': '' };
      dataForNotification['uid'] = this.currentUser['uid'] || '';
      dataForNotification['activityType'] = CommonConfig.vendorActivityType.addmembervendorprofile;
      dataForNotification['vendorId'] = this.vendor['id'];
      dataForNotification['sendToUsers'] = [this.userData['uid']];
      this.vendorsService.saveVendorActivity(dataForNotification, this.vendor);
      this.vendorsService.saveInsideNotificationPayload(dataForNotification, this.vendor);
      this.vendorsService.sendEmailOnAddAndRemoveVendorMember(this.userData, this.vendor['id'], this.vendor, true);
      this.vendorsService.saveVendorMembers(data); // save vendor member for display participant list
      this.parentObject.getParticipantInformation();
      this.parentObject.setScrollPosition(CommonConfig.vendorFeatures.participant);
      this.customService.showSnackBarSuccess(CommonConfig.successMessage.addVendorMember);
      this.ganalyticsService.analyticsTrackEvent(trackEvent.addVendorMember);
    }).catch((err) => {
      this.customService.showSnackBarError(CommonConfig.validationMessage.someError);
      this.ganalyticsService.analyticsTrackEvent(trackEvent.addVendorMemberError);
    });
  }

  setVendorMemberUid() {
    if (this.vendor.members) {
      this.vendor.members.push(this.userData['uid']);
    } else {
      this.vendor['members'] = [this.userData['uid']];
    }
  }

  checkAlreadyMember(uid: string) {
    let dataObject = {isOwner: false, userIndex: -1};
    if (this.isAddNewProjectMember) {
      dataObject = this.checkUserProjectMember(uid, dataObject);
    } else {
      dataObject = this.checkUserVendorMember(uid, dataObject);
    }
    if (dataObject.userIndex !== -1) {
      let msg = CommonConfig.validationMessage.alreadyMember;
      if (dataObject.isOwner) {
        msg = CommonConfig.validationMessage.ownerUser;
      }
      this.customService.showSnackBarError(msg);
      return true;
    } else {
      return false;
    }
  }

  /**
   * @description Function used for check user onwer or member of the project
   */
   checkUserProjectMember(uid: string, dataObject) {
      if (uid === this.projectInfo.uid) {
        dataObject.userIndex = 0;
        dataObject.isOwner = true;
      } else if (this.projectInfo.members) {
        dataObject.userIndex = this.projectInfo.members.indexOf(uid);
      }
      return dataObject;
   }

   /**
   * @description Function used for check user onwer or member of the vendor
   */
  checkUserVendorMember(uid: string, dataObject) {
    if (uid === this.vendor.uid) {
      dataObject.userIndex = 0;
      dataObject.isOwner = true;
    } else if (this.vendor.members) {
      dataObject.userIndex = this.vendor.members.indexOf(uid);
    }
    return dataObject;
 }

  trackByFn(index: number) {
    return index;
  }

}
