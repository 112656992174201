import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InstagramService {

  constructor(private http: HttpClient) { }

  getUsersDetail(token: string) {
    return this.http.get(`${environment.instagram.api_url}/users/self/?access_token=${token}`);
  }

  fetchSelfMedia(token: string, maxId?: string) {
    let queryParameter = '';
    if (maxId !== '') {
      queryParameter = '&max_id=' + maxId;
    }
    return this.http.get(`${environment.instagram.api_url}/users/self/media/recent/?access_token=${token}${queryParameter}`);
  }

}
