import { Pipe, PipeTransform } from '@angular/core';
import { CommonConfig } from '../common.config';
import { FirestoreService } from '../service/firestore/firestore.service';

@Pipe({
  name: 'checkUserVerified'
})
export class CheckUserVerified implements PipeTransform {
  commonConfig = CommonConfig;
  constructor(
    public fs: FirestoreService
  ) {

  }
  transform(vendorUid: string) {
    return this.setUserSubscription(vendorUid);
  }

  async setUserSubscription(vendorUid) {
    let isPremiumMember: boolean;
    if (vendorUid) {
      await this.fs.getCollection(CommonConfig.collectionName.users, vendorUid).get().then((users) => {
        if (users.exists) {
          const userInformation = users.data();
          if (userInformation.subscription && userInformation.subscription.plan_id !== this.commonConfig.subscriptionPlanType.basic) {
            isPremiumMember = true;
          } else {
            isPremiumMember = false;
          }
        }
      }).catch();
    } else {
      isPremiumMember = false;
    }
    return isPremiumMember;
  }

}

