import { Injectable } from '@angular/core';
import { FirestoreService } from '@app/shared/service/firestore/firestore.service';
import { CommonConfig } from '@shared/common.config';
import { OrganizationConfig } from '@shared/organization.config';
import { OrgUserPermissions } from '@app/shared/common.constants';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private readonly commonConfig = CommonConfig;
  private readonly organizationConfig = OrganizationConfig;
  public orgUserPermissions = OrgUserPermissions.data;
  private readonly authUserOrganization = new BehaviorSubject({});
  authUserOrganizationData = this.authUserOrganization.asObservable();
  constructor(private readonly fs: FirestoreService) { }

  /**
   * @description Function used for set auth/login user organization data
   * @param {object} data contains the organization member data
   */
  setAuthUserOrganizationData(data) {
    this.authUserOrganization.next(data);
  }

  /**
   * @description Function used for get organization data by org id
   * @param {string} orgId contains the organization id value
   */
  getOrganizationDetail(orgId: string) {
    return this.fs.doc$(`${this.commonConfig.collectionName.organizations}/${orgId}`);
  }

  /**
   * @description Function used for get organization data by org id
   * @param {string} orgId contains the organization id value
   */
  getOrgDetailByOrgId(orgId: string) {
    return new Promise((resolve) => {
      const orgDetail = this.fs.doc$(`${this.commonConfig.collectionName.organizations}/${orgId}`).subscribe((orgData) => {
          resolve(orgData);
          orgDetail.unsubscribe();
      });
    });
  }

  /**
   * @description Function used for get organization member data
   * @param {string} uid contains the user id value
   */
  getOrganizationMember(uid: string) {
    return this.fs.colWithIds$(this.commonConfig.collectionName.organizationMembers, (ref) => ref.where('uid', '==', uid)
      .where('isActive', '==', true).where('isSoftDelete', '==', false));
  }

  /**
   * @description Function used for get organization member data
   * @param {string} email contains the email
   */
  getOrganizationMemberByEmail(email: string) {
    return this.fs.colWithIds$(this.commonConfig.collectionName.organizationMembers, (ref) => ref.where('email', '==', email)
      .where('isSoftDelete', '==', false));
  }

  /**
   * @description Function used for get approved organization member data
   * @param {string} uid contains the user id value
   */
  getApprovedOrganizationMember(uid: string) {
    return this.fs.colWithIds$(this.commonConfig.collectionName.organizationMembers, (ref) => ref.where('uid', '==', uid)
      .where('isActive', '==', true).where('isSoftDelete', '==', false).where('status', '==', this.organizationConfig.orgMemberStatus.approved));
  }

  /**
   * @description Function used for create organization member records
   */
  createOrganizationMember(orgObj: Object) {
    const defaultData = {
      isActive: true,
      isSoftDelete: false,
      isHardDelete: false,
      ...orgObj
    };
    return this.fs.add(`${CommonConfig.collectionName.organizationMembers}`, defaultData);
  }

  /**
   * @description Function used to update organization details associated with users in user collection
   * @param data contains data of organization member.
   */
  updateOrgInUser(data) {
    const orgObj = {
      id: data.organization.id,
      name: data.organization.name,
      status: data.status,
      role: data.role,
      request: data.request
    };
    this.updateOrganizationInUser(data.uid, orgObj);
  }

  /**
   * @description Function used to update organization data in user
   * @param {string} uid user id
   * @param {Object} orgObj containe organization id and name
   */
  updateOrganizationInUser(uid: string, orgObj: Object) {
    this.fs.update(`${CommonConfig.collectionName.users}/${uid}`, { organization: orgObj }).then().catch();
  }

  /**
   * @description Function used for check is org already member of the project
   * @param {string} uid user id
   */
  isAlreadyOrgMember(uid: string) {
    return new Promise<boolean>((resovle) => {
      const orgMember = this.getOrganizationMember(uid).subscribe(orgResult => {
        orgMember.unsubscribe();
        resovle(!!orgResult.length);
      });
    });
  }

  /**
   * @description Function used for check is org already member of the project
   * @param {string} uid user id
   */
  checkEmailInOrgMembers(email: string) {
    return new Promise<boolean>((resovle) => {
      const orgMember = this.getOrganizationMemberByEmail(email).subscribe(orgRes => {
        orgMember.unsubscribe();
        resovle(!!orgRes.length);
      });
    });
  }

  /**
   * @description Function used for get organization data by org id and uid
   * @param {string} orgId contains the organization id value
   */
  getOrgMemberDetailByUidAndOrgid(orgId: string, uid: string) {
    return new Promise((resolve) => {
      const orgMemberSubscribe = this.fs.colWithIds$(this.commonConfig.collectionName.organizationMembers, (ref) => ref.where('uid', '==', uid).where('orgId', '==', orgId)
        .where('isActive', '==', true).where('isSoftDelete', '==', false).where('status', '==', this.organizationConfig.orgMemberStatus.approved)).subscribe((member) => {
          if (member.length) {
            resolve(member[0]);
          } else {
            resolve({});
          }
          orgMemberSubscribe.unsubscribe();
        });
    });
  }

  /**
   * @description Function used to get organization members by org id.
   * @param {string} orgId contains the organization id.
   */
  getOrganizationMembersByOrgId(orgId) {
    return new Promise(async (resolve) => {
      const orgMembersRef = this.fs.fetchCollection(CommonConfig.collectionName.organizationMembers).ref.where('orgId', '==', orgId)
      .where('isActive', '==', true)
      .where('isSoftDelete', '==', false)
      .where('status', '==', 'approved')
      .get();
      orgMembersRef.then((snapshot) => {
        const docs = snapshot.docs;
        if (docs.length) {
          resolve(docs.map(result => {
            return result.data();
          }));
        } else {
          resolve([]);
        }
      });
    });
  }

  /**
   * @description function used for approve join organizaiton request
   * @param {string} uid contains the user id
   * @param {string} orgId contains the organization id
   */
   approveJoinOrganizationRequest(uid, orgId, authUid) {
    return new Promise(async (resolve) => {
      this.fs.fetchCollection(CommonConfig.collectionName.organizationMembers).ref.where('uid', '==', uid)
      .where('orgId', '==', orgId).where('isActive', '==', true).where('isSoftDelete', '==', false).get().then((snap) => {
          snap.forEach((doc) => {
              doc.ref.update({ status: this.organizationConfig.orgMemberStatus.approved,
                 request: this.organizationConfig.orgMemberRequest.joined,
                 actionBy: authUid});
          });
          resolve(true);
      })
      .catch((err) => {
        resolve(false);
      });
    });
   }

   /**
   * @description function used for approve and reject leave organizaiton request
   * @param {string} uid contains the user id
   * @param {string} data contains the updated information object
   */
   approveRejectLeaveOrganizationRequest(uid, orgId, data) {
      return new Promise(async (resolve) => {
        this.fs.fetchCollection(CommonConfig.collectionName.organizationMembers).ref.where('uid', '==', uid)
        .where('orgId', '==', orgId).where('isActive', '==', true).where('isSoftDelete', '==', false).get().then((snap) => {
            snap.forEach((doc) => {
                doc.ref.update(data);
            });
            resolve(true);
        })
        .catch((err) => {
          resolve(false);
        });
      });
   }

   /**
   * @description function used for reject join organizaiton request
   * @param {string} uid contains the user id
   * @param {string} orgId contains the organization id
   */
  rejectJoinOrganizationRequest(uid, orgId, authUid) {
    return new Promise(async (resolve) => {
      this.fs.fetchCollection(CommonConfig.collectionName.organizationMembers).ref.where('uid', '==', uid)
      .where('orgId', '==', orgId).where('isActive', '==', true).where('isSoftDelete', '==', false).get().then((snap) => {
          snap.forEach((doc) => {
              doc.ref.update({ isActive: false, status: this.organizationConfig.orgMemberStatus.rejected,
                actionBy: authUid });
          });
          resolve(true);
      })
      .catch((err) => {
        resolve(false);
      });
    });
   }

  /**
   * @description Function used for check is org already member of the project
   * @param {object} member contain org member data object
   */
  isOrgAdmin = (member) => member && member.role && (member.role === this.organizationConfig.roles.admin.key || member.role === this.organizationConfig.roles.superAdmin.key);

  /**
   * @description Function used for check, is the user org super admin
   * @param {object} member contain org member data object
   */
  isOrgSuperAdmin = (member) => member && member.role && (member.role === this.organizationConfig.roles.superAdmin.key);

  /**
   * @description Function used for check, is the user org admin user
   * @param {object} member contain org member data object
   */
  isOrgAdminMember = (member) => member && member.role && (member.role === this.organizationConfig.roles.admin.key);

  /**
   * @description Function used for check org admin section permission
   * @param {object} member contain org member data object
   * @param {string} type contain org permission
   */
  orgAdminPermissions = (member, type: string) => member.permissions && member.permissions.includes(type);


  /**
   * @description Function used for redirect org admin panel link
   * @param {object} member contain org member data object
   */
  getOrgAdminRouterName(member) {
    let routerName = '';
    if (this.isProjectPermissions(member)) {
      routerName = this.commonConfig.routerName.manageprojects;
    } else if (this.isUserPermissions(member)) {
      routerName = this.commonConfig.routerName.manageusers;
    } else if (this.isVendorPermissions(member)) {
      routerName = this.commonConfig.routerName.managevendors;
    } else if (this.isBidsPermissions(member)) {
      routerName = this.commonConfig.routerName.managebids;
    }
    return routerName;
  }

  /**
   * @description Function used for check project permission access or not
   * @param {object} member contain org member data object
   */
  isProjectPermissions = (member) => this.isOrgSuperAdmin(member) || (this.isOrgAdminMember(member) && this.orgAdminPermissions(member, this.orgUserPermissions[1].value));

  /**
   * @description Function used for check vendor permission access or not
   * @param {object} member contain org member data object
   */
  isVendorPermissions = (member) => this.isOrgSuperAdmin(member) || (this.isOrgAdminMember(member) && this.orgAdminPermissions(member, this.orgUserPermissions[0].value));

  /**
   * @description Function used for check bids permission access or not
   * @param {object} member contain org member data object
   */
  isBidsPermissions = (member) =>
    this.isOrgSuperAdmin(member) || (this.isOrgAdminMember(member) && this.orgAdminPermissions(member, this.orgUserPermissions[this.commonConfig.two].value))

  /**
   * @description Function used for check user permission access or not
   * @param {object} member contain org member data object
   */
  isUserPermissions = (member) =>
    this.isOrgSuperAdmin(member) || (this.isOrgAdminMember(member) && this.orgAdminPermissions(member, this.orgUserPermissions[this.commonConfig.three].value))


}
