import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
// Model
import { User } from '@app/core/model/user';
// Service
import { AuthService } from '@app/core/auth.service';
import { ProjectsService } from '@shared/service/projects/projects.service';
import { DataStorageService } from '@shared/service/storage/data-storage.service';
@Component({
  selector: 'app-projectsnav',
  templateUrl: './projectsnav.component.html'
})
export class ProjectsnavComponent implements OnInit, OnDestroy {
  @Input() media;
  @Input() commonConfig;
  @Input() currentPage: string;
  @Input() userDetail;
  customId: string;
  isAdmin: boolean;
  adminSubscription: Subscription;

  constructor(
    public auth: AuthService,
    public projectsService: ProjectsService,
    public storage: DataStorageService
    ) { }

  ngOnInit() {
    this.customId = this.projectsService.guid();
    this.adminSubscription = this.storage.adminPanelStatus.subscribe(status => {
      if (status) {
        this.isAdmin = status.isAdmin;
      }
    });
  }

  ngOnDestroy() {
    if (this.adminSubscription) {
      this.adminSubscription.unsubscribe();
    }
  }

  navigateToProject() {
  }

}
