import {
  Directive,
  Input,
  Output,
  EventEmitter,
  HostListener,
  OnInit,
  OnDestroy
} from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument
} from 'angularfire2/firestore';
import { FormControl, FormGroup } from '@angular/forms';
import { tap, map, take, debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
// Config
import { CommonConfig } from '@shared/common.config';
// Service
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { CustomService } from '@shared/service/custom/custom.service';
import { TimestampService } from '@shared/service/timestamp/timestamp.service';
import { ChooseProjectFeatureService } from '@shared/service/projects/choose-project-feature.service';

@Directive({
  selector: '[appFireForm]'
})
export class FireFormDirective implements OnInit, OnDestroy {

  @Input() path: string;
  @Input() formGroup: FormGroup;
  @Input() userDetail;
  lastChar: string;
  isCreate = true;

  private _state: 'loading' | 'synced' | 'modified' | 'error';

  @Output() stateChange = new EventEmitter<string>();
  @Output() formError = new EventEmitter<string>();
  @Output() afterDataSync = new EventEmitter<string>();
  @Output() beforeDataSync = new EventEmitter<string>();

  // Firestore Document
  private docRef: AngularFirestoreDocument<Object>;

  // Subscriptions
  private formSub: Subscription;

  constructor(
    private readonly afs: AngularFirestore,
    public firestoreService: FirestoreService,
    private readonly customService: CustomService,
    private readonly timeStampService: TimestampService,
    private readonly chooseProjectFeatureService: ChooseProjectFeatureService,
  ) { }

  ngOnInit() {
    this.lastChar = this.path.slice(-1);
    if (this.lastChar === '/') {
      this.path = this.path.slice(0, -1);
    }
    this.preloadData();
    this.autoSave();
  }

  /**
   * @description update date formate
   */
  updateProjectDateFormate() {
    if (this.formGroup.value.bidStartAt && this.formGroup.value.bidStartAt.hasOwnProperty('_d')) {
      this.formGroup.value.bidStartAt = this.formGroup.value.bidStartAt._d;
    }
    if (this.formGroup.value.bidEndAt && this.formGroup.value.bidEndAt.hasOwnProperty('_d')) {
      this.formGroup.value.bidEndAt = this.formGroup.value.bidEndAt._d;
    }
    if (this.formGroup.value.projectStartAt && this.formGroup.value.projectStartAt.hasOwnProperty('_d')) {
      this.formGroup.value.projectStartAt = this.formGroup.value.projectStartAt._d;
    }
    if (this.formGroup.value.expiresAt && this.formGroup.value.expiresAt.hasOwnProperty('_d')) {
      this.formGroup.value.expiresAt = this.formGroup.value.expiresAt._d;
    }
  }

  // Loads initial form data from Firestore
  preloadData() {
    this.beforeDataSync.emit();
    this.state = 'loading';
    this.docRef = this.getDocRef(this.path);
    this.docRef
      .valueChanges()
      .pipe(
        tap(doc => {
          if (doc) {
            if (doc['bidStartAt']) {
              doc['bidStartAt'] = doc['bidStartAt'].toDate();
            }
            if (doc['bidEndAt']) {
              doc['bidEndAt'] = doc['bidEndAt'].toDate();
            }
            if (doc['projectStartAt']) {
              doc['projectStartAt'] = doc['projectStartAt'].toDate();
            }
            if (doc['expiresAt']) {
              doc['expiresAt'] = doc['expiresAt'].toDate();
            }
            this.formGroup.patchValue(doc);
            this.formGroup.markAsPristine();
            this.afterDataSync.emit();
            this.state = 'synced';
          }
        }),
        take(1)
      )
      .subscribe();
  }

  // Autosaves form changes
  autoSave() {
    this.formSub = this.formGroup.valueChanges
      .pipe(
        tap(change => {
          this.updateProjectDateFormate();
          this.state = 'modified';
        }),
        debounceTime(CommonConfig.delay100),
        tap(change => {
          if (this.formGroup.valid && this._state === 'modified') {
            if (this.formGroup.value.questionAnswers && this.formGroup.value.questionAnswers.length > 0) {
              this.chooseProjectFeatureService.questionAnswersUpdate(true);
            } else {
              this.chooseProjectFeatureService.questionAnswersUpdate(false);
            }
            this.setDoc();
          }
        })
      )
      .subscribe();
  }

  @HostListener('ngSubmit', ['$event'])
  onSubmit(e: string) {
    if (this.formGroup.valid) {
      this.setDoc();
    }
  }


  // Determines if path is a collection or document
  getDocRef(path: string) {
    if (path.split('/').length % CommonConfig.two) {
      this.isCreate = true;
      return this.afs.doc(`${path}/${this.afs.createId()}`);
    } else {
      this.isCreate = false;
      return this.afs.doc(path);
    }
  }

  // Writes changes to Firestore
  async setDoc() {
    try {
      this.updateProjectDateFormate();
      if (this.isCreate) {
        const projectCreation = this.customService.projectCreation(this.userDetail);
        if (this.customService.checkProjectCreation(this.userDetail, projectCreation)) {
          this.formGroup.value['createdAt'] = this.timeStampService.getTimestamp;
          this.formGroup.value['updatedAt'] = this.timeStampService.getTimestamp;
          await this.docRef.set(this.formGroup.value, { merge: true });
          await this.firestoreService.update(`${CommonConfig.collectionName.users}/${this.userDetail.uid}`, { projectCreation });
        }
      } else {
        await this.firestoreService.update(this.docRef, this.formGroup.value);
      }
      this.state = 'synced';
    } catch (err) {
      this.formError.emit(err.message);
      this.state = 'error';
    }
  }

  // Setter for state changes
  set state(val: 'loading' | 'synced' | 'modified' | 'error') {
    this._state = val;
    this.stateChange.emit(val);
  }

  ngOnDestroy() {
    this.formSub.unsubscribe();
  }

}
