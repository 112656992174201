import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// Config
import { CommonConfig } from '@shared/common.config';
import { trackEvent } from '@shared/common.constants';
// Service
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { CustomService } from '@shared/service/custom/custom.service';
import { VendorsService } from '@shared/service/vendors/vendors.service';
import { ProductService } from '@app/core/model/product-service';
import { GanalyticsService } from '@app/shared/service/ganalytics/ganalytics.service';

@Component({
  selector: 'app-create-services',
  templateUrl: './create-services.component.html',
  styleUrls: ['./../products-services.component.scss']
})
export class CreateServicesComponent implements OnInit {
  service: ProductService;
  vendorId: string;
  uid: string;
  commonConfig = CommonConfig;
  dialogRef;
  productsServicesObj;
  isSubmit = false;
  isDashboard = false;
  constructor(
    private readonly router: Router,
    private readonly firestoreService: FirestoreService,
    private readonly customService: CustomService,
    private readonly vendorsService: VendorsService,
    private readonly ganalyticsService: GanalyticsService
  ) { }

  ngOnInit() {
  }

  submit(formValue) {
    if (this.service && this.service['id']) {
      this.isSubmit = true;
      this.firestoreService.update(`${this.commonConfig.collectionName.services}/${this.service['id']}`,
      formValue).then(
          response => {
            for (let i = 0; i < this.productsServicesObj.productsServices.length; i++) {
              if (this.productsServicesObj.productsServices[i]['id'] === this.service['id']) {
                formValue['id'] = this.service['id'];
                this.productsServicesObj.productsServices[i] = formValue;
              }
            }
            this.isSubmit = false;
            this.customService.showSnackBarSuccess(this.commonConfig.successMessage.updateService);
            this.ganalyticsService.analyticsTrackEvent(trackEvent.updateService);
            this.dialogRef.close();
            this.vendorsService.saveProductActivity(this.vendorId, this.uid, this.commonConfig.vendorActivityType.updateservice);
          }
        ).catch(
          error => {
            this.isSubmit = false;
            this.customService.showSnackBarError(this.commonConfig.validationMessage.someError);
            this.ganalyticsService.analyticsTrackEvent(trackEvent.updateServiceError);
          }
        );
    } else {
      this.isSubmit = true;
      this.firestoreService.add(this.commonConfig.collectionName.services, formValue).then(
        response => {
          this.firestoreServiceSuccess(formValue, response);
        }
      ).catch(
        error => {
          this.isSubmit = false;
          this.customService.showSnackBarError(this.commonConfig.validationMessage.someError);
          this.ganalyticsService.analyticsTrackEvent(trackEvent.addServiceError);
        }
      );
    }
  }

  firestoreServiceSuccess(formValue, response) {
    formValue['id'] = response['id'];
    if (this.productsServicesObj.productsServices) {
      this.productsServicesObj.productsServices.unshift(formValue);
    }
    this.isSubmit = false;
    this.customService.showSnackBarSuccess(this.commonConfig.successMessage.addService);
    this.ganalyticsService.analyticsTrackEvent(trackEvent.addService);
    this.dialogRef.close();
    this.vendorsService.saveProductActivity(this.vendorId, this.uid, this.commonConfig.vendorActivityType.addservice);
    if (this.isDashboard) {
      this.router.navigate([`/${this.commonConfig.routerName.vendors}${this.commonConfig.routerName.vendorfeature}`, this.commonConfig.vendorFeatures.services,
      this.vendorId, this.commonConfig.vendorFeatureView.list]);
    }
  }

}
