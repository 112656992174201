import { Component } from '@angular/core';
import { MatSnackBarRef } from '@angular/material';

import { CommonConfig } from '../common.config';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom.snackbar.component.html'
})
export class CustomSnackBarComponent {
  messageText: string;
  messageType: string;
  label: string;
  color = 'red';
  icon = 'error';
  constructor(private snackBarRef: MatSnackBarRef<CustomSnackBarComponent>) {
    this.messageText = snackBarRef.containerInstance.snackBarConfig.data.messageText;
    this.messageType = snackBarRef.containerInstance.snackBarConfig.data.messageType;
    if (CommonConfig.typeSuccess === this.messageType) {
      this.color = 'green';
      this.icon = 'done';
      this.label = 'Success!';
    } else {
      this.label = 'Error!';
    }
  }
  close() {
    this.snackBarRef.dismiss();
  }
}
