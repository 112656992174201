import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TdLoadingService } from '@covalent/core/loading';
// Config
import { CommonConfig } from '@shared/common.config';
import { trackEvent } from '@shared/common.constants';
// Model
import { User } from '@app/core/model/user';
// Service
import { AuthService } from '@app/core/auth.service';
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { CustomService } from '@shared/service/custom/custom.service';
import { UserActivityService } from '@shared/service/user-activity/user-activity.service';
import { ChargebeeService } from '@shared/service/chargebee/chargebee.service';
import { GanalyticsService } from '@shared/service/ganalytics/ganalytics.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly ngUnsubscribe = new Subject();
  usersInfo: Partial<User>;

  constructor(
    private readonly router: Router,
    private readonly _loadingService: TdLoadingService,
    private readonly auth: AuthService,
    private readonly firestoreService: FirestoreService,
    private readonly customService: CustomService,
    private readonly userActivityService: UserActivityService,
    private readonly chargebeeService: ChargebeeService,
    private readonly ganalyticsService: GanalyticsService
  ) { }

  selectSubscription(email, parentObj) {
    this._loadingService.register();
    const createSubscriptioPayload = {
      customer: {
        first_name: '',
        email,
        company: ''
      },
      subscription: {
        plan_id: CommonConfig.subscriptionPlanType.premium
      }
    };
    this.chargebeeService.checkoutNewSubscription(createSubscriptioPayload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      const responseData = response['data'];
      const hostedPageData = responseData.hosted_page;
      this.chargebeeService.openCheckoutPopup(hostedPageData, parentObj);
      this.ganalyticsService.analyticsTrackEvent(trackEvent.selectSubscription);
    }, error => {
      this._loadingService.resolve();
      this.customService.showSnackBarError(CommonConfig.validationMessage.someError);
    });
  }

  async createUserSubscription(isSubmitSubscription, subscriptionDetail, premiumProjectId, premiumVendorId, formValue, userSubscriptionData, chargebeeIns) {
    this.firestoreService.add(`${CommonConfig.collectionName.users}`, userSubscriptionData).then(
      userAddResponse => {
        this.firestoreService.update(`${CommonConfig.collectionName.users}/${userAddResponse.id}`, { uid: userAddResponse.id });
        this.premiumLogin(isSubmitSubscription, subscriptionDetail, premiumProjectId, premiumVendorId, formValue, chargebeeIns);
      }
    ).catch(
      error => {
        this.showCommonSubscriptionError();
      }
    );
  }

  premiumLogin(isSubmitSubscription, subscriptionDetail, premiumProjectId, premiumVendorId, formValue, chargebeeIns) {
    this.auth.emailLogin(formValue['email'], formValue['password'], true)
      .then(async credential => {
        if (credential && credential.user.emailVerified === false) {
          this.customService.showSnackBarError(CommonConfig.validationMessage.emailUnverified);
          this._loadingService.resolve();
          this.router.navigate([`/${CommonConfig.routerName.login}`]);
        } else {
          this.updateUser(credential, isSubmitSubscription, subscriptionDetail, premiumProjectId, premiumVendorId, formValue, chargebeeIns);
          await this.auth.setDefaultKeyForUser(credential.user);
          this.ganalyticsService.analyticsTrackEvent(trackEvent.login);
        }
      })
      .catch(error => {
        if (error.code === 'auth/user-disabled') {
          this.customService.showSnackBarError(CommonConfig.validationMessage.userBlocked);
        } else {
          this.customService.showSnackBarSuccess(CommonConfig.successMessage.accountCreated);
        }
        this.ganalyticsService.analyticsTrackEvent(trackEvent.loginError);
        this._loadingService.resolve();
        this.router.navigate([`/${CommonConfig.routerName.login}`]);
      });
  }

  async updateUser(credential, isSubmitSubscription, subscriptionDetail, premiumProjectId, premiumVendorId, formValue, chargebeeIns) {
    const userSubscriptionData = {
      email: formValue['email'],
      premiumProjectId: '',
      premiumVendorId: premiumVendorId ? premiumVendorId : '',
      userHashKey: this.userActivityService.getHashKey(credential.user.uid),
      subscription: subscriptionDetail['subscription'],
      companyName: subscriptionDetail['companyName'] || '',
      phone: subscriptionDetail['phone'] || '',
      updatedAt: new Date()
    };
    this.updateUserSubscription(isSubmitSubscription, credential.user.uid, premiumProjectId, premiumVendorId, userSubscriptionData, chargebeeIns);
  }

  async updateUserSubscription(isSubmitSubscription, userId, premiumProjectId, premiumVendorId, userSubscriptionData, chargebeeIns) {
    this.firestoreService.update(`${CommonConfig.collectionName.users}/${userId}`, userSubscriptionData).then(
      response => {
        this.manageUserSubscriptionData(isSubmitSubscription, premiumProjectId, premiumVendorId, chargebeeIns);
      }
    ).catch(
      error => {
        this.showCommonSubscriptionError();
      }
    );
  }

  async manageUserSubscriptionData(isSubmitSubscription, premiumProjectId, premiumVendorId, chargebeeIns) {
    this.usersInfo = await this.auth.currentUser();
    const dataObject = { uid: this.usersInfo['uid'], activityType: '' };
    dataObject['ownerId'] = this.usersInfo['uid'];
    dataObject['activityType'] = isSubmitSubscription ? CommonConfig.userActivityType.subscriptionAdded :
      CommonConfig.userActivityType.profileCompleted;
    this.userActivityService.saveUserAccountActivity(dataObject);
    if (isSubmitSubscription) {
      chargebeeIns.closeAll();
      if (premiumVendorId) {
        this.router.navigate([`/${CommonConfig.routerName.profile}/${CommonConfig.routerName.completeprofile}`]);
      } else {
        this.router.navigate(
          [`${CommonConfig.routerName.projects}/${CommonConfig.routerName.browse_project}/${CommonConfig.routerName.projectdetail}`, premiumProjectId],
          { queryParams: { from: 'projects' } }
        );
      }
      this.customService.showSnackBarSuccess(CommonConfig.successMessage.subscriptionSuccess);
    }
    this.ganalyticsService.gaIdentifyRegisterUser(this.usersInfo);
    this.ganalyticsService.analyticsTrackEvent(trackEvent.upgradeSubscription);
    this._loadingService.resolve();
  }

  showCommonSubscriptionError() {
    this.customService.showSnackBarError(CommonConfig.validationMessage.someError);
    this.ganalyticsService.analyticsTrackEvent(trackEvent.upgradeSubscriptionError);
    this._loadingService.resolve();
  }


}
