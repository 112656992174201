import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Vendor } from '@app/core/model/vendor';
import { FirestoreService } from '@app/shared/service/firestore/firestore.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommonConfig } from '@app/shared/common.config';
import { TdDialogService } from '@covalent/core/dialogs';
import { CustomService } from '@app/shared/service/custom/custom.service';
import { TimestampService } from '@shared/service/timestamp/timestamp.service';
import { VendorMember } from '@app/core/model/vendor-member';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { User } from '@app/core/model/user';
import { CommonEmail } from '@app/core/model/common-email';
import { MessagingService } from '@app/shared/service/messaging/messaging.service';
import { AuthService } from '@app/core/auth.service';
import { HttpService } from '@shared/service/http/http.service';
import { UserActivity } from '@app/core/model/user-activity';

import { takeUntil } from 'rxjs/operators';
import { CommonUtils } from '@shared/common.util';
import { ControlContainer } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class VendorsService {
  private readonly commonConfig = CommonConfig;
  private readonly destroyed$ = new Subject();
  private readonly vendorProjects = new BehaviorSubject({});
  vendorProjectStatus = this.vendorProjects.asObservable();

  private readonly testimonials = new BehaviorSubject({});
  testimonialsStatus = this.testimonials.asObservable();

  private readonly licenses = new BehaviorSubject({});
  licensesStatus = this.licenses.asObservable();

  private readonly instagram = new BehaviorSubject({});
  instagramStatus = this.instagram.asObservable();

  private readonly instagramFeeds = new BehaviorSubject({});
  instagramTotalFeeds = this.instagramFeeds.asObservable();

  constructor(private readonly _dialogService: TdDialogService, private readonly fs: FirestoreService, private readonly customService: CustomService,
    private readonly http: HttpClient, private readonly router: Router, private readonly timeStampService: TimestampService,
    private readonly messagingService: MessagingService, public auth: AuthService,
    private readonly httpService: HttpService) { }

  vendorProjectsStatusUpdate(status: boolean) {
    this.vendorProjects.next({ isComplete: status });
  }

  testimonialsStatusUpdate(status: boolean) {
    this.testimonials.next({ isComplete: status });
  }

  licensesStatusUpdate(status: boolean) {
    this.licenses.next({ isComplete: status });
  }

  instagramStatusUpdate(status: boolean) {
    this.instagram.next({ isComplete: status });
  }

  updateInstagramFeeds(totalLength: number) {
    this.instagramFeeds.next(totalLength);
  }
  async updateVendorStatus(statusType: string, vendor: Partial<Vendor>, vendorList?: Vendor[]) {
    const vendorProfileId = this.getParentVendorProfileId(vendor);
    const vendorMainProfile: Partial<Vendor> = await this.getVendorDataById(vendorProfileId);
    if (this.isVendorExistsOrAssigned(vendorMainProfile, statusType)) {
      let alertMessage = '';
      let successMessage = '';
      let title = '';
      let templateId = '';
      let subject = '';
      let isRejected = false;
      if (statusType === CommonConfig.vendorStatus.published) {
        alertMessage = CommonConfig.popupAlertMessage.vendorPublished;
        successMessage = CommonConfig.successMessage.vendorAccepted;
        title = 'Accept Vendor';
        templateId = CommonConfig.emailTemplates.vendorAccept;
        subject = CommonConfig.emailSubject.vendorAccept;
      } else {
        alertMessage = CommonConfig.popupAlertMessage.vendorRejected;
        successMessage = CommonConfig.successMessage.vendorRejected;
        title = 'Reject Vendor';
        templateId = CommonConfig.emailTemplates.vendorReject;
        subject = CommonConfig.emailSubject.vendorReject;
        isRejected = true;
      }
      this._dialogService.openConfirm({
        message: alertMessage,
        title,
        acceptButton: CommonConfig.dialogService.accept,
        cancelButton: CommonConfig.dialogService.cancel
      }).afterClosed().subscribe(async (accept: boolean) => {
        if (accept) {
          /* if admin accept a claim vendor profile request then assigned unclaim profile to particular user*/
          const isClaimed = await this.acceptClaimVendorProfileRequest(statusType, vendor);
          if (isClaimed) {
            const dataObject = this.getDataForUpdateVendorStatus(vendor, statusType, isRejected);
            this.fs.update(`${CommonConfig.collectionName.vendors}/${vendor['id']}`,
              dataObject).then(async (res) => {
                this.updateUserVendorProfileStatus(vendor, statusType);
                this.customService.showSnackBarSuccess(successMessage);
                this.saveInNotificationList(statusType, vendor);
                vendor['status'] = statusType;
                vendor = this.updateCurrentVendorStatus(vendor, vendorMainProfile, statusType, vendorList);
                this.sendEmailToVendorApproved(vendor, statusType, subject, templateId);
              }).catch(err => {
                this.customService.showSnackBarError(CommonConfig.validationMessage.someError);
              });
          }
        }
      });
    }
  }

  /**
   * @description Function used for get main vendor profile id
   * @param {object} vendorMainProfile contain vendor profile data
   * @param {string} statusType contain vendor status type
   */
  isVendorExistsOrAssigned = (vendorMainProfile, statusType): boolean | undefined => {
    if (!vendorMainProfile) {
      this.customService.showSnackBarError(CommonConfig.validationMessage.someError);
    } else if (this.isVendorMainProfilePublished(vendorMainProfile, statusType)) {
      this.customService.showSnackBarError(CommonConfig.validationMessage.vendorAlreadyAssigned);
    } else {
      return true;
    }
  }

  /**
   * @description Function used for get main vendor profile id
   * @param {object} vendor contain vendor profile data
   */
  getParentVendorProfileId = (vendor: Partial<Vendor>) => {
    return vendor['parentVendorId'] ? vendor['parentVendorId'] : vendor['id'];
  }

  /**
   * @description Function used for check main vendor profile published or not
   * @param {object} vendorMainProfile contain vendor profile data
   * @param {string} statusType contain vendor status
   */
  isVendorMainProfilePublished = (vendorMainProfile: Partial<Vendor>, statusType: string) => {
    return (vendorMainProfile && vendorMainProfile['uid'] && vendorMainProfile['uid'] !== '' && vendorMainProfile['status']
      === CommonConfig.vendorStatus.published && statusType === CommonConfig.vendorStatus.published);
  }

  /**
   * @description Function used for create data object for update vendor status
   * @param {object} vendor contain data object
   * @param {string} statusType contain vendor status
   * @param {boolean} isRejected contain vendor status
   */
  getDataForUpdateVendorStatus = (vendor: Partial<Vendor>, statusType: string, isRejected: boolean) => {
    const dataObject = { status: statusType, isRejected, isDeleted: false };
    // remove temp profile after assigned orignal profile to user
    if (vendor['parentVendorId'] && vendor['parentVendorId'] !== '' && statusType === CommonConfig.vendorStatus.published) {
      dataObject['isDeleted'] = true;
      dataObject['isRejected'] = true;
      dataObject['status'] = CommonConfig.vendorStatus.rejected;
    }
    return dataObject;
  }

  /**
   * @description Function used for update user vendor status
   * @param {object} vendor contain data object
   * @param {object} vendorMainProfile contain vendor status
   * @param {string} statusType contain vendor status type
   * @param {array} vendorList contain value of all vendor list data
   */
  updateCurrentVendorStatus = (vendor: Partial<Vendor>, vendorMainProfile: Partial<Vendor>, statusType: string, vendorList: Partial<Vendor[]>) => {
    // after successfull accept then update current vendor id
    if (statusType === CommonConfig.vendorStatus.published) {
      vendor['title'] = vendorMainProfile['name'];
      vendor['profileType'] = CommonConfig.vendorProfileType.claim;
      if (vendor['parentVendorId'] && vendorList && vendorList.length > 0) {
        const index = vendorList.findIndex(data => data.id === vendor['parentVendorId']);
        vendorList.splice(index, 1);
        vendor['id'] = vendor['parentVendorId'];
        this.rejectPendingRequestAfterOneAccept(vendor, vendorList);
      }
    }
    return vendor;
  }

  /**
   * @description Function used for update user vendor status
   * @param {object} vendor contain data object
   * @param {string} statusType contain vendor status
   */
  updateUserVendorProfileStatus = (vendor: Partial<Vendor>, statusType: string) => {
    this.setUserIsVendor(vendor['userInformation']['uid'], statusType !== CommonConfig.vendorStatus.rejected);
  }

  /**
   * @description Function used for send email to vendor on published
   * @param {object} vendor contain data object
   * @param {string} statusType contain vendor status
   * @param {string} subject contain email subject
   * @param {string} templateId contain sendgrid template id
   */
  sendEmailToVendorApproved = async (vendor: Partial<Vendor>, statusType: string, subject: string, templateId: string) => {
    /*Send email to vendor user for vendor accept status*/
    if (vendor['userInformation'] && vendor['userInformation']['email'] &&
      statusType === CommonConfig.vendorStatus.published) {
      const userName = vendor['userInformation']['displayName'] ? vendor['userInformation']['displayName'] :
        vendor['userInformation']['email'];
      const data = {
        toEmail: vendor['userInformation']['email'],
        userName,
        objectId: vendor['id'],
        belongTo: CommonConfig.belongTo.vendor,
        title: vendor['title'],
        subject,
        templateId
      };
      const commonUtil = new CommonUtils(this.fs);
      const isAllowed = await commonUtil.isEmailAllow(data['toEmail'], data['belongTo']);
      if (isAllowed) {
        this.sendEmail(data);
      }
    }
  }

  sendEmail(data: CommonEmail) {
    const unsubscriber = this.http.post(`${environment.cloudEndPoint}emailSend`, data).subscribe(res => { unsubscriber.unsubscribe(); });
  }
  saveInNotificationList(statusType: string, vendor: Partial<Vendor>) {
    const dataForNotification = { 'uid': '', 'vendorId': '', 'activityType': '', 'activityBelongsTo': '' };
    if (statusType === CommonConfig.vendorStatus.rejected) {
      dataForNotification['activityType'] = CommonConfig.vendorActivityType.vendorprofilereject;
    } else {
      dataForNotification['activityType'] = CommonConfig.vendorActivityType.vendorprofileaccept;
    }
    dataForNotification['vendorId'] = vendor['id'];
    dataForNotification['uid'] = vendor['actionByUid'];
    dataForNotification['activityBelongsTo'] = CommonConfig.belongTo.vendor;
    this.saveInsideNotificationPayload(dataForNotification, vendor);
    if (vendor['parentVendorId'] && vendor['parentVendorId'] !== '' && statusType === CommonConfig.vendorStatus.published) {
      dataForNotification['vendorId'] = vendor['parentVendorId'];
    }
    this.saveVendorActivity(dataForNotification, vendor);
  }
  async saveInsideNotificationPayload(data, vendor: Partial<Vendor>) {
    const category = CommonConfig.notificationActivityType.vendorActivity;
    const activityType = data.activityType;
    const sendBy = data.uid;
    const id = data.vendorId;
    const readFlag = false;
    const forAdmin = data.forAdmin || false;
    const isActive = true;
    if (forAdmin) {
      const allAdmins = await this.auth.getAllAdmins();
      const allAdminIds = allAdmins.map((obj: Object) => obj['uid']);
      data['sendToUsers'] = allAdminIds;
    }
    const usersIds = this.getReceiverUsersId(data, vendor);
    for (const uId of usersIds) {
      if (data.uid && data.uid !== uId) {
        const userId = uId;
        const payloadData = { category, userId, activityType, sendBy, id, readFlag, forAdmin, isActive };
        this.messagingService.saveNotificationPayload(payloadData);
      }
    }
  }

  /**
   * @description Function used for get notifcation receiver user id
   * @param {object} data contain data object
   * @param {object} vendor contain vendor informations
   */
  getReceiverUsersId = (data, vendor: Partial<Vendor>) => {
    let usersId;
    if (data['sendToUsers'] && data['sendToUsers'].length) {
      usersId = data['sendToUsers'];
    } else if (vendor.members) {
      const members = vendor.members;
      const uid = vendor.uid;
      if (!members.includes(uid)) {
        members.push(uid);
      }
      const allUserId = members;
      usersId = allUserId;
    } else {
      usersId = [vendor.uid];
    }
    return usersId;
  }

  acceptClaimVendorProfileRequest(statusType: string, vendor: Partial<Vendor>) {
    return new Promise((resovle) => {
      if (vendor['parentVendorId'] && statusType === CommonConfig.vendorStatus.published) {
        const cond = { uid: vendor['uid'], userInformation: vendor['userInformation'], isClaim: true };
        this.fs.update(`${CommonConfig.collectionName.vendors}/${vendor['parentVendorId']}`,
          cond).then(res => {
            // update orignal vendor id in vendor activity when vendor claim request is approved
            const vendorActivity = this.fs.colWithIds$(CommonConfig.collectionName.userActivity, (ref) =>
              ref.orderBy('createdAt', 'desc').where('allMentors', 'array-contains', vendor['uid']).where('vendorId', '==', vendor['id'])
            ).subscribe(results => {
              this.updateUserActivity(results, vendor);
              vendorActivity.unsubscribe();
            });
            resovle(true);
          }).catch(err => {
            resovle(false);
          });
      } else {
        resovle(true);
      }
    });
  }

  /**
   * @description Functions used for update user activity data
   */
  updateUserActivity(results, vendor: Partial<Vendor>) {
    if (results.length) {
      results.forEach(result => {
        if (result['id']) {
          this.fs.update(`${CommonConfig.collectionName.userActivity}/${result['id']}`, { vendorId: vendor['parentVendorId'] })
            .then();
        }
      });
    }
  }

  isVendorProfileExists(uid: string) {
    return new Promise((resovle) => {
      if (uid) {
        const vendorProfile = this.fs.colWithIds$(CommonConfig.collectionName.vendors, (ref) => ref.where('uid', '==', uid)
          .where('isRejected', '==', false).where('isDeleted', '==', false).where('isSoftDelete', '==', false)).pipe(
            takeUntil(this.destroyed$)
          ).subscribe(vendor => {
            if (vendor.length > 0) {
              resovle(true);
            } else {
              resovle(false);
            }
            vendorProfile.unsubscribe();
          });
      } else {
        resovle(false);
      }
    });
  }
  rejectPendingRequestAfterOneAccept(vendor: Partial<Vendor>, vendorList?: Partial<Vendor[]>) {
    if (vendorList && vendorList.length > 0) {
      vendorList.forEach((data, index) => {
        if (data['parentVendorId'] === vendor['parentVendorId'] && vendor['id'] !== data['id']) {
          vendorList[index]['status'] = CommonConfig.vendorStatus.rejected;
        }
      });
    }
  }
  getVendorDataById(vendorId: string): Promise<Partial<Vendor>> {
    return new Promise((resovle) => {
      this.fs.getCollection(CommonConfig.collectionName.vendors, vendorId).get().then(function (vendors) {
        if (vendors.exists) {
          resovle(vendors.data());
        } else {
          resovle();
        }
      }).catch(err => {
        resovle();
      });
    });
  }
  setUserIsVendor(uid: string, status: boolean) {
    this.fs.update(`${CommonConfig.collectionName.users}/${uid}`, { isVendor: status });
  }
  getVendorInformation(uid: string) {
    return this.fs.colWithIds$(CommonConfig.collectionName.vendors,
      (ref) => ref.where('uid', '==', uid).where('isRejected', '==', false).where('isDeleted', '==', false)
        .where('status', '==', CommonConfig.vendorStatus.published));
  }
  updateVendorProfileOwner(user: Partial<User>, vendorId: string, vendor: Vendor) {
    if (user['uid']) {
      const userInformation = {
        uid: user['uid'], email: user['email'], displayName: '', photoUrl: '',
        createdAt: this.timeStampService.getTimestamp
      };
      userInformation['displayName'] = user['displayName'] ? user['displayName'] : user['email'];
      userInformation['photoUrl'] = user['profilePhoto']['url'] ? user['profilePhoto']['url'] : '';
      const oldUser = vendor['userInformation'] ? vendor['userInformation'] : null;
      this.fs.update(`${CommonConfig.collectionName.vendors}/${vendorId}`,
        { uid: user['uid'], userInformation }).then(res => {
          // set vendor profile for new users
          this.setUserIsVendor(user['uid'], true);
          this.sendEmailOnCreateUpdateVendor(user, vendorId, vendor, false, true);
          // remove vendor profile for existing user after new owner assigned
          if (oldUser && oldUser['uid'] && user['uid'] !== oldUser['uid']) {
            this.setUserIsVendor(oldUser['uid'], false);
          }
        });
      if (vendorId) {
        const data = {};
        data['activityType'] = CommonConfig.vendorActivityType.assignvendorprofile;
        data['uid'] = vendor['actionByUid'];
        data['vendorId'] = vendorId;
        data['sendToUsers'] = [user['uid']];
        this.saveInsideNotificationPayload(data, vendor);
      }
    }
  }
  saveVendorActivity(data: Partial<UserActivity>, vendor?: Partial<Vendor>) {
    if (data.uid !== '' && data.vendorId !== '' && data.activityType !== '') {
      let allMentors = [vendor.uid];
      const ownerId = vendor.uid || '';
      const isFavUnfavActivity = ((data.activityType === CommonConfig.vendorActivityType.favorite) ||
        (data.activityType === CommonConfig.vendorActivityType.unfavorite));
      if (vendor.members) {
        const members = vendor.members;
        const uid = vendor.uid;
        if (!members.includes(uid)) {
          members.push(uid);
        }
        if (!members.includes(data.uid) && isFavUnfavActivity) {
          members.push(data.uid);
        }
        const allUserId = members;
        allMentors = allUserId;
      }
      data['allMentors'] = allMentors;
      data['ownerId'] = ownerId;
      data['activityBelongsTo'] = CommonConfig.belongTo.vendor;
      const allowed = ['uid', 'vendorId', 'activityType', 'allMentors', 'ownerId', 'activityBelongsTo'];
      const filterObject = this.filterObject(allowed, data);
      this.fs.add(CommonConfig.collectionName.userActivity, filterObject);
    }
  }

  async saveProductActivity(vendorId: string, uid: string, actionType: string) {
    const vendorInfo = await this.getVendorDataById(vendorId);
    const dataForActivity = {} as UserActivity;
    dataForActivity['uid'] = uid;
    dataForActivity['vendorId'] = vendorId;
    dataForActivity['activityType'] = actionType;
    if (vendorInfo) {
      this.saveVendorActivity(dataForActivity, vendorInfo);
    }
  }
  async sendEmailOnCreateUpdateVendor(user: Partial<User>, vendorId: string, vendor: Vendor, isNewVendor: boolean, isVendorUpdate: boolean,
    that?) {
    /*Send email to vendor owner on create and update vendor*/
    if (user && user['email'] && vendorId && isVendorUpdate) {
      let subject = CommonConfig.emailSubject.vendorUpdate;
      let templateId = CommonConfig.emailTemplates.vendorUpdate;
      let activityType = CommonConfig.vendorActivityType.update;
      if (isNewVendor) {
        activityType = CommonConfig.vendorActivityType.create;
        if (vendor['status'] === CommonConfig.vendorStatus.published) {
          subject = CommonConfig.emailSubject.vendorCreate;
          templateId = CommonConfig.emailTemplates.vendorCreate;
        } else {
          subject = CommonConfig.emailSubject.vendorCreateRequest;
          templateId = CommonConfig.emailTemplates.vendorCreateRequest;
        }
      }
      if (that) {
        const dataObject = {
          uid: that.authUser['uid'],
          vendorId,
          activityType
        };
        this.saveVendorActivity(dataObject, that.vendorDetail);
      }
      this.sendEmailNotificationToVendor(user, vendor, { vendorId, templateId, subject });
    }
  }

  /**
   * @description send email notificaiton to vendor
   * @param user contain the user information
   * @param vendor containg the vendordata information
   * @param otherData contain the subject and template id information
   */
  async sendEmailNotificationToVendor(user, vendor, otherData) {
    const userName = user['displayName'] ? user['displayName'] : user['email'];
    const data = {
      toEmail: user['email'],
      userName,
      objectId: otherData.vendorId,
      belongTo: CommonConfig.belongTo.vendor,
      title: vendor['name'],
      subject: otherData.subject,
      templateId: otherData.templateId
    };
    const commonUtil = new CommonUtils(this.fs);
    const isAllowed = await commonUtil.isEmailAllow(data['toEmail'], data['belongTo']);
    if (isAllowed) {
      const unsubscriber = this.http.post(`${environment.cloudEndPoint}emailSend`, data).subscribe(res => { unsubscriber.unsubscribe(); });
    }
  }

  /**
   * @description Function used for claim the vendor profile
   * @param {object} that contain component object
   * @param {object} vendor contain the uncliam vendor information
   * @param {object} claimVendorInformation contain the vendor user informaion
   */
  vendorProfileClaim(that, vendor: Vendor, claimVendorInformation) {
    const vendorId = vendor['id'];
    if (claimVendorInformation['uid']) {
      const uid = claimVendorInformation['uid'];
      const userInformation = claimVendorInformation['userInformation'];
      this.removeMemberFromExistingDisableVendor(uid);
      const phoneNumbers = vendor['phoneNumbers'];
      phoneNumbers.push(claimVendorInformation['phoneNumbers'][0]);
      const dataObject = { uid, userInformation, phoneNumbers, isClaim: true, claimVendorInformation };
      this.fs.update(`${CommonConfig.collectionName.vendors}/${vendorId}`, dataObject).then((success) => {
        const requiredData = {
          actionByUid: uid,
          vendorId
        };
        this.sendClaimProfileNotificationToAdmins(requiredData, vendor);
        this.setUserIsVendor(uid, true);
        this.customService.showSnackBarSuccess(CommonConfig.successMessage.claimProfileSuccess);
        if (that.isBrowseVendor) {
          that.isVendorProfile = true;
          that.vendor.isClaim = true;
        }
        if (!that.stopRedirect) {
          this.router.navigate([`${CommonConfig.routerName.vendors}/${CommonConfig.routerName.vendorprofile}`, vendorId]);
        }
      }).catch(err => {
        this.customService.showSnackBarError(CommonConfig.validationMessage.someError);
      });
    } else {
      this.customService.showSnackBarError(CommonConfig.validationMessage.vendorEmpty);
    }
  }

  requestClaimVendorProfile(that, parentVendorId: string, claimVendorInformation) {
    this.fs.getCollection(CommonConfig.collectionName.vendors, parentVendorId).get().then(async (vendors) => {
      if (vendors.exists) {
        const vendorInformation = vendors.data();
        // rewrite status and onther informaion for requested claim profile
        if (claimVendorInformation['uid']) {
          this.removeMemberFromExistingDisableVendor(claimVendorInformation['uid']);
          vendorInformation['status'] = CommonConfig.vendorStatus.pending;
          vendorInformation['profileType'] = CommonConfig.vendorProfileType.claimRequest;
          vendorInformation['parentVendorId'] = parentVendorId;
          vendorInformation['uid'] = claimVendorInformation['uid'];
          vendorInformation['isClaim'] = true;
          if (claimVendorInformation.userInformation) {
            vendorInformation['userInformation'] = claimVendorInformation.userInformation;
          }
          vendorInformation['claimVendorInformation'] = claimVendorInformation;
          this.saveClaimVendorProfile(that, vendorInformation, claimVendorInformation);
        } else {
          this.customService.showSnackBarError(CommonConfig.validationMessage.vendorEmpty);
        }
      } else {
        this.customService.showSnackBarError(CommonConfig.validationMessage.someError);
      }
    }).catch(err => {
      this.customService.showSnackBarError(CommonConfig.validationMessage.someError);
    });
  }

  /**
   * @description Function used for remove member from existing disable vendor
   * @param {object} that contain component object
   * @param {object} vendorInformation contain vendor informations
   * @param {object} claimVendorInformation contain claim vendor informations
   */
  saveClaimVendorProfile = (that, vendorInformation: Partial<Vendor>, claimVendorInformation: Partial<Vendor>) => {
    this.fs.add(CommonConfig.collectionName.vendors, vendorInformation).then(response => {
      const vendorId = response['id'];
      // send the notification to all the Users
      const requiredData = {
        actionByUid: claimVendorInformation['uid'],
        vendorId
      };
      this.sendClaimProfileNotificationToAdmins(requiredData, vendorInformation);
      this.setUserIsVendor(claimVendorInformation['uid'], true);
      // update customId with new auto genrated vendorId for using same feature/ui for all type of profile
      this.fs.update(`${CommonConfig.collectionName.vendors}/${vendorId}`, { customId: vendorId });
      this.customService.showSnackBarSuccess(CommonConfig.successMessage.claimProfile);
      if (that.isAdmin) {
        this.router.navigate([`/${CommonConfig.routerName.admin}/${CommonConfig.routerName.vendorprofile}`, vendorId]);
      } else {
        if (that.isBrowseVendor) {
          that.isVendorProfile = true;
          that.vendor.isClaim = true;
          this.router.navigate([`${CommonConfig.routerName.vendors}/${CommonConfig.routerName.vendorprofile}`, vendorId]);
        } else {
          this.router.navigate([`${CommonConfig.routerName.vendors}/${CommonConfig.routerName.vendorprofile}`, vendorId]);
        }
      }
    }).catch(err => {
      this.customService.showSnackBarError(CommonConfig.validationMessage.someError);
    });
  }

  /**
   * @description Function used for remove member from existing disable vendor
   * @param {string} vendorUid contain a vendor user id
   */
  removeMemberFromExistingDisableVendor = async (vendorUid: string) => {
    const memberVendorInfo = await this.getVendorByUid(vendorUid, true);
    if (memberVendorInfo['id'] && memberVendorInfo['isActive'] === false) {
      this.removeDisabledVendorMember(vendorUid, memberVendorInfo['id']);
    }
  }

  vendorProjectAddMember(uid: string, refUrl: string) {
    return this.fs.setmerge(refUrl, { members: this.fs.firebaseFirestoreFieldValue().arrayUnion(uid) });
  }

  vendorProjectRemoveMember(uid: string, refUrl: string) {
    return this.fs.setmerge(refUrl, { members: this.fs.firebaseFirestoreFieldValue().arrayRemove(uid) });
  }

  saveVendorMembers(data: VendorMember) {
    if (data.uid !== '' && data.vendorId !== '' && data.role !== '') {
      this.fs.add(CommonConfig.collectionName.vendormembers, data);
      this.setUserIsVendor(data.uid, true);
    }
  }
  sendClaimProfileNotificationToAdmins(claimVendorInformation, vendorInformation) {
    const dataForNotification = { activityType: '', vendorId: '', uid: '' };
    dataForNotification['activityType'] = CommonConfig.vendorActivityType.createClaimVendorProfile;
    dataForNotification['vendorId'] = claimVendorInformation['vendorId'];
    dataForNotification['uid'] = claimVendorInformation['actionByUid'];
    dataForNotification['forAdmin'] = true;
    this.saveInsideNotificationPayload(dataForNotification, vendorInformation);
    if (vendorInformation) {
      this.saveVendorActivity(dataForNotification, vendorInformation);
    }
  }
  getVendorByUid(uid: string, isArrayContains?) {
    return new Promise((resovle) => {
      if (uid) {
        const refObj = this.getVendorQueryRef(uid, isArrayContains);
        const getVendor = refObj.subscribe(vendor => {
          if (vendor.length > 0) {
            resovle(vendor[0]);
          } else {
            resovle('');
          }
          getVendor.unsubscribe();
        });
      } else {
        resovle('');
      }
    });
  }

  /**
   * @description Function used for get vendor query object
   * @param {string} uid contain a user id
   * @param {boolean} isArrayContains contain true or false value
   */
  getVendorQueryRef = (uid: string, isArrayContains?) => {
    let refObj = this.fs.colWithIds$(CommonConfig.collectionName.vendors, (ref) => ref.where('uid', '==', uid)
      .where('isRejected', '==', false).where('isDeleted', '==', false).where('isSoftDelete', '==', false));
    if (isArrayContains) {
      refObj = this.fs.colWithIds$(CommonConfig.collectionName.vendors, (ref) => ref.where('members', 'array-contains', uid)
        .where('isRejected', '==', false).where('isDeleted', '==', false).where('isSoftDelete', '==', false));
    }
    return refObj;
  }

  getUserVendorId(uid: string) {
    return new Promise(async (resovle, reject) => {
      if (uid) {
        const iAmMemberOf = await this.getVendorByUid(uid, true);
        if (this.isVendorMemberActive(iAmMemberOf)) {
          resovle(iAmMemberOf['id']);
        } else {
          const iAmOwnerOf = await this.getVendorByUid(uid);
          if (this.isVendorMemberPublished(iAmOwnerOf)) {
            resovle(iAmOwnerOf['id']);
          } else {
            resovle('');
          }
        }
      } else {
        resovle('');
      }
    });
  }

  /**
   * @description Function used for check vendor status active or not
   * @param {Vendor} iAmMemberOf contain vendor information
   */
  isVendorMemberActive = (iAmMemberOf: Partial<Vendor>) => {
    return (iAmMemberOf['id'] && iAmMemberOf['isActive'] && iAmMemberOf['status'] === CommonConfig['vendorStatus']['published']);
  }

  /**
   * @description Function used for check vendor status active or not
   * @param {Vendor} iAmOwnerOf contain vendor information
   */
  isVendorMemberPublished = (iAmOwnerOf: Partial<Vendor>) => {
    return (iAmOwnerOf['id'] && iAmOwnerOf['status'] === CommonConfig['vendorStatus']['published']);
  }

  isVendorMember(vendor: Partial<Vendor>, authUid: string) {
    if (vendor.members) {
      const userIndex = vendor.members.indexOf(authUid);
      return userIndex !== -1;
    } else {
      return false;
    }
  }

  /*Send email to vendor member on add and remove vendor member*/
  async sendEmailOnAddAndRemoveVendorMember(user: Partial<User>, vendorId: string, vendor: Vendor, isMemberAdded: boolean) {
    if (user && user['email'] && vendorId) {
      let subject = CommonConfig.emailSubject.removeVendorMember;
      let templateId = CommonConfig.emailTemplates.removeVendorMember;
      if (isMemberAdded) {
        subject = CommonConfig.emailSubject.addVendorMember;
        templateId = CommonConfig.emailTemplates.addVendorMember;
      }
      const userName = user['displayName'] ? user['displayName'] : user['email'];
      const data = {
        toEmail: user['email'],
        userName,
        objectId: vendorId,
        belongTo: CommonConfig.belongTo.vendor,
        title: vendor['name'],
        subject,
        templateId
      };
      const commonUtil = new CommonUtils(this.fs);
      const isAllowed = await commonUtil.isEmailAllow(data['toEmail'], data['belongTo']);
      if (isAllowed) {
        const emailSubscribe = this.http.post(`${environment.cloudEndPoint}emailSend`, data).subscribe(res => {
          emailSubscribe.unsubscribe();
        });
      }
    }
  }

  removeDisabledVendorMember(uid: string, vendorId: string) {
    const memberRemove = this.fs.colWithIds$(CommonConfig.collectionName.vendormembers, (ref) => ref.where('uid', '==', uid)
      .where('vendorId', '==', vendorId)).subscribe(vendorMember => {
        if (vendorMember.length > 0) {
          const memberData = vendorMember[0];
          this.vendorProjectRemoveMember(uid, `${CommonConfig.collectionName.vendors}/${vendorId}`).then((res) => {
            this.fs.delete(`${CommonConfig.collectionName.vendormembers}/${memberData['id']}`);
            this.setUserIsVendor(uid, false);
          });
          memberRemove.unsubscribe();
        }
      });
  }
  filterObject = (allowed, raw) => {
    return Object.keys(raw)
      .filter(key => allowed.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: raw[key]
        };
      }, {});
  }

  /**
   * @description Function for update vendor data
   * @param {number} projectId contain vendor unique id
   * @param {object} dataObject contain vendor data object
   */
  updateVendorData(vendorId: string, dataObject: object) {
    this.fs.update(`${this.commonConfig.collectionName.vendors}/${vendorId}`, dataObject);
  }

  /**
  * @description function create for invite outside vendor
  */
  inviteOutsideVendor(formData) {
    return this.http.post(`${environment.cloudEndPoint}inviteOutsideVendor`, formData);
  }

  /**
   * @description Function used for set default vendor form object data
   */
  defaultVendorFormObject() {
    return {
      logoImage: [null],
      coverImage: [null],
      isArchive: [false],
      isRejected: [false],
      isRevised: [false],
      isActive: [true],
      isDeleted: [false],
      isClaim: [false],
      isSoftDelete: [false],
      isHardDelete: [false],
      organization: [null]
    };
  }

  /**
   * @description Function used for check vendor tax id is unique or not
   */
  isVendorUnique(vendorId, formData) {
    return new Promise<boolean>((resovle) => {
      if (!formData.taxId) {
        resovle(true);
      }
      const vendorSub = this.fs.colWithIds$(CommonConfig.collectionName.vendors,
        (ref) => ref.where('taxId', '==', formData.taxId).where('isRejected', '==', false)
          .where('isDeleted', '==', false)).subscribe(async (res) => {
            vendorSub.unsubscribe();
            let isUnique = true;
            for (const data of res) {
              if (data['id'] !== vendorId && formData.taxId === data['taxId']) {
                isUnique = false;
              }
            }
            resovle(isUnique);
          });
    });
  }


  getAllActiveVendors() {
    return new Promise((resolve) => {
      this.fs.colWithIds$(this.commonConfig.collectionName.vendors,
        (ref) => ref.where('isRejected', '==', false)
          .where('isDeleted', '==', false)).subscribe(async (vendors: []) => {
            resolve(vendors);
          });
    });
  }

  // Function used for remove duplicate vendor data
  removeDuplicateVendors(requestData) {
    return this.httpService.httpPost(`${environment.cloudEndPoint}scrapeVendors`, requestData).toPromise();
  }

}

