import { FormGroup, ValidationErrors, AbstractControl, FormArray, FormControl, Validators } from '@angular/forms';
import { CommonConfig } from '@app/shared/common.config';

export class CustomValidator {
  static matchPassword(form: FormGroup) {
    let pwd;
    if (form.controls.password) {
      pwd = form.controls.password.value;
    } else {
      pwd = form.controls.new_password.value;
    }

    const confirmPassword = form.controls.confirm_password.value;
    if (confirmPassword && confirmPassword.length <= CommonConfig.zero) {
      return null;
    } else if (confirmPassword !== pwd) {
      return {
        mismatch: true
      };
    }
    return null;
  }

  static validEmail(controls: AbstractControl): ValidationErrors | null {
    const email = controls.value;
    if (email && email.length <= 0) {
      return null;
    }
    if (/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(email)) {
      return null;
    } else {
      return {
        invalidEmail: true
      };
    }
  }

  static validUrl(controls: AbstractControl): ValidationErrors | null {
    const url = controls.value;
    if (!url || url.length <= 0) {
      return null;
    }
    if (/^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/.test(url) && !/\s/.test(url)) {
      return null;
    } else {
      return {
        invalidUrl: true
      };
    }
  }

  /**
   * @description validating the advertisement URL
   * @param {FormBuilder} controls contain the form instance
   */
  static advertisementValid(controls: AbstractControl): ValidationErrors | null {
    const url = controls.value;
    if (url.length <= 0) {
      return null;
    }
    if (/^((http[s]?):\/\/)(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/.test(url) && !/\s/.test(url)) {
      return null;
    } else {
      return {
        invalidUrl: true
      };
    }
  }
  static required(controls: AbstractControl): ValidationErrors | null {
    const isWhitespace = ((controls && controls.value) || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'required': true };
  }

  /**
   * @description method to validate campaign/notification fields on form submission
   * @param {Object} formGroup
   * @returns {Object}
   */
  public static validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      // markDirty FormGroup type
      if (control['controls'] && control['controls'].length > 0) {
        control['controls'].forEach(element => {
          element.markAsDirty(true);
          if (element instanceof FormGroup) {
            this.validateAllFormFields(element);
          }
        });
      }
      if ((control instanceof FormControl) || (control instanceof FormArray)) {
        control.markAsDirty({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
      this.markFormGroupTouched(formGroup);
    });
  }

  /**
   * Marks all controls in a form group as touched
   * @param formGroup - The group to caress..hah
   */
  public static markFormGroupTouched(formGroup: FormGroup) {
    (Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control['controls'] && control['controls'].length > 0) {
        control['controls'].forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  /**
   * @description Function used for reset form validation
   * @param {AbstractControl} formGroup contain form group object
   * @param {boolean} isValidate contain form group object
   */
  public static reSetFormValidation(fieldControl: AbstractControl, isValidate: boolean, isMin = false) {
    if (isValidate) {
      const validationRule = [Validators.required];
      if (isMin) {
        validationRule.push(Validators.min(CommonConfig.minAmount));
      }
      fieldControl.setValidators(validationRule);
    } else {
      fieldControl.setValidators([]);
    }
    fieldControl.updateValueAndValidity();
  }

}
