import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output, Input, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { GeolocationService } from '@app/shared/service/geolocation/geolocation.service';

import { CommonConfig } from '@shared/common.config';

@Component({
  selector: 'app-filter-vendor',
  templateUrl: './filter-vendor.component.html'
})
export class FilterVendorComponent implements OnInit, AfterViewInit {
  public commonConfig = CommonConfig;
  public filterVendor: FormGroup;
  public categories: Array<object> = [];
  @ViewChild('location', {static: false}) searchElementRef: ElementRef;
  @Output() getVendors = new EventEmitter();
  @Input() authUid: string;
  @Input() formObject;
  @Output() closeVendorFilter = new EventEmitter();
  constructor(private readonly fb: FormBuilder, public locationService: GeolocationService) { }

  ngOnInit() {
    this.buildVendorFrom();
  }

  ngAfterViewInit() {
    this.locationService.addressAutoComplete(this.searchElementRef, this.filterVendor.get('location'));
    this.getVendors.emit();
  }

  /**
   * @description Function used build vendor filter form
   */
  buildVendorFrom() {
    this.filterVendor = this.fb.group({
      keyword: [''],
      location: this.locationService.createLocationNoValidation(),
      category: [''],
      radious: ['', Validators.min(1)],
      type: [this.commonConfig.belongTo.vendor],
      starred: [false],
      authUid: [this.authUid]
    });
    this.setDefaultFormData();
  }

  /**
   * @description Function used for default filter form data
   */
  setDefaultFormData() {
    this.categories = Array.from(this.formObject.get('category').value);
    this.filterVendor.get('keyword').setValue('');
    this.filterVendor.get('category').setValue(this.categories);
    this.filterVendor.get('radious').setValue(this.commonConfig.hundred);
    this.filterVendor.get('location').get('address').setValue(this.formObject.get('location').value.address);
    this.filterVendor.get('location').get('lat').setValue(this.formObject.get('location').value.lat);
    this.filterVendor.get('location').get('long').setValue(this.formObject.get('location').value.long);
    this.filterVendor.get('starred').setValue(false);
  }

  clearFormData() {
    this.categories = [];
    this.filterVendor.get('keyword').setValue('');
    this.filterVendor.get('category').setValue('');
    this.filterVendor.get('radious').setValue('');
    this.filterVendor.get('location').get('address').setValue('');
    this.filterVendor.get('location').get('lat').setValue('');
    this.filterVendor.get('location').get('long').setValue('');
    this.filterVendor.get('starred').setValue(false);
    this.getVendors.emit();
    this.closeVendorFilter.emit();
  }

  /**
   * @description Function used for clear the location if address in not correct
   */
  onKeyUp() {
    this.filterVendor.get('location').get('lat').setValue(null);
    this.filterVendor.get('location').get('long').setValue(null);
  }

  /**
   * @description Function used get address field reference
   */
  get getAddress() {
    return this.filterVendor.get('location').get('address');
  }

  /**
   * @description Function used get lat field reference
   */
  get getLat() {
    return this.filterVendor.get('location').get('lat');
  }

}
