import { Injectable } from '@angular/core';
import { CommonConfig } from './../shared/common.config';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';


import { take, map } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { FirestoreService } from '@shared/service/firestore/firestore.service';
const routePrefix = CommonConfig.routerName.routePrefix;

import { User } from '@app/core/model/user';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  publicRouter: Array<string> = [
    routePrefix,
    routePrefix + CommonConfig.routerName.login,
    routePrefix + CommonConfig.routerName.signup,
    routePrefix + CommonConfig.routerName.forgot,
    routePrefix + CommonConfig.routerName.verifyreset,
    routePrefix + CommonConfig.routerName.home,
    routePrefix + CommonConfig.routerName.termsconditions,
    routePrefix + CommonConfig.routerName.privacypolicy];
  nextUrl: string;
  currentPage: string;
  currentModule: string;
  private profileComplete = [CommonConfig.routerName.completeprofile];
  private isProfileCompleteRoute = (route) => this.profileComplete.indexOf(route) > -1;
  constructor(
    private auth: AuthService,
    private fs: FirestoreService,
    private router: Router
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.nextUrl = state.url;
    if (this.nextUrl) {
      this.nextUrl = this.nextUrl.split('?')[0];
      const routers = this.nextUrl.split('/');
      this.currentModule = routers[1];
      this.currentPage = routers[CommonConfig.two];
    }
    return this.auth.user.pipe(
      take(1),
      map(auth => {
        if (auth) {
          if (this.auth.isProfileCompleted(auth) && this.isProfileCompleteRoute(this.currentPage)) {
            this.router.navigate([`/${CommonConfig.routerName.dashboard}`]);
            return false;
          }
          if (!this.auth.isProfileCompleted(auth) && !this.isProfileCompleteRoute(this.currentPage) && !this.auth.isPremiumSignup(auth)
          ) {
            this.router.navigate([`/${CommonConfig.routerName.profile}/${CommonConfig.routerName.completeprofile}`]);
            return false;
          }
          if (this.publicRouter.indexOf(this.nextUrl) > -1) {
            this.router.navigate([`${CommonConfig.routerName.projects}/${CommonConfig.routerName.browse_project}`, CommonConfig.mapType]);
            return false;
          }
          return true;
        } else {
          if (this.publicRouter.indexOf(this.nextUrl) === -1) {
            this.router.navigate([CommonConfig.routerName.login], { queryParams: { returnUrl: state.url } });
            return false;
          } else {
            this.router.navigate([CommonConfig.routerName.login]);
          }
          return true;
        }
      }));
  }
}
