import { Injectable } from '@angular/core';
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { CommonConfig } from '@shared/common.config';
import { OrderByDirection, orderByDesc } from '@app/core/model/strings-type';
import { GeolocationService } from '@app/shared/service/geolocation/geolocation.service';

@Injectable({
  providedIn: 'root'
})
export class AdvertisementService {
  private commonConfig = CommonConfig;
  private orderType: OrderByDirection = orderByDesc;
  constructor(private readonly firestoreService: FirestoreService, private readonly locationService: GeolocationService) { }

  fetchAdvertisement(lastKey: string, pageSize = this.commonConfig.pageSize, data = {fieldName: this.commonConfig.collectionFieldName.createdAt, orderType: this.orderType}) {
    let advQueryRef = this.firestoreService.fetchCollection(CommonConfig.collectionName.advertisements).ref
      .where('status', '==', CommonConfig.documentStatus.active).where('isSoftDelete', '==', false)
      .orderBy(data.fieldName, data.orderType).limit(pageSize);
    if (lastKey) {
      advQueryRef = advQueryRef.startAfter(lastKey);
    }
    return advQueryRef.get();
  }

  getAdsData(adsId: string) {
    return new Promise((resolve) => {
      const ref = this.firestoreService.doc$(`${CommonConfig.collectionName.advertisements}/${adsId}`).subscribe(result => {
        ref.unsubscribe();
        resolve(result);
      });
    });
  }


  /**
   * @description Function to count number of impressions view by a user
   * @param {string} adId contain a advertisement id
   * @param {number} viewCount contain total ad view count
   */
  viewImpressionCount(adId: string, viewCount: number) {
    viewCount = viewCount ? viewCount + 1 : 1;
    this.firestoreService.update(`${this.commonConfig.collectionName.advertisements}/${adId}`, { viewCount });
  }

  /**
   * @description function used for check is ads display or not
   * @param {string} currentTimestamp contain current timestamp value
   * @param {object} adData contain ads data
   */
  isAdDisplay(currentTimestamp, adData, advertisementList) {
    return currentTimestamp >= adData.startDate.toDate().getTime() && currentTimestamp <= adData.endDate.toDate().getTime()
    && adData.viewCount < adData.impressions && advertisementList.length < CommonConfig.adPageSize;
  }

  /**
   * @description function used for check user location valid for display ads
   */
  isLocationValid(adData, userDetail) {
    let isValidLocation = false;
    if (!adData.radius || !adData['location']['lat'] || !adData['location']['long']) {
      isValidLocation = true;
    } else if (userDetail.projectLocations) {
      for (const userLocation of userDetail.projectLocations) {
        if (this.isLocationLatLong(userLocation)) {
          const distence = this.locationService.getDistance(adData['location']['lat'], adData['location']['long']
            , userLocation['lat'], userLocation['long'], this.commonConfig.distanceUnit.M);
          if (distence <= adData.radius) {
            isValidLocation = true;
          }
        }
      }
    }
    return isValidLocation;
  }

  /**
   * @description function used for check user category valid for display ads
   */
  isUserCategoryValid(adData, userDetail) {
    let isValidCategory = false;
    if (adData.category && adData.category.length) {
      const matchedCategories = userDetail['category'].filter(obj1 => adData.category.filter(obj2 => obj1.name === obj2.name).length);
      isValidCategory = matchedCategories.length > this.commonConfig.zero;
    } else {
      isValidCategory = this.isAdForVendor(adData, userDetail) || this.isAdForProjectCreator(adData, userDetail) || this.isAdForAll(adData);
    }
    return isValidCategory;
  }

  isLocationLatLong = (userLocation) => userLocation['lat'] && userLocation['long'];

  /**
   * @description function used for check is ads for only vendor user
   * @param {object} adData contain ads data
   */
  isAdForVendor = (adData, userDetail) => (adData.segment === this.commonConfig.segment.vendor && userDetail.isVendorSegment);

  /**
   * @description function used for check is ads for only project creator user
   * @param {object} adData contain ads data
   */
  isAdForProjectCreator = (adData, userDetail) => (adData.segment === this.commonConfig.segment.projectCreator && userDetail.isProjectSegment);

  /**
   * @description function used for check is ads for all user
   * @param {object} adData contain ads data
   */
  isAdForAll = (adData) => (!adData.segment || adData.segment === this.commonConfig.segment.all.key);

}
