import { Component, OnInit } from '@angular/core';
import { CustomService } from '@app/shared/service/custom/custom.service';

@Component({
  selector: 'app-loading-mask',
  templateUrl: './loading-mask.component.html',
  styleUrls: ['./loading-mask.component.scss'],
  preserveWhitespaces: true,
})
export class LoadingMaskComponent implements OnInit {
  public isLoading = false;
  constructor(private readonly customService: CustomService) { }

  ngOnInit() {
    this.customService.loadingMaskStatus.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

}
