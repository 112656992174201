import { Pipe, PipeTransform } from '@angular/core';
import { CategoryService } from '../service/category/category.service';

@Pipe({
  name: 'imageDefaultClass'
})
export class ImageDefaultClassPipe implements PipeTransform {
  constructor(
    private readonly categoryService: CategoryService,
  ) {
  }
  transform(data, projectImageUrl: string, categoryId: string) {
    return this.fetchCategoryData(projectImageUrl, categoryId);
  }

  async fetchCategoryData(projectImageUrl: string, categoryId: string) {
    const categoryData = await this.categoryService.getCategoryData(categoryId);
    let imageClass: string;
    if (projectImageUrl || categoryData.photo) {
      imageClass = 'mat-card-image';
    } else {
      imageClass = 'default-image';
    }
    return imageClass;
  }

}
