import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
// Config
import { CommonConfig } from '@shared/common.config';

@Component({
  selector: 'app-admin-project-filters',
  templateUrl: './admin-project-filters.component.html'
})
export class AdminProjectFiltersComponent implements OnInit, OnDestroy {
  @Input() orgId: string;
  @Input() currentPage: string;
  @Output() projectFilterEvent = new EventEmitter();
  @Output() columnVisibleEvent = new EventEmitter();
  commonConfig = CommonConfig;
  projectFilter: FormGroup;
  queryEncodeData;
  algoliaIndices = this.commonConfig.algoliaIndices.projectSearch;
  revisionRequested = 'Revision Requested';
  projectSubscriberTypes: Array<object> = [
    {
      key: this.commonConfig.projectType.projectSubscriberType,
      value: this.commonConfig.projectType[this.commonConfig.projectType.projectSubscriberType]
    }];
  projectStatus: Array<object> = [
    {
      key: this.commonConfig.projectStatus.published,
      value: 'Accepted'
    }, {
      key: this.commonConfig.projectStatus.rejected,
      value: this.commonConfig.projectStatus.rejected
    }, {
      key: this.commonConfig.projectStatus.draft,
      value: this.commonConfig.projectStatus.draft
    }, {
      key: this.commonConfig.projectStatus.pending,
      value: this.commonConfig.projectStatus.pending
    }, {
      key: this.commonConfig.projectStatus.revision,
      value: this.revisionRequested
    }, {
      key: this.commonConfig.projectStatus.disable,
      value: this.commonConfig.projectStatus.disable
    }];
  selectedProjectType = [
    this.commonConfig.projectType.quote, this.commonConfig.projectType.competitive, this.commonConfig.projectType.sealedbid,
    this.commonConfig.projectType.express, this.commonConfig.projectType.materialBid
  ];
  selectedSubscriberType = [
    this.commonConfig.projectType.projectSubscriberType
  ];
  selectedStatus = [
    this.commonConfig.projectStatus.published, this.commonConfig.projectStatus.rejected, this.commonConfig.projectStatus.draft,
    this.commonConfig.projectStatus.pending, this.commonConfig.projectStatus.revision, this.commonConfig.projectStatus.disable
  ];
  projectColumnData: Array<object> = this.commonConfig.projectColumn;
  public projectColumnSelectedData = [];

  constructor(
    private readonly fb: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) { }

  ngOnInit() {
    this.buildFrom();
    this.patchSearchFromValue();
    this.projectFilter.valueChanges.pipe(debounceTime(this.commonConfig.debounceTime)).subscribe((value) => {
      this.projectFilterEvent.emit(this.projectFilter.value);
      this.submitFilter();
    });
    this.submitFilter();
  }

  ngOnDestroy() {
    const projectColumnDatas = this.projectColumnData;
    projectColumnDatas.forEach(async (data, key) => {
      this.projectColumnData[key]['selected'] = false;
    });
  }

  buildFrom() {
    this.projectFilter = this.fb.group({
      keyword: [''],
      projectType: [''],
      projectSubscriberType: [''],
      status: ['']
    });
    this.projectFilter.get('projectType').setValue(this.selectedProjectType);
    if (this.currentPage !== this.commonConfig.routerName.managebids) {
      this.projectFilter.get('projectSubscriberType').setValue(this.selectedSubscriberType);
    }
    if (this.currentPage === this.commonConfig.routerName.managebids) {
      this.projectFilter.get('status').setValue([this.commonConfig.projectStatus.published]);
    } else {
      this.projectFilter.get('status').setValue(this.selectedStatus);
    }
  }

  /**
  * @description function used for set form data on page reload
  */
  patchSearchFromValue() {
    let querydncodeData;
    if (this.route.snapshot.queryParams['projectdata']) {
      try {
        this.queryEncodeData = this.route.snapshot.queryParams['projectdata'];
        querydncodeData = JSON.parse(decodeURI(this.route.snapshot.queryParams['projectdata']));
      } catch (e) { }
    }
    if (querydncodeData) {
      if (querydncodeData['algoliaIndices']) {
        this.algoliaIndices = querydncodeData['algoliaIndices'];
      }
      this.projectFilter.patchValue(querydncodeData);
      this.projectFilter.markAsPristine();
      this.projectFilterEvent.emit(this.projectFilter.value);
    }
  }

  clearFormData() {
    this.projectFilter.get('keyword').setValue('');
  }

  submitFilter() {
    let routerSubString = this.commonConfig.routerName.manageprojects;
    this.projectFilter.value['algoliaIndices'] = this.algoliaIndices;
    this.queryEncodeData = encodeURI(JSON.stringify(this.projectFilter.value));
    if (this.currentPage === this.commonConfig.routerName.scrapeVendors) {
      routerSubString = this.commonConfig.routerName.scrapeVendors;
    } else if (this.currentPage === this.commonConfig.routerName.managebids) {
      routerSubString = this.commonConfig.routerName.managebids;
    }
    this.router.navigate([`/${this.commonConfig.routerName.admin}/${routerSubString}`],
      { queryParams: { projectdata: this.queryEncodeData, orgId: this.orgId } });
  }

  // used for on/off column
  manageProjectColumn(event: MatOptionSelectionChange, key: string, value: string) {
    const projectColumnIndex = this.projectColumnData.findIndex(x => x['key'] === key);
    const index = this.projectColumnSelectedData.findIndex(x => x === value);
    if (event.source.selected) {
      this.projectColumnData[projectColumnIndex]['selected'] = true;
      this.projectColumnSelectedData.push(value);
    } else {
      this.projectColumnData[projectColumnIndex]['selected'] = false;
      this.projectColumnSelectedData.splice(index, this.commonConfig.one);
    }
    this.columnVisibleEvent.emit(this.projectColumnData);
  }

}
