import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private readonly http: HttpClient) { }

  /**
   * @description Function used for hit http get request
   * @param url api url
   * @param options api request data
   */
  httpGet (url: string, options = {}) {
    return this.http.get(url, options);
  }

  /**
   * @description Function used for hit http post request
   * @param url api url
   * @param options api request data
   */
  httpPost (url: string, options = {}) {
    return this.http.post(url, options);
  }

}
