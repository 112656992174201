import { Injectable } from '@angular/core';
import { TdDialogService } from '@covalent/core/dialogs';
import { CommonConfig } from '@shared/common.config';
import { calendarIcsStatus, calendarInviteSubject, icsMethods } from '@shared/common.constants';
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { CustomService } from '@shared/service/custom/custom.service';
import { AuthService } from '@app/core/auth.service';
import { ProjectWalkthrough } from '@app/core/model/project-walkthrough';
import { Project } from '@app/core/model/project';
import { User } from '@app/core/model/user';
import { MessagingService } from '@shared/service/messaging/messaging.service';
import { VendorsService } from '@shared/service/vendors/vendors.service';
import { ICalendarService } from '@shared/service/iCalendar/i-calendar.service';

import { Vendor } from '@app/core/model/vendor';

@Injectable({
  providedIn: 'root'
})
export class ProjectActivityService {

  constructor(public fs: FirestoreService, public auth: AuthService, private _dialogService: TdDialogService,
    private customService: CustomService, private messagingService: MessagingService, private vendorsService: VendorsService,
    private iCalendarService: ICalendarService) { }
  getProjectActivity(that, userId: string) {
    if (!that.projectActivityLastKey) {
      that.projectActivityLastKey = '';
    }
    return new Promise((resolve) => {
      const projectRef = this.fs.colWithIds$(
        CommonConfig.collectionName.userActivity,
        (ref) => ref
          .limit(CommonConfig.pageSize)
          .orderBy('createdAt', 'desc')
          .startAfter(that.projectActivityLastKey)
          .where('allMentors', 'array-contains', userId)
      );
      const activitySubscriber = projectRef.subscribe((activities) => {
        if (activities.length) {
          that.projectActivityLastKey = activities[activities.length - 1].doc;
          const promises = [];
          activities.forEach(async (activityData) => {
            promises.push(
              this.auth.getSenderInfo(activityData['uid']).then((senderInfo) => {
                Object.assign(activityData, { userInfo: senderInfo });
              }),
              this.getProjectInfo(activityData['projectId']).then((projectData) => {
                Object.assign(activityData, { projectData });
              })
            );
          });
          Promise.all(promises).then(() => {
            that.loadingProjectActivity = true;
            resolve(activities);
          });
          activitySubscriber.unsubscribe();
        } else {
          that.loadingProjectActivity = true;
          resolve(activities);
        }
      });
    });
  }

  async getProjectInfo(projectId: string) {
    return new Promise((resolve) => {
      const projectSubscribe = this.fs.doc$(`${CommonConfig.collectionName.project}/${projectId}`).subscribe((projectData) => {
        const requireProjectData = {
          title: projectData['title'],
          imageUrl: projectData['imageUrl']
        };
        resolve(requireProjectData);
        projectSubscribe.unsubscribe();
      });
    });
  }

  deleteWalkthroughScheduler(walkthroughId: string) {
    return new Promise((resolve) => {
      const cond = { isDeleted: true };
      this.fs.update(`${CommonConfig.collectionName.projectwalkthrough}/${walkthroughId}`, cond)
        .then(res => {
          resolve(true);
        }).catch(error => {
          resolve(false);
        });
    });
  }

  booklWalkthroughScheduler(walkthroughId: string, vendorId) {
    return new Promise((resolve) => {
      const cond = { bookVendorId: this.fs.firebaseFirestoreFieldValue().arrayUnion(vendorId) };
      this.fs.update(`${CommonConfig.collectionName.projectwalkthrough}/${walkthroughId}`, cond)
        .then(res => {
          resolve(true);
        }).catch(error => {
          resolve(false);
        });
    });
  }

  requestlWalkthroughScheduler(walkthroughId: string, vendorId: string, walkthroughRequestFromData) {
    return new Promise((resolve) => {
      Object.assign(walkthroughRequestFromData, {requestVendorId: this.fs.firebaseFirestoreFieldValue().arrayUnion(vendorId)});

      if (walkthroughId) {
        this.fs.update(`${CommonConfig.collectionName.projectwalkthrough}/${walkthroughId}`, walkthroughRequestFromData)
          .then(res => {
            resolve(true);
          }).catch(error => {
            resolve(false);
          });
      } else {
        Object.assign(walkthroughRequestFromData, {projectId: walkthroughRequestFromData['projectId']});
        this.fs.add(`${CommonConfig.collectionName.projectwalkthrough}`, walkthroughRequestFromData)
          .then(res => {
            resolve(true);
          }).catch(error => {
            resolve(false);
          });
      }
    });
  }

  cancelWalkthroughScheduler(projectWalkthrough: Partial<ProjectWalkthrough>, data) {
    this._dialogService.openConfirm({
      message: CommonConfig.popupAlertMessage.cancelWalkthrough,
      title: 'Cancel',
      acceptButton: CommonConfig.dialogService.accept,
      cancelButton: CommonConfig.dialogService.cancel
    })
      .afterClosed().subscribe((accept: boolean) => {
        if (accept) {
          const cond = { bookVendorId: this.fs.firebaseFirestoreFieldValue().arrayRemove(data.vendorId) };
          this.fs.update(`${CommonConfig.collectionName.projectwalkthrough}/${projectWalkthrough['id']}`, cond)
            .then(res => {
              // create duplicate copy of canceled schedule for save history
              projectWalkthrough['isDeleted'] = true;
              const dataObject = projectWalkthrough;
              delete dataObject['doc'];
              delete dataObject['createdAt'];
              delete dataObject['updatedAt'];
              this.fs.add(CommonConfig.collectionName.projectwalkthrough, dataObject);
              if (projectWalkthrough.bookVendorId.indexOf(data.vendorId) > -1) {
                projectWalkthrough.bookVendorId.splice(projectWalkthrough.bookVendorId.indexOf(data.vendorId), 1);
              }
              this.customService.showSnackBarSuccess(CommonConfig.successMessage.scheduleCanceled);
              this.sendDataToMailWebNotification(data);
              this.sendCancelCalendarNotification(projectWalkthrough, data);
            }).catch(error => {
              this.customService.showSnackBarError(CommonConfig.validationMessage.someError);
            });
        }
      });
  }
  getAllMemberAndOwner(allUsers): Promise<Object[]> {
    return new Promise(resolve => {
      const usersPromises = allUsers.map(id => {
        return this.fs.getCollection(CommonConfig.collectionName.users, id).get().then(user => user.data());
      });
      Promise.all(usersPromises)
        .then(usersData => {
          resolve(usersData);
        })
        .catch(err => {
          resolve([]);
        });
    });
  }
  async sendMailAndWebNotification(projects: Partial<Project>, data) {
    const allUsers = data.allUsers || [];
    const dataObject = this.setEmailTemplateIdData(data);
    const subject = dataObject['subject'];
    const templateId = dataObject['templateId'];
    const action = dataObject['action'];
    const projectTitle = projects['title'];
    const objectId = projects['customId'];
    const belongTo = CommonConfig.belongTo.project;

    const usersData = await this.getAllMemberAndOwner(allUsers);
    const usersIds = usersData.map((obj: Object) => obj['uid']);
    const dataForNotify = {
      usersIds,
      projectId: projects['customId'],
      authUid: data.authUid,
      action
    };
    this.sendWebNotification(dataForNotify);
    const allPromises = usersData.map((user: string) => {
      const dataForEmail = {
        toEmail: user['email'],
        userName: user['displayName'] || user['email'],
        subject,
        templateId,
        title: projectTitle,
        objectId,
        belongTo
      };
      return this.auth.sendEmail(dataForEmail);
    });
    Promise.all(allPromises).then().catch();
  }

  /**
   * @description function used for set email template default data
   * @param data contain data object
   */
  setEmailTemplateIdData(data) {
    const dataObject = {};
    if (data.action === CommonConfig.walkthroughStatus.confirmed) {
      dataObject['subject'] = CommonConfig.emailSubject.walkthroughScheduleAlert;
      dataObject['templateId'] = CommonConfig.emailTemplates.walkthroughScheduleAlert;
      dataObject['action'] = CommonConfig.walkthroughStatus.confirmed;
    } else if (data.action === CommonConfig.walkthroughStatus.canceled || data.action === CommonConfig.walkthroughStatus.removed) {
      dataObject['subject'] = CommonConfig.emailSubject.walkthroughCancel;
      dataObject['templateId'] = CommonConfig.emailTemplates.walkthroughCancel;
      dataObject['action'] = CommonConfig.walkthroughStatus.canceled;
    } else if (data.action === CommonConfig.walkthroughStatus.request) {
      dataObject['subject'] = CommonConfig.emailSubject.walkthroughRequest;
      dataObject['templateId'] = CommonConfig.emailTemplates.walkthroughRequest;
      dataObject['action'] = CommonConfig.walkthroughStatus.request;
    }
    return dataObject;
  }

  /**
   *
   * @param walkthrough contains project walkthrough data.
   * @param requiredData contains vendorId, project Details etc. required to process calendar notification.
   */
  async sendIcsCalendarNotification(walkthrough, requiredData, requestDescription?: string) {
    let toEmails = [];
    const projectCreatorInfo: Partial<User> = await this.auth.getUserInformation(requiredData.projects.createdBy);
    await this.getMembersData(walkthrough, requiredData);
    if (walkthrough.vendorOwnerEmail !== requiredData.projects.userInformation['email']) {
      toEmails.push(requiredData.projects.userInformation['email'], walkthrough.vendorOwnerEmail);
    } else {
      toEmails.push(walkthrough.vendorOwnerEmail);
    }
    toEmails = toEmails.concat(walkthrough.allMemberEmails);
    const fromDt = requiredData.isRemoveWalthrough ? walkthrough.from : requiredData.isRequestWalthrough ? requiredData.fromRequest : walkthrough.from.toDate();
    let wtDescription: string;
    let defaultWtDesc: string;
    if (requestDescription) {
      wtDescription = requestDescription;
    } else {
      wtDescription = walkthrough.description;
    }
    if (projectCreatorInfo.phone) {
      defaultWtDesc = `For questions about this walkthrough please contact ${projectCreatorInfo.email} or ${projectCreatorInfo.phone}`;
    } else {
      defaultWtDesc = `For questions about this walkthrough please contact ${projectCreatorInfo.email}`;
    }
    const dataForCal = {
      fromDt,
      walkthroughId: walkthrough.id,
      toEmail: toEmails,
      userName: requiredData.projects.userInformation['displayName'] || requiredData.projects.userInformation['email'],
      subject: requiredData.subject,
      title: requiredData.projects.title,
      location: requiredData.projects.location,
      categories: requiredData.projects.category,
      status: requiredData.status,
      method: requiredData.method,
      sequence: requiredData.sequence,
      organizer: requiredData.projects.userInformation,
      attendees: walkthrough.attendees,
      objectId: requiredData.projects['customId'],
      belongTo: CommonConfig.belongTo.project,
      description: `${wtDescription}\n\n${defaultWtDesc}\n\nFor support email ${CommonConfig.supportEmail}`
    };
    return this.iCalendarService.send_iCalendar(dataForCal);
  }

  sendWebNotification(dataForNotify: Object) {
    const dataForNotification = {
      'uid': dataForNotify['authUid'],
      'projectId': dataForNotify['projectId'],
      'sendToUsers': dataForNotify['usersIds']
    };
    if (dataForNotify['action'] === CommonConfig.walkthroughStatus.confirmed) {
      dataForNotification['activityType'] = CommonConfig.projectActivityType.walkthroughBooked;
    } else if (dataForNotify['action'] === CommonConfig.walkthroughStatus.request) {
      dataForNotification['activityType'] = CommonConfig.projectActivityType.walkthroughRequested;
    } else if (dataForNotify['action'] === CommonConfig.walkthroughStatus.canceled) {
      dataForNotification['activityType'] = CommonConfig.projectActivityType.walkthroughCancelled;
    }
    this.saveInsideNotificationPayload(dataForNotification);
  }

  saveInsideNotificationPayload(data) {
    const category = CommonConfig.notificationActivityType.projectActivity;
    const activityType = data.activityType;
    const sendBy = data.uid;
    const id = data.projectId;
    const readFlag = false;
    const forAdmin = data.forAdmin || false;
    const isActive = true;
    const usersId = data.sendToUsers;
    for (const userId of usersId) {
      if (data.uid && data.uid !== userId) {
        const payloadData = { category, userId, activityType, sendBy, id, readFlag, forAdmin, isActive };
        this.messagingService.saveNotificationPayload(payloadData);
      }
    }
  }
  async sendDataToMailWebNotification(data) {
    let allUsers: Array<Object> = [];
    const projects = data.projects;
    const isOwner = this.checkIfOwnerOrMentor(data.authUid, projects);
    if (isOwner) {
      const vendorData = await this.vendorsService.getVendorDataById(data.vendorId);
      if (vendorData['members']) {
        allUsers = allUsers.concat(vendorData['members']);
      }
      allUsers.push(vendorData['uid']);
    } else {
      if (projects.members) {
        allUsers = allUsers.concat(projects.members);
      }
      allUsers.push(projects.uid);
    }
    data['allUsers'] = allUsers;
    this.sendMailAndWebNotification(data.projects, data);
  }
  checkIfOwnerOrMentor(authUid: string, project: Project) {
    if (authUid === project['uid'] || project.members && project.members.includes(authUid)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   *
   * @param projectWalkthrough contains project walkthrough data
   * @param requiredData contains vendorId, project Details and auth userId etc. required to process calendar notification.
   */
  sendCancelCalendarNotification(projectWalkthrough: Partial<ProjectWalkthrough>, requiredData) {
    requiredData['status'] = calendarIcsStatus.cancelled;
    requiredData['method'] = icsMethods.cancel;
    requiredData['sequence'] = 1;
    requiredData['subject'] = calendarInviteSubject.cancelEvent;
    this.sendIcsCalendarNotification(projectWalkthrough, requiredData);
  }

  /**
   *
   * @param walkthrough contains project walkthrough data.
   * @param requiredData contains vendorId, project Details etc. required to process calendar notification.
   */
  async getMembersData(walkthrough, requireData) {
    const vendorData: Partial<Vendor> = await this.vendorsService.getVendorDataById(requireData.vendorId);
    let allProjectMemebrs = [];
    const allMemberEmails = [];
    const attendees = [];
    if (vendorData && vendorData.members) {
      for (const member of vendorData.members) {
        const memberInfo: Partial<User> = await this.auth.getUserInformation(member);
        if (allMemberEmails.indexOf(memberInfo.email) <= -1) {
          allMemberEmails.push(memberInfo.email);
        }
        attendees.push({ email: memberInfo.email, name: memberInfo.displayName, rsvp: true });
      }
    }
    if (requireData.projects && requireData.projects.members) {
      allProjectMemebrs = allProjectMemebrs.concat(requireData.projects.members);
      for (const uid of allProjectMemebrs) {
        const userInfo: Partial<User> = await this.auth.getUserInformation(uid);
        if (allMemberEmails.indexOf(userInfo.email) <= -1) {
          allMemberEmails.push(userInfo.email);
        }
        attendees.push({ email: userInfo.email, name: userInfo.displayName });
      }
    }
    walkthrough['allMemberEmails'] = allMemberEmails;
    walkthrough['vendorOwnerEmail'] = vendorData.userInformation.email;
    walkthrough['attendees'] = attendees;
  }
}
