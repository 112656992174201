import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Config
import { environment } from 'environments/environment';
import { CommonConfig } from '@shared/common.config';
import { CommonUtils } from '@shared/common.util';
// Model
import { Project } from '@app/core/model/project';
import { User } from '@app/core/model/user';
// Service
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { CustomService } from '@shared/service/custom/custom.service';
import { ProjectsService } from '@shared/service/projects/projects.service';

interface ProjectsActions {
  isRevised?: boolean;
}

@Component({
  selector: 'app-need-project-revision',
  templateUrl: './need-project-revision.component.html',
  styleUrls: ['./need-project-revision.component.scss']
})
export class NeedProjectRevisionComponent implements OnInit {
  commonConfig = CommonConfig;
  isSubmit = false;
  dialogRef;
  project: Project;
  authUser: Partial<User>;
  constructor(
    private readonly http: HttpClient,
    public fs: FirestoreService,
    private readonly customService: CustomService,
    private readonly projectsService: ProjectsService
    ) { }

  ngOnInit() {
  }

  submit(revisionReasonValue: string) {
    this.isSubmit = true;
    let projectAction: ProjectsActions = {};
    if (this.project['projectAction']) {
      projectAction = this.project['projectAction'];
    }
    projectAction['isRevised'] = true;
    const revisionReason = revisionReasonValue;
    this.fs.update(`${this.commonConfig.collectionName.project}/${this.project['id']}`,
      { projectAction, revisionReason }).then(async (res) => {
        this.isSubmit = false;
        /*Send email to project owner for project revise*/
        if (this.project['userInformation'] && this.project['userInformation']['email']) {
          const userName = this.project['userInformation']['displayName'] ? this.project['userInformation']['displayName'] :
            this.project['userInformation']['email'];
          const data = {
            toEmail: this.project['userInformation']['email'],
            userName, revisionReason, objectId: this.project['id'],
            belongTo: this.commonConfig.belongTo.project, title: this.project['title'], subject:
              this.commonConfig.emailSubject.needRevision, templateId: this.commonConfig.emailTemplates.needRevision
          };
          const commonUtil = new CommonUtils(this.fs);
          const isAllowed = await commonUtil.isEmailAllow(data['toEmail'], data['belongTo']);
          if (isAllowed) {
            const sendEmail = this.http.post(`${environment.cloudEndPoint}emailSend`, data).subscribe(results => {
              sendEmail.unsubscribe();
            });
          }
          // save project activity
          const dataObject = {
            uid: this.authUser['uid'], projectId: this.project['id'], activityType:
              this.commonConfig.projectActivityType.projectrevision
          };
          this.projectsService.saveProjectActivity(dataObject, this.project);
        }
        this.customService.showSnackBarSuccess(this.commonConfig.successMessage.needRevision);
        this.project['projectAction'] = projectAction;
        this.dialogRef.close();
      }).catch(err => {
        this.customService.showSnackBarError(this.commonConfig.validationMessage.someError);
      });
  }

}
