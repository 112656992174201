import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from 'environments/environment';

import * as filestack from 'filestack-js';
const client = filestack.init(environment.filestack.app_key);

@Injectable({
  providedIn: 'root'
})
export class FilestackService {

  private fileInfo = new BehaviorSubject([]);
  cast = this.fileInfo.asObservable();
  fromSources: string[] = ['local_file_system', 'googledrive', 'dropbox', 'onedrive', 'webcam'];
  crop: Object = true;
  isCircle = true;
  constructor() {
  }

  openPicker(that) {
    if (that.aspectRatio) {
      this.crop = {
        aspectRatio: that.aspectRatio,
      };
    }
    if (that.removeCircle) {
      this.isCircle = false;
    }
    const options = {
      fromSources: this.fromSources,
      accept: that.fileAccept,
      transformations: {
        crop: this.crop,
        circle: this.isCircle,
      },
      maxFiles: that.maxFiles,
      onUploadDone (res) {
        that.filestackCallback(res);
      }
    };
    client.picker(options).open();
  }

  filePreview(handle) {
    client.preview(handle, { id: 'previewId' });
  }

  forImageUplad(file: object) {
    const token = {};
    client.upload(file, {}, {}, token)
      .then(res => {
        this.fileInfo.next(res);
      })
      .catch(err => {
        this.fileInfo.next(err);
      });
  }

  forCropAndUploadImage($event, that) {
    const files = $event.target.files;
    const pickOptions = {
      transformations: {
        crop: {
          aspectRatio: that.aspectRatio,
        },
        circle: that.isCircle
      },
      onUploadDone(res) {
        that.filestackCallback(res);
      }
    };
    const picker = client.picker(pickOptions);
    picker.crop(files);
  }

}
