import { Pipe, PipeTransform } from '@angular/core';
import { CommonConfig } from '../common.config';

@Pipe({ name: 'capitalize' })
export class CapitalizePipe implements PipeTransform {
  transform(value: string) {
    if (value) {
      return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
    }
    return value;
  }
}

@Pipe({ name: 'ellipsis' })
export class EllipsisPipe implements PipeTransform {
  transform(str: string, strLength = 150) {
    const withoutHtml = str.replace(/(<([^>]+)>)/ig, '');
    if (str.length >= strLength) {
      return `${withoutHtml.slice(0, strLength)}...`;
    }
    return withoutHtml;
  }
}

@Pipe({ name: 'linebreak' })
export class LinebreakPipe implements PipeTransform {
  transform(str: string) {
    if (str) {
      return str.replace(new RegExp('\n', 'g'), '<br />');
    } else {
      return str;
    }
  }
}

@Pipe({ name: 'titlecases' })
export class TitleCasePipe implements PipeTransform {
  transform(word: string) {
    if (!word) { return word; }
    const stringArray = word.split(' ');
    let characterCount = 1;
    if (!stringArray[1]) {
      characterCount = CommonConfig.two;
    }
    const firstLetter = stringArray[0] ? stringArray[0].slice(0, characterCount) : '';
    const secondLetter = stringArray[1] ? stringArray[1].slice(0, 1) : '';
    return firstLetter + secondLetter;
  }
}
