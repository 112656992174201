import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { CustomValidator } from '@app/shared/validator/custom.validator';
import { CommonConfig } from '@app/shared/common.config';
import { OrganizationConfig } from '@app/shared/organization.config';
import { FirestoreService } from '@app/shared/service/firestore/firestore.service';
import { CustomService } from '@app/shared/service/custom/custom.service';
import { OrganizationService } from '@app/shared/service/organization/organization.service';
import { User } from '@app/core/model/user';
import { OrgUserPermissions } from '@app/shared/common.constants';
import { UserInformation } from '@app/core/model/user-information';
import { AuthService } from '@app/core/auth.service';
import { OrganizationMembers } from '@app/core/model/organization-members';
import { Organization } from '@app/core/model/organization';
@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.scss']
})
export class InviteUserComponent implements OnInit {
  public commonConfig = CommonConfig;
  public orgUserPermissions = OrgUserPermissions.data;
  public organizationConfig = OrganizationConfig;
  public inviteUserToOrgForm: FormGroup;
  public isSubmit = false;
  public dialogRef;
  public userInfo: User;
  public organizationData: Partial<Organization>;
  public inviteUserToOrgRoles = [this.organizationConfig.roles.admin, this.organizationConfig.roles.teamMember];
  private userBriefDetails: UserInformation;

  constructor(private readonly formBuilder: FormBuilder, private readonly customService: CustomService,
    public fs: FirestoreService, public _authService: AuthService, private readonly organizationService: OrganizationService) { }

  ngOnInit() {
    this.buildFrom();
    this.onChanges();
  }

  /**
   * @description Function used for create reactive form to invite organization
   */
  buildFrom() {
    const selectAllControl = new FormControl(false);
    const formControls = this.orgUserPermissions.map(control => this.formBuilder.control(false));

    this.inviteUserToOrgForm = this.formBuilder.group({
      role: ['', CustomValidator.required],
      email: ['', [Validators.required, Validators.email, CustomValidator.validEmail]],
      uid: [''],
      userInformation: [null],
      invitedBy: [this.userInfo.uid],
      status: [this.organizationConfig.orgMemberStatus.pending],
      isActive: [false],
      isSoftDelete: [false],
      isHardDelete: [false],
      request: [this.organizationConfig.orgMemberRequest.join],
      all: selectAllControl,
      permissions: this.formBuilder.array(formControls)
    });
    this.inviteUserToOrgForm.get('userInformation').setValue(this.userBriefDetails);
  }

  onChanges(): void {
    // Subscribe to changes on the selectAll checkbox
    this.inviteUserToOrgForm.get('all').valueChanges.subscribe(bool => {
      this.inviteUserToOrgForm
        .get('permissions')
        .patchValue(Array(this.orgUserPermissions.length).fill(bool), { emitEvent: false });
    });

    // Subscribe to changes on the permissions checkboxes
    this.inviteUserToOrgForm.get('permissions').valueChanges.subscribe(val => {
      const allSelected = val.every(bool => bool);
      if (this.inviteUserToOrgForm.get('all').value !== allSelected) {
        this.inviteUserToOrgForm.get('all').patchValue(allSelected, { emitEvent: false });
      }
    });
  }

  /**
   * @description get email from form
   */
  get email() {
    return this.inviteUserToOrgForm.get('email');
  }

  /**
   * @description get role from form
   */
  get role() {
    return this.inviteUserToOrgForm.get('role');
  }

  /**
   * @description Function used for submit the invite organization form data
   */
  submit() {
    this.isSubmit = true;
    const inviteUserEmail = this.inviteUserToOrgForm.value.email;
    const payload = this.inviteUserToOrgForm.value;
    this.setOrgPermission(payload);
    this.setOrganizationData(payload);
    this._authService.getUserByEmail(inviteUserEmail).then(async (userRes: Array<User>) => {
      if (!this.isRegisteredUser(userRes)) {
        const isAlreadyInvited = await this.organizationService.checkEmailInOrgMembers(inviteUserEmail);
        if (isAlreadyInvited) {
          this.customService.showSnackBarError(this.commonConfig.validationMessage.alreadyInvited);
        } else {
          payload['userInformation'] = null;
          delete payload.all;
          this.addUserAsOrgMember(payload);
        }
      } else if (!this.isEnterpriseUser(userRes)) {
        this.customService.showSnackBarError(this.commonConfig.validationMessage.isValidSubscription);
      } else {
        const isAlreadyOrgMember = await this.organizationService.isAlreadyOrgMember(userRes[0].uid);
        if (isAlreadyOrgMember) {
        this.customService.showSnackBarError(this.commonConfig.validationMessage.alreadyOrgMember);
        } else {
          payload.uid = userRes[0].uid;
          payload.userInformation = this.getUserBriefDetails(userRes[0]);
          payload['status'] = this.organizationConfig.orgMemberStatus.approved;
          payload['isActive'] = true;
          payload['request'] = this.organizationConfig.orgMemberRequest.joined;
          delete payload.all;
          this.addUserAsOrgMember(payload);
        }
      }
      this.isSubmit = false;
    });
  }


  /**
   * @description function to get checked permissions.
   */
  getOrgPermission() {
    return this.inviteUserToOrgForm.value.permissions
      .map((checked, index) => checked ? this.orgUserPermissions[index].value : null)
      .filter(value => value !== null);
  }


  /**
   * @description function to add permissions with user. If invited user is admin only then permissions available.
   * team member doesn't have any permissions.
   * @param payload contains organization member data
   */
  setOrgPermission(payload) {
    if (payload.role === this.commonConfig.role.admin.toLocaleLowerCase()) {
      const selectedPermissions = this.getOrgPermission();
      payload['permissions'] = selectedPermissions;
    } else {
      payload['permissions'] = [];
    }
  }

  /**
   * @description function to check if the user to whom inviting is valid or not. user must have registered on ventract platform
   * @param userRes contains user data, to whom inviting.
   */
  isRegisteredUser(userRes) {
    return (userRes && userRes.length);
  }

  /**
   * @description function to check if the user to whom inviting is valid or not. user must have enterprise subscription
   * @param userRes contains user data, to whom inviting.
   */
  isEnterpriseUser(userRes) {
    return (userRes && userRes.length && userRes[0].subscription && userRes[0].subscription.plan_id === this.commonConfig.subscriptionPlanType.enterprise);
  }

  /**
   * @description function to set organization data with user, to be updated in user collection..
   * @param payload contains organization member data
   */
  setOrganizationData(payload) {
    payload.orgId = this.organizationData.id;
    payload['organization'] = {};
    payload['organization']['name'] = this.organizationData.name;
    payload['organization']['id'] = this.organizationData.id;
  }

  /**
   * @description Function used to get brief user details
   * @param userInfo contains user data
   */
  getUserBriefDetails(userInfo) {
    return {
      uid: userInfo.uid,
      displayName: userInfo.displayName || '',
      email: userInfo.email || '',
      photoUrl: (userInfo.profilePhoto && userInfo.profilePhoto.url) ? userInfo.profilePhoto.url : ''
    };
  }

  /**
   * @description Function used to add data in organization-members collection.
   * @param payload contains data of organization member to be saved.
   */
  addUserAsOrgMember(payload: OrganizationMembers) {
    this.fs.add(`${this.commonConfig.collectionName.organizationMembers}`, payload).then(async (res) => {
      payload.uid && this.updateOrgInUser(payload);
      this.isSubmit = false;
      this.customService.showSnackBarSuccess('User Invited Successfully.');
      this.dialogRef.close();
    }).catch(err => {
      this.customService.showSnackBarError(this.commonConfig.validationMessage.someError);
    });
  }

  /**
   * @description Function used to update organization details associated with users in user collection
   * @param data contains data of organization member.
   */
  updateOrgInUser(data: OrganizationMembers) {
    this.organizationService.updateOrgInUser(data);
  }
}
