import { Component, OnInit, Input } from '@angular/core';
import { StickyDirection } from '@angular/cdk/table';
import { Subscription } from 'rxjs';
import { VendorActions } from '@app/core/model/vendor-actions';
import { Vendor } from '@app/core/model/vendor';
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { CustomService } from '@shared/service/custom/custom.service';
import { AuthService } from '@app/core/auth.service';
import { VendorsService } from '@shared/service/vendors/vendors.service';
import { CommonConfig } from '@shared/common.config';

@Component({
  selector: 'app-vendor-favorite',
  templateUrl: './vendor-favorite.component.html',
  styleUrls: ['./vendor-favorite.component.scss']
})
export class VendorFavoriteComponent implements OnInit {
  uid: string;
  vendorId: string;
  @Input() starClass: string;
  @Input() vendor: Vendor;
  isFavorite: boolean;
  vendorActions: VendorActions;
  favoriteVendorIds = [];
  cond: {};
  private authSubscription: Subscription;
  constructor(private readonly firestoreService: FirestoreService, private readonly customService: CustomService, private readonly auth: AuthService,
    private readonly vendorsService: VendorsService) {
  }

  ngOnInit() {
    if (this.vendor && this.vendor['id']) {
      this.vendorId = this.vendor['id'];
      if (this.vendor['favorites']) {
        this.favoriteVendorIds = this.vendor['favorites'];
      }
    }
    this.authSubscription = this.auth.user.subscribe(res => {
      if (res) {
        this.uid = res['uid'];
      }
      this.authSubscription.unsubscribe();
    });
  }

  favoriteUnfavorite($event, vendorId: string) {
    $event.stopPropagation();
    let isFavoriteVendor: boolean;
    if (this.favoriteVendorIds && this.favoriteVendorIds.includes(this.uid)) {
      const index = this.favoriteVendorIds.indexOf(this.uid);
      if (index > -1) {
        isFavoriteVendor = false;
        this.cond = { favorites: this.firestoreService.firebaseFirestoreFieldValue().arrayRemove(this.uid) };
        this.favoriteVendorIds.splice(index, 1);
      }
    } else {
      isFavoriteVendor = true;
      this.cond = { favorites: this.firestoreService.firebaseFirestoreFieldValue().arrayUnion(this.uid) };
      this.favoriteVendorIds.push(this.uid);
    }

    this.firestoreService.update(`${CommonConfig.collectionName.vendors}/${vendorId}`, this.cond)
      .then(results => {
        const dataObject = { uid: this.uid, activityType: '' };
        dataObject['vendorId'] = vendorId;
        dataObject['activityType'] = isFavoriteVendor ? CommonConfig.vendorActivityType.favorite :
          CommonConfig.vendorActivityType.unfavorite;
        this.vendorsService.saveVendorActivity(dataObject, this.vendor);
      })
      .catch(error => {
        if (error) {
          this.favoriteUnfavoriteError();
        }
      });
  }

  favoriteUnfavoriteError() {
    if (this.favoriteVendorIds && this.favoriteVendorIds.includes(this.uid)) {
      const index = this.favoriteVendorIds.indexOf(this.uid);
      if (index > -1) {
        this.favoriteVendorIds.splice(index, 1);
      }
    } else {
      this.favoriteVendorIds.push(this.uid);
    }
    this.customService.showSnackBarError(CommonConfig.validationMessage.someError);
  }

}
