
import {catchError, map} from 'rxjs/operators';

import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
  constructor() { }
  currentData;
  intercept(req: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
    const clonedRequest = req.clone({
      responseType: 'text'
    });

    return next.handle(clonedRequest).pipe(
      map((event) => {
        if (event instanceof HttpResponse) {
          try {
            this.currentData = JSON.parse(event.body);
          } catch (e) {
            this.currentData = event.body;
          }
          return event.clone({
            body: this.currentData
          });
        }
      }),
      catchError((error: HttpErrorResponse) => {
        const parsedError = Object.assign({}, error, { error: JSON.parse(error.error) });
        return throwError(new HttpErrorResponse(parsedError));
      }));
  }
}
