import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CommonConfig } from '@shared/common.config';
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { CustomService } from '@shared/service/custom/custom.service';

import { ProductService } from '@app/core/model/product-service';
import { VendorsService } from '@shared/service/vendors/vendors.service';
import { trackEvent } from '../../../shared/common.constants';
import { GanalyticsService } from '@app/shared/service/ganalytics/ganalytics.service';


@Component({
  selector: 'app-create-products',
  templateUrl: './create-products.component.html',
  styleUrls: ['./../products-services.component.scss']
})
export class CreateProductsComponent implements OnInit {
  product: ProductService;
  vendorId: string;
  uid: string;
  commonConfig = CommonConfig;
  dialogRef;
  productsServicesObj;
  isSubmit = false;
  isDashboard = false;
  constructor(
    private readonly router: Router,
    private readonly firestoreService: FirestoreService,
    private readonly customService: CustomService,
    private readonly vendorsService: VendorsService,
    private readonly ganalyticsService: GanalyticsService
    ) { }

  ngOnInit() {
  }

  submit(formValue) {
    if (this.product && this.product['id']) {
      this.createProducts(formValue);
    } else {
      this.updateProducts(formValue);
    }
  }

  /**
   * @description Function used for create new vendor products
   */
  createProducts(formValue) {
    this.isSubmit = true;
    this.firestoreService.update(`${this.commonConfig.collectionName.products}/${this.product['id']}`,
    formValue).then(
      response => {
        for (let i = 0; i < this.productsServicesObj.productsServices.length; i++) {
          if (this.productsServicesObj.productsServices[i]['id'] === this.product['id']) {
            formValue['id'] = this.product['id'];
            this.productsServicesObj.productsServices[i] = formValue;
          }
        }
        this.isSubmit = false;
        this.customService.showSnackBarSuccess(this.commonConfig.successMessage.updateProduct);
        this.ganalyticsService.analyticsTrackEvent(trackEvent.updateProduct);
        this.dialogRef.close();
        this.vendorsService.saveProductActivity(this.vendorId, this.uid, this.commonConfig.vendorActivityType.updateproduct);
      }
    ).catch(
      error => {
        this.isSubmit = false;
        this.customService.showSnackBarError(this.commonConfig.validationMessage.someError);
        this.ganalyticsService.analyticsTrackEvent(trackEvent.updateProductError);
      }
    );
  }

  /**
   * @description Function used for update vendor products
   */
  updateProducts(formValue) {
    this.isSubmit = true;
    this.firestoreService.add(this.commonConfig.collectionName.products, formValue).then(
      response => {
        formValue['id'] = response['id'];
        if (this.productsServicesObj.productsServices) {
          this.productsServicesObj.productsServices.unshift(formValue);
        }
        this.isSubmit = false;
        this.customService.showSnackBarSuccess(this.commonConfig.successMessage.addProduct);
        this.ganalyticsService.analyticsTrackEvent(trackEvent.addProduct);
        this.dialogRef.close();
        this.vendorsService.saveProductActivity(this.vendorId, this.uid, this.commonConfig.vendorActivityType.addproduct);
        if (this.isDashboard) {
          this.router.navigate([`/${this.commonConfig.routerName.vendors}${this.commonConfig.routerName.vendorfeature}`, this.commonConfig.vendorFeatures.products,
          this.vendorId, this.commonConfig.vendorFeatureView.list]);
        }
      }
    ).catch(
      error => {
        this.isSubmit = false;
        this.customService.showSnackBarError(this.commonConfig.validationMessage.someError);
        this.ganalyticsService.analyticsTrackEvent(trackEvent.addProductError);
      }
    );
  }


}
