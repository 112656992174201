import { Component, OnInit, Input } from '@angular/core';
import { TdDialogService } from '@covalent/core/dialogs';
import { MatDialog } from '@angular/material';
import { CustomService } from '@app/shared/service/custom/custom.service';
import { FirestoreService } from '@app/shared/service/firestore/firestore.service';
import { ProjectsService } from '@app/shared/service/projects/projects.service';
import { CommonConfig } from '@app/shared/common.config';
import { ProjectBid } from '@app/core/model/project-bid';
import { User } from '@app/core/model/user';
import { NeedBidRevisionComponent } from './need-bid-revision/need-bid-revision.component';
import { ConfirmDeleteComponent } from '@app/+admin/confirm-delete/confirm-delete.component';
import { Router, ActivatedRoute } from '@angular/router';
import { GanalyticsService } from '@app/shared/service/ganalytics/ganalytics.service';
import { trackEvent } from '@app/shared/common.constants';
import { CommonUtils } from '@app/shared/common.util';

interface BidCondtions {
  status: string;
  isRejected?: boolean;
}

@Component({
  selector: 'app-owner-bid-list',
  templateUrl: './owner-bid-list.component.html',
  styleUrls: ['../../../../../assets/scss/details-pages.scss']
})
export class OwnerBidListComponent implements OnInit {
  commonConfig = CommonConfig;
  @Input() totalBid: number;
  @Input() lowestBid: ProjectBid;
  @Input() acceptedBid: ProjectBid;
  @Input() bidList: Array<ProjectBid>;
  @Input() showBidEndMsg: boolean;
  @Input() bidStatus: string;
  @Input() tabListLoading: boolean;
  @Input() projectsData;
  @Input() parentObject;
  @Input() usersInfo: User;
  @Input() isAdmin = false;
  @Input() isAdminBidList = false;
  @Input() currentPage: string;
  public currentURL;
  public selectedIds: Array<string> = [];
  @Input() orgId = '';
  deleteHeaderText = 'Delete Bid';
  deleteTitle = 'bid';
  public collectionName = this.commonConfig.collectionName.projectbid;
  private readonly currentTime = new Date().getTime();
  constructor(
    private readonly activatedRoutes: ActivatedRoute,
    public fs: FirestoreService,
    private readonly _dialogService: TdDialogService,
    private readonly customService: CustomService,
    public dialog: MatDialog,
    private readonly projectsService: ProjectsService,
    private readonly router: Router,
    private readonly ganalyticsService: GanalyticsService
  ) {
    this.currentURL = `/${new CommonUtils().makePrefixURL(this.router.url, 'grid')}/vendor-profile`;
    if (this.currentURL.indexOf('?') !== -1) {
      this.activatedRoutes.params.subscribe(param => {
        this.currentURL = `/admin/manage-bids/project-detail/vendor-profile/${param['id']}/${param['uid']}`;
      });
    }
  }

  ngOnInit() {
  }

  hideShowDetail(bidData: ProjectBid) {
    if (!bidData['display']) {
      bidData['display'] = true;
      if (!bidData['isRead']) {
        this.fs.update(`${this.commonConfig.collectionName.projectbid}/${bidData['id']}`,
          { isRead: true });
        bidData['isRead'] = true;
      }
    } else {
      bidData['display'] = false;
    }
  }

  updateBidStatus(statusType: string, bidData: ProjectBid) {
    if (this.isMaterialBidExpired(bidData) && statusType === this.commonConfig.projectBidStatus.accepted) {
      this._dialogService.openAlert({
        message: this.commonConfig.popupAlertMessage.bidValidity
      });
    } else if (bidData['vendorInfo'] && !bidData['vendorInfo']['isActive']) {
      this._dialogService.openAlert({
        message: this.commonConfig.popupAlertMessage.userVendorDeactivated
      });
    } else {
      const messageObject = this.setAlertSuccessMessage(statusType, bidData);
      const alertMessage = messageObject['alertMessage'];
      const successMessage = messageObject['successMessage'];
      const title = messageObject['title'];
      this._dialogService.openConfirm({
        message: alertMessage,
        title,
        acceptButton: this.commonConfig.dialogService.accept,
        cancelButton: this.commonConfig.dialogService.cancel
      })
        .afterClosed().subscribe((accept: boolean) => {
          if (accept) {
            this.submitBidStatusRequest(bidData, { statusType, successMessage });
          }
        });
    }
  }

  /**
   * @description function used for check the material bid line item price expired
   */
  isMaterialBidExpired(bidData) {
    return this.isVendorMaterialBids(bidData['projectType']) &&
      (bidData['isPricingExpire'] && this.isBidPricingExpired(bidData));
  }

  isBidPricingExpired = (bidData) => (bidData['priceExpireDate'] && this.currentTime > bidData['priceExpireDate'].toDate());

  /**
   * @description Function used for subkit bid status request
   * @param bidData contain bid data object
   * @param optionData contain otpion data success message or status type
   */
  submitBidStatusRequest(bidData, optionData) {
    const cond: BidCondtions = { status: optionData.statusType };
    if (optionData.statusType === this.commonConfig.projectBidStatus.rejected) {
      cond['isRejected'] = true;
      this.saveBidRejectNotification(bidData);
    }
    this.fs.update(`${this.commonConfig.collectionName.projectbid}/${bidData['id']}`,
      cond).then(res => {
        if (optionData.statusType === this.commonConfig.projectBidStatus.accepted
          || optionData.statusType === this.commonConfig.projectBidStatus.pending) {
          this.fs.update(`${this.commonConfig.collectionName.project}/${bidData['projectId']}`,
            { bidStatus: optionData.statusType });
          this.bidStatus = optionData.statusType;
          if (optionData.statusType === this.commonConfig.projectBidStatus.pending) {
            this.acceptedBid.bidAmount = this.commonConfig.zero;
          }
          this.saveActivityNotificationForBid(optionData.statusType, bidData);
        }
        this.customService.showSnackBarSuccess(optionData.successMessage);
        this.trackBidStatusEvent(optionData.statusType, bidData);
        bidData['status'] = optionData.statusType;
      }).catch(err => {
        this.customService.showSnackBarError(this.commonConfig.validationMessage.someError);
        this.ganalyticsService.analyticsTrackEvent((bidData['projectType'] === this.commonConfig.projectType.quote)
          ? trackEvent.quoteError : trackEvent.bidError, bidData['projectId']);
      });
  }

  /**
   * @description Function used for set alert and success message on bid accept and reject
   * @param {string} statusType contain the status of bid
   * @param {object} bidData contain bid data
   */
  setAlertSuccessMessage(statusType, bidData) {
    let dataObject = {};
    if (statusType === this.commonConfig.projectBidStatus.accepted) {
      dataObject = this.manageBidAcceptMessage(dataObject, bidData);
    } else if (statusType === this.commonConfig.projectBidStatus.pending) {
      dataObject = this.manageBidUnacceptMessage(dataObject, bidData);
    } else {
      dataObject = this.manageBidRejectedMessage(dataObject, bidData);
    }
    return dataObject;
  }

  // Managing bid accept messages
  manageBidAcceptMessage(dataObject, bidData) {
    if (bidData['projectType'] === this.commonConfig.projectType.quote) {
      dataObject['alertMessage'] = this.commonConfig.popupAlertMessage.quoteAccepted;
      dataObject['successMessage'] = this.commonConfig.successMessage.quoteAccepted;
      dataObject['title'] = 'Accept Quote';
    } else {
      dataObject['alertMessage'] = this.commonConfig.popupAlertMessage.bidAccepted;
      dataObject['successMessage'] = this.commonConfig.successMessage.bidAccepted;
      dataObject['title'] = 'Accept Bid';
    }
    return dataObject;
  }

  // Managing bid un accept messages
  manageBidUnacceptMessage(dataObject, bidData) {
    if (bidData['projectType'] === this.commonConfig.projectType.quote) {
      dataObject['alertMessage'] = this.commonConfig.popupAlertMessage.quoteUnaccepted;
      dataObject['successMessage'] = this.commonConfig.successMessage.quoteUnaccepted;
      dataObject['title'] = 'Unaccept Quote';
    } else {
      dataObject['alertMessage'] = this.commonConfig.popupAlertMessage.bidUnaccepted;
      dataObject['successMessage'] = this.commonConfig.successMessage.bidUnaccepted;
      dataObject['title'] = 'Unaccept Bid';
    }
    return dataObject;
  }

  // Managing bid rejetced messages
  manageBidRejectedMessage(dataObject, bidData) {
    if (bidData['projectType'] === this.commonConfig.projectType.quote) {
      dataObject['alertMessage'] = this.commonConfig.popupAlertMessage.quoteRejected;
      dataObject['successMessage'] = this.commonConfig.successMessage.quoteRejected;
      dataObject['title'] = 'Reject Quote';
    } else {
      dataObject['alertMessage'] = this.commonConfig.popupAlertMessage.bidRejected;
      dataObject['successMessage'] = this.commonConfig.successMessage.bidRejected;
      dataObject['title'] = 'Reject Bid';
    }
    return dataObject;
  }

  /**
   * @description Function used for save bid reject notification
   * @param {object} bidData contain bid data
   */
  saveBidRejectNotification(bidData) {
    const requireObject = {};
    requireObject['uid'] = this.usersInfo['uid'];
    requireObject['projectId'] = bidData['projectId'];
    requireObject['sendToUsers'] = [bidData['uid']];
    if (bidData['projectType'] === this.commonConfig.projectType.quote) {
      requireObject['activityType'] = this.commonConfig.projectActivityType.quotereject;
    } else {
      requireObject['activityType'] = this.commonConfig.projectActivityType.bidreject;
    }
    this.projectsService.saveInsideNotificationPayload(requireObject, this.projectsData);
  }

  /**
   * @description Function used for save project bid activity and notification for bid
   * @param {string} statusType contain the status of bid
   * @param {object} bidData contain bid data
   */
  saveActivityNotificationForBid(statusType: string, bidData) {
    if (this.projectsData && this.projectsData.lowbid) {
      this.projectsData.lowbid.bidAmount = bidData['bidAmount'] || 0;
      this.projectsData.bidStatus = statusType;
    }
    if (this.usersInfo['uid']) {
      const dataObject = {
        uid: this.usersInfo['uid'], projectId: bidData['projectId'], activityType:
          statusType === this.commonConfig.projectBidStatus.accepted ? this.commonConfig.projectActivityType.bidaccept : this.commonConfig.projectActivityType.bidunaccept
      };
      if (bidData['projectType'] === this.commonConfig.projectType.quote) {
        if (statusType === this.commonConfig.projectBidStatus.accepted) {
          dataObject['activityType'] = this.commonConfig.projectActivityType.quoteaccept;
        } else {
          dataObject['activityType'] = this.commonConfig.projectActivityType.quoteunaccept;
        }
      }
      const requireObject = dataObject;
      const members = this.projectsData.members || [];
      members.push(bidData['uid'], this.projectsData['uid']);
      const allUserId = members;
      requireObject['sendToUsers'] = allUserId;
      if (statusType === this.commonConfig.projectBidStatus.accepted) {
        this.projectsService.saveInsideNotificationPayload(requireObject, this.projectsData);
      }
      this.projectsService.saveProjectActivity(dataObject);
    }
  }

  /**
   * @description Function used for track bid status type event
   * @param {string} statusType contain the status of bid
   * @param {object} bidData contain bid data
   */
  trackBidStatusEvent(statusType: string, bidData) {
    if (this.parentObject) { this.parentObject.getLowestBid(); }
    if (statusType === this.commonConfig.projectBidStatus.accepted) {
      this.ganalyticsService.analyticsTrackEvent((bidData['projectType'] === this.commonConfig.projectType.quote)
        ? trackEvent.acceptQuote : trackEvent.acceptBid, bidData['projectId']);
    } else if (statusType === this.commonConfig.projectBidStatus.pending) {
      this.ganalyticsService.analyticsTrackEvent((bidData['projectType'] === this.commonConfig.projectType.quote)
        ? trackEvent.unacceptQuote : trackEvent.unacceptBid, bidData['projectId']);
    } else {
      this.ganalyticsService.analyticsTrackEvent((bidData['projectType'] === this.commonConfig.projectType.quote)
        ? trackEvent.rejectQuote : trackEvent.rejectBid, bidData['projectId']);
    }
  }

  sendNeedRevision(bidData: ProjectBid) {
    if (!bidData['vendorInfo']['isActive']) {
      this._dialogService.openAlert({
        message: this.commonConfig.popupAlertMessage.userVendorDeactivated
      });
    } else {
      const dialogRef = this.dialog.open(NeedBidRevisionComponent, {
        width: '360px'
      });
      const instance = dialogRef.componentInstance;
      instance.dialogRef = dialogRef;
      instance.bidData = bidData;
      instance.projectsData = this.projectsData;
    }
  }

  onDownload(link: string) {
    window.open(link, '_blank');
  }
  viewAllBids(projectId: string, uid: string) {
    this.router.navigate([`/${this.commonConfig.routerName.admin}/${this.commonConfig.routerName.projectdetail}`, projectId, uid], {
      queryParams: { from: this.currentPage }
    });
  }
  trackByFn(index: number) {
    return index;
  }
  /**
   * @description function used for delete bid
   * @param {string} objectId contain bid unique id
   */
  deleteBid(objectId: string) {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent);
    const instance = dialogRef.componentInstance;
    instance.dialogRef = dialogRef;
    this.selectedIds = [objectId];
    instance.parentObject = this;
  }
  /**
   * @description function used for update record after delete
   */
  updateRecord() {
    if (this.parentObject.projectid) {
      let totalBid = this.projectsData.totalBid - this.selectedIds.length;
      totalBid = (totalBid >= 0) ? totalBid : 0;
      this.fs.update(`${this.commonConfig.collectionName.project}/${this.parentObject.projectid}`, { totalBid });
    }
    this.bidList = this.bidList.filter(x => !this.selectedIds.includes(x['id']));
  }

  isVendorMaterialBids = (projectType) => projectType === this.commonConfig.projectType.materialBid;

}
