import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';

import { CustomValidator } from '@app/shared/validator/custom.validator';
import { ShareProjectService } from '@app/shared/service/share-project/share-project.service';

import { CommonConfig } from '@app/shared/common.config';
import { Project } from '@app/core/model/project';
import { User } from '@app/core/model/user';

@Component({
  selector: 'app-share-project',
  templateUrl: './share-project.component.html',
  styleUrls: ['./share-project.component.scss']
})
export class ShareProjectComponent implements OnInit {
  commonConfig = CommonConfig;
  shareForm: FormGroup;
  isSubmit = false;
  dialogRef;
  project: Partial<Project>;
  authUser: User;
  emails;
  formControl;
  constructor(private readonly formBuilder: FormBuilder,
    private readonly shareProjectService: ShareProjectService) { }

  ngOnInit() {
    this.shareForm = this.formBuilder.group({
      contactEmails: this.formBuilder.array([this.createEmails()])
    });
  }

  /**
   * @description functions used for create multiple email fields
   */
  createEmails(): FormGroup {
    return this.formBuilder.group({
      email: ['', [
        Validators.required,
        Validators.email,
        CustomValidator.validEmail
      ]]
    });
  }

  /**
   * @description functions used for add more emails
   */
  addEmail(): void {
    this.emails = this.shareForm.get('contactEmails');
    this.emails.push(this.createEmails());
  }

  /**
   * @description functions used for remove emails
   */
  removeEmail(index: number): void {
    this.formControl = this.shareForm.controls['contactEmails'];
    this.formControl.removeAt(index);
  }

  /**
   * @description functions used for return form email controls
   */
  getEmail(index: number) {
    return this.shareForm.controls['contactEmails']['controls'][index].controls.email;
  }

  /**
   * @description functions used for submit form data and share project on emails
   */
  submit() {
    const toEmails = this.shareForm.value.contactEmails;
    this.shareProjectService.shareProject(this.project.id, toEmails);
    this.dialogRef.close();
  }

  /**
   * @description functions used for get project preview url
   */
  projectPreviewUrl = () => `${window.location.origin}/${this.commonConfig.routerName.projects}/${this.project['id']}/${this.commonConfig.routerName.preview}`;

  checkDuplicateEmail(index: number, $event) {
    if (this.shareForm.value.contactEmails.filter((data) => data.email === $event.srcElement.value).length > this.commonConfig.one) {
      this.shareForm.controls['contactEmails']['controls'][index].get('email').setValue('');
      this.getEmail(index).errors['isUnique'] = true;
    }
  }

}
