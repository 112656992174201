import { Pipe, PipeTransform } from '@angular/core';
import { CategoryService } from '../service/category/category.service';

@Pipe({
  name: 'imageUrl'
})
export class ImageUrlPipe implements PipeTransform {
  constructor(
    private readonly categoryService: CategoryService,
  ) {
  }
  transform(data, projectImageUrl: string, placeholderImage: string, categoryId: string) {
    return this.fetchCategoryData(projectImageUrl, placeholderImage, categoryId);
  }

  async fetchCategoryData(projectImageUrl: string, placeholderImage: string, categoryId: string) {
    const categoryData = await this.categoryService.getCategoryData(categoryId);
    let imageUrl: string;
    if (projectImageUrl) {
      imageUrl = projectImageUrl;
    } else {
      if (categoryData.photo) {
        imageUrl = categoryData['photo']['url'];
      } else {
        imageUrl = `assets/images/${placeholderImage}`;
      }
    }
    return imageUrl;
  }

}
