import { Component, OnInit } from '@angular/core';
import { MessagingService } from '../../app/shared/service/messaging/messaging.service';

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html'
})
export class MessagingComponent implements OnInit {
  message: Object;
  constructor(private msgService: MessagingService) { }

  ngOnInit() {
    this.msgService.getPermission();
    this.msgService.receiveMessage();
    this.message = this.msgService.currentMessage || '';
  }

}
