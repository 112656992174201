import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
// Config
import { CommonConfig } from '@shared/common.config';

@Component({
  selector: 'app-choose-plan-list',
  templateUrl: './choose-plan-list.component.html',
  styleUrls: ['./choose-plan-list.component.scss']
})
export class ChoosePlanListComponent implements OnInit, OnDestroy {
  @Input() isList;
  @Input() planList;
  @Output() selectPlanEvent = new EventEmitter();
  commonConfig = CommonConfig;
  constructor(
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  selectSubscription(selectedPlan) {
    this.selectPlanEvent.emit(selectedPlan);
  }

}
