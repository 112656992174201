export const PLATFORM_TITLES = {
    '': 'Ventract Homepage',
    'default': 'Ventract',
    'login': 'Sign-In to Ventract',
    'signup': 'Sign Up for Ventract',
    'dashboard': 'Dashboard',
    'profile': 'Profile',
    'profile/edit-profile': 'Edit Profile',
    'profile/change': 'Change Password',
    'settings': 'Settings',
    'projects/my-project': 'My Projects',
    'projects/browse-project': 'Browse Projects',
    'projects/project-favorite': 'Favorite Projects',
    'projects/archive-project': 'Archived Projects',
    'vendors/browse-vendor': 'Browse Vendors',
    'vendors/my-vendor': 'Starred Vendors',
    'vendors/vendor-profile': 'Vendor Profile - {vendorName}',
    'admin/manage-projects': 'Admin - Manage Projects',
    'admin/manage-vendors': 'Admin - Manage Vendors',
    'admin/manage-bids': 'Admin - Manage Bids',
    'admin/manage-users': 'Admin - Manage Users',
    'admin/manage-ads': 'Admin - Manage Promotion',
    'admin/bulk-imports': 'Admin - Bulk Imports',
    'admin/categories': 'Admin - Categories'
};

export const DYNAMIC_KEY_NAMES = {
    vendorName: '{vendorName}'
};
