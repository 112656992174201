import { CommonConfig } from './common.config';
import { FirestoreService } from './service/firestore/firestore.service';


export class CommonUtils {
  constructor(private fs?: FirestoreService) { }


  /**
   * @description verify the user for email subscribe or not
   * @param {string} email contain the email
   * @param {string} activityName contain activity name
   */
  public async isEmailAllow(email, activityName) {

    const userDetails = await this.fs.fetchCollection(CommonConfig.collectionName.users).ref.where('email', '==', email).get();
    let isAllow = false;
    userDetails.forEach(element => {
      element.data()['emailNotification'] && (isAllow = element.data().emailNotification.includes(activityName));
    });
    return isAllow;
  }

  /**
   * @description make the prefix url
   * @param {string} url contain the current url
   * @param {string} ignoreString contain the ignore string from array traverse
   */
  public makePrefixURL = (url, ignoreString) => url.split('/').filter(x => x.search(ignoreString) === -1).join('/');
}
