import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CovalentStepsModule } from '@covalent/core/steps';
import { CovalentExpansionPanelModule } from '@covalent/core/expansion-panel';
import { CovalentFileModule } from '@covalent/core/file';
import { CovalentChipsModule } from '@covalent/core/chips';
import { CovalentDataTableModule } from '@covalent/core/data-table';
import { CovalentVirtualScrollModule } from '@covalent/core/virtual-scroll';
import { CovalentJsonFormatterModule } from '@covalent/core/json-formatter';
import { CovalentPagingModule } from '@covalent/core/paging';
import { CovalentNotificationsModule } from '@covalent/core/notifications';
import { CovalentMessageModule } from '@covalent/core/message';
import { CovalentSearchModule } from '@covalent/core/search';
import { CovalentCommonModule } from '@covalent/core/common';
import { CovalentLayoutModule } from '@covalent/core/layout';
import { CovalentBreadcrumbsModule } from '@covalent/core/breadcrumbs';


import { CovalentHttpModule } from '@covalent/http';
import { CovalentHighlightModule } from '@covalent/highlight';
import { CovalentMarkdownModule } from '@covalent/markdown';
import { CovalentDynamicFormsModule } from '@covalent/dynamic-forms';

import { CovalentDialogsModule } from '@covalent/core/dialogs';
import { CovalentLoadingModule } from '@covalent/core/loading';
import { CovalentMediaModule } from '@covalent/core/media';
import { TdLoadingMaskModule } from '@covalent/experimental/loading-mask';


@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    CovalentBreadcrumbsModule,
    CovalentStepsModule,
    CovalentExpansionPanelModule,
    CovalentFileModule,
    CovalentChipsModule,
    CovalentLoadingModule,
    CovalentDialogsModule,
    CovalentDataTableModule,
    CovalentVirtualScrollModule,
    CovalentJsonFormatterModule,
    CovalentPagingModule,
    CovalentNotificationsModule,
    CovalentMessageModule,
    CovalentSearchModule,
    CovalentMediaModule,
    CovalentCommonModule,
    CovalentLayoutModule,
    // start of optionals
    CovalentHttpModule,
    CovalentHighlightModule,
    CovalentMarkdownModule,
    CovalentDynamicFormsModule,
    TdLoadingMaskModule,
  ],
  declarations: []
})
export class AllCovalentModule { }
