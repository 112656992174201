import { Pipe, PipeTransform } from '@angular/core';
import { CommonConfig } from '@shared/common.config';

@Pipe({
  name: 'commaSeperated'
})
export class CommaSeperatedPipe implements PipeTransform {
  transform(data, key: string) {
    return data.map(function (obj) {
      return obj[key];
    }).join(', ');
  }
}

@Pipe({
  name: 'displayProjectType'
})
export class DisplayProjectTypePipe implements PipeTransform {
  transform(data, key: string) {
    const projectTypeArr = [];
    data.forEach(element => {
      projectTypeArr.push(CommonConfig.projectType[element]);
    });
    return projectTypeArr;
  }

}

