
import { Directive, ElementRef, OnInit, Output, EventEmitter } from '@angular/core';

declare var google;

@Directive({
  selector: '[appGooglePlace]'
})
export class GooglePlacesDirective implements OnInit {
  @Output() selectAddress = new EventEmitter();
  private element: HTMLInputElement;

  constructor(private elRef: ElementRef) {
    this.element = elRef.nativeElement;
  }

  ngOnInit() {
    const autocomplete = new google.maps.places.Autocomplete(this.element, {
      types: ['address']
    });
    const locationObj = {};
    autocomplete.addListener('place_changed', () => {
      const place: google.maps.places.PlaceResult = autocomplete.getPlace();
      if (place.geometry === undefined || place.geometry === null) {
        return;
      }
      locationObj['lat'] = place.geometry.location.lat();
      locationObj['lng'] = place.geometry.location.lng();
      locationObj['address'] = place.formatted_address;
      this.selectAddress.emit(locationObj);
    });
  }

}
