import { Pipe, PipeTransform } from '@angular/core';

import { CommonConfig } from '@app/shared/common.config';

@Pipe({
  name: 'shortAddress'
})
export class ShortAddressPipe implements PipeTransform {
  commonConfig = CommonConfig;
  transform(address: string) {
    const regFormat = /,(.+)/;
    let newAddress = address.split(regFormat)[this.commonConfig.one];
    newAddress = newAddress.split(regFormat)[this.commonConfig.one];
    return newAddress;
  }

}
