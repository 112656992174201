import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatChipInputEvent, MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
// Environment
import { environment } from 'environments/environment';
// Config
import { CommonConfig } from '@shared/common.config';
import { trackEvent } from '../common.constants';
// Component
import { PublicationPreviewComponent } from './preview-publication/preview-publication.component';
// Service
import { FirestoreService } from '../service/firestore/firestore.service';
import { CustomService } from '../service/custom/custom.service';
import { GanalyticsService } from '../service/ganalytics/ganalytics.service';
import { ChooseProjectFeatureService } from '@shared/service/projects/choose-project-feature.service';

@Component({
  selector: 'app-select-publication',
  templateUrl: './select-publication.component.html',
  styleUrls: ['./select-publication.component.scss']
})
export class SelectPublicationComponent implements OnInit {
  @Input() publication = [];
  @Input() form;
  @Input() parentObject;
  public commonConfig = CommonConfig;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public publicationCtrl = new FormControl();
  public filteredPublication: Observable<string[]>;
  public allCategories = [];
  public publicationError = false;
  public dialogRef;
  @ViewChild('selectPublicationInput', {static: false}) selectPublicationInput: ElementRef;
  public placeholder = this.commonConfig.placeholder.publication;
  public shortURL = '';
  private description = '';
  private projectType = '';
  private projectTitle = '';
  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private customService: CustomService,
    private firestoreService: FirestoreService,
    private ganalyticsService: GanalyticsService,
    public chooseProjectFeatureService: ChooseProjectFeatureService
  ) { }

  ngOnInit() {
    this.shortURL = this.parentObject.projectInfo['shortURL'] || `${new Date().getTime().toString(CommonConfig.thirtySix)}`;

    this.form.addControl('publication', this.fb.array([]));
    this.form.addControl('publicationTitle', new FormControl(''));
    this.form.addControl('publicationDescription', new FormControl(''));
    this.form.addControl('shortURL', new FormControl(this.shortURL));

    if (this.parentObject.projectInfo.hasOwnProperty('publication') && this.parentObject.projectInfo.publication.length > 0) {
      this.form.controls.publicationTitle.setValue(this.parentObject.projectInfo.publicationTitle);
      this.form.controls.publicationDescription.setValue(this.parentObject.projectInfo.publicationDescription);
      const publicationRef = this.form.controls.publication as FormArray;
      this.parentObject.projectInfo.publication.forEach(element => {
        this.insertPublicationObj(publicationRef, element);
      });
      setTimeout(() => {
        this.parentObject.advertisementsState = CommonConfig.actionState.none;
        this.setAdvertisementsState(this.parentObject.projectInfo.publication.length);
      }, this.commonConfig.delay100);
    }
    const nullValue = null;
    this.filteredPublication = this.publicationCtrl.valueChanges.pipe(startWith(nullValue), map((name: string | null) =>
      name ? this._filter(name) : this.publication.slice())
    );
  }

  /**
   * @description function used for check payment status
   */
  isPaymentStatus = () => {
    return (this.parentObject.projectInfo['advertisementPayment'] &&
     (this.parentObject.projectInfo['advertisementPayment']['paymentStatus'] === this.commonConfig.paymentStatus.paid ||
      this.parentObject.projectInfo['advertisementPayment']['paymentStatus'] === this.commonConfig.paymentStatus.payment_due)
      );
  }

  /**
   * @description Material Event Calling function
   * @param {event} event contain event
   */
  add(event: MatChipInputEvent): void {
    const input = event.input;
    input && (input.value = '');
    this.publicationCtrl.setValue(null);
  }

  /**
   * @description remove the publication from the index
   * @param {number} index contain the index
   */
  remove(index): void {
    const publicationRef = this.form.controls.publication as FormArray;
    publicationRef.removeAt(index);
  }

  /**
   * @description on select the publication from the list
   * @param {event} event contain the publication selected object
   */
  selected(event: MatAutocompleteSelectedEvent): void {
    const publicationRef = this.form.controls.publication as FormArray;
    if (event.option.value) {
      const keyValue = event.option.value;
      if (this.form.value.publication.filter(x => x.email === keyValue.email).length === this.commonConfig.zero) {
        this.insertPublicationObj(publicationRef, keyValue);
      }
    }
    this.selectPublicationInput.nativeElement.value = '';
    this.publicationCtrl.setValue(null);
  }

  /**
   * @description Insert the publication
   * @param {controls} publicationRef contain the controls ref
   * @param {object} keyValue contain the the object
   */
  insertPublicationObj(publicationRef, keyValue) {
    this.getPreviewBasicDetails();
    publicationRef.push(this.fb.group({
      email: keyValue.email,
      $key: keyValue.$key,
      id: keyValue.$key,
      name: keyValue.name,
      isEmailSent: keyValue.isEmailSent ? keyValue.isEmailSent : false
    }));
  }

  /**
   * @description filter the matching string
   * @param {string | object} value contain the string or object its depends on scenarios
   */
  private _filter(value): string[] {
    const filterValue = typeof value === 'object' ? value['name'].toLowerCase() : value.toLowerCase();
    return this.publication.filter(publication => publication.name.toLowerCase().indexOf(filterValue) !== -1);
  }

  /**
   * @description publication touch
   */
  publicationTouch = () => this.publicationError = true;


  /**
   * @description getting the publication details
   */
  get getPublication() {
    return this.form.get('publication');
  }

  /**
   * @description clear the publication text
   * @param {event} $event contain the event
   */
  clearPublicationText = ($event) => $event.target.value = '';

  /**
   * @description Function for set advertisements complete or not
   * @param {number} len contain the selected ad options
   */
  setAdvertisementsState(len: number) {
    this.parentObject.advertisementsState = (len > CommonConfig.zero) ? CommonConfig.actionState.complete : CommonConfig.actionState.none;
  }


  /**
    * @description Function for submit publication data
   */
  onSubmit() {
    this.setAdvertisementsState(this.form.value.publication.length);
    this.firestoreService.update(`${CommonConfig.collectionName.project}/${this.parentObject.projectId}`, {
      publication: this.form.value.publication,
      shortURL: this.shortURL,
      publicationTitle: this.projectTitle,
      publicationDescription: this.description
    }).then(res => {
      this.customService.showSnackBarSuccess(CommonConfig.successMessage.projectAdvertisement);
      this.ganalyticsService.analyticsTrackEvent(trackEvent.projectAdvertisement, this.parentObject.projectId);
    }).catch(err => {
    });
  }
  /**
   * @description preiview the publication
   * @param $event
   */
  previewPublication($event) {
    this.getPreviewBasicDetails();
    $event.stopPropagation();
    const { url = `${environment.baseUrl}ad/${this.shortURL}`
    } = this.parentObject.projectInfo;
    const dialogRef = this.dialog.open(PublicationPreviewComponent),
      instance = dialogRef.componentInstance;
    instance.dialogRef = dialogRef;
    instance.previewObj = { title: this.projectTitle, description: this.description, url, subProjectType: this.projectType };
  }

  /**
   * @description getting the project basic info
   */
  getPreviewBasicDetails() {
    if (this.parentObject.projectInfo.hasOwnProperty('advertisementPayment') &&
      this.parentObject.projectInfo['advertisementPayment']['paymentStatus'] === CommonConfig.paymentStatus.paid) {
      const description = this.customService.stripHtml(this.parentObject.projectInfo.publicationDescription);
      this.description = description.substring(0, this.commonConfig.adsLength);
      this.projectType = this.parentObject.projectInfo.subProjectType;
      this.projectTitle = this.parentObject.projectInfo.publicationTitle;
    } else {
      const description = this.customService.stripHtml(this.parentObject.projectBasicInfo.value.description);
      this.description = description.substring(0, this.commonConfig.adsLength);
      this.projectType = this.parentObject.projectBasicInfo.value.subProjectType;
      this.projectTitle = this.parentObject.projectBasicInfo.value.title;
      this.form.controls.publicationTitle.setValue(this.projectTitle);
      this.form.controls.publicationDescription.setValue(this.description);
    }
  }
}
