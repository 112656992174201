// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'http://localhost:4200/',
  firebase: {
    apiKey: 'AIzaSyBlvReWxmRlLH0u-1jj2ODuUeFEljExALo',
    authDomain: 'ventract-1478212250171.firebaseapp.com',
    databaseURL: 'https://ventract-1478212250171.firebaseio.com',
    projectId: 'ventract-1478212250171',
    storageBucket: 'ventract-1478212250171.appspot.com',
    messagingSenderId: '41245906775',
    appId: '1:41245906775:web:87f1dfef5e130e10'
  },
  filestack: {
    app_key: 'AreaVN6dTMmuAs7EUtCpAz',
    app_url: 'https://cdn.filestackcontent.com/'
  },
  googleMapsKey: 'AIzaSyBlvReWxmRlLH0u-1jj2ODuUeFEljExALo',
  instagram: {
    client_id: '9a89ad4e3290422fa4ca2db99545c6ad',
    auth_url: 'https://api.instagram.com/oauth/authorize/?',
    api_url: 'https://api.instagram.com/v1',
    response_type: 'token'
  },
  algoliya: {
    applicationID: '8A0ZT6MMDD',
    apiKey: 'ca1f4770465f69a851dcf796f1abd021',
    algolia_index: {
      common_search_index: 'common_search',
      user_search: 'user_search',
      organizationSearch: 'organization_search'
    }
  },
  isGAnalaytics: true,
  intercomAppId: 'sok0zb1k',
  interComSecretKey: 'nD8xtHpYHMfdWdmtz5zuTxlQuafpyzPdDhcdDi-3',
  intercomAppIdEmailLink: 'sok0zb1k@intercom-mail.com',
  cloudEndPoint: 'https://us-central1-ventract-1478212250171.cloudfunctions.net/',
  logoblack: 'assets/images/dev_logoblack.svg',
  logowhite: 'assets/images/dev_logowhite.svg',
  headerText: 'Dev',
  homeVideoUrl: 'https://firebasestorage.googleapis.com/v0/b/ventract-v2.appspot.com/o/handbrake-output-latest.mp4?alt=media',
  enableOrgFeature: true,
  cdnFilestackUrl: 'https://cdn.filestackcontent.com/',
  enableMaterialBid: true,
  allowFakerData: true
};
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
