import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TdLoadingService } from '@covalent/core/loading';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { CommonConfig } from '@shared/common.config';
@Component({
  selector: 'app-unsubscribe-reminder',
  templateUrl: './unsubscribe-reminder.component.html',
  styleUrls: ['./unsubscribe-reminder.component.scss']
})
export class UnsubscribeReminderComponent implements OnInit, OnDestroy {
  private readonly ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  private oid: string;
  private id: string;
  private readonly commonConfig = CommonConfig;
  public msg: string;

  constructor(private readonly route: ActivatedRoute, private readonly http: HttpClient, private readonly _loadingService: TdLoadingService) {
    this.getQueryParameter();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * @description Function used for get query parameter
   */
  getQueryParameter() {
    this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
      if (params['id']) {
        this.id = params['id'];
      }
    });
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
      if (params['oid']) {
        this.oid = params['oid'];
      }
    });
    this.unSubscribeProjectReminder();
  }

  /**
   * @description Function used for unsubscribe project email
   */
  unSubscribeProjectReminder() {
    const cond = {oid: this.oid, id: this.id};
    this._loadingService.register();
    this.http.post(`${environment.cloudEndPoint}unSubscribeProject`, cond).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (unsubRes) => {
        this._loadingService.resolveAll();
        if (unsubRes['status'] === this.commonConfig.status.success) {
          this.msg = this.commonConfig.successMessage.emailReminder;
        } else {
          this.msg = this.commonConfig.validationMessage.someError;
        }
      }, unErr => {
        this._loadingService.resolveAll();
        this.msg = this.commonConfig.validationMessage.someError;
      });
  }

}
