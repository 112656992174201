import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateProjectService } from '@app/shared/service/projects/create-project.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CommonConfig } from '@app/shared/common.config';


@Component({
  selector: 'app-ads',
  template: `<span></span>`,
  styleUrls: []
})
export class RedirectURLComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  constructor(private readonly router: Router, private readonly route: ActivatedRoute,
    private readonly createProjectService: CreateProjectService) {
    this.route.params.subscribe(param => {
      this.createProjectService.findProjectByAdsId(`${param['adsId']}`).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
        if (data.length > 0) {
          this.router.navigate([`/${CommonConfig.routerName.dashboard}/${CommonConfig.routerName.projectdetail}/`, data[0]['$key']]);
        } else {
          this.router.navigate([`dashboard`]);
        }
      });
    });
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.unsubscribe();
  }
}
