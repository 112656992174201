import {
  Directive,
  Input,
  Output,
  EventEmitter,
  HostListener,
  OnInit,
  OnDestroy
} from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument
} from 'angularfire2/firestore';
import { FormControl, FormGroup } from '@angular/forms';
import { tap, map, take, debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { VendorsService } from '@shared/service/vendors/vendors.service';
import { CommonConfig } from '@shared/common.config';
import { TimestampService } from '@shared/service/timestamp/timestamp.service';
import { Vendor } from '@app/core/model/vendor';

@Directive({
  selector: '[appVendorForm]'
})
export class VendorFormDirective implements OnInit, OnDestroy {

  @Input() path: string;
  @Input() formGroup: FormGroup;
  @Input() objectId?: string;
  lastChar: string;
  isCreate = true;

  private _state: 'loading' | 'synced' | 'modified' | 'error' | 'none-unique';

  @Output() stateChange = new EventEmitter<string>();
  @Output() formError = new EventEmitter<string>();

  // Firestore Document
  private docRef: AngularFirestoreDocument<any>;

  // Subscriptions
  private formSub: Subscription;

  constructor(private afs: AngularFirestore, public firestoreService: FirestoreService,
    private vendorsService: VendorsService, private timeStampService: TimestampService) { }

  ngOnInit() {
    this.lastChar = this.path.slice(-1);
    if (this.lastChar === '/') {
      this.path = this.path.slice(0, -1);
    }
    this.preloadData();
    this.autoSave();
  }

  // Loads initial form data from Firestore
  preloadData() {
    this.state = 'loading';
    this.docRef = this.getDocRef(this.path);
    this.docRef
      .valueChanges()
      .pipe(
        tap(doc => {
          if (doc) {
            if (doc.vendorProjects && doc.vendorProjects.length > 0) {
              this.resetProjectDate(doc);
            }
            this.formGroup.patchValue(doc);
            this.formGroup.markAsPristine();
            this.state = 'synced';
          }
        }),
        take(1)
      )
      .subscribe();
  }

  /**
   * @description Function used for reset project date value
   */
  resetProjectDate(doc) {
    for (const i in doc.vendorProjects) {
      if (doc.vendorProjects.hasOwnProperty(i)) {
        if (doc.vendorProjects[i].startDate) {
          doc.vendorProjects[i].startDate = doc.vendorProjects[i].startDate.toDate();
        }
        if (doc.vendorProjects[i].endDate) {
          doc.vendorProjects[i].endDate = doc.vendorProjects[i].endDate.toDate();
        }
      }
    }
  }

  // Autosaves form changes
  autoSave() {
    this.formSub = this.formGroup.valueChanges
      .pipe(
        tap(change => {
          if (this.formGroup.value.vendorProjects && this.formGroup.value.vendorProjects.length > 0) {
            this.setVendorPrjectDate();
          }
          this.state = 'modified';
        }),
        debounceTime(CommonConfig.delay100),
        tap(change => {
          if (this.formGroup.valid && this._state === 'modified' && this.formGroup.value.profileType !== '') {
            this.updateVendorformStepStatus();
            this.setDoc();
          }
        })
      )
      .subscribe();
  }

  /**
   * @description set vendor projects only date before save
   */
  setVendorPrjectDate() {
    const vendorProjects = this.formGroup.value.vendorProjects;
    for (const i in vendorProjects) {
      if (vendorProjects.hasOwnProperty(i)) {
        if (vendorProjects[i].startDate && vendorProjects[i].startDate._d) {
          this.formGroup.value.vendorProjects[i].startDate = vendorProjects[i].startDate._d;
        }
        if (vendorProjects[i].endDate && vendorProjects[i].endDate._d) {
          this.formGroup.value.vendorProjects[i].endDate = vendorProjects[i].endDate._d;
        }
      }
    }
  }

  /**
   * @description update vendor form step status compete or not
   */
  updateVendorformStepStatus() {
    if (this.formGroup.value.vendorProjects && this.formGroup.value.vendorProjects.length > 0) {
      this.vendorsService.vendorProjectsStatusUpdate(true);
    }
    if (this.formGroup.value.testimonials && this.formGroup.value.testimonials.length > 0) {
      this.vendorsService.testimonialsStatusUpdate(true);
    }
    if (this.formGroup.value.licensesCertifications && this.formGroup.value.licensesCertifications.length > 0) {
      this.vendorsService.licensesStatusUpdate(true);
    }
  }

  @HostListener('ngSubmit', ['$event'])
  onSubmit(e: string) {
    if (this.formGroup.valid && this.formGroup.value.profileType !== '') {
      this.setDoc();
    }
  }

  // Determines if path is a collection or document
  getDocRef(path: string) {
    if (path.split('/').length % CommonConfig.two) {
      this.isCreate = true;
      return this.afs.doc(`${path}/${this.afs.createId()}`);
    } else {
      this.isCreate = false;
      return this.afs.doc(path);
    }
  }

  // Writes changes to Firestore
  async setDoc() {
    try {
      if (this.formGroup.value.taxId) {
        const uniqueKey = this.firestoreService.colWithIds$(CommonConfig.collectionName.vendors,
          (ref) => ref.where('taxId', '==', this.formGroup.value.taxId).where('isRejected', '==', false)
            .where('isDeleted', '==', false)).subscribe(async (res) => {
              uniqueKey.unsubscribe();
              if (this.isVendorUnique(res)) {
                this.saveUpdateVendor();
              }
            });
      } else {
        this.saveUpdateVendor();
      }
    } catch (err) {
      this.formError.emit(err.message);
      this.state = 'error';
    }
  }

  /**
   * @description check is vendor unique
   */
  isVendorUnique (res) {
    let isUnique = true;
    for (const data of res) {
      if (data['id'] !== this.objectId && this.formGroup.value.taxId === data['taxId']) {
        this.state = 'none-unique';
        isUnique = false;
      }
    }
    return isUnique;
  }

  /**
   * @description save and update vendor collection
   */
  async saveUpdateVendor() {
    if (this.isCreate) {
      this.formGroup.value['createdAt'] = this.timeStampService.getTimestamp;
      this.formGroup.value['updatedAt'] = this.timeStampService.getTimestamp;
      await this.docRef.set(this.formGroup.value, { merge: true });
      if (this.formGroup.value.uid) {
        this.firestoreService.update(`${CommonConfig.collectionName.users}/${this.formGroup.value.uid}`, { isVendor: true });
      }
    } else {
      await this.firestoreService.update(this.docRef, this.formGroup.value);
    }
    this.state = 'synced';
  }

  // Setter for state changes
  set state(val) {
    this._state = val;
    this.stateChange.emit(val);
  }

  ngOnDestroy() {
    this.formSub.unsubscribe();
  }

}
