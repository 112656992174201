import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FirebaseOptionsToken } from 'angularfire2';
import { AngularFirestoreModule, FirestoreSettingsToken  } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireDatabaseModule } from 'angularfire2/database';
// Config
import { environment } from '../environments/environment';
// Module
import { AppRoutingModule } from '@app/app-routing.module';
import { CommonModuleModule } from '@app/shared/module/common-module.module';
// Component
import { AppComponent } from '@app/app.component';
import { MessagingComponent } from './+messaging/messaging.component';
import { DialogflowComponent } from '@app/dialogflow/dialogflow.component';
import { TermsAndConditionsComponent } from '@app/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from '@app/privacy-policy/privacy-policy.component';
import { UnsubscribeReminderComponent } from '@app/shared/component/projects/unsubscribe-reminder/unsubscribe-reminder.component';
// Service
import { AuthService } from '@app/core/auth.service';
import { MessagingService } from '@app/shared/service/messaging/messaging.service';
// Interceptor
import { HttpResponseInterceptor } from '@app/shared/interceptor/http-response-interceptor';
import { UnclaimedVendorsComponent } from './+vendors/unclaimed-vendors/unclaimed-vendors.component';

@NgModule({
  declarations: [
    AppComponent,
    MessagingComponent,
    DialogflowComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    UnsubscribeReminderComponent,
    UnclaimedVendorsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    HttpClientModule,
    CommonModuleModule,
    AngularFireDatabaseModule
  ],
  providers: [AuthService, MessagingService, { provide: FirebaseOptionsToken, useValue: environment.firebase },
    { provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi: true }, { provide: FirestoreSettingsToken, useValue: {} }],
  bootstrap: [AppComponent],
  entryComponents: [UnclaimedVendorsComponent]
})
export class AppModule { }
