import { environment } from 'environments/environment';
import { title } from 'process';
import { MessageConfig } from './message.config';
import { PlatformMetricsConfig } from './platform.metrics';
const commonSearchIndex = environment.algoliya.algolia_index.common_search_index;
const twentyFive = 25;
const seven = 7, eight = 8, nine = 9, ten = 10, eleven = 11, twelve = 12, thirteen = 13;
const pointSeventTwo = 0.72;
const pointFive = 0.5;
const ZERO = 0, ONE = 1, TWO = 2, THREE = 3, FOUR = 4, FIVE = 5, SIX = 6;
const firebaseVentractAppUrl = 'https://firebasestorage.googleapis.com/v0/b/ventract-v2.appspot.com/o/';


export const CommonConfig = {
  MMDDYY: 'MM/DD/YYYY',
  YYYYMMDD: 'YYYY/MM/DD',
  ddMMyy: 'dd/MM/yyyy',
  mmddyy: 'MM/dd/yyyy',
  Hmmaa: 'h:mm aa',
  ddMMMyyyy: 'dd MMM, yyyy',
  MMMddyyyy: 'MMM dd, yyyy',
  MMMM: 'MMMM',
  DD: 'DD',
  oneMinute: 60000,
  minusFour: -4,
  zero: ZERO,
  one: ONE,
  two: TWO,
  three: THREE,
  four: FOUR,
  five: FIVE,
  six: SIX,
  seven,
  eight,
  nine,
  ten,
  eleven,
  twelve,
  thirteen,
  fifteen: 15,
  sixteen: 16,
  twenty: 20,
  twoThousand: 2000,
  twentyFive: 25,
  thirtySix: 36,
  fifty: 50,
  delay1000: 1000,
  delay2000: 2000,
  delay3000: 3000,
  delay100: 100,
  delay300: 300,
  delay500: 500,
  delay10Second: 10000,
  delay5Second: 5000,
  debounceTime: 500,
  adsLength: 200,
  productServiceLimit: 6,
  lessHeight: 75,
  addHeight: 150,
  hexaRandomNumber: 0x10000,
  size1024: 1024,
  size2024: 2024,
  minAmount: 0.01,
  yAxisValue: pointFive,
  oneHundredEighty: 180,
  distanceUnit: {
    K: 'K',
    N: 'N',
    M: 'M'
  },
  status: {
    success: 200,
    badRequest: 400,
    internalServerError: 500
  },
  mobileResponsiveClass: {
    mobile_margin_top_0: 'mobile-margin-top-0',
    mobile_margin_top_1: 'mobile-margin-top-1',
    mobile_margin_top_2: 'mobile-margin-top-2',
    mobile_margin_top_default: 'mobile-margin-top',
    mobile_margin_top_4: 'mobile-margin-top-4',
    mobile_margin_top_5: 'mobile-margin-top-5',
  },
  exampleURL: 'eg.http://example.com',
  notificationActivityMapping: {
    projectActivity: 'Project',
    vendorActivity: 'Vendor',
    marketingActivity: 'Marketing',
    systemActivity: 'System'
  },
  milesMaskCharacter: '000000000000000',
  emailMaxLength: 150,
  passwordMinLength: 8,
  passwordMaxLength: 128,
  userNameLength: 50,
  companyNameLength: 120,
  jobTitleLength: 100,
  walkthroughLength: 500,
  revisionDescriptionLength: 500,
  addendumsDescription: 500,
  estimatedLength: 20,
  qtyLength: 10,
  snackBarDuration: 4000,
  totalProjectQuestions: 10,
  totalProjectLineItems: 100,
  snackBarClassError: 'ui-snackbar-error',
  snackBarClassSuccess: 'ui-snackbar-success',
  mapLocationsClass: 'map-locations',
  typeSuccess: 'success',
  typeError: 'error',
  supportEmail: 'support@ventract.com',
  passwordPattern: `^(?=.*[0-9])(?=.*[$@$!%*?&_()#])([a-zA-Z0-9$@$!%*?&_()#])+$`,
  emailPattern: '^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$',
  resetPasswordMode: 'resetPassword',
  verifyEmailMode: 'verifyEmail',
  mapType: 'map',
  listType: 'list',
  gridType: 'grid',
  pageSize: 18,
  activityPageSize: 25,
  adPageSize: 1,
  commentPageSize: 10,
  commentReplyPageSize: 5,
  bidPageSigeForAdmin: 6,
  projectTitleLength: 140,
  lineItemsLength: 500,
  mandatoryStar: '*',
  days: 'days',
  revsionDialogWidth: '360px',
  slelectUserDialogWidth: '500px',
  mapZoomLevel: 8,
  mapClusterImagePath: 'https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m',
  projectStatus: {
    draft: 'draft',
    pending: 'pending',
    published: 'published',
    deleted: 'deleted',
    rejected: 'rejected',
    revision: 'revised',
    disable: 'disabled'
  },
  vendorStatus: {
    draft: 'draft',
    pending: 'pending',
    published: 'published',
    rejected: 'rejected',
    revision: 'revision',
    unclaimed: 'unclaimed', // for display unclaimed status if not claimed by any user
    disable: 'disabled'
  },
  vendorOptionLabel: {
    accept: 'Accept',
    reject: 'Reject',
    pending: 'Pending',
    revision: 'Need Revision',
    enable: 'Enable',
    disable: 'Disable'
  },
  projectFeatures: {
    media: 'media',
    files: 'files',
    walkthrough: 'walkthrough',
    questionAnswer: 'qa',
    compliance: 'compliance',
    permission: 'permission',
    advertisements: 'advertisements',
  },
  documentStatus: {
    active: 'active',
    deactive: 'deactive',
    deleted: 'deleted'
  },
  statusText: {
    active: 'Activate',
    deactive: 'Deactivate'
  },
  mapIcons: {
    project: {
      small: 'assets/images/project-pin-small.svg',
      large: 'assets/images/project-pin-large.svg'
    },
    vendor: {
      small: 'assets/images/vendor-pin-small.svg',
      large: 'assets/images/vendor-pin-large.svg'
    }
  },
  US: {
    address: 'New York Ave NW, Washington, DC, USA',
    lat: 38.9031763,
    lon: -77.021256
  },
  projectType: {
    'quote': 1,
    'competitive': 2,
    'sealedbid': 3,
    'express': 4,
    'materialBid': 5,
    'projectSubscriberType': 'public',
    '1': 'Quote',
    '2': 'Competitive-Bid',
    '3': 'Sealed-Bid',
    '4': 'Express Project',
    '5': 'Vendor Material Bid',
    'public': 'Public'
  },
  subProjectType: {
    sealed: {
      ice: { key: 'ICE', value: 'Just Quotes Portal' },
      ifb: { key: 'IFB', value: 'Invitation for Bid' },
      rfp: { key: 'RFP', value: 'Request for Proposal' },
      rfq: { key: 'RFQ', value: 'Request for Quotes' },
    }
  },
  budgetType: [
    { key: 'perItem', value: 'Per Item' },
    { key: 'allItems', value: 'All Items' }
  ],
  maxProjectMedia: 999,
  maxProjectVideo: 20,
  maxProjectFiles: 20,
  maxBidFiles: 100,
  maxUrlLength: 100,
  maxVendorNameLength: 150,
  maxTaxIdLength: 9,
  maxPhoneLength: 10,
  totalVendorPhone: 25,
  totalVendorEmail: 25,
  totalShareProjectEmail: 25,
  totalVendorAddress: 25,
  testimonialsLength: 10,
  licensesLength: 10,
  vendorProjectsLength: 10,
  walkthroughStepLength: 10,
  vendorProjectsPhotos: 10,
  projectNameLength: 140,
  descriptionLength: 500,
  testimonialQuote: 1000,
  licensesTitleLength: 140,
  testimonialsNameLength: 140,
  productTitleLength: 90,
  productServiceDescription: 140,
  projectMemberLength: 10,
  vendorMemberLength: 10,
  totalDefaultLocation: 25,
  titleLength: 90,
  zipCodeLength: 5,
  hundred: 100,
  minInviteVendor: 3,
  thousand: 1000,
  sixty: 60,
  fourHundred: 400,
  previewDescriptionLength: 100,
  testimonialQuoteView: 500,
  acceptPhoto: [
    'image/*',
  ],
  acceptProjectMedia: [
    'image/*',
    'video/*'
  ],
  supportedMedia: `Ventract supports all common image and video formats.`,
  acceptProjectVideo: [
    'video/*'
  ],
  acceptProjectFiles: [
    'application/*'
  ],
  supportedFiles: 'Ventract supports all common files including PDFs, Microsoft Word, Excel, Powerpoint, and more!',
  acceptBidFiles: [
    'application/*',
    'image/*'
  ],
  videoType: {
    mp4: 'video/mp4'
  },
  mimeType: {
    video: 'video',
    image: 'image'
  },
  display: {
    resizeDetailPage: 'resize=height:200',
    resizeSmall: 'resize=height:60',
    quality: 'quality=value:75',
    compress: 'compress'
  },
  belongTo: {
    project: 'project',
    vendor: 'vendor',
    user: 'user',
    organization: 'organization',
    system: 'system',
    projectFiles: 'projectFiles'
  },
  vendorFeatures: {
    projects: 'projects',
    testimonials: 'testimonials',
    instagram: 'instagram',
    licenses: 'licenses',
    products: 'products',
    services: 'services',
    discussion: 'discussion',
    participant: 'participant',
    compliance: 'compliance',
    projectPreference: 'project-preference',
    description: 'description'
  },
  vendorFeatureView: {
    list: 'list',
    detail: 'detail'
  },
  mapRadious: 2000,
  defaultRadius: 100,
  maxRadius: 3000,
  profilePhoto: {
    logo: 'logo',
    cover: 'cover'
  },
  vendorProfileType: {
    claim: 'claim', // type when admin create a unclaimed profile
    new: 'new', // when user and admin create a new profile
    claimRequest: 'claimrequest' // when a user create profile for request to claim the unclaimed profile
  },
  noneUnique: 'none-unique',
  logoAspectRatio: 1,
  coverAspectRatio: twentyFive / seven,
  adsAspectRatio: pointSeventTwo / ONE,
  dataSynced: 'synced',
  dataModified: 'modified',
  dataError: 'error',
  localStorageKeys: {
    lastKey: 'lastKey'
  },
  validationMessage: {
    ...MessageConfig.validationMessage
  },
  successMessage: {
    ...MessageConfig.successMessage
  },
  popupAlertMessage: {
    ...MessageConfig.popupAlertMessage
  },
  confirmType: {
    goBack: 'back'
  },
  collectionName: {
    project: 'projects',
    document: 'documents',
    categories: 'categories',
    vendors: 'vendors',
    users: 'users',
    vendorActions: 'vendoractions',
    discussions: 'discussions',
    discussionsReplies: 'discussions-replies',
    products: 'products',
    services: 'services',
    projectbid: 'project-bids',
    notification: 'notifications',
    projectmembers: 'project-members',
    vendormembers: 'vendor-members',
    userActivity: 'user-activity',
    advertisements: 'advertisements',
    projectwalkthrough: 'project-walkthrough',
    publication: 'publication-list',
    configuration: 'configuration',
    organizations: 'organizations',
    organizationMembers: 'organization-members',
    materialLineItem: 'material-line-item',
    platformMetrics: 'platform-metrics',
  },
  collectionFieldName: {
    createdAt: 'createdAt',
    updatedAt: 'updatedAt',
    name: 'name',
    segment: 'segment',
    status: 'status'
  },
  categoryCollectionFieldName: {
    projectCategoryCount: 'projectCategoryCount',
    userCategoryCount: 'userCategoryCount',
    claimVendorCategoryCount: 'claimVendorCategoryCount',
    unClaimVendorCategoryCount: 'unClaimVendorCategoryCount'
  },
  steps: {
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5,
    six: 6,
    seven: 7,
    eight: 8
  },
  routerName: {
    'routePrefix': '/',
    'root': '',
    'login': 'login',
    'signup': 'signup',
    'forgot': 'forgot',
    'verifyreset': 'verify-reset',
    'home': 'home',
    'termsconditions': 'terms-and-conditions',
    'privacypolicy': 'privacy-policy',
    'chooseplan': 'choose-plan',
    'dashboard': 'dashboard',
    'projects': 'projects',
    'browse_project': 'browse-project',
    'myprojects': 'my-project',
    'createproject': 'create-project',
    'editproject': 'edit-project',
    'changepassword': `change`,
    'vendors': 'vendors',
    'browse_vendor': 'browse-vendor',
    'vendorprofile': 'vendor-profile',
    'archiveProject': 'archive-project',
    'createvendor': 'create-vendor',
    'editvendor': 'edit-vendor',
    'editprofile': 'edit-profile',
    'profile': 'profile',
    'completeprofile': 'complete',
    'myvendor': 'my-vendor',
    'vendorfeature': 'vendor-feature',
    'favoriteprojects': 'project-favorite',
    'search': 'search',
    'projectdetail': 'project-detail',
    'admin': 'admin',
    'manageprojects': 'manage-projects',
    'managevendors': 'manage-vendors',
    'manageusers': 'manage-users',
    'managebids': 'manage-bids',
    'bulkImports': 'bulk-imports',
    'billing': 'billing',
    'manageads': 'manage-ads',
    'manageorg': 'manage-organization',
    'adsdetail': 'ads-detail',
    'settings': 'settings',
    'categories': 'categories',
    'categorydetail': 'category-detail',
    'preview': 'preview',
    'upgradePlan': 'upgrade-plan',
    'scrapeVendors': 'scrapeVendors',
    'platformMetrics': 'platform-metrics',
    'fakerData': 'faker-data'
  },
  dialogService: {
    cancel: 'No',
    accept: 'Yes',
    ok: 'Ok',
    cancelService: 'Cancel',
    signup: 'Sign Up',
    upgrade: 'Upgrade',
    contactSupport: 'Contact Support',
    stay: 'Stay Here',
    continue: 'Continue to 3rd Party site'
  },
  anonymous: 'Anonymous',
  questionType: {
    project: 'project',
    vendor: 'vendor'
  },
  projectBidStatus: {
    revision: 'revision',
    pending: 'pending',
    accepted: 'accepted',
    rejected: 'rejected'
  },
  bidStatusDisplay: {
    needRevision: 'Need Revision',
    revisionRequested: 'Revision requested',
    bidAccepte: 'Accept',
    accepted: 'Accepted',
    bidReject: 'Reject',
    rejected: 'Rejected',
    disable: 'Disable'
  },
  vendorActivityType: {
    vendorrevision: 'vendorrevision',
    vendorprofileaccept: 'vendorprofileaccept',
    vendorprofilereject: 'vendorprofilereject',
    vendorprofilepending: 'vendorprofilepending',
    vendorprofiledisable: 'vendorprofiledisable',
    commentvendorprofile: 'commentvendorprofile',
    assignvendorprofile: 'assignvendorprofile',
    createvendorbyadmin: 'createvendorbyadmin',
    addmembervendorprofile: 'addmembervendorprofile',
    removemembervendorprofile: 'removemembervendorprofile',
    createvendorbyuser: 'createvendorbyuser',
    createClaimVendorProfile: 'createClaimVendorProfile',
    updaterevisionrequestvendor: 'updaterevisionrequestvendor',
    addservice: 'addservice',
    updateservice: 'updateservice',
    deleteservice: 'deleteservice',
    addproduct: 'addproduct',
    updateproduct: 'updateproduct',
    deleteproduct: 'deleteproduct',
    create: 'create',
    update: 'update',
    favorite: 'favorite',
    unfavorite: 'unfavorite',
    vendorStarred: 'vendorStarred',
    vendorUnStarred: 'vendorUnStarred'
  },
  vendorActivityMessage: {
    create: 'created this vendor profile.',
    update: 'edited this vendor profile.',
    addmembervendorprofile: 'added you as a member of this profile.',
    removemembervendorprofile: 'removed a member.',
    commentvendorprofile: 'added a comment on this profile.',
    addproduct: 'added a new product on this profile.',
    updateproduct: 'edited the product on this profile.',
    deleteproduct: 'deleted the product on this profile.',
    addservice: 'added a new service on this profile.',
    updateservice: 'edited the service on this profile.',
    deleteservice: 'deleted the service on this profile.',
    createClaimVendorProfile: 'claimed this Vendor profile.',
    vendorprofilereject: 'rejected this vendor profile.',
    vendorprofilepending: 'changed this vendor profile status in pending.',
    vendorprofiledisable: 'disabled this vendor profile.',
    vendorprofileaccept: 'accepted this vendor profile.',
    vendorrevision: 'created the vendor revision request.',
    favorite: 'starred this vendor.',
    unfavorite: 'removed this vendor from starred list.',
    vendorStarred: '(Admin) starred vendors for you.',
    vendorUnStarred: '(Admin) unstarred vendors for you.'
  },
  projectActivityType: {
    create: 'create',
    update: 'update',
    view: 'view',
    bidplace: 'bidplace',
    bidupdate: 'bidupdate',
    bidaccept: 'bidaccept',
    bidunaccept: 'bidunaccept',
    quoteplace: 'quoteplace',
    quoteupdate: 'quoteupdate',
    quoteaccept: 'quoteaccept',
    quoteunaccept: 'quoteunaccept',
    projectrevision: 'projectrevision',
    projectaccept: 'projectaccept',
    projectreject: 'projectreject',
    addmember: 'addmember',
    removemember: 'removemember',
    addcomment: 'addcomment',
    replycomment: 'replycomment',
    createprojectbyadmin: 'createprojectbyadmin',
    assignproject: 'assignproject',
    quoterevisionrequest: 'quoterevisionrequest',
    bidrevisionrequest: 'bidrevisionrequest',
    bidreject: 'bidreject',
    quotereject: 'quotereject',
    createprojectbyuser: 'createprojectbyuser',
    updaterevisionrequest: 'updaterevisionrequest',
    walkthroughschedule: 'walkthroughschedule',
    walkthroughcancel: 'walkthroughcancel',
    createWalkthrough: 'createWalkthrough',
    updateWalkthrough: 'updateWalkthrough',
    walkthroughBooked: 'walkthroughBooked',
    walkthroughCancelled: 'walkthroughCancelled',
    walkthroughRequested: 'walkthroughRequested',
    favorite: 'favorite',
    unfavorite: 'unfavorite',
    invitevendor: 'invitevendor',
    addendums: 'addendums',
    fileDownloaded: 'fileDownloaded',
    anonymousView: 'anonymousView',
    publicProjectActionUrl: 'publicProjectActionUrl'
  },
  notificationActivityMessage: {
    create: 'created the project',
    update: 'edited the project',
    view: 'viewed the project',
    bidplace: 'placed a bid',
    bidupdate: 'updated the bid',
    bidaccept: 'accepted the bid on this project.',
    bidunaccept: 'unaccepted the bid on this project.',
    quoteplace: 'placed a quote',
    quoteupdate: 'updated the quote',
    quoteaccept: 'accepted the quote on this project.',
    quoteunaccept: 'unaccepted the quote on this project.',
    projectaccept: 'accepted the project request',
    projectreject: 'rejected the project request',
    projectrevision: 'created the project revision request',
    addmember: 'added as a member of this project.',
    removemember: 'member removed from project',
    addcomment: 'added a comment',
    replycomment: 'replied to a comment on this project',
    vendorprofileaccept: 'accepted the vendor profile.',
    vendorprofilereject: 'rejected the vendor profile.',
    vendorrevision: 'created the vendor revision request',
    commentvendorprofile: 'added a comment on this vendor.',
    createprojectbyadmin: 'created project for you.',
    assignproject: 'assigned this project to you.',
    assignvendorprofile: 'assigned this vendor profile to you.',
    createvendorbyadmin: 'create this vendor profile for you.',
    addmembervendorprofile: 'added you as a member of this profile.',
    quoterevisionrequest: 'requested a quote revision',
    bidrevisionrequest: 'requested a bid revision',
    bidreject: 'rejected a bid on this project.',
    quotereject: 'rejected a quote on this project.',
    createvendorbyuser: 'created a new vendor profile.',
    createprojectbyuser: 'created a new project.',
    createClaimVendorProfile: 'claimed this Vendor profile.',
    updaterevisionrequest: 'updated the revison request',
    updaterevisionrequestvendor: 'updated the revision request',
    createWalkthrough: 'created a walkthrough on this project',
    updateWalkthrough: 'updated a walkthrough on this project',
    walkthroughBooked: 'booked walkthrough of the project',
    walkthroughCancelled: 'cancelled walkthrough of the project',
    walkthroughRequested: 'requested walkthrough of the project',
    favorite: 'starred this project.',
    unfavorite: 'removed this project from starred list.',
    invitevendor: 'You are invited to bid on this project!',
    addendums: 'New addendum added.',
    fileDownloaded: 'downloaded file',
    anonymousView: ' user views this project',
    publicProjectActionUrl: ' clicked the call to action',
    walkthroughschedule: 'scheduled a walkthrough',
    expressProjectReminder: 'was reminded about an Express Project',
    inviteUserToOrganization: 'invited a user to an organization',
    createOrganization: 'created an organization',
    acceptOrganization: 'accepted an organization',
    rejectOrganization: 'rejected an organization',
    joinUserToOrganization: 'joined an organization',
    joinOrgRequestApproved: 's request to join an organization was approved',
    joinOrgRequestRejected: 's request to join an organization was rejected',
    leaveOrganizationReq: 'requested to leave an organization',
    leaveOrgRequestApproved: 's request to leave an organization was approved',
    leaveOrgRequestRejected: 's request to leave an organization was rejected'
  },
  notificationActivityType: {
    projectActivity: 'projectActivity',
    vendorActivity: 'vendorActivity',
    systemActivity: 'systemActivity',
    marketingActivity: 'marketingActivity',
    userActivity: 'userActivity',
  },
  userActivityType: {
    createUserAccount: 'createUserAccount',
    profileCompleted: 'profileCompleted',
    subscriptionAdded: 'subscriptionAdded',
    updateAccount: 'updateAccount',
    adminUpdateAccount: 'adminUpdateAccount',
    changePassword: 'changePassword',
    coverPicUploaded: 'coverPicUploaded',
    profilePicUploaded: 'profilePicUploaded',
    signIn: 'signIn',
    signOut: 'signOut'
  },
  userActivityMessage: {
    createUserAccount: 'Created a new Account',
    profileCompleted: 'Completed user Profile.',
    subscriptionAdded: 'Added a subscription.',
    updateAccount: 'Updated Account Info.',
    adminUpdateAccount: 'Admin Updated Account Info.',
    changePassword: 'Changed Password.',
    coverPicUploaded: 'Uploaded Cover Photo.',
    profilePicUploaded: 'Uploaded Profile Photo.',
    signIn: 'Signed In.',
    signOut: 'Signed Out.'
  },
  systemActivityType: {
    createOrganization: 'createOrganization'
  },
  systemActivityMessage: {
    createOrganization: 'created the organization'
  },
  actionState: {
    edit: 'edit',
    valid: 'valid',
    confirm: 'Confirm',
    viewby: 'viewby',
    bidby: 'bidby',
    add: 'ADD',
    update: 'UPDATE',
    complete: 'complete',
    none: 'none',
    required: 'required',
    continue: 'continue',
    justNow: 'Just Now',
    requested: 'Revision Requested',
    allRequests: 'All Requests',
    accepted: 'Accepted',
    quote: 'Quote',
    bidding: 'Bidding',
    leaveOrganization: 'Leave an Organization',
    archived: 'archived',
    projects: 'projects',
    myProject: 'my-project',
    true: 'Yes',
    false: 'No'
  },
  menuLabel: {
    orgSuperAdmin: 'Org Super Admin',
    orgAdmin: 'Org Admin'
  },
  algoliaIndices: {
    projectSearch: commonSearchIndex,
    projectTitleAsc: commonSearchIndex + '_title_asc',
    projectTitleDesc: commonSearchIndex + '_title_desc',
    projectBidstartAsc: commonSearchIndex + '_bidStartAt_asc',
    projectBidstartDesc: commonSearchIndex + '_bidStartAt_desc',
    projectBidendAsc: commonSearchIndex + '_bidEndAt_asc',
    projectBidendDesc: commonSearchIndex + '_bidEndAt_desc',
    projectCreatedAtAsc: commonSearchIndex + '_createdAt_asc',
    projectCreatedAtDesc: commonSearchIndex + '_createdAt_desc',
    projectStartedAtAsc: commonSearchIndex + '_startedAt_asc',
    projectStartedAtDesc: commonSearchIndex + '_startedAt_desc',
    projectTypeAsc: commonSearchIndex + '_projectType_asc',
    projectTypeDesc: commonSearchIndex + '_projectType_desc',
    projectUsernameAsc: commonSearchIndex + '_username_asc',
    projectUsernameDesc: commonSearchIndex + '_username_desc',
    projectUpdatedAtAsc: commonSearchIndex + '_updatedAt_asc',
    projectUpdatedAtDesc: commonSearchIndex + '_updatedAt_desc',
    userSearch: environment.algoliya.algolia_index.user_search,
    userUpdatedAtAsc: 'user_updatedAt_asc',
    userUpdatedAtDesc: 'user_updatedAt_desc',
    userCreatedAtAsc: 'user_createdAt_asc',
    userCreatedAtDesc: 'user_createdAt_desc',
    organizationSearch: environment.algoliya.algolia_index.organizationSearch,
    organizationCreatedAtAsc: 'organization_createdAt_asc',
    organizationCreatedAtDesc: 'organization_createdAt_desc',
    organizationUpdatedAtDesc: 'organization_updatedAt_desc',
    organizationUpdatedAtAsc: 'organization_updatedAt_asc',
  },
  emailSubject: {
    // Send grid email subject message
    needRevision: 'Project needs revision',
    projectAccept: 'Project Accepted',
    projectReject: 'Project Rejected',
    vendorAccept: 'Vendor Profile Accepted',
    vendorReject: 'Vendor Profile Rejected',
    vendorCreate: 'Vendor Profile Created',
    vendorCreateRequest: 'Vendor Profile Creation Request',
    vendorUpdate: 'Vendor Profile Updated',
    projectCreate: 'Project Created',
    projectUpdate: 'Project Updated',
    vendorRevision: 'Vendor profile needs revision',
    vendorPending: 'Vendor profile pending',
    vendorDisable: 'Vendor profile disable',
    blockUserByAdmin: 'Account blocked',
    unBlockUserByAdmin: 'Account unblocked',
    addProjectMember: 'Project Member Added',
    removeProjectMember: 'Project Member Removed',
    addVendorMember: 'Vendor Member Added',
    removeVendorMember: 'Vendor Member Removed',
    walkthroughScheduleAlert: 'Walkthrough Scheduler Alert',
    walkthroughCancel: 'Walkthrough canceled',
    walkthroughRequest: 'Walkthrough requested',
    starVendorsByAdmin: 'Vendors marked as starred by admin',
    unStarVendorsByAdmin: 'Vendors marked as unstarred by admin'
  },
  emailTemplates: {
    // Send grid email template ids
    needRevision: 'd-d8038252fa124c4fb4710b7a6f5b8e95',
    projectAccept: 'd-82b891d0971744e790b807bb2fe6b30b',
    projectReject: 'd-bd42bcea177e4e6c9fb4cbb7e05b0784',
    vendorAccept: 'd-374fdc1ab1894a49a00ce11fc5d311cf',
    vendorReject: 'd-66c3b520ef104420a2b8bc9015cefe20',
    vendorCreate: 'd-976796888b5b4647bf06da7960100ac8',
    vendorCreateRequest: 'd-4943bf02c5974bab9e93c4b3d942e181',
    vendorUpdate: 'd-713638a688cd4fb7b171436aeb7a87d4',
    projectCreate: 'd-bb305ade9cc1437cb36e3561a2385a76',
    projectUpdate: 'd-1273c4ca46e74773aeaf232397aca11a',
    vendorRevision: 'd-32b2e1f6d911465dbdd20004325a0640',
    vendorPending: 'd-6f4384792b264ff79295dd9299a321de',
    vendorDisable: 'd-2f178a559424480ea43844fce810c8c8',
    blockUserByAdmin: 'd-4e1f2d24244a4ab7817b035ed0629417',
    unBlockUserByAdmin: 'd-b7cf2b09b7254008b4c3f828821cf15a',
    addProjectMember: 'd-d4036d6db51e439993a845939fa1026e',
    removeProjectMember: 'd-327a19a9b7d54aae94f235e6ba43b4e3',
    addVendorMember: 'd-d6a24acd8c4d48c7adbc0a99afe85d63',
    removeVendorMember: 'd-e4432fdee70a45cc94d0ad2baf90e781',
    walkthroughScheduleAlert: 'd-66e68415a97b4cc687758a2ea328e8a3',
    walkthroughCancel: 'd-72eab243656942ac8d4850b48a35c06c',
    walkthroughRequest: 'd-86f8e7aa5a9f41539a07b0dbc22d270e',
    starVendorsByAdmin: 'd-32ac34998f9d402385eb9dbdea6f8b9e',
    unStarVendorsByAdmin: 'd-9844a2bf586b40649c480c7bbbe0cd5d'
  },
  courierTemplates: {
    projectVendorInvite: '7H0S5P2YPH4PQGM6JQHAQHMQVRWK',
    projectReminderVendorInvite: 'G38TKS29ZX4YW2JEA2TC43R06B6P',
    dailyDigest: 'D3E3WPHM6XMQ1TKA3DRD2SXN23CG',
    projectActivity: 'BK6QJ0F215M3XQK3DCQBBPWPNFW4'
  },
  walkthroughStatus: {
    canceled: 'canceled',
    confirmed: 'confirmed',
    removed: 'removed',
    request: 'request'
  },
  role: {
    admin: 'Admin',
    vendor: 'Vendor',
    user: 'User',
    owner: 'Owner',
    member: 'Member',
  },
  subscriptionPlanType: {
    'basic': 'basic',
    'premium': 'premium',
    'enterprise': 'enterprise'
  },
  subscriptionPlanText: {
    'basic': 'Basic Subscription',
    'premium': 'Premium Subscription',
    'enterprise': 'Enterprise Subscription'
  },
  subscriptionStatus: {
    future: 'future',
    in_trail: 'in_trail',
    active: 'active',
    non_renewing: 'non_renewing',
    paused: 'paused',
    cancelled: 'cancelled'
  },
  downgradeSubscriptionMessage: {
    'premium': 'To Downgrade to Basic, Please contact Live Chat and Support.',
    'enterprise': 'To Downgrade to Basic or Premium, Please contact Live Chat and Support.'
  },
  placeholder: {
    whatDoYouDo: 'What do you do?',
    category: 'Type Category here...',
    publication: 'Type Publication here...'
  },
  noAvatar: 'assets/images/no-avatar.png',
  defaultProjectImage: 'assets/images/my-project-placeholder.png',
  defaultVendorImage: 'assets/images/vendor-project-placeholder.png',
  defaultVendorCoverImage: 'assets/images/vendor-cover-placeholder.png.png',
  marketingWebsiteUrl: environment.baseUrl,
  loginBy: {
    ventract: 'ventract',
    google: 'google'
  },
  cardOptions: {
    style: {
      base: {
        iconColor: '#363636',
        color: '#363636',
        lineHeight: '40px',
        fontWeight: 400,
        fontFamily: '"Roboto, Helvetica Neue", Helvetica, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#757575',
        },
      },
      invalid: {
        color: '#e53935',
        ':focus': {
          color: '#363636',
        },
      },
    }
  },
  paymentStatus: {
    'paid': 'paid',
    'pending': 'pending',
    'notPaid': 'not paid',
    'not_paid': 'not_paid',
    'paymentDue': 'payment due',
    'payment_due': 'payment_due'
  },
  dateFormat: ['DD MMM YYYY'],
  paymentEventType: {
    'payment_failed': 'payment_failed',
    'payment_succeeded': 'payment_succeeded',
    'subscription_activated': 'subscription_activated',
    'subscription_reactivated': 'subscription_reactivated',
    'subscription_renewed': 'subscription_renewed',
    'subscription_cancelled': 'subscription_cancelled'
  },
  planStatus: {
    active: 'active',
    deactive: 'deactive',
    inTrial: 'in_trial'
  },
  userMenuOptions: {
    profile: 'User Profile',
    myVendorProfile: 'Vendor Profile',
    createVendorProfile: 'Create Vendor Profile',
    userSetting: 'Settings',
    changePassword: 'Change Password',
    logout: 'Logout'
  },
  segment: {
    all: { key: 'all', value: 'Select All' },
    vendor: 'Vendor',
    projectCreator: 'Project Creator',
    other: 'Other'
  },
  optionalLabel: '(Optional)',
  statusCode: {
    success: 200,
    badRequest: 400,
    internalServerError: 500
  },
  courierNotifyStatus: {
    optedIn: 'OPTED_IN',
    optedOut: 'OPTED_OUT'
  },
  defaultTimeZone: {
    americaNewYork: 'America/New_York'
  },
  staticPromotion: {
    one: {
      title: `About promotions`,
      description: `Send an email to support@ventract.com to run your own promotion.`,
      url: ``,
      photoUrl: `assets/images/ad-placeholder-image.png`,
      mailto: `contact@ventract.com`
    }
  },
  quillConfig: {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],         // toggled buttons
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
      [{ 'color': [] }, { 'background': [] }],           // dropdown with defaults from theme
      ['link'],
      ['clean'],                                         // remove formatting button
    ]
  },
  vendorEventType: {
    star: 'star',
    unstar: 'unstar'
  },
  projectColumn: [
    { key: 'modifiedDate', value: 'Modified Date', selected: false },
    { key: 'projectStartDate', value: 'Project Start Date', selected: false },
    { key: 'ownerName', value: 'Owner Name', selected: false },
    { key: 'projectDescription', value: 'Project Description', selected: false },
    { key: 'projectAddress', value: 'Project Address', selected: false }
  ],
  userColumn: [
    { key: 'isVendorSegment', value: 'Is Vendor Segment?', selected: false },
    { key: 'isProjectSegment', value: 'Is Project Segment?', selected: false },
    { key: 'isAdmin', value: 'Is Admin?', selected: false },
    { key: 'subscriptionStatus', value: 'Subscription Status', selected: false },
    { key: 'lastLoginTime', value: 'Last Sign In Time', selected: false },
    { key: 'projectLocation', value: 'Project Locations', selected: false }
  ],
  categoryColumn: [
    { key: 'projectCategoryCount', value: 'Project Category Count', selected: false },
    { key: 'userCategoryCount', value: 'User Category Count', selected: false },
    { key: 'claimedVendorCategoryCount', value: 'Claimed Vendor Category Count', selected: false },
    { key: 'unclaimedVendorCategoryCount', value: 'Unclaimed Vendor Category Count', selected: false }
  ],
  projectSubscribeType: {
    public: 'public'
  },
  platformMetricsText: {
    ...PlatformMetricsConfig.platformMetricsText
  },
  projectTypes: [
    {
      key: 1, value: 'Quote'
    }, {
      key: 2, value: 'Competitive-Bid'
    }, {
      key: 3, value: 'Sealed-Bid'
    }, {
      key: 4, value: 'Express Project'
    }, {
      key: 5, value: 'Vendor Material Bid'
    }],
  xxxxxxxxxxx: 'XXXXXXXXXX',
  intercomAppIdEmailLink: environment.intercomAppIdEmailLink,
  usersCsvUrl: `${firebaseVentractAppUrl}csv%2Fsample-import-users-valid.csv?alt=media&token=23508ed0-a42d-4bd4-871c-b9a3a8b0751e`,
  vendorsCsvUrl: `${firebaseVentractAppUrl}csv%2Fsample-import-vendors-valid.csv?alt=media&token=aa005e46-e92b-44a5-a61a-bfa2c0b37fbb`,
  projectsCsvUrl: `${firebaseVentractAppUrl}csv%2Fsample-import-projects.csv?alt=media&token=6bc221d2-557c-49e2-9270-b5322e7a3f97`,
  projectDocsCsvUrl: `${firebaseVentractAppUrl}csv%2Fsample-import-project-docs.csv?alt=media&token=e253740b-6af7-41b1-a022-e1d85e5efcd3`,
  projectImageType: {
    unsplash: 'unsplash',
    googleMap: 'gmap'
  },
  dateFormatOption: { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' },
  timeFormatOption: { hour: 'numeric', minute: 'numeric', hour12: true },
};
