import { Component, OnInit } from '@angular/core';

import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { Vendor } from '@app/core/model/vendor';

import { CommonConfig } from '@app/shared/common.config';

@Component({
  selector: 'app-claim-vendor-detail',
  templateUrl: './claim-vendor-detail.component.html',
  styleUrls: ['./claim-vendor-detail.component.scss']
})
export class ClaimVendorDetailComponent implements OnInit {
  vendorId: string;
  vendorInformation: Vendor;
  loading = true;
  constructor(private fs: FirestoreService) { }

  ngOnInit() {
    this.fs.getCollection(CommonConfig.collectionName.vendors, this.vendorId).get().then((vendors) => {
      if (vendors.exists && vendors.data().claimVendorInformation) {
        this.vendorInformation = vendors.data().claimVendorInformation;
      }
      this.loading = false;
    });
  }

}
