import { Injectable, NgZone, ElementRef } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { TdDialogService } from '@covalent/core/dialogs';
import { MapsAPILoader } from '@agm/core';
// Config
import { CommonConfig } from '@shared/common.config';
// Service
import { CustomService } from '@shared/service/custom/custom.service';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  private CommonConfig = CommonConfig;
  constructor(
    private readonly mapsAPILoader: MapsAPILoader,
    private readonly ngZone: NgZone,
    private readonly fb: FormBuilder,
    private readonly _dialogService: TdDialogService,
    private readonly customService: CustomService,
    ) { }

  getUserLocation(formData) {
    /// get user current location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        formData.get('lat').setValue(position.coords.latitude);
        formData.get('long').setValue(position.coords.longitude);
        const currgeocoder = new google.maps.Geocoder();
        const myLatlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
        currgeocoder.geocode({ 'location': myLatlng }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            formData.get('address').setValue(results[0].formatted_address);
          }
        });
      }, error => {
        if (error.code) {
          this.customService.showSnackBarError(CommonConfig.validationMessage.locationDenid);
        }
      });
    }
  }

  /**
   * @description function create location with validation
   */
  createLocation(): FormGroup {
    return this.fb.group({
      address: ['', Validators.required],
      lat: ['', Validators.required],
      long: ['', Validators.required]
    });
  }

  /**
   * @description function create location without validation
   */
  createLocationNoValidation(): FormGroup {
    return this.fb.group({
      address: [''],
      lat: [''],
      long: ['']
    });
  }

  /**
   * @description function used for add location in field
   * @param {string} className contain the name of the class
   * @param formFieldControl contain form field control
   */
  addLocations(className: string, formFieldControl): void {
    const contactAddress = formFieldControl as FormArray;
    contactAddress.push(this.createLocation());
    this.multipleAddressAutoComplete(className, formFieldControl['controls']);
  }
  removeLocations(index: number, formFieldControl): void {
    this._dialogService.openConfirm({
      message: CommonConfig.popupAlertMessage.address,
      acceptButton: CommonConfig.dialogService.accept,
      cancelButton: CommonConfig.dialogService.cancel
    })
      .afterClosed().subscribe((accept: boolean) => {
        if (accept) {
          const control = formFieldControl as FormArray;
          control.removeAt(index);
        }
      });
  }
  addDynamicLocations(addressData, formFieldControl) {
    if (addressData && addressData.length > 0) {
      for (const data of addressData) {
        const contactAddress = formFieldControl as FormArray;
        contactAddress.push(this.createLocation());
      }
      const control = formFieldControl as FormArray;
      control.removeAt(addressData.length - 1);
    }
  }
  // end multiple address

  multipleAddressAutoComplete(className: string, formFieldControl) {
    // TODO - need to fix set timeout
    setTimeout(() => {
      const locationElement: Object = document.getElementsByClassName(className);
      for (let i = 0; i < locationElement['length']; i++) {
        // load Places Autocomplete
        this.mapsAPILoader.load().then(() => {
          const autocomplete = new google.maps.places.Autocomplete(locationElement[i], {
            types: ['address']
          });
          autocomplete.addListener('place_changed', () => {
            this.ngZone.run(() => {
              const place: google.maps.places.PlaceResult = autocomplete.getPlace();
              if (!place.geometry) {
                return;
              }
              this.setFormLocationData(formFieldControl[i], place);
            });
          });
        });
      }
    }, this.CommonConfig.delay1000);
  }

  setFormLocationData (formFieldControl, place) {
    if (formFieldControl) {
      if (formFieldControl.get('address')) {
        formFieldControl.get('address').setValue(place.formatted_address);
        formFieldControl.get('lat').setValue(place.geometry.location.lat());
        formFieldControl.get('long').setValue(place.geometry.location.lng());
      } else {
        formFieldControl.get('location').get('address').setValue(place.formatted_address);
        formFieldControl.get('location').get('lat').setValue(place.geometry.location.lat());
        formFieldControl.get('location').get('long').setValue(place.geometry.location.lng());
      }
    }
  }

  addressAutoComplete(searchElementRef: ElementRef, location) {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(searchElementRef.nativeElement, {
        types: ['address']
      });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          location.get('address').setValue(place.formatted_address);
          location.get('lat').setValue(place.geometry.location.lat());
          location.get('long').setValue(place.geometry.location.lng());
        });
      });
    });
  }

  /**
   * @description functions used for get distance between lat and long
   * @param lat1 Latitude location one
   * @param lon1 Longitude location one
   * @param lat2 Latitude location two
   * @param lon2 Longitude location two
   */
  getDistance(lat1, lon1, lat2, lon2, unit) {
    let dist = 0;
    if ((lat1 !== lat2) || (lon1 !== lon2)) {
      const radlat1 = Math.PI * lat1 / this.CommonConfig.oneHundredEighty;
      const radlat2 = Math.PI * lat2 / this.CommonConfig.oneHundredEighty;
      const theta = lon1 - lon2;
      const radtheta = Math.PI * theta / this.CommonConfig.oneHundredEighty;
      dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * this.CommonConfig.oneHundredEighty / Math.PI;
      const distUnitVal = 1.1515;
      dist = dist * this.CommonConfig.sixty * distUnitVal;
      const kUnitValue = 1.609344;
      const nUnitValue = 0.8684;
      if (unit === this.CommonConfig.distanceUnit.K) { dist = dist * kUnitValue; }
      if (unit === this.CommonConfig.distanceUnit.N) { dist = dist * nUnitValue; }
    }
    return dist;
  }

}
