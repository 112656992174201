import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import * as algoliasearch from 'algoliasearch';
import * as algoliasearchHelper from 'algoliasearch-helper';
import * as moment from 'moment';

import { PROJECT_PERMISSION } from '@app/shared/common.constants';
import { CommonConfig } from '@app/shared/common.config';
import { getTime } from 'date-fns';

const commonSearchIndex = environment.algoliya.algolia_index.common_search_index;
let userSearch = environment.algoliya.algolia_index.user_search;
const client = algoliasearch(environment.algoliya.applicationID, environment.algoliya.apiKey);
const index = client.initIndex(commonSearchIndex);

@Injectable({
  providedIn: 'root'
})
export class AlgoliaService {
  private readonly categoryKey = 'category.key';
  private readonly notActiveFalse = 'NOT isActive : false';
  private readonly organizationId = 'organization.id';
  public commonConfig = CommonConfig;
  constructor() { }

  defaultHelperConfigure(algoliaIndices?) {
    if (!algoliaIndices) {
      algoliaIndices = commonSearchIndex;
    }
    const helper = algoliasearchHelper(client, algoliaIndices, {
      facets: ['projectType', 'projectSubscriberType', 'status', 'isArchive', 'type', 'projectAction.isRevised', 'isRevised', 'profileType', 'isClaim', 'isActive',
        'isDeleted', 'isSoftDelete', this.organizationId],
      disjunctiveFacets: [this.categoryKey, 'uid', 'viewBy.userId']
    });
    helper.addFacetRefinement('status', CommonConfig.projectStatus.published);
    helper.addFacetRefinement('isArchive', false);
    helper.addFacetRefinement('isActive', true);
    helper.addFacetRefinement('isSoftDelete', false);
    return helper;
  }

  setSortingOrder(orderCond: string) {
    return new Promise(resolve => {
      index.setSettings({
        'customRanking': [orderCond]
      }, function (err, content) {
        resolve(true);
      });
    });
  }

  /**
   * @description Function used for get project or vendor data by objectid
   * @param {any} that contain the component refrence
   * @param {any} data contain the filter data value
   * @param {Function} callback contain the callback function
   */
  async searchProjects(that, data, callback: Function) {
    if (data.sortBy) {
      await this.setSortingOrder(data.sortBy);
    }
    const filterQuery = '';
    let filterSliceQuery = '';
    const helper = this.defaultHelperConfigure(that.algoliaIndices);
    filterSliceQuery += this.setInviteVendorFilter(that, data, helper, filterQuery);
    filterSliceQuery += this.addCategoryKeywordTypeFilter(that, data, helper, filterQuery);
    this.addRadiusFilter(data, helper);
    this.addOrgFilter(data, helper);
    filterSliceQuery += this.addOldProjectFilter(that, data, filterQuery);
    filterSliceQuery += this.addPublicProjectFilter(that, data, filterQuery);
    filterSliceQuery += this.addBidEndRangeProjectFilter(that, data, filterQuery);

    if (typeof data.status === 'string' && data.status) {
      filterSliceQuery += this.addStatusFilter(that, data, helper, filterQuery);
    }

    if (data.currentPage) {
      filterSliceQuery += this.manageAdminProjectFilter(that, data, helper);
    }

    if (data.starred && data.authUid) {
      filterSliceQuery += ` AND favorites : ${data.authUid}`;
    }

    helper.setQueryParameter('filters', filterSliceQuery);

    helper.setQueryParameter('hitsPerPage', CommonConfig.pageSize);
    if (that.page) {
      helper.setQueryParameter('page', that.page);
    }

    helper.search();
    helper.client.clearCache();

    helper.on('result', function (result: object) {
      callback(null, result);
    });

  }

  manageProjectMultiStatusFilter(that, data, helper) {
    if ((typeof data.projectType === 'object' && data.projectType.length > this.commonConfig.zero) ||
      (typeof data.status === 'object' && data.status.length > this.commonConfig.zero)) {
      return this.setProjectMultiTypeStatusFilter(that, data, helper);
    } else {
      return '';
    }
  }

  /**
   * @description Functions used for set invite vendor filter in search
   * @param {any} that contain the component refrence
   * @param {object} data contain the filter data value
   * @param {object} helper contain the search helper refrence
   */
  setInviteVendorFilter(that, data, helper, filterQuery) {
    if (data.type && data.type === 'vendor') {
      helper.addFacetRefinement('type', data.type);
      helper.addFacetRefinement('isDeleted', false);
    } else {
      helper.addFacetRefinement('type', 'project');
      if (!that.isAdmin) {
        filterQuery += `(permission.projectVisible : ${PROJECT_PERMISSION.PROJECT_PERMISSION_PUBLIC} OR inviteAllVendor : true OR inviteRadius : true)`;
        if (that.uid) {
          filterQuery += ` OR uid : ${that.uid} OR members : ${that.uid}`;
        }
        if (that.isInvitedVendors && that.myVendorId) {
          filterQuery += ` OR invitedVendors : ${that.myVendorId}`;
        }
      }
    }
    return filterQuery;
  }

  /**
   * @description Functions used for set category, keywoard and type filter
   * @param {any} that contain the component refrence
   * @param {object} data contain the filter data value
   * @param {object} helper contain the search helper refrence
   */
  addCategoryKeywordTypeFilter(that, data, helper, filterQuery) {
    let filQuery = '';
    if (data.category && data.category.length) {
      for (const obj of data.category) {
        helper.addDisjunctiveFacetRefinement(this.categoryKey, obj.key);
      }
    }

    if (!that.isAdmin && data.projectType && data.projectType.length > this.commonConfig.zero) {
      let filterSliceQuery = ' AND (';
      data.projectType.forEach(async (projectTypeValue, projectTypeKey) => {
        filterQuery += `projectType : ${projectTypeValue} OR `;
      });
      filterSliceQuery += filterQuery.slice(this.commonConfig.zero, this.commonConfig.minusFour);
      filterSliceQuery += ')';
      filQuery = filterSliceQuery;
    }

    if (that.isAdmin) {
      this.buildQueryForAdmin(helper, that);
    } else {
      helper.setQueryParameter('restrictSearchableAttributes', ['title', 'description']);
    }
    if (data.keyword) {
      helper.setQuery(data.keyword);
      helper.setQueryParameter('optionalWords', data.keyword);
    }
    return filQuery;
  }

  /**
   * @description Functions used for set radious filter
   * @param {object} data contain the filter data value
   * @param {object} helper contain the search helper refrence
   */
  addRadiusFilter(data, helper) {
    if (data.location && data.location.lat && data.location.long) {
      helper.setQueryParameter('aroundLatLng', `${data.location.lat},${data.location.long}`);
      let radious = CommonConfig.mapRadious;
      if (data.radious) {
        radious = Math.round(Number(data.radious));
      }
      helper.setQueryParameter('aroundRadius', radious * CommonConfig.thousand);
    }
  }

  /**
   * @description Functions used for set organization filter
   * @param {object} data contain the filter data value
   * @param {object} helper contain the search helper refrence
   */
  addOrgFilter(data, helper) {
    if (data.orgId) {
      helper.addFacetRefinement(this.organizationId, data.orgId);
    }
  }

  /**
   * @description Functions used for set display old project filter
   * @param {any} that contain the component refrence
   * @param {object} data contain the filter data value
   */
  addOldProjectFilter(that, data, filterQuery) {
    if (!that.isAdmin && (data.type && data.type !== 'vendor')) {
      const nowTimestamp = Date.now();
      if (!data.oldProjects) {
        filterQuery += ` AND (bidEndAt >= ${nowTimestamp})`;
      }
    }
    return filterQuery;
  }

  // Functions used for set display public project filter
  addPublicProjectFilter(that, data, filterQuery) {
    if (!that.isAdmin && (data.type && data.type !== 'vendor')) {
      if (data.publicProjects && data.publicProjects === true) {
        filterQuery += ` AND (projectSubscriberType : ${CommonConfig.projectSubscribeType.public})`;
      } else {
        filterQuery += ' AND NOT ';
        filterQuery += `projectSubscriberType:${CommonConfig.projectSubscribeType.public}`;
      }
    }
    return filterQuery;
  }

  addBidEndRangeProjectFilter(that, data, filterQuery) {
    if (!that.isAdmin && (data.bidEndDateRange && data.bidEndDateRange.startDate && data.bidEndDateRange.endDate) && (data.type && data.type !== 'vendor')) {
      const bidEndStartTmStamp = moment(data.bidEndDateRange.startDate).format('x');
      const bidEndEndTmStamp = moment(data.bidEndDateRange.endDate).format('x');
      filterQuery += ` AND (bidEndAt >= ${bidEndStartTmStamp} AND bidEndAt <= ${bidEndEndTmStamp})`;
    }
    return filterQuery;
  }

  /**
   * @description Functions used for set project subscriber type filter
   * @param {any} that contain the component refrence
   * @param {object} helper contain the search helper refrence
   */
  addProjectSubscriberTypeFilter(that, helper) {
    const allowedPages = [this.commonConfig.routerName.manageprojects, this.commonConfig.routerName.scrapeVendors];
    if (that.isAdmin && allowedPages.includes(that.currentPage)) {
      helper.addFacetRefinement('projectSubscriberType', this.commonConfig.projectSubscribeType.public);
    }
  }

  /**
   * @description Functions used for add status filter
   * @param {any} that contain the component refrence
   * @param {object} data contain the filter data value
   * @param {object} helper contain the search helper refrence
   */
  addStatusFilter(that, data, helper, filterQuery) {
    if (this.isAdminStatusRevision(that, data)) {
      this.addIsRevisedFacet(data, helper);
    } else if (this.isAdminStatusDisable(that, data)) {
      helper.addFacetRefinement('isActive', false);
    } else if (this.isAdminStatusUnclaimed(that, data)) {
      helper.addFacetRefinement('isClaim', false);
      helper.addFacetRefinement('status', CommonConfig.vendorStatus.published);
    } else if (this.isAdminStatusPublished(that, data)) {
      this.addIsClaimStatusFacet(data, helper);
    } else {
      filterQuery += ` AND ${this.notActiveFalse}`;
      helper.addFacetRefinement('status', data.status);
    }
    return filterQuery;
  }

  manageAdminProjectFilter(that, data, helper) {
    let fiterQuery = '';
    fiterQuery += this.manageProjectMultiStatusFilter(that, data, helper);
    if (typeof data.projectSubscriberType === 'object' && data.projectSubscriberType.length === this.commonConfig.zero ||
      typeof data.projectSubscriberType === 'string' && data.projectSubscriberType === '') {
      helper.removeFacetRefinement('projectSubscriberType', this.commonConfig.projectSubscribeType.public);
      if (fiterQuery) {
        fiterQuery += ' AND NOT ';
      } else {
        fiterQuery += ' NOT ';
      }
      fiterQuery += `projectSubscriberType:${CommonConfig.projectSubscribeType.public}`;
    } else {
      if (fiterQuery) {
        fiterQuery += ' AND ';
      } else {
        fiterQuery += ' ';
      }
      fiterQuery += `projectSubscriberType:${CommonConfig.projectSubscribeType.public}`;
    }
    return fiterQuery;
  }

  /**
   * @description Functions used for set multi type nad status filter
   * @param {any} that contain the component refrence
   * @param {object} data contain the filter data value
   * @param {object} helper contain the search helper refrence
   */
  setProjectMultiTypeStatusFilter(that, data, helper) {
    let fiterQuery = '';
    let filterSliceQuery = '(';
    if (typeof data.projectType === 'object'
      && data.projectType.length > this.commonConfig.zero
      && typeof data.status === 'object'
      && data.status.length > this.commonConfig.zero) {
      let fiterQueryStatus = '';
      data.projectType.forEach(async (projectTypeValue, projectTypeKey) => {
        fiterQuery += `projectType : ${projectTypeValue} OR `;
      });
      filterSliceQuery += fiterQuery.slice(this.commonConfig.zero, this.commonConfig.minusFour);
      filterSliceQuery += ') AND (';
      data.status.forEach(async (projectStatusValue, projectStatusKey) => {
        fiterQueryStatus += `status : ${projectStatusValue} OR `;
      });
      filterSliceQuery += fiterQueryStatus.slice(this.commonConfig.zero, this.commonConfig.minusFour);
      filterSliceQuery += ')';
    } else if (typeof data.projectType === 'object' && data.projectType.length > this.commonConfig.zero && typeof data.status === 'object'
      && data.status.length === this.commonConfig.zero) {
      data.projectType.forEach(async (projectTypeValue, projectTypeKey) => {
        fiterQuery += `projectType : ${projectTypeValue} OR `;
      });
      filterSliceQuery += fiterQuery.slice(this.commonConfig.zero, this.commonConfig.minusFour);
      filterSliceQuery += ')';
    } else if (typeof data.status === 'object' && data.status.length > this.commonConfig.zero && typeof data.projectType === 'object'
      && data.projectType.length === this.commonConfig.zero) {
      data.status.forEach(async (projectTypeValue, projectTypeKey) => {
        fiterQuery += `status : ${projectTypeValue} OR `;
      });
      filterSliceQuery += fiterQuery.slice(this.commonConfig.zero, this.commonConfig.minusFour);
      filterSliceQuery += ')';
    }
    return filterSliceQuery;
  }

  /**
   * @description Functions used for check is admin and status disable
   * @param {object} data contain the filter data value
   * @param {object} helper contain the search helper refrence
   */
  isAdminStatusRevision = (that, data) => (data.status === CommonConfig.projectStatus.revision || (that.isAdmin && data.status === CommonConfig.vendorStatus.revision));

  /**
   * @description Functions used for check is admin and status disable
   * @param {object} data contain the filter data value
   * @param {object} helper contain the search helper refrence
   */
  isAdminStatusDisable = (that, data) => (that.isAdmin && data.status === CommonConfig.projectStatus.disable);

  /**
   * @description Functions used for add is revised filter
   * @param {object} data contain the filter data value
   * @param {object} helper contain the search helper refrence
   */
  addIsRevisedFacet(data, helper) {
    helper.setQueryParameter('filters', this.notActiveFalse);
    helper.addFacetRefinement('status', CommonConfig.projectStatus.pending);
    if (data.type && data.type === 'vendor') {
      helper.addFacetRefinement('isRevised', true);
    } else {
      helper.addFacetRefinement('projectAction.isRevised', true);
    }
  }

  /**
   * @description Functions used for check is admin and status published
   * @param {object} data contain the filter data value
   * @param {object} helper contain the search helper refrence
   */
  isAdminStatusPublished = (that, data) => (that.isAdmin && data.status === CommonConfig.vendorStatus.published);

  /**
   * @description Functions used for check is admin and status unclaimed
   * @param {object} data contain the filter data value
   * @param {object} helper contain the search helper refrence
   */
  isAdminStatusUnclaimed = (that, data) => (that.isAdmin && data.status === CommonConfig.vendorStatus.unclaimed);

  addIsClaimStatusFacet(data, helper) {
    helper.setQueryParameter('filters', this.notActiveFalse);
    if (data.type && data.type === 'vendor') {
      helper.addFacetRefinement('isClaim', true);
    }
    helper.addFacetRefinement('status', data.status);
  }

  buildQueryForAdmin(helper, that) {
    helper.setQueryParameter('restrictSearchableAttributes', ['title', 'userInformation.displayName']);
    // fetch published project on admin bid list
    if (that.currentPage && that.currentPage === CommonConfig.routerName.managebids) {
      helper.addFacetRefinement('status', CommonConfig.projectStatus.published);
    } else {
      helper.removeFacetRefinement('status', CommonConfig.projectStatus.published);
      helper.removeFacetRefinement('isArchive', false);
      helper.removeFacetRefinement('isActive', true);
    }
  }

  searchMyProjects(that, data, callback: Function) {
    const helper = this.defaultHelperConfigure();

    helper.addFacetRefinement('type', 'project');

    let fiterQuery = '';
    fiterQuery = this.setViewedByAndOwner(fiterQuery, data);
    fiterQuery = this.setBidByAndMember(fiterQuery, data);

    if (!data.viewed && !data.owner && !data.bidby && !data.member) {
      fiterQuery = `viewby: ${data.uid} OR uid: ${data.uid} OR bidby: ${data.uid} OR members: ${data.uid}`;
    }

    if (data.calendarEvents && data.calendarEvents.startDt && data.calendarEvents.endDt) {
      const strtTimeStamp = getTime(data.calendarEvents.startDt);
      const endTimeStamp = getTime(data.calendarEvents.endDt);
      fiterQuery += ` AND (bidStartAt: ${strtTimeStamp} TO ${endTimeStamp}`;
      fiterQuery += ` OR projectStartAt: ${strtTimeStamp} TO ${endTimeStamp})`;
    }

    helper.setQueryParameter('filters', fiterQuery);
    this.projectStatusFilter(helper, data);
    helper.setQueryParameter('hitsPerPage', CommonConfig.pageSize);
    if (that.page) {
      helper.setQueryParameter('page', that.page);
    }
    helper.search();
    helper.on('result', function (result: object) {
      callback(null, result);
    });
  }

  /**
   * @description Function used for add status filter in project data search
   * @param helper contain algolia helper reference
   * @param data contain the data object
   */
  projectStatusFilter(helper, data) {
    helper.removeFacetRefinement('status', CommonConfig.projectStatus.published);
    if (data.status) {
      helper.addFacetRefinement('status', data.status);
    }
  }

  /**
   * @description function used for set viewed by and project owner filter
   */
  setViewedByAndOwner(fiterQuery, data) {
    if (data.viewed) {
      fiterQuery = `viewby : ${data.uid}`;
    }
    if (data.owner) {
      if (data.viewed) {
        fiterQuery += ' OR ';
      }
      fiterQuery += `uid:${data.uid}`;
    }
    return fiterQuery;
  }

  setBidByAndMember(fiterQuery, data) {
    if (data.bidby) {
      if (data.viewed || data.owner) {
        fiterQuery += ' OR ';
      }
      fiterQuery += `bidby:${data.uid}`;
    }
    if (data.member) {
      if (data.viewed || data.owner || data.bidby) {
        fiterQuery += ' OR ';
      }
      fiterQuery += `members:${data.uid}`;
    }
    return fiterQuery;
  }

  searchHybrid(that, data, callback: Function) {
    const helper = this.defaultHelperConfigure();
    if (data.keyword) {
      helper.setQuery(data.keyword);
      helper.setQueryParameter('optionalWords', data.keyword);
    }
    if (data.category && data.category.length) {
      for (const obj of data.category) {
        helper.addDisjunctiveFacetRefinement(this.categoryKey, obj.key);
      }
    }
    if (data.resultType && data.resultType !== 'all') {
      helper.addFacetRefinement('type', data.resultType);
    }
    this.setLocatonFilterWithMiles(helper, data);
    helper.setQueryParameter('hitsPerPage', CommonConfig.pageSize);
    if (that.page) {
      helper.setQueryParameter('page', that.page);
    }
    helper.search();
    helper.on('result', function (result) {
      callback(null, result);
    });
  }

  /**
   * @description function used for set location filter with miles
   */
  setLocatonFilterWithMiles(helper, data) {
    if (data.location.lat && data.location.long) {
      helper.setQueryParameter('aroundLatLng', `${data.location.lat},${data.location.long}`);
      let radious = CommonConfig.mapRadious;
      if (data.miles) {
        radious = Math.round(Number(data.miles));
      }
      helper.setQueryParameter('aroundRadius', radious * CommonConfig.thousand);
    }
  }

  getAutoSuggest(that, data, callback: Function) {
    const helper = this.defaultHelperConfigure();
    helper.setQueryParameter('restrictSearchableAttributes', ['title', 'productService.title']);
    helper.setQueryParameter('attributesToRetrieve', ['type', 'title', 'name', 'imageUrl']);
    if (data.keyword) {
      helper.setQuery(data.keyword);
      helper.setQueryParameter('optionalWords', data.keyword);
    }
    if (!that.skipPage) {
      helper.setQueryParameter('hitsPerPage', CommonConfig.pageSize);
    }
    helper.search();
    helper.on('result', function (result: object) {
      callback(null, result);
    });
  }

  getUserAutoSuggest(that, data, callback: Function) {
    if (that.algoliaIndices) {
      userSearch = that.algoliaIndices;
    }
    const userHelper = algoliasearchHelper(client, userSearch, {
      facets: ['isVendor', 'isActive', 'isSoftDelete', this.organizationId, 'organization.status', 'organization.request']
    });
    userHelper.addFacetRefinement('isActive', true);
    userHelper.addFacetRefinement('isSoftDelete', false);
    this.setUserOrginizationFilter(userHelper, data);
    if (data.isAdmin) {
      userHelper.removeFacetRefinement('isActive', true);
    }
    userHelper.setQueryParameter('restrictSearchableAttributes', ['displayName', 'email']);
    if (data.keyword) {
      userHelper.setQuery(data.keyword);
      userHelper.setQueryParameter('optionalWords', data.keyword);
    }
    if ((that.currentPage && that.currentPage === CommonConfig.routerName.createvendor) || that.isAddNewVendorMember) {
      userHelper.addFacetRefinement('isVendor', false);
    }
    userHelper.setQueryParameter('hitsPerPage', CommonConfig.pageSize);
    if (that.page) {
      userHelper.setQueryParameter('page', that.page);
    }
    userHelper.search();
    userHelper.on('result', function (result: object) {
      callback(null, result);
    });
  }

  /**
   * @description set organizaiotion filter in user search
   * @param {object} data contain the filter data value
   * @param {object} userHelper contain the user search helper refrence
   */
  setUserOrginizationFilter(userHelper, data) {
    if (data.orgId) {
      userHelper.addFacetRefinement(this.organizationId, data.orgId);
      if (data.memberStatus) {
        userHelper.addFacetRefinement('organization.status', data.memberStatus);
      }
      if (data.requestType) {
        userHelper.addFacetRefinement('organization.request', data.requestType);
      }
    }
  }

  async fetchUnclaimVendors(data, callback: Function) {
     const helper = this.defaultHelperConfigure();
    helper.addFacetRefinement('type', CommonConfig.belongTo.vendor);
    helper.addFacetRefinement('profileType', CommonConfig.vendorProfileType.claim);
    helper.addFacetRefinement('isClaim', false);

    let fiterQuery = '';

    if (data.taxId) {
      fiterQuery = `taxId : ${data.taxId} OR `;
    }
    if (data.name) {
      fiterQuery += `title : "${data.name}"`;
    }
    if (data.website) {
      fiterQuery += ` OR website : "${data.website}"`;
    }
    fiterQuery = this.setPhoneFilter(fiterQuery, data);
    fiterQuery = this.setEmailFilter(fiterQuery, data);
    fiterQuery = this.setContactAddressFilter(fiterQuery, data);

    if (fiterQuery !== '') {
      helper.setQueryParameter('filters', fiterQuery);
    }
    helper.search();
    helper.client.clearCache();
    helper.on('result', function (result: object) {
      callback(null, result);
    });
  }

  /**
   * @description Function used for filter vendor data by email and phone number
   * @param {any} that contain the component refrence
   * @param {any} data contain the filter data value
   * @param {Function} callback contain the callback function
   */
  findVendors(data, callback: Function) {
    const helper = this.defaultHelperConfigure();
    let fiterQuery = '';
    fiterQuery = this.setPhoneFilter(fiterQuery, data);
    fiterQuery = this.setEmailFilter(fiterQuery, data);
    helper.setQueryParameter('filters', fiterQuery);
    helper.search();
    helper.client.clearCache();
    helper.on('result', function (result: object) {
      callback(null, result);
    });
  }

  /**
   * @description Function used for filter vendor data by phone number
   * @param {string} fiterQuery contain the filter query string
   * @param {any} data contain the filter data value
   */
  setPhoneFilter(fiterQuery: string, data) {
    if (data.phoneNumbers && data.phoneNumbers.length) {
      for (const obj of data.phoneNumbers) {
        if (obj.phone) {
          if (fiterQuery) {
            fiterQuery += ` OR `;
          }
          fiterQuery += `phoneNumbers.phone : ${obj.phone}`;
        }
      }
    }
    return fiterQuery;
  }

  /**
   * @description Function used for set vendor data filter by email
   * @param {string} fiterQuery contain the filter query string
   * @param {any} data contain the filter data value
   */
  setEmailFilter(fiterQuery: string, data) {
    if (data.contactEmails && data.contactEmails.length) {
      for (const obj of data.contactEmails) {
        if (obj.email) {
          if (fiterQuery) {
            fiterQuery += ` OR `;
          }
          fiterQuery += `contactEmails.email : ${obj.email}`;
        }
      }
    }
    return fiterQuery;
  }

  /**
   * @description Function used for set vendor data filter by contact address
   * @param {string} fiterQuery contain the filter query string
   * @param {any} data contain the filter data value
   */
  setContactAddressFilter(fiterQuery: string, data) {
    if (data.contactAddress && data.contactAddress.length) {
      for (const obj of data.contactAddress) {
        if (obj.lat && obj.long) {
          if (fiterQuery) {
            fiterQuery += ` OR `;
          }
          fiterQuery += `contactAddress.address : "${obj.address}"`;
        }
      }
    }
    return fiterQuery;
  }

  /**
   * @description Function used for get total number of records
   * @param {any} that contain the component refrence
   * @param {any} data contain the filter data value
   * @param {Function} callback contain the callback function
   */
  getTotalRecords(that, data, callback: Function) {
    const filterQuery = '';
    let filterSliceQuery = '';
    const helper = this.defaultHelperConfigure();
    filterSliceQuery += this.setInviteVendorFilter(that, data, helper, filterQuery);
    filterSliceQuery += this.addCategoryKeywordTypeFilter(that, data, helper, filterQuery);
    this.addRadiusFilter(data, helper);

    if (data['isClaimStatus'] && data['isClaimStatus'] !== '') {
      helper.addFacetRefinement('isClaim', data['isClaim']);
    }

    helper.setQueryParameter('filters', filterSliceQuery);
    if (data.isVendorData) {
      helper.setQueryParameter('hitsPerPage', CommonConfig.thousand);
      helper.setQueryParameter('page', data.page);
    } else {
      helper.setQueryParameter('page', CommonConfig.zero);
    }
    helper.search();
    helper.client.clearCache();
    helper.on('result', function (result: object) {
      callback(null, result);
    });
  }

  getUnclaimedVendorCount(filterDataObject) {
    filterDataObject['isUnclaimVendor'] = true;
    filterDataObject['isClaimStatus'] = 'unclaimed';
    filterDataObject['isClaim'] = false;
    return this.getInvitedVendorCount(filterDataObject);
  }

  getClaimedVendorCount(filterDataObject) {
    filterDataObject['isClaimVendor'] = true;
    filterDataObject['isClaimStatus'] = 'claimed';
    filterDataObject['isClaim'] = true;
    return this.getInvitedVendorCount(filterDataObject);
  }

  getInvitedVendorCount(filterDataObject, isVendorData = false) {
    return new Promise<number>((resolve) => {
      this.getTotalRecords(this, filterDataObject, (err, result) => {
        if (result.hits) {
          if (isVendorData) {
            resolve(result);
          } else {
            resolve(result.nbHits);
          }
        } else {
          resolve(this.commonConfig.zero);
        }
      });
    });
  }

}
