import { Component, OnInit } from '@angular/core';
import { TdDialogService } from '@covalent/core/dialogs';
// Config
import { CommonConfig } from '@shared/common.config';
import { calendarIcsStatus, calendarInviteSubject, icsMethods } from '@shared/common.constants';
// Model
import { ProjectWalkthrough } from '@app/core/model/project-walkthrough';
import { Project } from '@app/core/model/project';
// Service
import { CustomService } from '@shared/service/custom/custom.service';
import { ProjectsService } from '@shared/service/projects/projects.service';
import { ProjectActivityService } from '@shared/service/project-activity/project-activity.service';

@Component({
  selector: 'app-walkthroughmodal',
  templateUrl: './walkthroughmodal.component.html',
  styleUrls: ['./walkthroughmodal.component.scss']
})
export class WalkthroughmodalComponent implements OnInit {

  constructor(
    private readonly _dialogService: TdDialogService,
    private readonly customService: CustomService,
    public projectsService: ProjectsService,
    private readonly projectActivityService: ProjectActivityService
    ) { }
  data: Partial<ProjectWalkthrough>;
  walkthroughId: string;
  vendorId: string;
  dialogRef;
  projects: Project;
  authUid: string;
  public dateFrom: string;
  ngOnInit() {
    const dateRef: Object = this.data;
    this.dateFrom = dateRef['from'].toDate();
  }

  async confirmBooking() {
    this.walkthroughId = (this.data && this.data['id']) ? this.data['id'] : '';
    if (this.vendorId !== '') {
      const isBooked = await this.projectActivityService.booklWalkthroughScheduler(this.walkthroughId, this.vendorId);
      if (isBooked) {
        const activityDataObject = { uid: this.authUid, projectId: this.data.projectId,
          activityType: CommonConfig.projectActivityType.walkthroughBooked, walkthroughId: this.walkthroughId,
          walkthroughTime: this.data.from };
        this.projectsService.saveProjectActivity(activityDataObject);
        this.customService.showSnackBarSuccess(CommonConfig.successMessage.scheduleBooked);
        this.sendCalendarNotification();
        this.sendMailAndWebNotification();
      } else {
        this.customService.showSnackBarError(CommonConfig.validationMessage.someError);
      }
      this.dialogRef.close();
    } else {
      this._dialogService.openAlert({
        message: CommonConfig.popupAlertMessage.walkthroughSchedule
      });
    }
  }

  sendMailAndWebNotification() {
    let allUsers: Array<Object> = [];
    if (this.projects.members) {
      allUsers = allUsers.concat(this.projects.members);
    }
    allUsers.push(this.projects.uid);
    const requireData = {
      authUid: this.authUid,
      action: CommonConfig.walkthroughStatus.confirmed,
      vendorId: this.vendorId,
      allUsers
    };

    this.projectActivityService.sendMailAndWebNotification(this.projects, requireData);
  }

  /**
   * @description send calendar invite notification mail.
   */
  async sendCalendarNotification() {
    const walkthroughData = this.data;
    const requireData = {
      authUid: this.authUid,
      status: calendarIcsStatus.confirmed,
      method: icsMethods.publish,
      sequence: 0,
      vendorId: this.vendorId,
      projects: this.projects,
      subject: calendarInviteSubject.addEvent
    };
    this.projectActivityService.sendIcsCalendarNotification(walkthroughData, requireData);
  }
}
