import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';


@Injectable({
  providedIn: 'root'
})

export class TaskService {

  constructor(private db: AngularFireDatabase) { }

  performIn(time: number, worker: string, opts = {}) {
    const queueRef = this.db.list('tasks');

    time = Date.now() + time;
    queueRef.push({ time, worker, opts });
  }

}
