import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { CommonConfig } from '@shared/common.config';
import { OrganizationConfig } from '@app/shared/organization.config';
import { AlgoliaService } from '@shared/service/algolia/algolia.service';
import { FirestoreService } from '@shared/service/firestore/firestore.service';
import { AuthService } from '@app/core/auth.service';
import { MessagingService } from '@shared/service/messaging/messaging.service';
import { VendorsService } from '@shared/service/vendors/vendors.service';
import { CustomService } from '@shared/service/custom/custom.service';
import { environment } from 'environments/environment';
@Component({
  selector: 'app-select-user',
  templateUrl: './select-multiple-user.component.html',
  styleUrls: ['./select-multiple-user.component.scss']
})
export class SelectMultipleUserComponent implements OnInit {

  currentUser;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredUsers;
  users = [];
  allUsers = [];

  commonConfig = CommonConfig;
  eventType;
  suggestData = [];
  searchInputCtrl = new FormControl();
  userData = {};
  selectedUsers = [];
  dialogRef;
  vendorsToBeStarred;
  parentObject;
  isOpen = false;
  heading = 'Select Users';
  public organizationConfig = OrganizationConfig;
  public orgId = '';
  public memberStatus = this.organizationConfig.orgMemberStatus.approved;

  constructor(private readonly _algoliya: AlgoliaService,
    private readonly fs: FirestoreService,
    public auth: AuthService,
    private readonly messagingService: MessagingService,
    private readonly vendorsService: VendorsService,
    private readonly customService: CustomService) {
    const authSubscribe = this.auth.user.subscribe(res => {
      this.currentUser = res;
      authSubscribe.unsubscribe();
    });
  }

  ngOnInit() {
    this.searchInputCtrl.valueChanges.pipe(debounceTime(CommonConfig.delay300)).subscribe((value) => {
      if (value) {
        this.hitAutoSuggest(value);
      } else {
        this.suggestData = [];
      }
    });
  }

  hitAutoSuggest(keywordValue: string) {
    const keyword = { keyword: keywordValue, orgId: this.orgId, memberStatus: this.memberStatus };
    this.suggestData = [];
    if (this.isOpen) {
      this._algoliya.getUserAutoSuggest(this, keyword, (err, result) => {
        if (result.hits) {
          this.suggestData = [...this.suggestData, ...result.hits];
        }
      });
    }
    this.isOpen = true;
  }

  selectedSuggest(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value && !(this.userExists(event.option.value['id']))) {
      this.userData = event.option.value;
      this.userData['uid'] = this.userData['id'];
      this.userData['photoUrl'] = this.userData['profilePhoto'] ? this.userData['profilePhoto'] : '';
      this.selectedUsers.push(this.userData);
    }
    this.searchInputCtrl.setValue(null);
  }

  userExists(uid: string) {
    return this.selectedUsers.some(function (el) {
      return el.uid === uid;
    });
  }

  remove(userId) {
    const index = this.selectedUsers.findIndex((x) => x.uid === userId);
    if (index >= 0) {
      this.selectedUsers.splice(index, 1);
    }
  }

  trackByFn(index: number) {
    return index;
  }

  async continue() {
    if (this.vendorsToBeStarred.length && this.selectedUsers.length) {
      this.starUnstarVendorsForSelectedUsers();
      this.uncheckVenors();
    }
    if (this.eventType === this.commonConfig.vendorEventType.star) {
      this.customService.showSnackBarSuccess(CommonConfig.successMessage.vendorsStarred);
    } else {
      this.customService.showSnackBarSuccess(CommonConfig.successMessage.vendorsUnStarred);
    }
    this.dialogRef.close();
  }

  uncheckVenors() {
    this.parentObject.vendors.map(v => {
      v.is_selected && (v.is_selected = false);
      return v;
    });
  }

  starUnstarVendorsForSelectedUsers() {
    for (const user of this.selectedUsers) {
      const vendorCount = { count: 0 };
      for (const vendor of this.vendorsToBeStarred) {
        if (vendor.status === this.commonConfig.vendorStatus.published && vendor.uid !== user.uid) {
          switch (this.eventType) {
            case this.commonConfig.vendorEventType.star:
                this.starVendorsForSelectedUsers(vendor, user, vendorCount);
                break;
            case this.commonConfig.vendorEventType.unstar:
                this.unstarVendorsForSelectedUsers(vendor, user, vendorCount);
          }
        }
      }
      if (vendorCount.count) {
        this.sendNotificationToUser(user);
        this.sendEmailToUser(user, vendorCount.count);
      }
      this.parentObject.selectedVendors = [];
      this.parentObject.selectedIds = [];
    }
  }

  starVendorsForSelectedUsers(vendor, user, vendorCount) {
    if (!vendor.favorites || !vendor.favorites.includes(user.uid)) {
      const dataObject = { favorites: this.fs.firebaseFirestoreFieldValue().arrayUnion(user.uid) };
      this.updateParentObject(vendor, user.uid);
      this.fs.update(`${this.commonConfig.collectionName.vendors}/${vendor.id}`, dataObject);
      vendorCount.count++;
    }
  }

  async unstarVendorsForSelectedUsers(vendor, user, vendorCount) {
    if (vendor.favorites && vendor.favorites.includes(user.uid)) {
      const dataObject = { favorites: this.fs.firebaseFirestoreFieldValue().arrayRemove(user.uid) };
      this.updateParentObject(vendor, user.uid);
      this.fs.update(`${this.commonConfig.collectionName.vendors}/${vendor.id}`, dataObject);
      vendorCount.count++;
    }
  }
  updateParentObject(vendor, uid) {
    this.parentObject.vendors.map(v => {
      if (v.id === vendor.id) {
        (!v.favorites) && (v.favorites = []);
        switch (this.eventType) {
          case this.commonConfig.vendorEventType.star:
            v.favorites.push(uid);
            break;
          case this.commonConfig.vendorEventType.unstar:
            const index = v.favorites.indexOf(uid);
            v.favorites.splice(index, 1);
        }
      }
      return v;
    });
  }

  sendNotificationToUser(user) {
    const category = this.commonConfig.notificationActivityType.vendorActivity;
    let activityType = this.commonConfig.vendorActivityType.vendorStarred;
    (this.eventType === this.commonConfig.vendorEventType.unstar) && (activityType = this.commonConfig.vendorActivityType.vendorUnStarred);
    const sendBy = this.currentUser.uid;
    const id = user.uid;
    const readFlag = false;
    const forAdmin = false;
    const isActive = true;
    const payloadData = { id, category, userId: user.uid, activityType, sendBy, readFlag, forAdmin, isActive };
    this.currentUser.organization && (payloadData['orgId'] = this.currentUser.organization.id);
    this.messagingService.saveNotificationPayload(payloadData);
  }

  sendEmailToUser = async (user, vendorToBeMarkedFavCount) => {
    const userName = user['displayName'] ? user['displayName'] : user['email'];
    let templateId = this.commonConfig.emailTemplates.starVendorsByAdmin;
    let subject = this.commonConfig.emailSubject.starVendorsByAdmin;
    if (this.eventType === this.commonConfig.vendorEventType.unstar) {
      templateId = this.commonConfig.emailTemplates.unStarVendorsByAdmin;
      subject = this.commonConfig.emailSubject.unStarVendorsByAdmin;
    }
    const urlLink = `${environment.baseUrl}/vendors/my-vendor`;
    const data = {
      toEmail: user['email'],
      userName,
      subject,
      urlLink,
      templateId,
      vendorCount: vendorToBeMarkedFavCount
    };
    this.vendorsService.sendEmail(data);
  }

  clearUserText($event) {
    $event.target.value = '';
  }
}
